
footer {
    .footer-logo{
        img {
            max-height: 36px;
        }
    }
    a{
        color: $white;
        &:hover, &:active{
            color: #C982F7;
        }
        &:focus {
            color: $white;
        } 
    }
    ul{
        padding-left: 0;
        list-style: none;
        padding-left: 10px;
        li {
            margin-bottom: 10px;
            a {
                text-shadow: 0px 2px 6px #000000A5;
                text-decoration: none;
                position: relative;
                transition-duration: 0.5s;
                -moz-transition-duration: 0.5s;
                -webkit-transition-duration: 0.5s;
                -o-transition-duration: 0.5s;
                &::before {
                    content: '';
                    position: absolute;
                    background-color: $green;
                    width: 4px;
                    height: 115%;
                    top: 0;
                    left: -11px;
                    max-width: 100%;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-top{
        ul{
            li{
                &.media{
                    margin-bottom: 25px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    p {
        margin: 15px 0 15px;
        @media(max-width:767px){
            flex-direction: column;
        }
        @media(min-width:768px){
            justify-content: flex-end;
        }
    }
}
  
.elements-social{
    .large-icon {
        li {
            padding-right: 11%;
            a {
                font-size: 20px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-shadow: none;
                &::before {
                    content: '';
                    position: relative;
                    background-color: transparent;
                    width: 3px;
                    height: 115%;
                    top: 0;
                    left: 0px;
                }
            }
        }
    }
}
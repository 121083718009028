/* ===================================
    05. Custom
====================================== */

section {
    padding: 130px 0;
    overflow: hidden;
    &.extra-big-section-bottom {
        padding: 100px 0 200px;
    }
    &.big-section {
        padding: 120px 0;
    }
  
    &.extra-big-section {
        padding: 200px 0;
    }
  
    &.half-section {
        padding: 80px 0;
    }
    &.half-section-no-bottom {
        padding-top: 80px;
        padding-bottom: 0;
    }
    &.small-section {
        padding: 50px 0;
    }
    &.smaller-section {
        padding: 40px 0;
    }
    &.extra-small-section {
        padding: 50px 0;
    }
    &.big-section-top-2 {
        padding: 140px 0 60px;
        overflow: inherit;
    }
    &.no-section {
        padding: 0;
    }
    &.general-banner{
        padding-top: 160px;
        padding-bottom: 75px;
    }
    &.steps-section{
        padding: 0;
        @media(min-width:768px){
            padding: 75px 0;
        }
    }
    &.faqs-section{
        padding: 90px 0;
    }
    &.politics-section{
        padding: 130px 0;
    }
}

.flex-container {
    display: flex;
    flex-wrap: nowrap;
      @media (max-width: 500px) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    & > div {
        width: 6.8%;
        text-align: center;
        line-height: 75px;
        font-size: 30px;
        @media (max-width: 500px) {
            width: 10%;
        }
        &:nth-of-type(even) {
            width: 40%;
            background-color: #EFEFEF;
            @media (max-width: 500px) {
                width: 90%;
            }
            img {
                @media (max-width: 500px) {
                    max-width: 50px;
                }
            }
            .process-step-style-04 {
                position: relative;
                min-width: 275px;
            }
        }
        &:last-child {
            @media (max-width: 500px) {
            display: none;
            }
        }
    }
}
  
.flex-container-big {
    // img{
    //     min-width: 100%;
    //     min-height: 100%;
    // }
    .why-content{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media(min-width:768px){
            min-height: 200px;
            padding: 0 100px;
            &:before{
                content: url('../images/decoration-elements/graf-banner-why.svg');
                position: absolute;
                left: -1px;
                top: 0;
                height: 100%;
                width: auto;
            }
            &:after{
                content: url('../images/decoration-elements/graf-banner-why-revert.svg');
                position: absolute;
                right: -1px;
                top: 0;
                height: 100%;
                width: auto;
            }

        }
    }
}

/* absolute middle center */

.absolute-middle-center {
    left: 50%;
    top: 50%;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  
    &.move-top-bottom, &.move-bottom-top {
        margin-left: -10px;
        margin-top: -10px;
    }
}

.absolute-left-middle-center {
    &.bg-green{
        left: 25%;
    }
    left: 35%;
    top: 50%;
    position: absolute;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  
    &.move-top-bottom, &.move-bottom-top {
        margin-left: -10px;
        margin-top: -10px;
    }
    .move-to-bottom {
        @media (min-width: 992px) {
            margin-top: 55%;
        }
        @media (min-width: 1200px) {
            margin-top: 45%;
        }
    }
    .max-w {
        max-width: 65%;
        @media (max-width: 991px) {
            max-width: 100%;
        }
    }
    @media (max-width: 991px) {
        left: 0%;
        top: 0%;
        position: relative;
        -ms-transform: translateX(0%) translateY(0%);
        -moz-transform: translateX(0%) translateY(0%);
        -webkit-transform: translateX(0%) translateY(0%);
        transform: translateX(0%) translateY(0%);
        &.bg-green{
            left: 25%;
            position: absolute;
            -ms-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }
    }
    @media (max-width:767px) {
        &.bg-green{
            left: 20%;
        }
    }
}

.absolute-left-middle-work-it {
    left: 15%;
    top: 70%;
    position: absolute;
    -webkit-transform: translateX(-15%) translateY(-54%);
    transform: translateX(-15%) translateY(-54%);
    .title-large-2{
        @media(max-width:1199px){
            font-size: 35px;
            line-height: 40px;
        }
        @media(max-width:575px){
            font-size: 29px;
            line-height: 35px;
        }
    }
    &.move-top-bottom, &.move-bottom-top {
        margin-left: -10px;
        margin-top: -10px;
    }
    .move-to-bottom {
            margin-top: 30%;
            @media (max-width: 1199px) {
                margin-top: 10%;
        }
    }
    .max-w {
        max-width: 55%;
        @media (max-width: 991px) {
            max-width: 100%;
        }
    }
    @media (max-width: 991px) {
        left: 0%;
        top: 0%;
        position: relative;
        -ms-transform: translateX(0%) translateY(0%);
        -moz-transform: translateX(0%) translateY(0%);
        -webkit-transform: translateX(0%) translateY(0%);
        transform: translateX(0%) translateY(0%);
    }
    @media (min-width: 992px) and (max-width: 1199px) {
        position: absolute;
        -webkit-transform: translateX(-10%) translateY(-34%);
        transform: translateX(-10%) translateY(-34%);
    }
}

/* box shadow */
.box-shadow, .box-shadow-hover:hover {
    box-shadow: 0px 3px 6px #00000029;
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.box-shadow-small, .box-shadow-small-hover:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.box-shadow-medium, .box-shadow-medium-hover:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.box-shadow-large, .box-shadow-large-hover:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}

.box-shadow-very-light-hover:hover, .box-shadow-light-hover:hover, .box-shadow-dark-hover:hover, .box-shadow-large-hover:hover, .box-shadow-extra-large-hover:hover, .box-shadow-medium-hover:hover, .box-shadow-small-hover:hover, .box-shadow-double-large-hover:hover {
    -moz-transform: translate3d(0, -3px, 0);
    -o-transform: translate3d(0, -3px, 0);
    -ms-transform: translate3d(0, -3px, 0);
    -webkit-transform: translate3d(0, -3px, 0);
    transform: translate3d(0, -3px, 0);
  }

.box-shadow-double-large, .box-shadow-double-large-hover:hover {
    box-shadow: 0 0 35px rgba(40, 42, 54, 0.18);
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
.box-sahdow-iconTitleHor{
    box-shadow: 0px 3px 6px #00000029;
}
/* opacity */
.opacity-medium {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.75;
    top: 0;
    left: 0;
}

/* border radius */
.border-radius-0-px{
    border-radius: 0px !important;
}

/*Responsive*/
@media (max-width: 1199px) {
    /* reset */
    section { 
        padding: 90px 0;
        &.big-section { 
            padding:120px 0; 
        }
        &.extra-big-section { 
            padding:150px 0 240px; 
        }
        &.mission{
            padding: 130px 0;
        }
        &.section-404{
            padding: 130px 0;
        }
    }
}

@media (max-width: 991px) {
    /* reset */
    section{
        padding: 75px 0;
        &.half-section { 
            padding:40px 0; 
            
        }
        &.half-section-no-bottom {
            padding-top: 40px;
            padding-bottom: 0;
        }
        &.extra-big-section { 
            padding: 110px 0 40px; 
        }
        &.big-section { 
            padding:95px 0; 
        }
        &.mission{
            padding: 100px 0;
        }
        &.politics-section{
            padding: 110px 0;
        }
        &.big-section-top-2 {
            padding: 120px 0 20px;
            overflow: inherit;
        }
    }
}

@media (max-width: 767px) {
    /* reset */
    section{
        padding: 90px 0 50px;
        &.big-section{
            padding:80px 0;
        }
        &.mission{
            padding-top: 50px;
            padding-bottom: 0;
        }
        &.extra-big-section { 
            padding: 90px 0 30px; 
        }
        &.small-section {
            padding: 35px 0;
        }
        &.general-banner{
            padding-bottom: 0px;
            margin-top: 70px;
            .bg-white{
                margin-top: -70px;
            }
        }
        &.half-section {
            padding: 30px 0;
        }
    }
}

@media screen and (max-width: 575px) {
    /* reset */
    section{
        padding: 40px 0;
        &.big-section { 
            padding:70px 0; 
        }
        &.extra-small-section {
            padding: 25px 0;
        }
        &.big-section-top-2 {
            padding-top: 40px;
            padding-bottom: 0px;
        }
        &.stack-section{
            padding: 15px 0;
        }
        &.extra-big-section { 
            padding: 90px 0 20px; 
        }
        &.faqs-section{
            padding: 90px 0 30px;
        }
        &.half-section {
            padding: 20px 0;
        }
        &.politics-section{
            padding: 100px 0;
        }
    }
}

/*General Bnner*/
.banner-text{
    position: relative;
    @media(min-width:992px){
        &:after{
            content: url('../images/decoration-elements/graf-banner-text.svg');
            position: absolute;
            right: 0px;
            bottom: -85px;
        }
    }
    @media(min-width:1200px){
        &:after{
            content: url('../images/decoration-elements/graf-banner-text.svg');
            position: absolute;
            right: -65px;
            bottom: -85px;
        }
    }
}
.banner-no-text{
    position: relative;
    @media(min-width:992px){
        &:after{
            content: url('../images/decoration-elements/graf-banner-no-text.svg');
            position: absolute;
            right: 0px;
            bottom: -85px;
        }
    }
    @media(min-width:1200px){
        &:after{
            content: url('../images/decoration-elements/graf-banner-no-text.svg');
            position: absolute;
            right: -65px;
            bottom: -85px;
        }
    }
}


@mixin font-size($value: 16){
    font-size: $value + px;
    font-size: $value / 10 + rem;
}

@mixin line-height($value: 22){
    line-height: $value + px;
    line-height:($value / 10) + rem;
}

@mixin bg-image($path-webp, $path-png, $selector){
    .webp {
        #{$selector}{
            background-image: url($path-webp);
        }
    }
    .no-webp {
        #{$selector}{
            background-image: url($path-png);
        }
    }
}

@mixin width-fit-content{
    width: fit-content;
    width: -moz-fit-content; //Firefox
    display: table; //IE
}



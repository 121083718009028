.CookieConsent{
    &::before{
        content: "";
        position: absolute;
        top: -5px;
        left: -5px;
        z-index: -1;
        height: 5px;
        width: 100%;
        margin: 5px;
        border-radius: inherit;
        background: linear-gradient(90deg,#00ffc0,#4d00f0);
    }
    p{
        line-height: 1.3;
        a{
            &:hover, &:active{
                color: #C982F7;
            }
            &:focus{
                color: $white;
            }
        }
    }
    .button-group{
        display:flex;
        flex-direction: column;
        margin-right: 20px;
        @media(max-width:575px){
            margin: auto;
            #rcc-decline-button{
                margin: 0 !important;
                padding-right: 0 !important;   
            }
            #rcc-confirm-button{
                padding-right: 0 !important;
                margin-right: 0 !important;
            }
        }
    }
    .cookies-content{
        margin-left: 20px;
    }
}
.text-decoration-line-thick{
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 75px;
        height: 4px;
        background: $green;
        left: 0;
        top: -50%;
        transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        z-index: -1;
    }
}
p{
    &.text-decoration-line-thick{
        &:before {
            top: -20px;
        }
    }
}
.rightElement{
    float: right;
    margin-top: 30px;
    @media(max-width:767px){
        display: none;
    }
}
/* ===================================
    03. Typography
====================================== */

/* heading size */
  
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 25px 0;
    padding: 0;
    letter-spacing: -0.5px;
    font-weight: inherit;
}
  
h1 {
    font-size: 5rem;
    line-height: 5rem;
}
  
h2 {
    font-size: 3.9rem;
    line-height: 4.2rem;
}
  
h3 {
    font-size: 3.4rem;
    line-height: 3.9rem;
}
  
h4 {
    font-size: 2.85rem;
    line-height: 3.4rem;
}

h5 {
    font-size: 2.30rem;
    line-height: 3rem;
}

h6 {
    font-size: 1.80rem;
    line-height: 2.5rem;
}

/* text size */

.font-size-0 {
    font-size: 0;
}
  
.text-very-small {
    font-size: 11px;
    line-height: 14px;
}
  
.text-extra-small {
    font-size: 12px;
    line-height: 16px;
}

.text-small {
    font-size: 13px;
    line-height: 22px;
}

.text-medium {
    font-size: 14px;
    line-height: 20px;
}

.text-extra-medium {
    font-size: 16px !important;
    line-height: 28px !important;
    &.stack{
        @media(max-width: 991px){
            font-size: 14px !important;
            line-height: 22px !important;
        }
        @media(max-width: 576px){
            font-size: 10px !important;
            line-height: 18px !important;
        }
    }
    &.culture{
        @media(max-width: 576px){
            font-size: 12px !important;
            line-height: 20px !important;
        }
    }
}

.text-large {
    font-size: 18px;
    line-height: 28px;
}

.text-politics{
    font-size: 14px;
    line-height: 24px;
    .green{
        &:hover{
            color: $green ;
            text-decoration: underline;
        }
    }
    @media(min-width: 992px){
        font-size: 16px;
        line-height: 26px;
    }
}

.text-extra-large {
    font-size: 20px;
    line-height: 28px;
    &.text-uppercase{
        @media(max-width:991px){
            font-size: 17px;
            line-height: 25px;
        }
        @media(max-width:767px){
            font-size: 15px;
            line-height: 23px;
        }
    }
}

.title-extra-small {
    font-size: 25px;
    line-height: 35px;
    @media(max-width:991px){
        font-size: 23px;
        line-height: 33px;
    }
}

.title-extra-extra-small {
    font-size: 25px;
    line-height: 35px;
}

.title-small {
    font-size: 36px;
    line-height: 46px;
    @media(max-width:991px){
        font-size: 30px;
        line-height: 40px;
    }
    @media(max-width:767px){
        &.text-uppercase{
            font-size: 28px;
            line-height: 40px;
        }
    }
    @media(max-width:575px){
        &.text-uppercase{
            font-size: 20px;
            line-height: 32px;
        }
        font-size: 26px;
        line-height: 36px;
    }
}

.title-large {
    font-size: 100px;
    line-height: 95px;
}

.title-large-2 {
    font-size: 40px;
    line-height: 50px;
    @media (max-width: 991px) {
        font-size: 30px;
        line-height: 40px;
    }
    @media (max-width: 767px) {
        font-size: 26px;
        line-height: 32px;
    }
    @media (max-width: 575px) {
        font-size: 22px;
        line-height: 28px;
    }
    &.text-uppercase{
        @media (max-width: 767px) {
            font-size: 28px;
            line-height: 40px;
        }
    }
}
.title-large-4 {
    font-size: 50px;
    line-height: 65px;
    @media (max-width: 991px) {
        font-size: 40px;
        line-height: 55px;
    }
    @media(max-width:767px){
        font-size: 35px;
        line-height: 50px;
    }
}
  
.title-large-6 {
    font-size: 63px;
    line-height: 80px;
}
  
.title-large-3 {
    font-size: 35px;
    line-height: 60px;
    @media (max-width: 991px) {
        font-size: 30px;
        line-height: 55px;
    }
    &.text-uppercase{
        @media (max-width: 767px) {
            font-size: 26px;
            line-height: 40px;
        }
    }
}
  
.title-extra-large {
    font-size: 130px;
    line-height: 120px;
}
  
.title-extra-large-heavy {
    font-size: 11rem;
    line-height: 11rem;
}
  
.text-big {
    font-size: 200px;
    line-height: 200px;
}
  
.text-extra-big {
    font-size: 230px;
    line-height: 230px;
}
  
.text-extra-big-2 {
    font-size: 300px;
    line-height: 260px;
}
  
.parallax-title {
    padding: 16px 35px;
    display: inline-block;
}

/* font weight */

.font-weight-100 {
    font-weight: 100 !important;
}

.font-weight-200 {
    font-weight: 200 !important;
}

.font-weight-300 {
    font-weight: 300 !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}

.font-weight-700 {
    font-weight: 700 !important;
}

.font-weight-800 {
    font-weight: 800 !important;
}

.font-weight-900 {
    font-weight: 900 !important;
}

/* lineheight */

.line-height-0px {
  line-height: 0px;
}

.line-height-8px {
  line-height: 8px;
}

.line-height-10px {
  line-height: 10px;
}

.line-height-12px {
  line-height: 12px;
}

.line-height-14px {
  line-height: 14px;
}

.line-height-15px {
  line-height: 15px;
}

.line-height-16px {
  line-height: 16px;
}

.line-height-18px {
  line-height: 18px;
}

.line-height-20px {
  line-height: 20px;
}

.line-height-22px {
  line-height: 22px;
}

.line-height-24px {
  line-height: 24px;
}

.line-height-26px {
  line-height: 26px;
}

.line-height-28px {
  line-height: 28px;
}

.line-height-30px {
  line-height: 30px;
}

.line-height-32px {
  line-height: 32px;
}

.line-height-34px {
  line-height: 34px;
}

.line-height-36px {
  line-height: 36px;
}

.line-height-38px {
  line-height: 38px;
}

.line-height-40px {
  line-height: 40px;
}

.line-height-42px {
  line-height: 42px;
}

.line-height-44px {
  line-height: 44px;
}

.line-height-46px {
  line-height: 46px;
}

.line-height-50px {
  line-height: 50px;
}

.line-height-55px {
  line-height: 55px;
}

.line-height-60px {
  line-height: 60px;
}

.line-height-65px {
  line-height: 65px;
}

.line-height-70px {
  line-height: 70px;
}

.line-height-75px {
  line-height: 75px;
}

.line-height-80px {
  line-height: 80px;
}

.line-height-85px {
  line-height: 85px;
}

.line-height-90px {
  line-height: 90px;
}

.line-height-95px {
  line-height: 95px;
}

.line-height-100px {
  line-height: 100px;
}

.line-height-120px {
  line-height: 120px;
}

.line-height-140px {
  line-height: 140px;
}

.line-height-160px {
  line-height: 160px;
}

.line-height-170px {
  line-height: 170px;
}

/* text stroke */
.text-border-neon {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #00FFC2;
}

/* text stroke width */
.text-border-width-1px {
    -webkit-text-stroke-width: 1px;
}
  
.text-border-width-2px {
    -webkit-text-stroke-width: 2px;
}
  
.text-border-width-3px {
    -webkit-text-stroke-width: 3px;
}
  
.text-border-width-4px {
    -webkit-text-stroke-width: 4px;
}
  
.text-border-width-5px {
    -webkit-text-stroke-width: 5px;
}

.text-shadow-1{
    text-shadow: 0px 2px 6px #000000A6;
}
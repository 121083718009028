@keyframes video-icon-sonar {
    0% {
      opacity: 1;
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
    }
  
    100% {
      opacity: 0;
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
}

@-webkit-keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.flex-container-big-cta {
    display: flex;
    flex-wrap: nowrap;
        @media (max-width: 767px) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: nowrap;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            justify-content: center;
            .text-bg-cta, .title-large-3{
                text-align: center;
            }
            .feature-box-content{
                padding-top: 0;
            }
            
    }
    & > div {
        width: 8%;
        text-align: center;
        line-height: 65px;
        font-size: 30px;
        position: relative;
        img {
            margin: 0;
            left: 1rem;
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            max-height: 100%;
            @media (max-width: 500px) {
                position: relative;
                left: 2rem;
            }
        }
        .title-large-3{
            flex-shrink: 0;
        }
        @media (max-width: 767px) {
            width: 100%;
            text-align: left;
            line-height: 50px;
        }
        &:nth-of-type(even) {
            width: 92%;
            @media (max-width: 500px) {
                width: 100%;
            }
            img {
                @media (max-width: 500px) {
                    max-width: 50px;
                }
            }
        }
        .text-bg-cta {
            font-size: 30px;
            font-weight: 600;
            span {
                background-color: #fff;
                padding: 10px;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;
                line-height: 10px;
                width: 100%;
              
            }
            @media(max-width:991px){
                font-size: 25px;
            }
            @media(max-width: 767px){
                font-size: 20px;
                span {
                    padding: 8px;
                    line-height: 8px;
                }
            }
        }
    }
    .feature-box-hover {
        .feature-box {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: left;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: left;
            -ms-flex-pack: center;
            justify-content: flex-start;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: row;
            text-align: left;
            z-index: 1;
            position: relative;
            transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -ms-transition-duration: 0.4s;
            flex-wrap: wrap;
            align-content: stretch;
        }
        &:hover {  
            .feature-box {
                background-color: transparent!important;
                .text-blue-neo {
                    color: #4D00F2!important;
                }
            }
        }
    }
    
    .process-step-item {
        display: block;
        position: relative;
        float: left;
        padding: 0;
        z-index: 1;
       // max-width: 92%;
    }
    
}
.cta-btn{
    cursor: pointer;
    &.mission-video{
        .process-step-number {
            width: 45px;
            height: 45px;
            .play-cta {
                width: 15px;
                height: 15px;
                left: 15px;
                top: 15px !important;
            }
        }
    }
    .process-step-number {
        opacity: 1;
        width: 80px;
        height:80px;
        display: block;
        flex-shrink: 0;
        border-radius: 50%;
        box-shadow: 0px 3px 6px #00000029;
        @media(max-width: 991px){
            width: 60px;
            height:60px;
        }
        .play-cta {
            margin: 0;
            position: relative;
            width: 30px;
            height:30px;
            z-index: 10;
            left: 30px;
            top: 25px;
            @media(max-width: 991px){
                width: 20px;
                height:20px;
                left: 22px;
                top: 15px;
            }
        }
        .process-step-number-bfr {
            width: 110px;
            height:110px;
            border-radius: 50%;
            opacity: 0;
            flex-shrink: 0;
            margin-left: -15px;
            margin-top: -45px;
            z-index: 0;
            display: block;
            position: relative;
            @media(max-width: 992px){
                width:90px;
                height:90px;
                margin-left: -15px;
                margin-top: -45px;
            }     
        }
    }
    &:hover{
        .process-step-number-bfr {
            opacity: 0.6;
        }
    }
}
.languages{
    opacity: 0;
    height: 220px;
    width: 65px;
    border-radius: 0 0 50px 50px;
    background-color: rgba(35, 35, 35, 0.75);
    position: absolute;
    top:-250px;
    z-index: 1;
    transform: translateX(-25%);
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -ms-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
    @media(min-width:768px){
        height: 250px;
        width: 80px;
    }
    &.active{
        opacity: 1;
        top:-25px;
    }
    button{
        width: 35px;
        height: 35px;
        top: 70px;
        z-index: 10;
        margin-top: 10px;
        position: relative;
        cursor: pointer;
        background: $white;
        color: $purple;
        border: 2.5px solid transparent;
        -webkit-transition: .6s;
        transition: .6s;
        font-size: 12px;
        text-align: center;
        border-radius: 50%;
        font-weight: 800;
        &:hover, &.active{
            background: $green;
        }
        @media(min-width:768px){
            width: 40px;
            height: 40px;
            font-size: 14px;
        }
    }
}




/* feature box */
.feature-box {
    * {
        transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -ms-transition-duration: 0.4s;
    }
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    z-index: 1;
    position: relative;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -ms-transition-duration: 0.4s;
  
    .feature-box-content {
        max-width: 100%;
    }
    img{
        position: relative;
        z-index: 5;
    }
    &.verticalLine {
        @media(min-width:768px){
            border-left: 40px solid #00FFC2;
        }
    }
}

.feature-box-left-icon-middle {
    flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: left;
  
    .feature-box-content {
        overflow: hidden;
        -webkit-box-flex: 1;
        flex: 1;
    }
}

.feature-box {
    &.text-left {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
  
    &.text-right {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
  
    &.feature-box-shadow:hover, .feature-box-shadow:hover {
        border-color: transparent !important;
        -moz-transform: translate3d(0, -3px, 0);
        -o-transform: translate3d(0, -3px, 0);
        -ms-transform: translate3d(0, -3px, 0);
        -webkit-transform: translate3d(0, -3px, 0);
        transform: translate3d(0, -3px, 0);
    }
  
    &.feature-box-shadow {
        .feature-box-overlay {
            box-shadow: 0px 3px 6px #00000029;
            opacity: 0;
        }
        &:hover .feature-box-overlay {
            opacity: 1;
        }
    }
}

.feature-box {
    &:hover .feature-box-overlay {
        opacity: 1;
    }
  
    &.feature-box-dark-hover:hover {
        .feature-box-icon, i, span, p, .btn, a, h1, h2, h3, h4, h5, h6 {
            color: #ffffff !important;
        }
        .btn {
            border-color: #ffffff !important;
        }
    
        [class^="text-gradient"], [class*=" text-gradient"] {
            color: #ffffff !important;
            background-image: none;
            -webkit-background-clip: inherit;
            -webkit-text-fill-color: inherit;
        }
    }
  
    &.feature-box-light-hover:hover {
        .feature-box-icon, i, span, p, .btn, a, h1, h2, h3, h4, h5, h6 {
            color: #232323 !important;
        }
        .btn {
            border-color: #232323 !important;
        }
  
        [class^="text-gradient"], [class*=" text-gradient"] {
            color: #232323 !important;
            background-image: none;
            -webkit-background-clip: inherit;
            -webkit-text-fill-color: inherit;
        }
        p{
            opacity: .65;
        }
    }
  
    &.feature-box-dark-hover:hover p {
        opacity: .65;
    }
}

.feature-box {
    &:hover {
        .feature-box-icon-hover {
            opacity: 1;
        }
        .light i {
            color: #232323;
        }
        .dark i {
            color: #ffffff;
        }
    }
  
    &.feature-box-show-hover {
        .feature-box-move-bottom-top {
            width: 100%;
            -webkit-transform: translateY(20px);
            -moz-transform: translateY(20px);
            -ms-transform: translateY(20px);
            -o-transform: translateY(20px);
            transform: translateY(20px);
            overflow: hidden;
        }
  
      &:hover .feature-box-move-bottom-top {
            -webkit-transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
            -o-transform: translateY(-5px);
            transform: translateY(-5px);
        }
  
        .move-bottom-top {
            opacity: 0;
            -webkit-transform: translateY(20px);
            -moz-transform: translateY(20px);
            -ms-transform: translateY(20px);
            -o-transform: translateY(20px);
            transform: translateY(20px);
        }
  
        &:hover .move-bottom-top {
            opacity: 1;
            -webkit-transform: translateY(0px);
            -moz-transform: translateY(0px);
            -ms-transform: translateY(0px);
            -o-transform: translateY(0px);
            transform: translateY(0px);
        }
    }
  
    &.feature-box-hide-show-hover {
        .feature-box-move-bottom-top {
            width: 100%;
            -webkit-transform: translateY(30px);
            -moz-transform: translateY(30px);
            -ms-transform: translateY(30px);
            -o-transform: translateY(30px);
            transform: translateY(30px);
            overflow: hidden;
        }
  
        &:hover .feature-box-move-bottom-top {
            -webkit-transform: translateY(-30px);
            -moz-transform: translateY(-30px);
            -ms-transform: translateY(-30px);
            -o-transform: translateY(-30px);
            transform: translateY(-30px);
        }
  
        .move-bottom-top {
            opacity: 0;
            -webkit-transform: translateY(20px);
            -moz-transform: translateY(20px);
            -ms-transform: translateY(20px);
            -o-transform: translateY(20px);
            transform: translateY(20px);
        }
  
        &:hover {
            .move-bottom-top {
                opacity: 1;
                -webkit-transform: translateY(0px);
                -moz-transform: translateY(0px);
                -ms-transform: translateY(0px);
                -o-transform: translateY(0px);
                transform: translateY(0px);
            }
    
            .feature-box-icon {
                opacity: 0;
            }
        }
    }
}

    .feature-box-hover {
        .process-step-style-04  {
            .process-step-icon {
                position: absolute;
                margin-bottom: 0;
                line-height: 1;
                top: -5px;
                right: -72px;
                left: auto;
                font-weight: 700;
                margin-left: 0;
                width: 36px;
                height: 36px;
                text-align: center;
            }
            .process-step-item {
                display: block;
                position: relative;
                float: left;
                padding: 0;
                z-index: 1;
            }
        }
        .feature-box {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: left;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: left;
            -ms-flex-pack: center;
            justify-content: space-around;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: row;
            text-align: left;
            z-index: 1;
            position: relative;
            transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -ms-transition-duration: 0.4s;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-line-pack: stretch;
            align-content: stretch;
        }
        &:hover {  
            .feature-box {
                background-color: $purple!important;
                h3 {
                    color: #ffffff!important;
                }
            }
        }
    }



.accordion-item-faqs{
    position:  relative !important;
    box-shadow: none !important;
    margin-bottom: 35px !important;
    @media(max-width:767px){
        margin-bottom: 15px !important;  
    }
    &:after{
        content:"";
        display: block;
        width: 40px;
        background: $green;
        height: 100%;
        position: absolute !important;
        top: 0;
        left: 0;
        @media(max-width:767px){
            width: 25px;
        }
    }
    .MuiAccordionSummary-expandIconWrapper{
        svg{
            fill: $pink !important;
            font-size: 2.5rem;
        }
    }
}
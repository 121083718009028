/* hamburger menu */

.hamburger-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 50%;
    height: 100%;
    z-index: 1031;
    overflow-y: auto;
    -webkit-transition-duration: 0.6s;
    -moz-transition-duration: 0.6s;
    -ms-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
  
    &.full-width {
        width: 100%;
    }
    &.show-menu{
        right: 0;
    }
}
  
.menu-list-wrapper {
    overflow: auto;
    height: calc(100vh - 200px);  
}

.menu-list {  
    li {
        clear: both;
        position: relative;
        list-style: none;

        a, .accordion-button {
            font-size: 18px;
            line-height: 28px;
            position: relative;
            color: #fff;
            padding: 10px 0;
            display: inline-block;
            background-color: transparent !important;
            border: 0;
            i {
                margin-right: 10px;
                vertical-align: middle;
            }
            p{
                margin-bottom: 0;
            }
        }
        a {
            &.active{
                color: $pink !important;
            }
            @media (max-width: 767px) {
                max-width: 75%;        
            }
        }
        &:hover, &:focus {
            a{
                color: $pink !important;
                font-size: 19px;
            }
            svg{
                max-width: 18px;
                max-height: 24px;
                fill: $pink !important;
            }
        }
        
        .accordion-header{
            margin: 0;
            .accordion-button{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding-right: 10px;
                &.collapsed{
                    .menu-toggle{
                        &:after{
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }
    }
    .menu-list-item{
        .sub-menu-item {
            li{
                a {
                    font-size: 13px;
                    line-height: normal;
                    color: #fff;
                    padding: 0;
                }
            } 
            position: relative;
            width: 100%;
            background-color: transparent;
            padding-left: 0px;
      
            .sub-menu-item {
                padding-left: 15px;
                li {
                    &:first-child {
                        margin-top: 8px;
                    }
            
                    &:last-child {
                        margin-bottom: 10px;
                    }
            
                    a {
                        color: #888888;
                    }
                }
                li.active {
                    a {
                        color: rgba(255, 255, 255, 0.5) !important;
                    }
    
                    > .sub-menu-item li {
                        &.active a {
                            color: rgba(255, 255, 255, 0.3) !important;
                        }
    
                        a {
                            &:hover, &:focus {
                                color: rgba(255, 255, 255, 0.3) !important;
                            }
                        }
                    }
                }
    
                > li {
                    &:hover a, &:focus a {
                        color: rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
        }
    }
} 
.menu-toggle {
    position: absolute;
    right: 25px;
    width: 30px;
    height: 30px;
    text-align: center;
    top: 30px;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    z-index: 1;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -ms-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    svg {
        max-width: 15px;
        max-height:21px;
    }
}
    
.sub-menu-item{
    .menu-toggle {
        width: 20px;
        height: 20px;
        top: 15px;
        &:before, &:after {
            right: 9px;
            top: 10px;
            height: 1px;
            width: 9px;
            background: rgba(255, 255, 255, 0.5);
        }
    }
} 

/* header push menu close button */
.close-menu {
    cursor: pointer;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
    @media(min-width:768px){
        height: 50px;
        width: 50px;
        right: 20px;
        top: 20px;
    }
  
}

.menu-list-wrapper {
    height: 100%;
    overflow-y: auto;
    //padding: 75px 0;
}

.show-menu {
    overflow: hidden;
}

/* Social icons */
.social-icon-style-12 {  
    .light {
        a {
            color: $purple;
            display: inline-block;
            background: $white;
            border-radius: 50%;
            box-shadow: 0px 3px 6px #00000029;
            &:hover{
                color: $white;
                background: $pink;
            }
        }
    }
}
.elements-social{
    .small-icon{
        //padding-left: 55px;
        padding-left: 25px;
        // @media(min-width:1600px){
        //     padding-left: 40px;
        // }
        a {
            font-size: 16px;
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
    } 
}

/* RESPONSIVE */

@media (max-width: 991px) {
    /* hamburger menu half */
    .hamburger-menu-half{
        .menu-list{
            li {
                // .menu-toggle { 
                //     top: 29px; 
                // }
                ul{
                    li{
                        .menu-toggle { 
                            top: 15px; 
                        }
                    }
                }
            }
            .menu-list-item{
                >.sub-menu-item { 
                    padding-top: 5px;
                }
                .sub-menu-item{
                    .sub-menu-item { 
                        padding-top: 0
                    }
                } 
            }
        } 
        .sub-menu-item{
            .menu-toggle{
                &:before{
                    right: 8px;
                }
                &:after{
                    right: 8px;
                }
            }
        } 
    } 
}

@media (max-width: 767px) {
    /* elements social icon page */
    .elements-social{
        .small-icon{
            li {
                margin-bottom: 20px;
            }
        } 
    } 

    .hamburger-menu-half{
        .menu-list{
            li {
                .menu-toggle { 
                    top: 24px; 
                }
            }
        }
    }
}

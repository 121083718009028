/*MODAL*/
.modal-dialog{
    margin:0;
    position: fixed;
    top: 30%;
    left: 0;
    width: 100% !important;
    background-color: $purple;
    color: $white;
    a {
        color: $white;
        &:hover {
            color: $green !important;
        }
    }
    @media (max-width: 575px) {
        max-width: 100%;
        width: 100%;
        left: 0;
    }
    @media (min-width: 576px) and (max-width: 991px) {
        max-width: 80%;
        width: 80%;
        left: 10%;
    }
    @media (min-width: 992px) {
        max-width: 50%;
        width: 50%;
        left: 25%;
        top: 40%;
    }
}

.modal-content{
    padding: 35px;
    background-color: $purple;
}

.btn-close {
    width: 25px;
    height: 25px;
    display: block;
    float: right;
    position: absolute;
    cursor: pointer;
    top: 16px;
    right: 16px;
    &::before {
        content: "";
        width: 25px;
        height: 4px;
        margin-top: 11px;
        display: block;
        background: $white;
        border-radius: 5px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &:after {
        content: "";
        width: 25px;
        height: 4px;
        display: block;
        background: $white;
        border-radius: 5px;
        margin-top: -5px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}
/* ===================================
    12. Display and overflow
====================================== */

.d-initial {
    display: initial !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-auto {
    overflow: auto !important;
}

@media (max-width: 1600px) {
    /* display and overflow */
    .xl-d-initial { display: initial !important; }
    .xl-overflow-hidden { overflow:hidden !important; }
    .xl-overflow-visible { overflow:visible !important; }
    .xl-overflow-auto { overflow:auto !important; }
}
@media (max-width: 1199px) {
    /* display and overflow */
    .lg-d-initial { display: initial !important; }
    .lg-overflow-hidden { overflow:hidden !important; }
    .lg-overflow-visible { overflow:visible !important; }
    .lg-overflow-auto { overflow:auto !important; }
}
@media (max-width: 991px) {
    /* display and overflow */
    .md-d-initial { display: initial !important; }
    .md-overflow-hidden { overflow:hidden !important; }
    .md-overflow-visible { overflow:visible !important; }
    .md-overflow-auto { overflow:auto !important; }
}

@media (max-width: 767px) {
    /* display and overflow */
    .sm-d-initial { display: initial !important; }
    .sm-overflow-hidden { overflow:hidden !important; }
    .sm-overflow-visible { overflow:visible !important; }
    .sm-overflow-auto { overflow:auto !important; }
}

@media screen and (max-width: 575px) {
    /* display and overflow */
    .xs-d-initial { display: initial !important; }
    .xs-overflow-hidden { overflow:hidden !important; }
    .xs-overflow-visible { overflow:visible !important; }
    .xs-overflow-auto { overflow:auto !important; }
}

/* ===================================
    13. Position
====================================== */

.position-inherit {
    position: inherit !important;
}
  
  /* top */
  
.top-0px {
    top: 0;
}
  
.top-1px {
    top: 1px;
}
  
.top-2px {
    top: 2px;
}
  
.top-3px {
    top: 3px;
}
  
.top-4px {
    top: 4px;
}
  
.top-5px {
    top: 5px;
}
  
.top-6px {
    top: 6px;
}
  
.top-7px {
    top: 7px;
}
  
.top-8px {
    top: 8px;
}
  
.top-9px {
    top: 9px;
}
  
.top-10px {
    top: 10px;
}
  
.top-15px {
    top: 15px;
}
  
.top-20px {
    top: 20px;
}
  
.top-25px {
    top: 25px;
}
  
.top-30px {
    top: 30px;
}
  
.top-35px {
    top: 35px;
}
  
.top-40px {
    top: 40px;
}
  
.top-45px {
    top: 45px;
}
  
.top-50px {
    top: 50px;
}
  
.top-60px {
    top: 60px;
}
  
.top-70px {
    top: 70px;
}
  
.top-80px {
    top: 80px;
}
  
.top-90px {
    top: 90px;
}
  
.top-100px {
    top: 100px;
}
  
.top-auto {
    top: auto;
}
  
.top-inherit {
    top: inherit;
}
  
  /* top minus */
  
.top-minus-1px {
    top: -1px;
}
  
.top-minus-2px {
    top: -2px;
}
  
.top-minus-3px {
    top: -3px;
}
  
.top-minus-4px {
    top: -4px;
}
  
.top-minus-5px {
    top: -5px;
}
  
.top-minus-6px {
    top: -6px;
}
  
.top-minus-7px {
    top: -7px;
}
  
.top-minus-8px {
    top: -8px;
}
  
.top-minus-9px {
    top: -9px;
}
  
.top-minus-10px {
    top: -10px;
}
  
.top-minus-15px {
    top: -15px;
}
  
.top-minus-20px {
    top: -20px;
}
  
.top-minus-25px {
    top: -25px;
}
  
.top-minus-30px {
    top: -30px;
}
  
.top-minus-35px {
    top: -35px;
}
  
.top-minus-40px {
    top: -40px;
}
  
.top-minus-45px {
    top: -45px;
}
  
.top-minus-50px {
    top: -50px;
}
  
.top-minus-60px {
    top: -60px;
}
  
.top-minus-70px {
    top: -70px;
}
  
  /* bottom */
  
.bottom-0px {
    bottom: 0;
}
  
.bottom-1px {
    bottom: 1px;
}
  
.bottom-2px {
    bottom: 2px;
}
  
.bottom-3px {
    bottom: 3px;
}
  
.bottom-4px {
    bottom: 4px;
}
  
.bottom-5px {
    bottom: 5px;
}
  
.bottom-6px {
    bottom: 6px;
}
  
.bottom-7px {
    bottom: 7px;
}
  
.bottom-8px {
    bottom: 8px;
}
  
.bottom-9px {
    bottom: 9px;
}
  
.bottom-10px {
    bottom: 10px;
}
  
.bottom-15px {
    bottom: 15px;
}
  
.bottom-20px {
    bottom: 20px;
}
  
.bottom-25px {
    bottom: 25px;
}
  
.bottom-30px {
    bottom: 30px;
}
  
.bottom-35px {
    bottom: 35px;
}
  
.bottom-40px {
    bottom: 40px;
}
  
.bottom-45px {
    bottom: 45px;
}
  
.bottom-50px {
    bottom: 50px;
}
  
.bottom-55px {
    bottom: 55px;
}
  
.bottom-60px {
    bottom: 60px;
}
  
.bottom-65px {
    bottom: 65px;
}
  
.bottom-70px {
    bottom: 70px;
}
  
.bottom-75px {
    bottom: 75px;
}
  
.bottom-80px {
    bottom: 80px;
}
  
.bottom-85px {
    bottom: 85px;
}
  
.bottom-90px {
    bottom: 90px;
}
  
.bottom-95px {
    bottom: 95px;
}
  
.bottom-100px {
    bottom: 100px;
}
  
.bottom-auto {
    bottom: auto;
}
  
.bottom-inherit {
    bottom: inherit;
}
  
  /* bottom minus */
  
.bottom-minus-1px {
    bottom: -1px;
}
  
.bottom-minus-2px {
    bottom: -2px;
}
  
.bottom-minus-3px {
    bottom: -3px;
}
  
.bottom-minus-4px {
    bottom: -4px;
}
  
.bottom-minus-5px {
    bottom: -5px;
}
  
.bottom-minus-6px {
    bottom: -6px;
}
  
.bottom-minus-7px {
    bottom: -7px;
}
  
.bottom-minus-8px {
    bottom: -8px;
}
  
.bottom-minus-9px {
    bottom: -9px;
}
  
.bottom-minus-10px {
    bottom: -10px;
}
  
.bottom-minus-15px {
    bottom: -15px;
}
  
.bottom-minus-20px {
    bottom: -20px;
}
  
.bottom-minus-25px {
    bottom: -25px;
}
  
.bottom-minus-30px {
    bottom: -30px;
}
  
.bottom-minus-35px {
    bottom: -35px;
}
  
.bottom-minus-40px {
    bottom: -40px;
}
  
.bottom-minus-45px {
    bottom: -45px;
}
  
.bottom-minus-50px {
    bottom: -50px;
}
  
  /* right */
  
.right-0px {
    right: 0;
}
  
.right-1px {
    right: 1px;
}
  
.right-2px {
    right: 2px;
}
  
.right-3px {
    right: 3px;
}
  
.right-4px {
    right: 4px;
}
  
.right-5px {
    right: 5px;
}
  
.right-6px {
    right: 6px;
}
  
.right-7px {
    right: 7px;
}
  
.right-8px {
    right: 8px;
}
  
.right-9px {
    right: 9px;
}
  
.right-10px {
    right: 10px;
}
  
.right-15px {
    right: 15px;
}
  
.right-20px {
    right: 20px;
}
  
.right-25px {
    right: 25px;
}
  
.right-30px {
    right: 30px;
}
  
.right-35px {
    right: 35px;
}
  
.right-40px {
    right: 40px;
}
  
.right-45px {
    right: 45px;
}
  
.right-50px {
    right: 50px;
}
  
.right-55px {
    right: 55px;
}
  
.right-60px {
    right: 60px;
}
  
.right-auto {
    right: auto;
}
  
.right-inherit {
    right: inherit;
}
  
  /* right minus */
  
.right-minus-1px {
    right: -1px;
}
  
.right-minus-2px {
    right: -2px;
}
  
.right-minus-3px {
    right: -3px;
}
  
.right-minus-4px {
    right: -4px;
}
  
.right-minus-5px {
    right: -5px;
}
  
.right-minus-6px {
    right: -6px;
}
  
.right-minus-7px {
    right: -7px;
}
  
.right-minus-8px {
    right: -8px;
}
  
.right-minus-9px {
    right: -9px;
}
  
.right-minus-10px {
    right: -10px;
}
  
.right-minus-15px {
    right: -15px;
}
  
.right-minus-20px {
    right: -20px;
}
  
.right-minus-25px {
    right: -25px;
}
  
.right-minus-30px {
    right: -30px;
}
  
.right-minus-35px {
    right: -35px;
}
  
.right-minus-40px {
    right: -40px;
}
  
.right-minus-45px {
    right: -45px;
}
  
.right-minus-50px {
    right: -50px;
}
  
.right-minus-60px {
    right: -60px;
}
  
.right-minus-70px {
    right: -70px;
}
  
  /* left */
  
.left-0px {
    left: 0;
}
  
.left-1px {
    left: 1px;
}
  
.left-2px {
    left: 2px;
}
  
.left-3px {
    left: 3px;
}
  
.left-4px {
    left: 4px;
}
  
.left-5px {
    left: 5px;
}
  
.left-6px {
    left: 6px;
}
  
.left-7px {
    left: 7px;
}
  
.left-8px {
    left: 8px;
}
  
.left-9px {
    left: 9px;
}
  
.left-10px {
    left: 10px;
}
  
.left-15px {
    left: 15px;
}
  
.left-20px {
    left: 20px;
}
  
.left-25px {
    left: 25px;
}
  
.left-30px {
    left: 30px;
}
  
.left-35px {
    left: 35px;
}
  
.left-40px {
    left: 40px;
}
  
.left-45px {
    left: 45px;
}
  
.left-50px {
    left: 50px;
}
  
.left-auto {
    left: auto;
}
  
.left-inherit {
    left: inherit;
}
  
  /* left minus */
  
.left-0px {
    left: 0;
}
  
.left-minus-1px {
    left: -1px;
}
  
.left-minus-2px {
    left: -2px;
}
  
.left-minus-3px {
    left: -3px;
}
  
.left-minus-4px {
    left: -4px;
}
  
.left-minus-5px {
    left: -5px;
}
  
.left-minus-6px {
    left: -6px;
}
  
.left-minus-7px {
    left: -7px;
}
  
.left-minus-8px {
    left: -8px;
}
  
.left-minus-9px {
    left: -9px;
}
  
.left-minus-10px {
    left: -10px;
}
  
.left-minus-15px {
    left: -15px;
}
  
.left-minus-20px {
    left: -20px;
}
  
.left-minus-25px {
    left: -25px;
}
  
.left-minus-30px {
    left: -30px;
}
  
.left-minus-35px {
    left: -35px;
}
  
.left-minus-40px {
    left: -40px;
}
  
.left-minus-45px {
    left: -45px;
}
  
.left-minus-50px {
    left: -50px;
}


/*RESPONSIVE*/

@media(max-width: 1600px){
    /* position */
    .xl-position-relative { position: relative !important; }
    .xl-position-absolute { position: absolute !important; }
    .xl-position-fixed { position: fixed !important; }
    .xl-position-inherit { position: inherit !important; }
    .xl-position-initial { position: initial !important; }

    /* top */
    .xl-top-0px { top: 0; }
    .xl-top-1px { top: 1px; }
    .xl-top-2px { top: 2px; }
    .xl-top-3px { top: 3px; }
    .xl-top-4px { top: 4px; }
    .xl-top-5px { top: 5px; }
    .xl-top-6px { top: 6px; }
    .xl-top-7px { top: 7px; }
    .xl-top-8px { top: 8px; }
    .xl-top-9px { top: 9px; }
    .xl-top-10px { top: 10px; }
    .xl-top-15px { top: 15px; }
    .xl-top-20px { top: 20px; }
    .xl-top-25px { top: 25px; }
    .xl-top-30px { top: 30px; }
    .xl-top-35px { top: 35px; }
    .xl-top-40px { top: 40px; }
    .xl-top-45px { top: 45px; }
    .xl-top-50px { top: 50px; }
    .xl-top-auto { top:auto; }
    .xl-top-inherit { top:inherit; }

    /* top minus */
    .xl-top-minus-1px { top: -1px; }
    .xl-top-minus-2px { top: -2px; }
    .xl-top-minus-3px { top: -3px; }
    .xl-top-minus-4px { top: -4px; }
    .xl-top-minus-5px { top: -5px; }
    .xl-top-minus-6px { top: -6px; }
    .xl-top-minus-7px { top: -7px; }
    .xl-top-minus-8px { top: -8px; }
    .xl-top-minus-9px { top: -9px; }
    .xl-top-minus-10px { top: -10px; }
    .xl-top-minus-15px { top: -15px; }
    .xl-top-minus-20px { top: -20px; }
    .xl-top-minus-25px { top: -25px; }
    .xl-top-minus-30px { top: -30px; }
    .xl-top-minus-35px { top: -35px; }
    .xl-top-minus-40px { top: -40px; }
    .xl-top-minus-45px { top: -45px; }
    .xl-top-minus-50px { top: -50px; }

    /* bottom */
    .xl-bottom-0px { bottom:0; }
    .xl-bottom-1px { bottom:1px; }
    .xl-bottom-2px { bottom:2px; }
    .xl-bottom-3px { bottom:3px; }
    .xl-bottom-4px { bottom:4px; }
    .xl-bottom-5px { bottom:5px; }
    .xl-bottom-6px { bottom:6px; }
    .xl-bottom-7px { bottom:7px; }
    .xl-bottom-8px { bottom:8px; }
    .xl-bottom-9px { bottom:9px; }
    .xl-bottom-10px { bottom:10px; }
    .xl-bottom-15px { bottom:15px; }
    .xl-bottom-20px { bottom:20px; }
    .xl-bottom-25px { bottom:25px; }
    .xl-bottom-30px { bottom:30px; }
    .xl-bottom-35px { bottom:35px; }
    .xl-bottom-40px { bottom:40px; }
    .xl-bottom-45px { bottom:45px; }
    .xl-bottom-50px { bottom:50px; }
    .xl-bottom-55px { bottom:55px; }
    .xl-bottom-60px { bottom:60px; }
    .xl-bottom-auto { bottom: auto; }
    .xl-bottom-inherit { bottom: inherit; }

    /* bottom minus */
    .xl-bottom-minus-1px { bottom: -1px; }
    .xl-bottom-minus-2px { bottom: -2px; }
    .xl-bottom-minus-3px { bottom: -3px; }
    .xl-bottom-minus-4px { bottom: -4px; }
    .xl-bottom-minus-5px { bottom: -5px; }
    .xl-bottom-minus-6px { bottom: -6px; }
    .xl-bottom-minus-7px { bottom: -7px; }
    .xl-bottom-minus-8px { bottom: -8px; }
    .xl-bottom-minus-9px { bottom: -9px; }
    .xl-bottom-minus-10px { bottom: -10px; }
    .xl-bottom-minus-15px { bottom: -15px; }
    .xl-bottom-minus-20px { bottom: -20px; }
    .xl-bottom-minus-25px { bottom: -25px; }
    .xl-bottom-minus-30px { bottom: -30px; }
    .xl-bottom-minus-35px { bottom: -35px; }
    .xl-bottom-minus-40px { bottom: -40px; }
    .xl-bottom-minus-45px { bottom: -45px; }
    .xl-bottom-minus-50px { bottom: -50px; }

    /* right */
    .xl-right-0px { right: 0; }
    .xl-right-1px { right: 1px; }
    .xl-right-2px { right: 2px; }
    .xl-right-3px { right: 3px; }
    .xl-right-4px { right: 4px; }
    .xl-right-5px { right: 5px; }
    .xl-right-6px { right: 6px; }
    .xl-right-7px { right: 7px; }
    .xl-right-8px { right: 8px; }
    .xl-right-9px { right: 9px; }
    .xl-right-10px { right: 10px; }
    .xl-right-15px { right: 15px; }
    .xl-right-20px { right: 20px; }
    .xl-right-25px { right: 25px; }
    .xl-right-30px { right: 30px; }
    .xl-right-35px { right: 35px; }
    .xl-right-40px { right: 40px; }
    .xl-right-45px { right: 45px; }
    .xl-right-50px { right: 50px; }
    .xl-right-auto { right: auto; }
    .xl-right-inherit { right: inherit; }

    /* right minus */
    .xl-right-minus-1px { right: -1px; }
    .xl-right-minus-2px { right: -2px; }
    .xl-right-minus-3px { right: -3px; }
    .xl-right-minus-4px { right: -4px; }
    .xl-right-minus-5px { right: -5px; }
    .xl-right-minus-6px { right: -6px; }
    .xl-right-minus-7px { right: -7px; }
    .xl-right-minus-8px { right: -8px; }
    .xl-right-minus-9px { right: -9px; }
    .xl-right-minus-10px { right: -10px; }
    .xl-right-minus-15px { right: -15px; }
    .xl-right-minus-20px { right: -20px; }
    .xl-right-minus-25px { right: -25px; }
    .xl-right-minus-30px { right: -30px; }
    .xl-right-minus-35px { right: -35px; }
    .xl-right-minus-40px { right: -40px; }
    .xl-right-minus-45px { right: -45px; }
    .xl-right-minus-50px { right: -50px; }

    /* left */
    .xl-left-0px { left: 0; }
    .xl-left-1px { left: 1px; }
    .xl-left-2px { left: 2px; }
    .xl-left-3px { left: 3px; }
    .xl-left-4px { left: 4px; }
    .xl-left-5px { left: 5px; }
    .xl-left-6px { left: 6px; }
    .xl-left-7px { left: 7px; }
    .xl-left-8px { left: 8px; }
    .xl-left-9px { left: 9px; }
    .xl-left-10px { left: 10px; }
    .xl-left-15px { left: 15px; }
    .xl-left-20px { left: 20px; }
    .xl-left-25px { left: 25px; }
    .xl-left-30px { left: 30px; }
    .xl-left-35px { left: 35px; }
    .xl-left-40px { left: 40px; }
    .xl-left-45px { left: 45px; }
    .xl-left-50px { left: 50px; }
    .xl-left-55px { left: 55px; }
    .xl-left-60px { left: 60px; }
    .xl-left-auto { left: auto; }
    .xl-left-inherit { left: inherit; }

    /* left minus */
    .xl-left-0px { left: 0; }
    .xl-left-minus-1px { left: -1px; }
    .xl-left-minus-2px { left: -2px; }
    .xl-left-minus-3px { left: -3px; }
    .xl-left-minus-4px { left: -4px; }
    .xl-left-minus-5px { left: -5px; }
    .xl-left-minus-6px { left: -6px; }
    .xl-left-minus-7px { left: -7px; }
    .xl-left-minus-8px { left: -8px; }
    .xl-left-minus-9px { left: -9px; }
    .xl-left-minus-10px { left: -10px; }
    .xl-left-minus-15px { left: -15px; }
    .xl-left-minus-20px { left: -20px; }
    .xl-left-minus-25px { left: -25px; }
    .xl-left-minus-30px { left: -30px; }
    .xl-left-minus-35px { left: -35px; }
    .xl-left-minus-40px { left: -40px; }
    .xl-left-minus-45px { left: -45px; }
    .xl-left-minus-50px { left: -50px; }
}

@media (max-width: 1199px) {
    /* position */
    .lg-position-relative { position: relative !important; }
    .lg-position-absolute { position: absolute !important; }
    .lg-position-fixed { position: fixed !important; }
    .lg-position-inherit { position: inherit !important; }
    .lg-position-initial { position: initial !important; }

    /* top */
    .lg-top-0px { top: 0; }
    .lg-top-1px { top: 1px; }
    .lg-top-2px { top: 2px; }
    .lg-top-3px { top: 3px; }
    .lg-top-4px { top: 4px; }
    .lg-top-5px { top: 5px; }
    .lg-top-6px { top: 6px; }
    .lg-top-7px { top: 7px; }
    .lg-top-8px { top: 8px; }
    .lg-top-9px { top: 9px; }
    .lg-top-10px { top: 10px; }
    .lg-top-15px { top: 15px; }
    .lg-top-20px { top: 20px; }
    .lg-top-25px { top: 25px; }
    .lg-top-30px { top: 30px; }
    .lg-top-35px { top: 35px; }
    .lg-top-40px { top: 40px; }
    .lg-top-45px { top: 45px; }
    .lg-top-50px { top: 50px; }
    .lg-top-auto { top:auto; }
    .lg-top-inherit { top:inherit; }

    /* top minus */
    .lg-top-minus-1px { top: -1px; }
    .lg-top-minus-2px { top: -2px; }
    .lg-top-minus-3px { top: -3px; }
    .lg-top-minus-4px { top: -4px; }
    .lg-top-minus-5px { top: -5px; }
    .lg-top-minus-6px { top: -6px; }
    .lg-top-minus-7px { top: -7px; }
    .lg-top-minus-8px { top: -8px; }
    .lg-top-minus-9px { top: -9px; }
    .lg-top-minus-10px { top: -10px; }
    .lg-top-minus-15px { top: -15px; }
    .lg-top-minus-20px { top: -20px; }
    .lg-top-minus-25px { top: -25px; }
    .lg-top-minus-30px { top: -30px; }
    .lg-top-minus-35px { top: -35px; }
    .lg-top-minus-40px { top: -40px; }
    .lg-top-minus-45px { top: -45px; }
    .lg-top-minus-50px { top: -50px; }

    /* bottom */
    .lg-bottom-0px { bottom:0; }
    .lg-bottom-1px { bottom:1px; }
    .lg-bottom-2px { bottom:2px; }
    .lg-bottom-3px { bottom:3px; }
    .lg-bottom-4px { bottom:4px; }
    .lg-bottom-5px { bottom:5px; }
    .lg-bottom-6px { bottom:6px; }
    .lg-bottom-7px { bottom:7px; }
    .lg-bottom-8px { bottom:8px; }
    .lg-bottom-9px { bottom:9px; }
    .lg-bottom-10px { bottom:10px; }
    .lg-bottom-15px { bottom:15px; }
    .lg-bottom-20px { bottom:20px; }
    .lg-bottom-25px { bottom:25px; }
    .lg-bottom-30px { bottom:30px; }
    .lg-bottom-35px { bottom:35px; }
    .lg-bottom-40px { bottom:40px; }
    .lg-bottom-45px { bottom:45px; }
    .lg-bottom-50px { bottom:50px; }
    .lg-bottom-55px { bottom:55px; }
    .lg-bottom-60px { bottom:60px; }
    .lg-bottom-auto { bottom: auto; }
    .lg-bottom-inherit { bottom: inherit; }

    /* bottom minus */
    .lg-bottom-minus-1px { bottom: -1px; }
    .lg-bottom-minus-2px { bottom: -2px; }
    .lg-bottom-minus-3px { bottom: -3px; }
    .lg-bottom-minus-4px { bottom: -4px; }
    .lg-bottom-minus-5px { bottom: -5px; }
    .lg-bottom-minus-6px { bottom: -6px; }
    .lg-bottom-minus-7px { bottom: -7px; }
    .lg-bottom-minus-8px { bottom: -8px; }
    .lg-bottom-minus-9px { bottom: -9px; }
    .lg-bottom-minus-10px { bottom: -10px; }
    .lg-bottom-minus-15px { bottom: -15px; }
    .lg-bottom-minus-20px { bottom: -20px; }
    .lg-bottom-minus-25px { bottom: -25px; }
    .lg-bottom-minus-30px { bottom: -30px; }
    .lg-bottom-minus-35px { bottom: -35px; }
    .lg-bottom-minus-40px { bottom: -40px; }
    .lg-bottom-minus-45px { bottom: -45px; }
    .lg-bottom-minus-50px { bottom: -50px; }

    /* right */
    .lg-right-0px { right: 0; }
    .lg-right-1px { right: 1px; }
    .lg-right-2px { right: 2px; }
    .lg-right-3px { right: 3px; }
    .lg-right-4px { right: 4px; }
    .lg-right-5px { right: 5px; }
    .lg-right-6px { right: 6px; }
    .lg-right-7px { right: 7px; }
    .lg-right-8px { right: 8px; }
    .lg-right-9px { right: 9px; }
    .lg-right-10px { right: 10px; }
    .lg-right-15px { right: 15px; }
    .lg-right-20px { right: 20px; }
    .lg-right-25px { right: 25px; }
    .lg-right-30px { right: 30px; }
    .lg-right-35px { right: 35px; }
    .lg-right-40px { right: 40px; }
    .lg-right-45px { right: 45px; }
    .lg-right-50px { right: 50px; }
    .lg-right-auto { right: auto; }
    .lg-right-inherit { right: inherit; }

    /* right minus */
    .lg-right-minus-1px { right: -1px; }
    .lg-right-minus-2px { right: -2px; }
    .lg-right-minus-3px { right: -3px; }
    .lg-right-minus-4px { right: -4px; }
    .lg-right-minus-5px { right: -5px; }
    .lg-right-minus-6px { right: -6px; }
    .lg-right-minus-7px { right: -7px; }
    .lg-right-minus-8px { right: -8px; }
    .lg-right-minus-9px { right: -9px; }
    .lg-right-minus-10px { right: -10px; }
    .lg-right-minus-15px { right: -15px; }
    .lg-right-minus-20px { right: -20px; }
    .lg-right-minus-25px { right: -25px; }
    .lg-right-minus-30px { right: -30px; }
    .lg-right-minus-35px { right: -35px; }
    .lg-right-minus-40px { right: -40px; }
    .lg-right-minus-45px { right: -45px; }
    .lg-right-minus-50px { right: -50px; }

    /* left */
    .lg-left-0px { left: 0; }
    .lg-left-1px { left: 1px; }
    .lg-left-2px { left: 2px; }
    .lg-left-3px { left: 3px; }
    .lg-left-4px { left: 4px; }
    .lg-left-5px { left: 5px; }
    .lg-left-6px { left: 6px; }
    .lg-left-7px { left: 7px; }
    .lg-left-8px { left: 8px; }
    .lg-left-9px { left: 9px; }
    .lg-left-10px { left: 10px; }
    .lg-left-15px { left: 15px; }
    .lg-left-20px { left: 20px; }
    .lg-left-25px { left: 25px; }
    .lg-left-30px { left: 30px; }
    .lg-left-35px { left: 35px; }
    .lg-left-40px { left: 40px; }
    .lg-left-45px { left: 45px; }
    .lg-left-50px { left: 50px; }
    .lg-left-55px { left: 55px; }
    .lg-left-60px { left: 60px; }
    .lg-left-auto { left: auto; }
    .lg-left-inherit { left: inherit; }

    /* left minus */
    .lg-left-minus-1px { left: -1px; }
    .lg-left-minus-2px { left: -2px; }
    .lg-left-minus-3px { left: -3px; }
    .lg-left-minus-4px { left: -4px; }
    .lg-left-minus-5px { left: -5px; }
    .lg-left-minus-6px { left: -6px; }
    .lg-left-minus-7px { left: -7px; }
    .lg-left-minus-8px { left: -8px; }
    .lg-left-minus-9px { left: -9px; }
    .lg-left-minus-10px { left: -10px; }
    .lg-left-minus-15px { left: -15px; }
    .lg-left-minus-20px { left: -20px; }
    .lg-left-minus-25px { left: -25px; }
    .lg-left-minus-30px { left: -30px; }
    .lg-left-minus-35px { left: -35px; }
    .lg-left-minus-40px { left: -40px; }
    .lg-left-minus-45px { left: -45px; }
    .lg-left-minus-50px { left: -50px; }
}

@media(max-width:991px){
    /* position */
    .md-position-relative { position: relative !important; }
    .md-position-absolute { position: absolute !important; }
    .md-position-fixed { position: fixed !important; }
    .md-position-inherit { position: inherit !important; }
    .md-position-initial { position: initial !important; }

    /* top */
    .md-top-0px { top: 0; }
    .md-top-1px { top: 1px; }
    .md-top-2px { top: 2px; }
    .md-top-3px { top: 3px; }
    .md-top-4px { top: 4px; }
    .md-top-5px { top: 5px; }
    .md-top-6px { top: 6px; }
    .md-top-7px { top: 7px; }
    .md-top-8px { top: 8px; }
    .md-top-9px { top: 9px; }
    .md-top-10px { top: 10px; }
    .md-top-15px { top: 15px; }
    .md-top-20px { top: 20px; }
    .md-top-25px { top: 25px; }
    .md-top-30px { top: 30px; }
    .md-top-35px { top: 35px; }
    .md-top-40px { top: 40px; }
    .md-top-45px { top: 45px; }
    .md-top-50px { top: 50px; }
    .md-top-auto { top:auto; }
    .md-top-inherit { top:inherit; }

    /* top minus */
    .md-top-minus-1px { top: -1px; }
    .md-top-minus-2px { top: -2px; }
    .md-top-minus-3px { top: -3px; }
    .md-top-minus-4px { top: -4px; }
    .md-top-minus-5px { top: -5px; }
    .md-top-minus-6px { top: -6px; }
    .md-top-minus-7px { top: -7px; }
    .md-top-minus-8px { top: -8px; }
    .md-top-minus-9px { top: -9px; }
    .md-top-minus-10px { top: -10px; }
    .md-top-minus-15px { top: -15px; }
    .md-top-minus-20px { top: -20px; }
    .md-top-minus-25px { top: -25px; }
    .md-top-minus-30px { top: -30px; }
    .md-top-minus-35px { top: -35px; }
    .md-top-minus-40px { top: -40px; }
    .md-top-minus-45px { top: -45px; }
    .md-top-minus-50px { top: -50px; }

    /* bottom */
    .md-bottom-0px { bottom:0; }
    .md-bottom-1px { bottom:1px; }
    .md-bottom-2px { bottom:2px; }
    .md-bottom-3px { bottom:3px; }
    .md-bottom-4px { bottom:4px; }
    .md-bottom-5px { bottom:5px; }
    .md-bottom-6px { bottom:6px; }
    .md-bottom-7px { bottom:7px; }
    .md-bottom-8px { bottom:8px; }
    .md-bottom-9px { bottom:9px; }
    .md-bottom-10px { bottom:10px; }
    .md-bottom-15px { bottom:15px; }
    .md-bottom-20px { bottom:20px; }
    .md-bottom-25px { bottom:25px; }
    .md-bottom-30px { bottom:30px; }
    .md-bottom-35px { bottom:35px; }
    .md-bottom-40px { bottom:40px; }
    .md-bottom-45px { bottom:45px; }
    .md-bottom-50px { bottom:50px; }
    .md-bottom-55px { bottom:55px; }
    .md-bottom-60px { bottom:60px; }
    .md-bottom-auto { bottom: auto; }
    .md-bottom-inherit { bottom: inherit; }

    /* bottom minus */
    .md-bottom-minus-1px { bottom: -1px; }
    .md-bottom-minus-2px { bottom: -2px; }
    .md-bottom-minus-3px { bottom: -3px; }
    .md-bottom-minus-4px { bottom: -4px; }
    .md-bottom-minus-5px { bottom: -5px; }
    .md-bottom-minus-6px { bottom: -6px; }
    .md-bottom-minus-7px { bottom: -7px; }
    .md-bottom-minus-8px { bottom: -8px; }
    .md-bottom-minus-9px { bottom: -9px; }
    .md-bottom-minus-10px { bottom: -10px; }
    .md-bottom-minus-15px { bottom: -15px; }
    .md-bottom-minus-20px { bottom: -20px; }
    .md-bottom-minus-25px { bottom: -25px; }
    .md-bottom-minus-30px { bottom: -30px; }
    .md-bottom-minus-35px { bottom: -35px; }
    .md-bottom-minus-40px { bottom: -40px; }
    .md-bottom-minus-45px { bottom: -45px; }
    .md-bottom-minus-50px { bottom: -50px; }

    /* right */
    .md-right-0px { right: 0; }
    .md-right-1px { right: 1px; }
    .md-right-2px { right: 2px; }
    .md-right-3px { right: 3px; }
    .md-right-4px { right: 4px; }
    .md-right-5px { right: 5px; }
    .md-right-6px { right: 6px; }
    .md-right-7px { right: 7px; }
    .md-right-8px { right: 8px; }
    .md-right-9px { right: 9px; }
    .md-right-10px { right: 10px; }
    .md-right-15px { right: 15px; }
    .md-right-20px { right: 20px; }
    .md-right-25px { right: 25px; }
    .md-right-30px { right: 30px; }
    .md-right-35px { right: 35px; }
    .md-right-40px { right: 40px; }
    .md-right-45px { right: 45px; }
    .md-right-50px { right: 50px; }
    .md-right-auto { right: auto; }
    .md-right-inherit { right: inherit; }

    /* right minus */
    .md-right-minus-1px { right: -1px; }
    .md-right-minus-2px { right: -2px; }
    .md-right-minus-3px { right: -3px; }
    .md-right-minus-4px { right: -4px; }
    .md-right-minus-5px { right: -5px; }
    .md-right-minus-6px { right: -6px; }
    .md-right-minus-7px { right: -7px; }
    .md-right-minus-8px { right: -8px; }
    .md-right-minus-9px { right: -9px; }
    .md-right-minus-10px { right: -10px; }
    .md-right-minus-15px { right: -15px; }
    .md-right-minus-20px { right: -20px; }
    .md-right-minus-25px { right: -25px; }
    .md-right-minus-30px { right: -30px; }
    .md-right-minus-35px { right: -35px; }
    .md-right-minus-40px { right: -40px; }
    .md-right-minus-45px { right: -45px; }
    .md-right-minus-50px { right: -50px; }

    /* left */
    .md-left-0px { left: 0; }
    .md-left-1px { left: 1px; }
    .md-left-2px { left: 2px; }
    .md-left-3px { left: 3px; }
    .md-left-4px { left: 4px; }
    .md-left-5px { left: 5px; }
    .md-left-6px { left: 6px; }
    .md-left-7px { left: 7px; }
    .md-left-8px { left: 8px; }
    .md-left-9px { left: 9px; }
    .md-left-10px { left: 10px; }
    .md-left-15px { left: 15px; }
    .md-left-20px { left: 20px; }
    .md-left-25px { left: 25px; }
    .md-left-30px { left: 30px; }
    .md-left-35px { left: 35px; }
    .md-left-40px { left: 40px; }
    .md-left-45px { left: 45px; }
    .md-left-50px { left: 50px; }
    .md-left-55px { left: 55px; }
    .md-left-60px { left: 60px; }
    .md-left-auto { left: auto; }
    .md-left-inherit { left: inherit; }

    /* left minus */
    .md-left-minus-1px { left: -1px; }
    .md-left-minus-2px { left: -2px; }
    .md-left-minus-3px { left: -3px; }
    .md-left-minus-4px { left: -4px; }
    .md-left-minus-5px { left: -5px; }
    .md-left-minus-6px { left: -6px; }
    .md-left-minus-7px { left: -7px; }
    .md-left-minus-8px { left: -8px; }
    .md-left-minus-9px { left: -9px; }
    .md-left-minus-10px { left: -10px; }
    .md-left-minus-15px { left: -15px; }
    .md-left-minus-20px { left: -20px; }
    .md-left-minus-25px { left: -25px; }
    .md-left-minus-30px { left: -30px; }
    .md-left-minus-35px { left: -35px; }
    .md-left-minus-40px { left: -40px; }
    .md-left-minus-45px { left: -45px; }
    .md-left-minus-50px { left: -50px; }

}

@media(max-width:767px){
    /* position */
    .sm-position-relative { position: relative !important; }
    .sm-position-absolute { position: absolute !important; }
    .sm-position-fixed { position: fixed !important; }
    .sm-position-inherit { position: inherit !important; }
    .sm-position-initial { position: initial !important; }

    /* top */
    .sm-top-0px { top: 0; }
    .sm-top-1px { top: 1px; }
    .sm-top-2px { top: 2px; }
    .sm-top-3px { top: 3px; }
    .sm-top-4px { top: 4px; }
    .sm-top-5px { top: 5px; }
    .sm-top-6px { top: 6px; }
    .sm-top-7px { top: 7px; }
    .sm-top-8px { top: 8px; }
    .sm-top-9px { top: 9px; }
    .sm-top-10px { top: 10px; }
    .sm-top-15px { top: 15px; }
    .sm-top-20px { top: 20px; }
    .sm-top-25px { top: 25px; }
    .sm-top-30px { top: 30px; }
    .sm-top-35px { top: 35px; }
    .sm-top-40px { top: 40px; }
    .sm-top-45px { top: 45px; }
    .sm-top-50px { top: 50px; }
    .sm-top-auto { top:auto; }
    .sm-top-inherit { top:inherit; }

    /* top minus */
    .sm-top-minus-1px { top: -1px; }
    .sm-top-minus-2px { top: -2px; }
    .sm-top-minus-3px { top: -3px; }
    .sm-top-minus-4px { top: -4px; }
    .sm-top-minus-5px { top: -5px; }
    .sm-top-minus-6px { top: -6px; }
    .sm-top-minus-7px { top: -7px; }
    .sm-top-minus-8px { top: -8px; }
    .sm-top-minus-9px { top: -9px; }
    .sm-top-minus-10px { top: -10px; }
    .sm-top-minus-15px { top: -15px; }
    .sm-top-minus-20px { top: -20px; }
    .sm-top-minus-25px { top: -25px; }
    .sm-top-minus-30px { top: -30px; }
    .sm-top-minus-35px { top: -35px; }
    .sm-top-minus-40px { top: -40px; }
    .sm-top-minus-45px { top: -45px; }
    .sm-top-minus-50px { top: -50px; }

    /* bottom */
    .sm-bottom-0px { bottom:0; }
    .sm-bottom-1px { bottom:1px; }
    .sm-bottom-2px { bottom:2px; }
    .sm-bottom-3px { bottom:3px; }
    .sm-bottom-4px { bottom:4px; }
    .sm-bottom-5px { bottom:5px; }
    .sm-bottom-6px { bottom:6px; }
    .sm-bottom-7px { bottom:7px; }
    .sm-bottom-8px { bottom:8px; }
    .sm-bottom-9px { bottom:9px; }
    .sm-bottom-10px { bottom:10px; }
    .sm-bottom-15px { bottom:15px; }
    .sm-bottom-20px { bottom:20px; }
    .sm-bottom-25px { bottom:25px; }
    .sm-bottom-30px { bottom:30px; }
    .sm-bottom-35px { bottom:35px; }
    .sm-bottom-40px { bottom:40px; }
    .sm-bottom-45px { bottom:45px; }
    .sm-bottom-50px { bottom:50px; }
    .sm-bottom-55px { bottom:55px; }
    .sm-bottom-60px { bottom:60px; }
    .sm-bottom-auto { bottom: auto; }
    .sm-bottom-inherit { bottom: inherit; }

    /* bottom minus */
    .sm-bottom-minus-1px { bottom: -1px; }
    .sm-bottom-minus-2px { bottom: -2px; }
    .sm-bottom-minus-3px { bottom: -3px; }
    .sm-bottom-minus-4px { bottom: -4px; }
    .sm-bottom-minus-5px { bottom: -5px; }
    .sm-bottom-minus-6px { bottom: -6px; }
    .sm-bottom-minus-7px { bottom: -7px; }
    .sm-bottom-minus-8px { bottom: -8px; }
    .sm-bottom-minus-9px { bottom: -9px; }
    .sm-bottom-minus-10px { bottom: -10px; }
    .sm-bottom-minus-15px { bottom: -15px; }
    .sm-bottom-minus-20px { bottom: -20px; }
    .sm-bottom-minus-25px { bottom: -25px; }
    .sm-bottom-minus-30px { bottom: -30px; }
    .sm-bottom-minus-35px { bottom: -35px; }
    .sm-bottom-minus-40px { bottom: -40px; }
    .sm-bottom-minus-45px { bottom: -45px; }
    .sm-bottom-minus-50px { bottom: -50px; }

    /* right */
    .sm-right-0px { right: 0; }
    .sm-right-1px { right: 1px; }
    .sm-right-2px { right: 2px; }
    .sm-right-3px { right: 3px; }
    .sm-right-4px { right: 4px; }
    .sm-right-5px { right: 5px; }
    .sm-right-6px { right: 6px; }
    .sm-right-7px { right: 7px; }
    .sm-right-8px { right: 8px; }
    .sm-right-9px { right: 9px; }
    .sm-right-10px { right: 10px; }
    .sm-right-15px { right: 15px; }
    .sm-right-20px { right: 20px; }
    .sm-right-25px { right: 25px; }
    .sm-right-30px { right: 30px; }
    .sm-right-35px { right: 35px; }
    .sm-right-40px { right: 40px; }
    .sm-right-45px { right: 45px; }
    .sm-right-50px { right: 50px; }
    .sm-right-auto { right: auto; }
    .sm-right-inherit { right: inherit; }

    /* right minus */
    .sm-right-minus-1px { right: -1px; }
    .sm-right-minus-2px { right: -2px; }
    .sm-right-minus-3px { right: -3px; }
    .sm-right-minus-4px { right: -4px; }
    .sm-right-minus-5px { right: -5px; }
    .sm-right-minus-6px { right: -6px; }
    .sm-right-minus-7px { right: -7px; }
    .sm-right-minus-8px { right: -8px; }
    .sm-right-minus-9px { right: -9px; }
    .sm-right-minus-10px { right: -10px; }
    .sm-right-minus-15px { right: -15px; }
    .sm-right-minus-20px { right: -20px; }
    .sm-right-minus-25px { right: -25px; }
    .sm-right-minus-30px { right: -30px; }
    .sm-right-minus-35px { right: -35px; }
    .sm-right-minus-40px { right: -40px; }
    .sm-right-minus-45px { right: -45px; }
    .sm-right-minus-50px { right: -50px; }

    /* left */
    .sm-left-0px { left: 0; }
    .sm-left-1px { left: 1px; }
    .sm-left-2px { left: 2px; }
    .sm-left-3px { left: 3px; }
    .sm-left-4px { left: 4px; }
    .sm-left-5px { left: 5px; }
    .sm-left-6px { left: 6px; }
    .sm-left-7px { left: 7px; }
    .sm-left-8px { left: 8px; }
    .sm-left-9px { left: 9px; }
    .sm-left-10px { left: 10px; }
    .sm-left-15px { left: 15px; }
    .sm-left-20px { left: 20px; }
    .sm-left-25px { left: 25px; }
    .sm-left-30px { left: 30px; }
    .sm-left-35px { left: 35px; }
    .sm-left-40px { left: 40px; }
    .sm-left-45px { left: 45px; }
    .sm-left-50px { left: 50px; }
    .sm-left-55px { left: 55px; }
    .sm-left-60px { left: 60px; }
    .sm-left-auto { left: auto; }
    .sm-left-inherit { left: inherit; }

    /* left minus */
    .sm-left-minus-1px { left: -1px; }
    .sm-left-minus-2px { left: -2px; }
    .sm-left-minus-3px { left: -3px; }
    .sm-left-minus-4px { left: -4px; }
    .sm-left-minus-5px { left: -5px; }
    .sm-left-minus-6px { left: -6px; }
    .sm-left-minus-7px { left: -7px; }
    .sm-left-minus-8px { left: -8px; }
    .sm-left-minus-9px { left: -9px; }
    .sm-left-minus-10px { left: -10px; }
    .sm-left-minus-15px { left: -15px; }
    .sm-left-minus-20px { left: -20px; }
    .sm-left-minus-25px { left: -25px; }
    .sm-left-minus-30px { left: -30px; }
    .sm-left-minus-35px { left: -35px; }
    .sm-left-minus-40px { left: -40px; }
    .sm-left-minus-45px { left: -45px; }
    .sm-left-minus-50px { left: -50px; }
}

@media screen and (max-width: 575px) {
    /* position */
    .xs-position-relative { position: relative !important; }
    .xs-position-absolute { position: absolute !important; }
    .xs-position-fixed { position: fixed !important; }
    .xs-position-inherit { position: inherit !important; }
    .xs-position-initial { position: initial !important; }

    /* top */
    .xs-top-0px { top: 0; }
    .xs-top-1px { top: 1px; }
    .xs-top-2px { top: 2px; }
    .xs-top-3px { top: 3px; }
    .xs-top-4px { top: 4px; }
    .xs-top-5px { top: 5px; }
    .xs-top-6px { top: 6px; }
    .xs-top-7px { top: 7px; }
    .xs-top-8px { top: 8px; }
    .xs-top-9px { top: 9px; }
    .xs-top-10px { top: 10px; }
    .xs-top-15px { top: 15px; }
    .xs-top-20px { top: 20px; }
    .xs-top-25px { top: 25px; }
    .xs-top-30px { top: 30px; }
    .xs-top-35px { top: 35px; }
    .xs-top-40px { top: 40px; }
    .xs-top-45px { top: 45px; }
    .xs-top-50px { top: 50px; }
    .xs-top-auto { top:auto; }
    .xs-top-inherit { top:inherit; }

    /* top minus */
    .xs-top-minus-1px { top: -1px; }
    .xs-top-minus-2px { top: -2px; }
    .xs-top-minus-3px { top: -3px; }
    .xs-top-minus-4px { top: -4px; }
    .xs-top-minus-5px { top: -5px; }
    .xs-top-minus-6px { top: -6px; }
    .xs-top-minus-7px { top: -7px; }
    .xs-top-minus-8px { top: -8px; }
    .xs-top-minus-9px { top: -9px; }
    .xs-top-minus-10px { top: -10px; }
    .xs-top-minus-15px { top: -15px; }
    .xs-top-minus-20px { top: -20px; }
    .xs-top-minus-25px { top: -25px; }
    .xs-top-minus-30px { top: -30px; }
    .xs-top-minus-35px { top: -35px; }
    .xs-top-minus-40px { top: -40px; }
    .xs-top-minus-45px { top: -45px; }
    .xs-top-minus-50px { top: -50px; }

    /* bottom */
    .xs-bottom-0px { bottom:0; }
    .xs-bottom-1px { bottom:1px; }
    .xs-bottom-2px { bottom:2px; }
    .xs-bottom-3px { bottom:3px; }
    .xs-bottom-4px { bottom:4px; }
    .xs-bottom-5px { bottom:5px; }
    .xs-bottom-6px { bottom:6px; }
    .xs-bottom-7px { bottom:7px; }
    .xs-bottom-8px { bottom:8px; }
    .xs-bottom-9px { bottom:9px; }
    .xs-bottom-10px { bottom:10px; }
    .xs-bottom-15px { bottom:15px; }
    .xs-bottom-20px { bottom:20px; }
    .xs-bottom-25px { bottom:25px; }
    .xs-bottom-30px { bottom:30px; }
    .xs-bottom-35px { bottom:35px; }
    .xs-bottom-40px { bottom:40px; }
    .xs-bottom-45px { bottom:45px; }
    .xs-bottom-50px { bottom:50px; }
    .xs-bottom-55px { bottom:55px; }
    .xs-bottom-60px { bottom:60px; }
    .xs-bottom-auto { bottom: auto; }
    .xs-bottom-inherit { bottom: inherit; }

    /* bottom minus */
    .xs-bottom-minus-1px { bottom: -1px; }
    .xs-bottom-minus-2px { bottom: -2px; }
    .xs-bottom-minus-3px { bottom: -3px; }
    .xs-bottom-minus-4px { bottom: -4px; }
    .xs-bottom-minus-5px { bottom: -5px; }
    .xs-bottom-minus-6px { bottom: -6px; }
    .xs-bottom-minus-7px { bottom: -7px; }
    .xs-bottom-minus-8px { bottom: -8px; }
    .xs-bottom-minus-9px { bottom: -9px; }
    .xs-bottom-minus-10px { bottom: -10px; }
    .xs-bottom-minus-15px { bottom: -15px; }
    .xs-bottom-minus-20px { bottom: -20px; }
    .xs-bottom-minus-25px { bottom: -25px; }
    .xs-bottom-minus-30px { bottom: -30px; }
    .xs-bottom-minus-35px { bottom: -35px; }
    .xs-bottom-minus-40px { bottom: -40px; }
    .xs-bottom-minus-45px { bottom: -45px; }
    .xs-bottom-minus-50px { bottom: -50px; }

    /* right */
    .xs-right-0px { right: 0; }
    .xs-right-1px { right: 1px; }
    .xs-right-2px { right: 2px; }
    .xs-right-3px { right: 3px; }
    .xs-right-4px { right: 4px; }
    .xs-right-5px { right: 5px; }
    .xs-right-6px { right: 6px; }
    .xs-right-7px { right: 7px; }
    .xs-right-8px { right: 8px; }
    .xs-right-9px { right: 9px; }
    .xs-right-10px { right: 10px; }
    .xs-right-15px { right: 15px; }
    .xs-right-20px { right: 20px; }
    .xs-right-25px { right: 25px; }
    .xs-right-30px { right: 30px; }
    .xs-right-35px { right: 35px; }
    .xs-right-40px { right: 40px; }
    .xs-right-45px { right: 45px; }
    .xs-right-50px { right: 50px; }
    .xs-right-auto { right: auto; }
    .xs-right-inherit { right: inherit; }

    /* right minus */
    .xs-right-minus-1px { right: -1px; }
    .xs-right-minus-2px { right: -2px; }
    .xs-right-minus-3px { right: -3px; }
    .xs-right-minus-4px { right: -4px; }
    .xs-right-minus-5px { right: -5px; }
    .xs-right-minus-6px { right: -6px; }
    .xs-right-minus-7px { right: -7px; }
    .xs-right-minus-8px { right: -8px; }
    .xs-right-minus-9px { right: -9px; }
    .xs-right-minus-10px { right: -10px; }
    .xs-right-minus-15px { right: -15px; }
    .xs-right-minus-20px { right: -20px; }
    .xs-right-minus-25px { right: -25px; }
    .xs-right-minus-30px { right: -30px; }
    .xs-right-minus-35px { right: -35px; }
    .xs-right-minus-40px { right: -40px; }
    .xs-right-minus-45px { right: -45px; }
    .xs-right-minus-50px { right: -50px; }

    /* left */
    .xs-left-0px { left: 0; }
    .xs-left-1px { left: 1px; }
    .xs-left-2px { left: 2px; }
    .xs-left-3px { left: 3px; }
    .xs-left-4px { left: 4px; }
    .xs-left-5px { left: 5px; }
    .xs-left-6px { left: 6px; }
    .xs-left-7px { left: 7px; }
    .xs-left-8px { left: 8px; }
    .xs-left-9px { left: 9px; }
    .xs-left-10px { left: 10px; }
    .xs-left-15px { left: 15px; }
    .xs-left-20px { left: 20px; }
    .xs-left-25px { left: 25px; }
    .xs-left-30px { left: 30px; }
    .xs-left-35px { left: 35px; }
    .xs-left-40px { left: 40px; }
    .xs-left-45px { left: 45px; }
    .xs-left-50px { left: 50px; }
    .xs-left-55px { left: 55px; }
    .xs-left-60px { left: 60px; }
    .xs-left-auto { left: auto; }
    .xs-left-inherit { left: inherit; }

    /* left minus */
    .xs-left-minus-1px { left: -1px; }
    .xs-left-minus-2px { left: -2px; }
    .xs-left-minus-3px { left: -3px; }
    .xs-left-minus-4px { left: -4px; }
    .xs-left-minus-5px { left: -5px; }
    .xs-left-minus-6px { left: -6px; }
    .xs-left-minus-7px { left: -7px; }
    .xs-left-minus-8px { left: -8px; }
    .xs-left-minus-9px { left: -9px; }
    .xs-left-minus-10px { left: -10px; }
    .xs-left-minus-15px { left: -15px; }
    .xs-left-minus-20px { left: -20px; }
    .xs-left-minus-25px { left: -25px; }
    .xs-left-minus-30px { left: -30px; }
    .xs-left-minus-35px { left: -35px; }
    .xs-left-minus-40px { left: -40px; }
    .xs-left-minus-45px { left: -45px; }
    .xs-left-minus-50px { left: -50px; }
}
.whyBox{
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -ms-transition-duration: 0.4s;
    .process-step-icon-bfr{
        position: relative;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: block;
        flex-shrink: 0;
        opacity: 0.5;
        @media(max-width:992px){
            width: 60px;
            height: 60px;
        }
    }
    .process-step-number{
        flex-shrink: 0;
        position: relative;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        border-radius: 50%;
        display: block;
        box-shadow: 0px 3px 6px #00000029;
        -webkit-transition: 0.3s cubic-bezier(0.3, 0.58, 0.55, 1);
        transition: 0.3s cubic-bezier(0.3, 0.58, 0.55, 1);
        @media(max-width: 992px){
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 25px;
        }
    }
    &:hover {  
        background-color: $purple!important;
        h3{
            &.text-purple {
                color: $white!important;
            }
        } 
        .process-step-icon {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -ootransform: scale(1.2);
            transform: scale(1.2);
        }
        .process-step-number{
            box-shadow: 0px 3px 6px #00000029;
        }
    }
}

.address-block{
    cursor: pointer;
    &:hover{
        .green{
            color: $pink !important;
        }
    }
}

.address-block-selected{
    .green{
        color: $pink !important;
    }
}

.contacts{
    @media(min-width:768px) and (max-width:991px){
        flex-direction: row !important;
        a{
            &:not(:last-child){
                &:after{
                    content: " | ";
                    margin-left: 10px;
                }
            }
        }
    }
    a{
        @include width-fit-content;
        &:hover{
            color: $pink !important;
        }
    }
}
a {
    text-decoration: none;
    &:hover, &:focus {
        text-decoration: none;
        outline: none;
        outline-offset: 0;
        color: #0038e3;
    }
    color: #828282;
  
    &:active, &:focus {
      color: #828282;
    }
  
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
}
/* ===================================
    11. Padding
====================================== */

.padding-one-all {
    padding: 1%;
}
  
.padding-two-all {
    padding: 2%;
}
  
.padding-three-all {
    padding: 3%;
}
  
.padding-four-all {
    padding: 4%;
}
  
.padding-five-all {
    padding: 5%;
}
  
.padding-six-all {
    padding: 6%;
}
  
.padding-seven-all {
    padding: 7%;
}
  
.padding-eight-all {
    padding: 8%;
}
  
.padding-nine-all {
    padding: 9%;
}
  
.padding-ten-all {
    padding: 10%;
}
  
.padding-eleven-all {
    padding: 11%;
}
  
.padding-twelve-all {
    padding: 12%;
}
  
.padding-thirteen-all {
    padding: 13%;
}
  
.padding-fourteen-all {
    padding: 14%;
}
  
.padding-fifteen-all {
    padding: 15%;
}
  
.padding-sixteen-all {
    padding: 16%;
}
  
.padding-seventeen-all {
    padding: 17%;
}
  
.padding-eighteen-all {
    padding: 18%;
}
  
.padding-nineteen-all {
    padding: 19%;
}
  
.padding-twenty-all {
    padding: 20%;
}
  
.padding-5px-all {
    padding: 5px;
}
  
.padding-10px-all {
    padding: 10px;
}
  
.padding-15px-all {
    padding: 15px;
}
  
.padding-20px-all {
    padding: 20px;
}
  
.padding-25px-all {
    padding: 25px;
}
  
.padding-30px-all {
    padding: 30px;
}
  
.padding-35px-all {
    padding: 35px;
}
  
.padding-40px-all {
    padding: 40px;
}
  
.padding-45px-all {
    padding: 45px;
}
  
.padding-50px-all {
    padding: 50px;
}
  
.padding-55px-all {
    padding: 55px;
}
  
.padding-60px-all {
    padding: 60px;
}
  
.padding-65px-all {
    padding: 65px;
}
  
.padding-70px-all {
    padding: 70px;
}
  
.padding-75px-all {
    padding: 75px;
}
  
.padding-80px-all {
    padding: 80px;
}
  
.padding-85px-all {
    padding: 85px;
}
  
.padding-90px-all {
    padding: 90px;
}
  
.padding-95px-all {
    padding: 95px;
}
  
.padding-100px-all {
    padding: 100px;
}
  
.padding-1-rem-all {
    padding: 1rem;
}
  
.padding-1-half-rem-all {
    padding: 1.5rem;
}
  
.padding-2-rem-all {
    padding: 2rem;
}
  
.padding-2-half-rem-all {
    padding: 2.5rem;
}
  
.padding-3-rem-all {
    padding: 3rem;
}
  
.padding-3-half-rem-all {
    padding: 3.5rem;
}
  
.padding-4-rem-all {
    padding: 4rem;
}
  
.padding-4-half-rem-all {
    padding: 4.5rem;
}
  
.padding-5-rem-all {
    padding: 5rem;
}
  
.padding-5-half-rem-all {
    padding: 5.5rem;
}
  
.padding-6-rem-all {
    padding: 6rem;
}
  
.padding-6-half-rem-all {
    padding: 6.5rem;
}
  
.padding-7-rem-all {
    padding: 7rem;
}
  
.padding-7-half-rem-all {
    padding: 7.5rem;
}
  
.padding-8-rem-all {
    padding: 8rem;
}
  
.padding-8-half-rem-all {
    padding: 8.5rem;
}
  
.padding-9-rem-all {
    padding: 9rem;
}
  
.padding-9-half-rem-all {
    padding: 9.5rem;
}
  
.padding-10-rem-all {
    padding: 10rem;
}
  
.padding-10-half-rem-all {
    padding: 10.5rem;
}
  
  /* padding top */
  
.padding-one-top {
    padding-top: 1%;
}
  
.padding-two-top {
    padding-top: 2%;
}
  
.padding-three-top {
    padding-top: 3%;
}
  
.padding-four-top {
    padding-top: 4%;
}
  
.padding-five-top {
    padding-top: 5%;
}
  
.padding-six-top {
    padding-top: 6%;
}
  
.padding-seven-top {
    padding-top: 7%;
}
  
.padding-eight-top {
    padding-top: 8%;
}
  
.padding-nine-top {
    padding-top: 9%;
}
  
.padding-ten-top {
    padding-top: 10%;
}
  
.padding-eleven-top {
    padding-top: 11%;
}
  
.padding-twelve-top {
    padding-top: 12%;
}
  
.padding-thirteen-top {
    padding-top: 13%;
}
  
.padding-fourteen-top {
    padding-top: 14%;
}
  
.padding-fifteen-top {
    padding-top: 15%;
}
  
.padding-sixteen-top {
    padding-top: 16%;
}
  
.padding-seventeen-top {
    padding-top: 17%;
}
  
.padding-eighteen-top {
    padding-top: 18%;
}
  
.padding-nineteen-top {
    padding-top: 19%;
}
  
.padding-twenty-top {
    padding-top: 20%;
}
  
.padding-5px-top {
    padding-top: 5px;
}
  
.padding-10px-top {
    padding-top: 10px;
}
  
.padding-15px-top {
    padding-top: 15px;
}
  
.padding-20px-top {
    padding-top: 20px;
}
  
.padding-25px-top {
    padding-top: 25px;
}
  
.padding-30px-top {
    padding-top: 30px;
}
  
.padding-35px-top {
    padding-top: 35px;
}
  
.padding-40px-top {
    padding-top: 40px;
}
  
.padding-45px-top {
    padding-top: 45px;
}
  
.padding-50px-top {
    padding-top: 50px;
}
  
.padding-55px-top {
    padding-top: 55px;
}
  
.padding-60px-top {
    padding-top: 60px;
}
  
.padding-65px-top {
    padding-top: 65px;
}
  
.padding-70px-top {
    padding-top: 70px;
}
  
.padding-75px-top {
    padding-top: 75px;
}
  
.padding-80px-top {
    padding-top: 80px;
}
  
.padding-85px-top {
    padding-top: 85px;
}
  
.padding-90px-top {
    padding-top: 90px;
}
  
.padding-95px-top {
    padding-top: 95px;
}
  
.padding-100px-top {
    padding-top: 100px;
}
  
.padding-1-rem-top {
    padding-top: 1rem;
}
  
.padding-1-half-rem-top {
    padding-top: 1.5rem;
}
  
.padding-2-rem-top {
    padding-top: 2rem;
}
  
.padding-2-half-rem-top {
    padding-top: 2.5rem;
}
  
.padding-3-rem-top {
    padding-top: 3rem;
}
  
.padding-3-half-rem-top {
    padding-top: 3.5rem;
}
  
.padding-4-rem-top {
    padding-top: 4rem;
}
  
.padding-4-half-rem-top {
    padding-top: 4.5rem;
}
  
.padding-5-rem-top {
    padding-top: 5rem;
}
  
.padding-5-half-rem-top {
    padding-top: 5.5rem;
}
  
.padding-6-rem-top {
    padding-top: 6rem;
}
  
.padding-6-half-rem-top {
    padding-top: 6.5rem;
}
  
.padding-7-rem-top {
    padding-top: 7rem;
}
  
.padding-7-half-rem-top {
    padding-top: 7.5rem;
}
  
.padding-8-rem-top {
    padding-top: 8rem;
}
  
.padding-8-half-rem-top {
    padding-top: 8.5rem;
}
  
.padding-9-rem-top {
    padding-top: 9rem;
}
  
.padding-9-half-rem-top {
    padding-top: 9.5rem;
}
  
.padding-10-rem-top {
    padding-top: 10rem;
}
  
.padding-10-half-rem-top {
    padding-top: 10.5rem;
}
  
  /* padding bottom */
  
.padding-one-bottom {
    padding-bottom: 1%;
}
  
.padding-two-bottom {
    padding-bottom: 2%;
}
  
.padding-three-bottom {
    padding-bottom: 3%;
}
  
.padding-four-bottom {
    padding-bottom: 4%;
}
  
.padding-five-bottom {
    padding-bottom: 5%;
}
  
.padding-six-bottom {
    padding-bottom: 6%;
}
  
.padding-seven-bottom {
    padding-bottom: 7%;
}
  
.padding-eight-bottom {
    padding-bottom: 8%;
}
  
.padding-nine-bottom {
    padding-bottom: 9%;
}
  
.padding-ten-bottom {
    padding-bottom: 10%;
}
  
.padding-eleven-bottom {
    padding-bottom: 11%;
}
  
.padding-twelve-bottom {
    padding-bottom: 12%;
}
  
.padding-thirteen-bottom {
    padding-bottom: 13%;
}
  
.padding-fourteen-bottom {
    padding-bottom: 14%;
}
  
.padding-fifteen-bottom {
    padding-bottom: 15%;
}
  
.padding-sixteen-bottom {
    padding-bottom: 16%;
}
  
.padding-seventeen-bottom {
    padding-bottom: 17%;
}
  
.padding-eighteen-bottom {
    padding-bottom: 18%;
}
  
.padding-nineteen-bottom {
    padding-bottom: 19%;
}
  
.padding-twenty-bottom {
    padding-bottom: 20%;
}
  
.padding-5px-bottom {
    padding-bottom: 5px;
}
  
.padding-10px-bottom {
    padding-bottom: 10px;
}
  
.padding-15px-bottom {
    padding-bottom: 15px;
}
  
.padding-20px-bottom {
    padding-bottom: 20px;
}
  
.padding-25px-bottom {
    padding-bottom: 25px;
}
  
.padding-30px-bottom {
    padding-bottom: 30px;
}
  
.padding-35px-bottom {
    padding-bottom: 35px;
}
  
.padding-40px-bottom {
    padding-bottom: 40px;
}
  
.padding-45px-bottom {
    padding-bottom: 45px;
}
  
.padding-50px-bottom {
    padding-bottom: 50px;
}
  
.padding-55px-bottom {
    padding-bottom: 55px;
}
  
.padding-60px-bottom {
    padding-bottom: 60px;
}
  
.padding-65px-bottom {
    padding-bottom: 65px;
}
  
.padding-70px-bottom {
    padding-bottom: 70px;
}
  
.padding-75px-bottom {
    padding-bottom: 75px;
}
  
.padding-80px-bottom {
    padding-bottom: 80px;
}
  
.padding-85px-bottom {
    padding-bottom: 85px;
}
  
.padding-90px-bottom {
    padding-bottom: 90px;
}
  
.padding-95px-bottom {
    padding-bottom: 95px;
}
  
.padding-100px-bottom {
    padding-bottom: 100px;
}
  
.padding-1-rem-bottom {
    padding-bottom: 1rem;
}
  
.padding-1-half-rem-bottom {
    padding-bottom: 1.5rem;
}
  
.padding-2-rem-bottom {
    padding-bottom: 2rem;
}
  
.padding-2-half-rem-bottom {
    padding-bottom: 2.5rem;
}
  
.padding-3-rem-bottom {
    padding-bottom: 3rem;
}
  
.padding-3-half-rem-bottom {
    padding-bottom: 3.5rem;
}
  
.padding-4-rem-bottom {
    padding-bottom: 4rem;
}
  
.padding-4-half-rem-bottom {
    padding-bottom: 4.5rem;
}
  
.padding-5-rem-bottom {
    padding-bottom: 5rem;
}
  
.padding-5-half-rem-bottom {
    padding-bottom: 5.5rem;
}
  
.padding-6-rem-bottom {
    padding-bottom: 6rem;
}
  
.padding-6-half-rem-bottom {
    padding-bottom: 6.5rem;
}
  
.padding-7-rem-bottom {
    padding-bottom: 7rem;
}
  
.padding-7-half-rem-bottom {
    padding-bottom: 7.5rem;
}
  
.padding-8-rem-bottom {
    padding-bottom: 8rem;
}
  
.padding-8-half-rem-bottom {
    padding-bottom: 8.5rem;
}
  
.padding-9-rem-bottom {
    padding-bottom: 9rem;
}
  
.padding-9-half-rem-bottom {
    padding-bottom: 9.5rem;
}
  
.padding-10-rem-bottom {
    padding-bottom: 10rem;
}
  
.padding-10-half-rem-bottom {
    padding-bottom: 10.5rem;
}
  
  /* padding right */
  
.padding-one-right {
    padding-right: 1%;
}
  
.padding-two-right {
    padding-right: 2%;
}
  
.padding-three-right {
    padding-right: 3%;
}
  
.padding-four-right {
    padding-right: 4%;
}
  
.padding-five-right {
    padding-right: 5%;
}
  
.padding-six-right {
    padding-right: 6%;
}
  
.padding-seven-right {
    padding-right: 7%;
}
  
.padding-eight-right {
    padding-right: 8%;
}
  
.padding-nine-right {
    padding-right: 9%;
}
  
.padding-ten-right {
    padding-right: 10%;
}
  
.padding-eleven-right {
    padding-right: 11%;
}
  
.padding-twelve-right {
    padding-right: 12%;
}
  
.padding-thirteen-right {
    padding-right: 13%;
}
  
.padding-fourteen-right {
    padding-right: 14%;
}
  
.padding-fifteen-right {
    padding-right: 15%;
}
  
.padding-sixteen-right {
    padding-right: 16%;
}
  
.padding-seventeen-right {
    padding-right: 17%;
}
  
.padding-eighteen-right {
    padding-right: 18%;
}
  
.padding-nineteen-right {
    padding-right: 19%;
}
  
.padding-twenty-right {
    padding-right: 20%;
}
  
.padding-5px-right {
    padding-right: 5px;
}
  
.padding-10px-right {
    padding-right: 10px;
}
  
.padding-15px-right {
    padding-right: 15px;
}
  
.padding-20px-right {
    padding-right: 20px;
}
  
.padding-25px-right {
    padding-right: 25px;
}
  
.padding-30px-right {
    padding-right: 30px;
}
  
.padding-35px-right {
    padding-right: 35px;
}
  
.padding-40px-right {
    padding-right: 40px;
}
  
.padding-45px-right {
    padding-right: 45px;
}
  
.padding-50px-right {
    padding-right: 50px;
}
  
.padding-55px-right {
    padding-right: 55px;
}
  
.padding-60px-right {
    padding-right: 60px;
}
  
.padding-65px-right {
    padding-right: 65px;
}
  
.padding-70px-right {
    padding-right: 70px;
}
  
.padding-75px-right {
    padding-right: 75px;
}
  
.padding-80px-right {
    padding-right: 80px;
}
  
.padding-85px-right {
    padding-right: 85px;
}
  
.padding-90px-right {
    padding-right: 90px;
}
  
.padding-95px-right {
    padding-right: 95px;
}
  
.padding-100px-right {
    padding-right: 100px;
}
  
.padding-1-rem-right {
    padding-right: 1rem;
}
  
.padding-1-half-rem-right {
    padding-right: 1.5rem;
}
  
.padding-2-rem-right {
    padding-right: 2rem;
}
  
.padding-2-half-rem-right {
    padding-right: 2.5rem;
}
  
.padding-3-rem-right {
    padding-right: 3rem;
}
  
.padding-3-half-rem-right {
    padding-right: 3.5rem;
}
  
.padding-4-rem-right {
    padding-right: 4rem;
}
  
.padding-4-half-rem-right {
    padding-right: 4.5rem;
}
  
.padding-5-rem-right {
    padding-right: 5rem;
}
  
.padding-5-half-rem-right {
    padding-right: 5.5rem;
}
  
.padding-6-rem-right {
    padding-right: 6rem;
}
  
.padding-6-half-rem-right {
    padding-right: 6.5rem;
}
  
.padding-7-rem-right {
    padding-right: 7rem;
}
  
.padding-7-half-rem-right {
    padding-right: 7.5rem;
}
  
.padding-8-rem-right {
    padding-right: 8rem;
}
  
.padding-8-half-rem-right {
    padding-right: 8.5rem;
}
  
.padding-9-rem-right {
    padding-right: 9rem;
}
  
.padding-9-half-rem-right {
    padding-right: 9.5rem;
}
  
.padding-10-rem-right {
    padding-right: 10rem;
}
  
.padding-10-half-rem-right {
    padding-right: 10.5rem;
}
  
  /* padding left */
  
.padding-one-left {
    padding-left: 1%;
}
  
.padding-two-left {
    padding-left: 2%;
}
  
.padding-three-left {
    padding-left: 3%;
}
  
.padding-four-left {
    padding-left: 4%;
}
  
.padding-five-left {
    padding-left: 5%;
}
  
.padding-six-left {
    padding-left: 6%;
}
  
.padding-seven-left {
    padding-left: 7%;
}
  
.padding-eight-left {
    padding-left: 8%;
}
  
.padding-nine-left {
    padding-left: 9%;
}
  
.padding-ten-left {
    padding-left: 10%;
}
  
.padding-eleven-left {
    padding-left: 11%;
}
  
.padding-twelve-left {
    padding-left: 12%;
}
  
.padding-thirteen-left {
    padding-left: 13%;
}
  
.padding-fourteen-left {
    padding-left: 14%;
}
  
.padding-fifteen-left {
    padding-left: 15%;
}
  
.padding-sixteen-left {
    padding-left: 16%;
}
  
.padding-seventeen-left {
    padding-left: 17%;
}
  
.padding-eighteen-left {
    padding-left: 18%;
}
  
.padding-nineteen-left {
    padding-left: 19%;
}
  
.padding-twenty-left {
    padding-left: 20%;
}
  
.padding-5px-left {
    padding-left: 5px;
}
  
.padding-10px-left {
    padding-left: 10px;
}
  
.padding-15px-left {
    padding-left: 15px;
}
  
.padding-20px-left {
    padding-left: 20px;
}
  
.padding-25px-left {
    padding-left: 25px;
}
  
.padding-30px-left {
    padding-left: 30px;
}
  
.padding-35px-left {
    padding-left: 35px;
}
  
.padding-40px-left {
    padding-left: 40px;
}
  
.padding-45px-left {
    padding-left: 45px;
}
  
.padding-50px-left {
    padding-left: 50px;
}
  
.padding-55px-left {
    padding-left: 55px;
}
  
.padding-60px-left {
    padding-left: 60px;
}
  
.padding-65px-left {
    padding-left: 65px;
}
  
.padding-70px-left {
    padding-left: 70px;
}
  
.padding-75px-left {
    padding-left: 75px;
}
  
.padding-80px-left {
    padding-left: 80px;
}
  
.padding-85px-left {
    padding-left: 85px;
}
  
.padding-90px-left {
    padding-left: 90px;
}
  
.padding-95px-left {
    padding-left: 95px;
}
  
.padding-100px-left {
    padding-left: 100px;
}
  
.padding-1-rem-left {
    padding-left: 1rem;
}
  
.padding-1-half-rem-left {
    padding-left: 1.5rem;
}
  
.padding-2-rem-left {
    padding-left: 2rem;
}
  
.padding-2-half-rem-left {
    padding-left: 2.5rem;
}
  
.padding-3-rem-left {
    padding-left: 3rem;
}
  
.padding-3-half-rem-left {
    padding-left: 3.5rem;
}
  
.padding-4-rem-left {
    padding-left: 4rem;
}
  
.padding-4-half-rem-left {
    padding-left: 4.5rem;
}
  
.padding-5-rem-left {
    padding-left: 5rem;
}
  
.padding-5-half-rem-left {
    padding-left: 5.5rem;
}
  
.padding-6-rem-left {
    padding-left: 6rem;
}
  
.padding-6-half-rem-left {
    padding-left: 6.5rem;
}
  
.padding-7-rem-left {
    padding-left: 7rem;
}
  
.padding-7-half-rem-left {
    padding-left: 7.5rem;
}
  
.padding-8-rem-left {
    padding-left: 8rem;
}
  
.padding-8-half-rem-left {
    padding-left: 8.5rem;
}
  
.padding-9-rem-left {
    padding-left: 9rem;
}
  
.padding-9-half-rem-left {
    padding-left: 9.5rem;
}
  
.padding-10-rem-left {
    padding-left: 10rem;
}
  
.padding-10-half-rem-left {
    padding-left: 10.5rem;
}
  
  /* padding top bottom */
  
.padding-one-tb {
    padding-top: 1%;
    padding-bottom: 1%;
}
  
.padding-two-tb {
    padding-top: 2%;
    padding-bottom: 2%;
}
  
.padding-three-tb {
    padding-top: 3%;
    padding-bottom: 3%;
}
  
.padding-four-tb {
    padding-top: 4%;
    padding-bottom: 4%;
}
  
.padding-five-tb {
    padding-top: 5%;
    padding-bottom: 5%;
}
  
.padding-six-tb {
    padding-top: 6%;
    padding-bottom: 6%;
}
  
.padding-seven-tb {
    padding-top: 7%;
    padding-bottom: 7%;
}
  
.padding-eight-tb {
    padding-top: 8%;
    padding-bottom: 8%;
}
  
.padding-nine-tb {
    padding-top: 9%;
    padding-bottom: 9%;
}
  
.padding-ten-tb {
    padding-top: 10%;
    padding-bottom: 10%;
}
  
.padding-eleven-tb {
    padding-top: 11%;
    padding-bottom: 11%;
}
  
.padding-twelve-tb {
    padding-top: 12%;
    padding-bottom: 12%;
}
  
.padding-thirteen-tb {
    padding-top: 13%;
    padding-bottom: 13%;
}
  
.padding-fourteen-tb {
    padding-top: 14%;
    padding-bottom: 14%;
}
  
.padding-fifteen-tb {
    padding-top: 15%;
    padding-bottom: 15%;
}
  
.padding-sixteen-tb {
    padding-top: 16%;
    padding-bottom: 16%;
}
  
.padding-seventeen-tb {
    padding-top: 17%;
    padding-bottom: 17%;
}
  
.padding-eighteen-tb {
    padding-top: 18%;
    padding-bottom: 18%;
}
  
.padding-nineteen-tb {
    padding-top: 19%;
    padding-bottom: 19%;
}
  
.padding-twenty-tb {
    padding-top: 20%;
    padding-bottom: 20%;
}
  
.padding-5px-tb {
    padding-top: 5px;
    padding-bottom: 5px;
}
  
.padding-10px-tb {
    padding-top: 10px;
    padding-bottom: 10px;
}
  
.padding-15px-tb {
    padding-top: 15px;
    padding-bottom: 15px;
}
  
.padding-20px-tb {
    padding-top: 20px;
    padding-bottom: 20px;
}
  
.padding-25px-tb {
    padding-top: 25px;
    padding-bottom: 25px;
}
  
.padding-30px-tb {
    padding-top: 30px;
    padding-bottom: 30px;
}
  
.padding-35px-tb {
    padding-top: 35px;
    padding-bottom: 35px;
}
  
.padding-40px-tb {
    padding-top: 40px;
    padding-bottom: 40px;
}
  
.padding-45px-tb {
    padding-top: 45px;
    padding-bottom: 45px;
}
  
.padding-50px-tb {
    padding-top: 50px;
    padding-bottom: 50px;
}
  
.padding-55px-tb {
    padding-top: 55px;
    padding-bottom: 55px;
}
  
.padding-60px-tb {
    padding-top: 60px;
    padding-bottom: 60px;
}
  
.padding-65px-tb {
    padding-top: 65px;
    padding-bottom: 65px;
}
  
.padding-70px-tb {
    padding-top: 70px;
    padding-bottom: 70px;
}
  
.padding-75px-tb {
    padding-top: 75px;
    padding-bottom: 75px;
}
  
.padding-80px-tb {
    padding-top: 80px;
    padding-bottom: 80px;
}
  
.padding-85px-tb {
    padding-top: 85px;
    padding-bottom: 85px;
}
  
.padding-90px-tb {
    padding-top: 90px;
    padding-bottom: 90px;
}
  
.padding-95px-tb {
    padding-top: 95px;
    padding-bottom: 95px;
}
  
.padding-100px-tb {
    padding-top: 100px;
    padding-bottom: 100px;
}
  
.padding-1-rem-tb {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
  
.padding-1-half-rem-tb {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
  
.padding-2-rem-tb {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
  
.padding-2-half-rem-tb {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}
  
.padding-3-rem-tb {
    padding-top: 3rem;
    padding-bottom: 3rem;
}
  
.padding-3-half-rem-tb {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
}
  
.padding-4-rem-tb {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
  
.padding-4-half-rem-tb {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}
  
.padding-5-rem-tb {
    padding-top: 5rem;
    padding-bottom: 5rem;
}
  
.padding-5-half-rem-tb {
    padding-top: 5.5rem;
    padding-bottom: 5.5rem;
}
  
.padding-6-rem-tb {
    padding-top: 6rem;
    padding-bottom: 6rem;
}
  
.padding-6-half-rem-tb {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
}
  
.padding-7-rem-tb {
    padding-top: 7rem;
    padding-bottom: 7rem;
}
  
.padding-7-half-rem-tb {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
}
  
.padding-8-rem-tb {
    padding-top: 8rem;
    padding-bottom: 8rem;
}
  
.padding-8-half-rem-tb {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
}
  
.padding-9-rem-tb {
    padding-top: 9rem;
    padding-bottom: 9rem;
}
  
.padding-9-half-rem-tb {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
}
  
.padding-10-rem-tb {
    padding-top: 10rem;
    padding-bottom: 10rem;
}
  
.padding-10-half-rem-tb {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
}
  
  
.padding-custom-rem-tb {
    padding-top: 3.5rem;
    padding-bottom: 11.5rem;
}
  
  /* padding left right */
  
.padding-one-lr {
    padding-left: 1%;
    padding-right: 1%;
}
  
.padding-two-lr {
    padding-left: 2%;
    padding-right: 2%;
}
  
.padding-three-lr {
    padding-left: 3%;
    padding-right: 3%;
}
  
.padding-four-lr {
    padding-left: 4%;
    padding-right: 4%;
}
  
.padding-five-lr {
    padding-left: 5%;
    padding-right: 5%;
}
  
.padding-six-lr {
    padding-left: 6%;
    padding-right: 6%;
}
  
.padding-seven-lr {
    padding-left: 7%;
    padding-right: 7%;
}
  
.padding-eight-lr {
    padding-left: 8%;
    padding-right: 8%;
}
  
.padding-nine-lr {
    padding-left: 9%;
    padding-right: 9%;
}
  
.padding-ten-lr {
    padding-left: 10%;
    padding-right: 10%;
}
  
.padding-eleven-lr {
    padding-left: 11%;
    padding-right: 11%;
}
  
.padding-twelve-lr {
    padding-left: 12%;
    padding-right: 12%;
}
  
.padding-thirteen-lr {
    padding-left: 13%;
    padding-right: 13%;
}
  
.padding-fourteen-lr {
    padding-left: 14%;
    padding-right: 14%;
}
  
.padding-fifteen-lr {
    padding-left: 15%;
    padding-right: 15%;
}
  
.padding-sixteen-lr {
    padding-left: 16%;
    padding-right: 16%;
}
  
.padding-seventeen-lr {
    padding-left: 17%;
    padding-right: 17%;
}
  
.padding-eighteen-lr {
    padding-left: 18%;
    padding-right: 18%;
}
  
.padding-nineteen-lr {
    padding-left: 19%;
    padding-right: 19%;
}
  
.padding-twenty-lr {
    padding-left: 20%;
    padding-right: 20%;
}
  
.padding-5px-lr {
    padding-left: 5px;
    padding-right: 5px;
}
  
.padding-10px-lr {
    padding-left: 10px;
    padding-right: 10px;
}
  
.padding-15px-lr {
    padding-left: 15px;
    padding-right: 15px;
}
  
.padding-20px-lr {
    padding-left: 20px;
    padding-right: 20px;
}
  
.padding-25px-lr {
    padding-left: 25px;
    padding-right: 25px;
}
  
.padding-30px-lr {
    padding-left: 30px;
    padding-right: 30px;
}
  
.padding-35px-lr {
    padding-left: 35px;
    padding-right: 35px;
}
  
.padding-40px-lr {
    padding-left: 40px;
    padding-right: 40px;
}
  
.padding-45px-lr {
    padding-left: 45px;
    padding-right: 45px;
}
  
.padding-50px-lr {
    padding-left: 50px;
    padding-right: 50px;
}
  
.padding-55px-lr {
    padding-left: 55px;
    padding-right: 55px;
}
  
.padding-60px-lr {
    padding-left: 60px;
    padding-right: 60px;
}
  
.padding-65px-lr {
    padding-left: 65px;
    padding-right: 65px;
}
  
.padding-70px-lr {
    padding-left: 70px;
    padding-right: 70px;
}
  
.padding-75px-lr {
    padding-left: 75px;
    padding-right: 75px;
}
  
.padding-80px-lr {
    padding-left: 80px;
    padding-right: 80px;
}
  
.padding-85px-lr {
    padding-left: 85px;
    padding-right: 85px;
}
  
.padding-90px-lr {
    padding-left: 90px;
    padding-right: 90px;
}
  
.padding-95px-lr {
    padding-left: 95px;
    padding-right: 95px;
}
  
.padding-100px-lr {
    padding-left: 100px;
    padding-right: 100px;
}
  
.padding-1-rem-lr {
    padding-left: 1rem;
    padding-right: 1rem;
}
  
.padding-1-half-rem-lr {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
  
.padding-2-rem-lr {
    padding-left: 2rem;
    padding-right: 2rem;
}
  
.padding-2-half-rem-lr {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
  
.padding-3-rem-lr {
    padding-left: 3rem;
    padding-right: 3rem;
}
  
.padding-3-half-rem-lr {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}
  
.padding-4-rem-lr {
    padding-left: 4rem;
    padding-right: 4rem;
}
  
.padding-4-half-rem-lr {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
}
  
.padding-5-rem-lr {
    padding-left: 5rem;
    padding-right: 5rem;
}
  
.padding-5-half-rem-lr {
    padding-left: 5.5rem;
    padding-right: 5.5rem;
}
  
.padding-6-rem-lr {
    padding-left: 6rem;
    padding-right: 6rem;
}
  
.padding-6-half-rem-lr {
    padding-left: 6.5rem;
    padding-right: 6.5rem;
}
  
.padding-7-rem-lr {
    padding-left: 7rem;
    padding-right: 7rem;
}
  
.padding-7-half-rem-lr {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
}
  
.padding-8-rem-lr {
    padding-left: 8rem;
    padding-right: 8rem;
}
  
.padding-8-half-rem-lr {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
}
  
.padding-9-rem-lr {
    padding-left: 9rem;
    padding-right: 9rem;
}
  
.padding-9-half-rem-lr {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
}
  
.padding-10-rem-lr {
    padding-left: 10rem;
    padding-right: 10rem;
}
  
.padding-10-half-rem-lr {
    padding-left: 10.5rem;
    padding-right: 10.5rem;
}
  
.no-padding {
    padding: 0 !important;
}
  
.no-padding-lr {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
  
  .no-padding-tb {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
  
  .no-padding-top {
    padding-top: 0 !important;
}
  
  .no-padding-bottom {
    padding-bottom: 0 !important;
}
  
.no-padding-left {
    padding-left: 0 !important;
}
  
.no-padding-right {
    padding-right: 0 !important;
}

/*RESPONSIVE*/

@media(max-width: 1600px){
    .xl-padding-one-all { padding:1%; }
    .xl-padding-two-all { padding:2%; }
    .xl-padding-three-all { padding:3%; }
    .xl-padding-four-all { padding:4%; }
    .xl-padding-five-all { padding:5%; }
    .xl-padding-six-all { padding:6%; }
    .xl-padding-seven-all { padding:7%; }
    .xl-padding-eight-all { padding:8%; }
    .xl-padding-nine-all { padding:9%; }
    .xl-padding-ten-all { padding:10%; }
    .xl-padding-eleven-all { padding:11%; }
    .xl-padding-twelve-all { padding:12%; }
    .xl-padding-thirteen-all { padding:13%; }
    .xl-padding-fourteen-all { padding:14%; }
    .xl-padding-fifteen-all { padding:15%; }
    .xl-padding-sixteen-all { padding:16%; }
    .xl-padding-seventeen-all { padding:17%; }
    .xl-padding-eighteen-all { padding:18%; }
    .xl-padding-nineteen-all { padding:19%; }
    .xl-padding-twenty-all { padding:20%; }
    .xl-padding-5px-all { padding:5px; }
    .xl-padding-10px-all { padding:10px; }
    .xl-padding-15px-all { padding:15px; }
    .xl-padding-20px-all { padding:20px; }
    .xl-padding-25px-all { padding:25px; }
    .xl-padding-30px-all { padding:30px; }
    .xl-padding-35px-all { padding:35px; }
    .xl-padding-40px-all { padding:40px; }
    .xl-padding-45px-all { padding:45px; }
    .xl-padding-50px-all { padding:50px; }
    .xl-padding-55px-all { padding:55px; }
    .xl-padding-60px-all { padding:60px; }
    .xl-padding-65px-all { padding:65px; }
    .xl-padding-70px-all { padding:70px; }
    .xl-padding-75px-all { padding:75px; }
    .xl-padding-80px-all { padding:80px; }
    .xl-padding-85px-all { padding:85px; }
    .xl-padding-90px-all { padding:90px; }
    .xl-padding-95px-all { padding:95px; }
    .xl-padding-100px-all { padding:100px; }
    .xl-padding-1-rem-all { padding: 1rem; }
    .xl-padding-1-half-rem-all { padding: 1.5rem; }
    .xl-padding-2-rem-all { padding: 2rem; }
    .xl-padding-2-half-rem-all { padding: 2.5rem; }
    .xl-padding-3-rem-all { padding: 3rem; }
    .xl-padding-3-half-rem-all { padding: 3.5rem; }
    .xl-padding-4-rem-all { padding: 4rem; }
    .xl-padding-4-half-rem-all { padding: 4.5rem; }
    .xl-padding-5-rem-all { padding: 5rem; }
    .xl-padding-5-half-rem-all { padding: 5.5rem; }
    .xl-padding-6-rem-all { padding: 6rem; }
    .xl-padding-6-half-rem-all { padding: 6.5rem; }
    .xl-padding-7-rem-all { padding: 7rem; }
    .xl-padding-7-half-rem-all { padding: 7.5rem; }
    .xl-padding-8-rem-all { padding: 8rem; }
    .xl-padding-8-half-rem-all { padding: 8.5rem; }
    .xl-padding-9-rem-all { padding: 9rem; }
    .xl-padding-9-half-rem-all { padding: 9.5rem; }
    .xl-padding-10-rem-all { padding: 10rem; }
    .xl-padding-10-half-rem-all { padding: 10.5rem; }

    /* padding top */
    .xl-padding-one-top { padding-top:1%; }
    .xl-padding-two-top { padding-top:2%; }
    .xl-padding-three-top { padding-top:3%; }
    .xl-padding-four-top { padding-top:4%; }
    .xl-padding-five-top { padding-top:5%; }
    .xl-padding-six-top { padding-top:6%; }
    .xl-padding-seven-top { padding-top:7%; }
    .xl-padding-eight-top { padding-top:8%; }
    .xl-padding-nine-top { padding-top:9%; }
    .xl-padding-ten-top { padding-top:10%; }
    .xl-padding-eleven-top { padding-top:11%; }
    .xl-padding-twelve-top { padding-top:12%; }
    .xl-padding-thirteen-top { padding-top:13%; }
    .xl-padding-fourteen-top { padding-top:14%; }
    .xl-padding-fifteen-top { padding-top:15%; }
    .xl-padding-sixteen-top { padding-top:16%; }
    .xl-padding-seventeen-top { padding-top:17%; }
    .xl-padding-eighteen-top { padding-top:18%; }
    .xl-padding-nineteen-top { padding-top:19%; }
    .xl-padding-twenty-top { padding-top:20%; }
    .xl-padding-5px-top { padding-top:5px; }
    .xl-padding-10px-top { padding-top:10px; }
    .xl-padding-15px-top { padding-top:15px; }
    .xl-padding-20px-top { padding-top:20px; }
    .xl-padding-25px-top { padding-top:25px; }
    .xl-padding-30px-top { padding-top:30px; }
    .xl-padding-35px-top { padding-top:35px; }
    .xl-padding-40px-top { padding-top:40px; }
    .xl-padding-45px-top { padding-top:45px; }
    .xl-padding-50px-top { padding-top:50px; }
    .xl-padding-55px-top { padding-top:55px; }
    .xl-padding-60px-top { padding-top:60px; }
    .xl-padding-65px-top { padding-top:65px; }
    .xl-padding-70px-top { padding-top:70px; }
    .xl-padding-75px-top { padding-top:75px; }
    .xl-padding-80px-top { padding-top:80px; }
    .xl-padding-85px-top { padding-top:85px; }
    .xl-padding-90px-top { padding-top:90px; }
    .xl-padding-95px-top { padding-top:95px; }
    .xl-padding-100px-top { padding-top:100px; }
    .xl-padding-1-rem-top { padding-top: 1rem; }
    .xl-padding-1-half-rem-top { padding-top: 1.5rem; }
    .xl-padding-2-rem-top { padding-top: 2rem; }
    .xl-padding-2-half-rem-top { padding-top: 2.5rem; }
    .xl-padding-3-rem-top { padding-top: 3rem; }
    .xl-padding-3-half-rem-top { padding-top: 3.5rem; }
    .xl-padding-4-rem-top { padding-top: 4rem; }
    .xl-padding-4-half-rem-top { padding-top: 4.5rem; }
    .xl-padding-5-rem-top { padding-top: 5rem; }
    .xl-padding-5-half-rem-top { padding-top: 5.5rem; }
    .xl-padding-6-rem-top { padding-top: 6rem; }
    .xl-padding-6-half-rem-top { padding-top: 6.5rem; }
    .xl-padding-7-rem-top { padding-top: 7rem; }
    .xl-padding-7-half-rem-top { padding-top: 7.5rem; }
    .xl-padding-8-rem-top { padding-top: 8rem; }
    .xl-padding-8-half-rem-top { padding-top: 8.5rem; }
    .xl-padding-9-rem-top { padding-top: 9rem; }
    .xl-padding-9-half-rem-top { padding-top: 9.5rem; }
    .xl-padding-10-rem-top { padding-top: 10rem; }
    .xl-padding-10-half-rem-top { padding-top: 10.5rem; }

    /* padding bottom */
    .xl-padding-one-bottom { padding-bottom:1%; }
    .xl-padding-two-bottom { padding-bottom:2%; }
    .xl-padding-three-bottom { padding-bottom:3%; }
    .xl-padding-four-bottom { padding-bottom:4%; }
    .xl-padding-five-bottom { padding-bottom:5%; }
    .xl-padding-six-bottom { padding-bottom:6%; }
    .xl-padding-seven-bottom { padding-bottom:7%; }
    .xl-padding-eight-bottom { padding-bottom:8%; }
    .xl-padding-nine-bottom { padding-bottom:9%; }
    .xl-padding-ten-bottom { padding-bottom:10%; }
    .xl-padding-eleven-bottom { padding-bottom:11%; }
    .xl-padding-twelve-bottom { padding-bottom:12%; }
    .xl-padding-thirteen-bottom { padding-bottom:13%; }
    .xl-padding-fourteen-bottom { padding-bottom:14%; }
    .xl-padding-fifteen-bottom { padding-bottom:15%; }
    .xl-padding-sixteen-bottom { padding-bottom:16%; }
    .xl-padding-seventeen-bottom { padding-bottom:17%; }
    .xl-padding-eighteen-bottom { padding-bottom:18%; }
    .xl-padding-nineteen-bottom { padding-bottom:19%; }
    .xl-padding-twenty-bottom { padding-bottom:20%; }
    .xl-padding-5px-bottom { padding-bottom:5px; }
    .xl-padding-10px-bottom { padding-bottom:10px; }
    .xl-padding-15px-bottom { padding-bottom:15px; }
    .xl-padding-20px-bottom { padding-bottom:20px; }
    .xl-padding-25px-bottom { padding-bottom:25px; }
    .xl-padding-30px-bottom { padding-bottom:30px; }
    .xl-padding-35px-bottom { padding-bottom:35px; }
    .xl-padding-40px-bottom { padding-bottom:40px; }
    .xl-padding-45px-bottom { padding-bottom:45px; }
    .xl-padding-50px-bottom { padding-bottom:50px; }
    .xl-padding-55px-bottom { padding-bottom:55px; }
    .xl-padding-60px-bottom { padding-bottom:60px; }
    .xl-padding-65px-bottom { padding-bottom:65px; }
    .xl-padding-70px-bottom { padding-bottom:70px; }
    .xl-padding-75px-bottom { padding-bottom:75px; }
    .xl-padding-80px-bottom { padding-bottom:80px; }
    .xl-padding-85px-bottom { padding-bottom:85px; }
    .xl-padding-90px-bottom { padding-bottom:90px; }
    .xl-padding-95px-bottom { padding-bottom:95px; }
    .xl-padding-100px-bottom { padding-bottom:100px; }
    .xl-padding-1-rem-bottom { padding-bottom: 1rem; }
    .xl-padding-1-half-rem-bottom { padding-bottom: 1.5rem; }
    .xl-padding-2-rem-bottom { padding-bottom: 2rem; }
    .xl-padding-2-half-rem-bottom { padding-bottom: 2.5rem; }
    .xl-padding-3-rem-bottom { padding-bottom: 3rem; }
    .xl-padding-3-half-rem-bottom { padding-bottom: 3.5rem; }
    .xl-padding-4-rem-bottom { padding-bottom: 4rem; }
    .xl-padding-4-half-rem-bottom { padding-bottom: 4.5rem; }
    .xl-padding-5-rem-bottom { padding-bottom: 5rem; }
    .xl-padding-5-half-rem-bottom { padding-bottom: 5.5rem; }
    .xl-padding-6-rem-bottom { padding-bottom: 6rem; }
    .xl-padding-6-half-rem-bottom { padding-bottom: 6.5rem; }
    .xl-padding-7-rem-bottom { padding-bottom: 7rem; }
    .xl-padding-7-half-rem-bottom { padding-bottom: 7.5rem; }
    .xl-padding-8-rem-bottom { padding-bottom: 8rem; }
    .xl-padding-8-half-rem-bottom { padding-bottom: 8.5rem; }
    .xl-padding-9-rem-bottom { padding-bottom: 9rem; }
    .xl-padding-9-half-rem-bottom { padding-bottom: 9.5rem; }
    .xl-padding-10-rem-bottom { padding-bottom: 10rem; }
    .xl-padding-10-half-rem-bottom { padding-bottom: 10.5rem; }

    /* padding right */
    .xl-padding-one-right { padding-right:1%; }
    .xl-padding-two-right { padding-right:2%; }
    .xl-padding-three-right { padding-right:3%; }
    .xl-padding-four-right { padding-right:4% }
    .xl-padding-five-right { padding-right:5%; }
    .xl-padding-six-right { padding-right:6%; }
    .xl-padding-seven-right { padding-right:7%; }
    .xl-padding-eight-right { padding-right:8%; }
    .xl-padding-nine-right { padding-right:9%; }
    .xl-padding-ten-right { padding-right:10%; }
    .xl-padding-eleven-right { padding-right:11%; }
    .xl-padding-twelve-right { padding-right:12%; }
    .xl-padding-thirteen-right { padding-right:13%; }
    .xl-padding-fourteen-right { padding-right:14%; }
    .xl-padding-fifteen-right { padding-right:15%; }
    .xl-padding-sixteen-right { padding-right:16%; }
    .xl-padding-seventeen-right { padding-right:17%; }
    .xl-padding-eighteen-right { padding-right:18%; }
    .xl-padding-nineteen-right { padding-right:19%; }
    .xl-padding-twenty-right { padding-right:20%; }
    .xl-padding-5px-right { padding-right:5px; }
    .xl-padding-10px-right { padding-right:10px; }
    .xl-padding-15px-right { padding-right:15px; }
    .xl-padding-20px-right { padding-right:20px; }
    .xl-padding-25px-right { padding-right:25px; }
    .xl-padding-30px-right { padding-right:30px; }
    .xl-padding-35px-right { padding-right:35px; }
    .xl-padding-40px-right { padding-right:40px; }
    .xl-padding-45px-right { padding-right:45px; }
    .xl-padding-50px-right { padding-right:50px; }
    .xl-padding-55px-right { padding-right:55px; }
    .xl-padding-60px-right { padding-right:60px; }
    .xl-padding-65px-right { padding-right:65px; }
    .xl-padding-70px-right { padding-right:70px; }
    .xl-padding-75px-right { padding-right:75px; }
    .xl-padding-80px-right { padding-right:80px; }
    .xl-padding-85px-right { padding-right:85px; }
    .xl-padding-90px-right { padding-right:90px; }
    .xl-padding-95px-right { padding-right:95px; }
    .xl-padding-100px-right { padding-right:100px; }
    .xl-padding-1-rem-right { padding-right: 1rem; }
    .xl-padding-1-half-rem-right { padding-right: 1.5rem; }
    .xl-padding-2-rem-right { padding-right: 2rem; }
    .xl-padding-2-half-rem-right { padding-right: 2.5rem; }
    .xl-padding-3-rem-right { padding-right: 3rem; }
    .xl-padding-3-half-rem-right { padding-right: 3.5rem; }
    .xl-padding-4-rem-right { padding-right: 4rem; }
    .xl-padding-4-half-rem-right { padding-right: 4.5rem; }
    .xl-padding-5-rem-right { padding-right: 5rem; }
    .xl-padding-5-half-rem-right { padding-right: 5.5rem; }
    .xl-padding-6-rem-right { padding-right: 6rem; }
    .xl-padding-6-half-rem-right { padding-right: 6.5rem; }
    .xl-padding-7-rem-right { padding-right: 7rem; }
    .xl-padding-7-half-rem-right { padding-right: 7.5rem; }
    .xl-padding-8-rem-right { padding-right: 8rem; }
    .xl-padding-8-half-rem-right { padding-right: 8.5rem; }
    .xl-padding-9-rem-right { padding-right: 9rem; }
    .xl-padding-9-half-rem-right { padding-right: 9.5rem; }
    .xl-padding-10-rem-right { padding-right: 10rem; }
    .xl-padding-10-half-rem-right { padding-right: 10.5rem; }

    /* padding left */
    .xl-padding-one-left { padding-left:1%; }
    .xl-padding-two-left { padding-left:2%; }
    .xl-padding-three-left { padding-left:3%; }
    .xl-padding-four-left { padding-left:4%; }
    .xl-padding-five-left { padding-left:5%; }
    .xl-padding-six-left { padding-left:6%; }
    .xl-padding-seven-left { padding-left:7%; }
    .xl-padding-eight-left { padding-left:8%; }
    .xl-padding-nine-left { padding-left:9%; }
    .xl-padding-ten-left { padding-left:10%; }
    .xl-padding-eleven-left { padding-left:11%; }
    .xl-padding-twelve-left { padding-left:12%; }
    .xl-padding-thirteen-left { padding-left:13%; }
    .xl-padding-fourteen-left { padding-left:14%; }
    .xl-padding-fifteen-left { padding-left:15%; }
    .xl-padding-sixteen-left { padding-left:16%; }
    .xl-padding-seventeen-left { padding-left:17%; }
    .xl-padding-eighteen-left { padding-left:18%; }
    .xl-padding-nineteen-left { padding-left:19%; }
    .xl-padding-twenty-left { padding-left:20%; }
    .xl-padding-5px-left { padding-left:5px; }
    .xl-padding-10px-left { padding-left:10px; }
    .xl-padding-15px-left { padding-left:15px; }
    .xl-padding-20px-left { padding-left:20px; }
    .xl-padding-25px-left { padding-left:25px; }
    .xl-padding-30px-left { padding-left:30px; }
    .xl-padding-35px-left { padding-left:35px; }
    .xl-padding-40px-left { padding-left:40px; }
    .xl-padding-45px-left { padding-left:45px; }
    .xl-padding-50px-left { padding-left:50px; }
    .xl-padding-55px-left { padding-left:55px; }
    .xl-padding-60px-left { padding-left:60px; }
    .xl-padding-65px-left { padding-left:65px; }
    .xl-padding-70px-left { padding-left:70px; }
    .xl-padding-75px-left { padding-left:75px; }
    .xl-padding-80px-left { padding-left:80px; }
    .xl-padding-85px-left { padding-left:85px; }
    .xl-padding-90px-left { padding-left:90px; }
    .xl-padding-95px-left { padding-left:95px; }
    .xl-padding-100px-left { padding-left:100px; }
    .xl-padding-1-rem-left { padding-left: 1rem; }
    .xl-padding-1-half-rem-left { padding-left: 1.5rem; }
    .xl-padding-2-rem-left { padding-left: 2rem; }
    .xl-padding-2-half-rem-left { padding-left: 2.5rem; }
    .xl-padding-3-rem-left { padding-left: 3rem; }
    .xl-padding-3-half-rem-left { padding-left: 3.5rem; }
    .xl-padding-4-rem-left { padding-left: 4rem; }
    .xl-padding-4-half-rem-left { padding-left: 4.5rem; }
    .xl-padding-5-rem-left { padding-left: 5rem; }
    .xl-padding-5-half-rem-left { padding-left: 5.5rem; }
    .xl-padding-6-rem-left { padding-left: 6rem; }
    .xl-padding-6-half-rem-left { padding-left: 6.5rem; }
    .xl-padding-7-rem-left { padding-left: 7rem; }
    .xl-padding-7-half-rem-left { padding-left: 7.5rem; }
    .xl-padding-8-rem-left { padding-left: 8rem; }
    .xl-padding-8-half-rem-left { padding-left: 8.5rem; }
    .xl-padding-9-rem-left { padding-left: 9rem; }
    .xl-padding-9-half-rem-left { padding-left: 9.5rem; }
    .xl-padding-10-rem-left { padding-left: 10rem; }
    .xl-padding-10-half-rem-left { padding-left: 10.5rem; }

    /* padding top bottom */
    .xl-padding-one-tb { padding-top:1%; padding-bottom:1%; }
    .xl-padding-two-tb { padding-top:2%; padding-bottom:2%; }
    .xl-padding-three-tb { padding-top:3%; padding-bottom:3%; }
    .xl-padding-four-tb { padding-top:4%; padding-bottom:4%; }
    .xl-padding-five-tb { padding-top:5%; padding-bottom:5%; }
    .xl-padding-six-tb { padding-top:6%; padding-bottom:6%; }
    .xl-padding-seven-tb { padding-top:7%; padding-bottom:7%; }
    .xl-padding-eight-tb { padding-top:8%; padding-bottom:8%; }
    .xl-padding-nine-tb { padding-top:9%; padding-bottom:9%; }
    .xl-padding-ten-tb { padding-top:10%; padding-bottom:10%; }
    .xl-padding-eleven-tb { padding-top:11%; padding-bottom:11%; }
    .xl-padding-twelve-tb { padding-top:12%; padding-bottom:12%; }
    .xl-padding-thirteen-tb { padding-top:13%; padding-bottom:13%; }
    .xl-padding-fourteen-tb { padding-top:14%; padding-bottom:14%; }
    .xl-padding-fifteen-tb { padding-top:15%; padding-bottom:15%; }
    .xl-padding-sixteen-tb { padding-top:16%; padding-bottom:16%; }
    .xl-padding-seventeen-tb { padding-top:17%; padding-bottom:17%; }
    .xl-padding-eighteen-tb { padding-top:18%; padding-bottom:18%; }
    .xl-padding-nineteen-tb { padding-top:19%; padding-bottom:19%; }
    .xl-padding-twenty-tb { padding-top:20%; padding-bottom:20%; }
    .xl-padding-5px-tb { padding-top:5px; padding-bottom:5px; }
    .xl-padding-10px-tb { padding-top:10px; padding-bottom:10px; }
    .xl-padding-15px-tb { padding-top:15px; padding-bottom:15px; }
    .xl-padding-20px-tb { padding-top:20px; padding-bottom:20px; }
    .xl-padding-25px-tb { padding-top:25px; padding-bottom:25px; }
    .xl-padding-30px-tb { padding-top:30px; padding-bottom:30px; }
    .xl-padding-35px-tb { padding-top:35px; padding-bottom:35px; }
    .xl-padding-40px-tb { padding-top:40px; padding-bottom:40px; }
    .xl-padding-45px-tb { padding-top:45px; padding-bottom:45px; }
    .xl-padding-50px-tb { padding-top:50px; padding-bottom:50px; }
    .xl-padding-55px-tb { padding-top:55px; padding-bottom:55px; }
    .xl-padding-60px-tb { padding-top:60px; padding-bottom:60px; }
    .xl-padding-65px-tb { padding-top:65px; padding-bottom:65px; }
    .xl-padding-70px-tb { padding-top:70px; padding-bottom:70px; }
    .xl-padding-75px-tb { padding-top:75px; padding-bottom:75px; }
    .xl-padding-80px-tb { padding-top:80px; padding-bottom:80px; }
    .xl-padding-85px-tb { padding-top:85px; padding-bottom:85px; }
    .xl-padding-90px-tb { padding-top:90px; padding-bottom:90px; }
    .xl-padding-95px-tb { padding-top:95px; padding-bottom:95px; }
    .xl-padding-100px-tb { padding-top:100px; padding-bottom:100px; }
    .xl-padding-1-rem-tb { padding-top: 1rem; padding-bottom: 1rem; }
    .xl-padding-1-half-rem-tb { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .xl-padding-2-rem-tb { padding-top: 2rem; padding-bottom: 2rem; }
    .xl-padding-2-half-rem-tb { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .xl-padding-3-rem-tb { padding-top: 3rem; padding-bottom: 3rem; }
    .xl-padding-3-half-rem-tb { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .xl-padding-4-rem-tb { padding-top: 4rem; padding-bottom: 4rem; }
    .xl-padding-4-half-rem-tb { padding-top: 4.5rem; padding-bottom: 4.5rem; }
    .xl-padding-5-rem-tb { padding-top: 5rem; padding-bottom: 5rem; }
    .xl-padding-5-half-rem-tb { padding-top: 5.5rem; padding-bottom: 5.5rem; }
    .xl-padding-6-rem-tb { padding-top: 6rem; padding-bottom: 6rem; }
    .xl-padding-6-half-rem-tb { padding-top: 6.5rem; padding-bottom: 6.5rem; }
    .xl-padding-7-rem-tb { padding-top: 7rem; padding-bottom: 7rem; }
    .xl-padding-7-half-rem-tb { padding-top: 7.5rem; padding-bottom: 7.5rem; }
    .xl-padding-8-rem-tb { padding-top: 8rem; padding-bottom: 8rem; }
    .xl-padding-8-half-rem-tb { padding-top: 8.5rem; padding-bottom: 8.5rem; }
    .xl-padding-9-rem-tb { padding-top: 9rem; padding-bottom: 9rem; }
    .xl-padding-9-half-rem-tb { padding-top: 9.5rem; padding-bottom: 9.5rem; }
    .xl-padding-10-rem-tb { padding-top: 10rem; padding-bottom: 10rem; }
    .xl-padding-10-half-rem-tb { padding-top: 10.5rem; padding-bottom: 10.5rem; }

    /* padding left right */
    .xl-padding-one-lr { padding-left:1%; padding-right:1%; }
    .xl-padding-two-lr { padding-left:2%; padding-right:2%; }
    .xl-padding-three-lr { padding-left:3%; padding-right:3%; }
    .xl-padding-four-lr { padding-left:4%; padding-right:4%; }
    .xl-padding-five-lr { padding-left:5%; padding-right:5%; }
    .xl-padding-six-lr { padding-left:6%; padding-right:6%; }
    .xl-padding-seven-lr { padding-left:7%; padding-right:7%; }
    .xl-padding-eight-lr { padding-left:8%; padding-right:8%; }
    .xl-padding-nine-lr { padding-left:9%; padding-right:9%; }
    .xl-padding-ten-lr { padding-left:10%; padding-right:10%; }
    .xl-padding-eleven-lr { padding-left:11%; padding-right:11%; }
    .xl-padding-twelve-lr { padding-left:12%; padding-right:12%; }
    .xl-padding-thirteen-lr { padding-left:13%; padding-right:13%; }
    .xl-padding-fourteen-lr { padding-left:14%; padding-right:14%; }
    .xl-padding-fifteen-lr { padding-left:15%; padding-right:15%; }
    .xl-padding-sixteen-lr { padding-left:16%; padding-right:16%; }
    .xl-padding-seventeen-lr { padding-left:17%; padding-right:17%; }
    .xl-padding-eighteen-lr { padding-left:18%; padding-right:18%; }
    .xl-padding-nineteen-lr { padding-left:19%; padding-right:19%; }
    .xl-padding-twenty-lr { padding-left:20%; padding-right:20%; }
    .xl-padding-5px-lr { padding-left:5px; padding-right:5px; }
    .xl-padding-10px-lr { padding-left:10px; padding-right:10px; }
    .xl-padding-15px-lr { padding-left:15px; padding-right:15px; }
    .xl-padding-20px-lr { padding-left:20px; padding-right:20px; }
    .xl-padding-25px-lr { padding-left:25px; padding-right:25px; }
    .xl-padding-30px-lr { padding-left:30px; padding-right:30px; }
    .xl-padding-35px-lr { padding-left:35px; padding-right:35px; }
    .xl-padding-40px-lr { padding-left:40px; padding-right:40px; }
    .xl-padding-45px-lr { padding-left:45px; padding-right:45px; }
    .xl-padding-50px-lr { padding-left:50px; padding-right:50px; }
    .xl-padding-55px-lr { padding-left:55px; padding-right:55px; }
    .xl-padding-60px-lr { padding-left:60px; padding-right:60px; }
    .xl-padding-65px-lr { padding-left:65px; padding-right:65px; }
    .xl-padding-70px-lr { padding-left:70px; padding-right:70px; }
    .xl-padding-75px-lr { padding-left:75px; padding-right:75px; }
    .xl-padding-80px-lr { padding-left:80px; padding-right:80px; }
    .xl-padding-85px-lr { padding-left:85px; padding-right:85px; }
    .xl-padding-90px-lr { padding-left:90px; padding-right:90px; }
    .xl-padding-95px-lr { padding-left:95px; padding-right:95px; }
    .xl-padding-100px-lr { padding-left:100px; padding-right:100px; }
    .xl-padding-1-rem-lr { padding-left: 1rem; padding-right: 1rem; }
    .xl-padding-1-half-rem-lr { padding-left: 1.5rem; padding-right: 1.5rem; }
    .xl-padding-2-rem-lr { padding-left: 2rem; padding-right: 2rem; }
    .xl-padding-2-half-rem-lr { padding-left: 2.5rem; padding-right: 2.5rem; }
    .xl-padding-3-rem-lr { padding-left: 3rem; padding-right: 3rem; }
    .xl-padding-3-half-rem-lr { padding-left: 3.5rem; padding-right: 3.5rem; }
    .xl-padding-4-rem-lr { padding-left: 4rem; padding-right: 4rem; }
    .xl-padding-4-half-rem-lr { padding-left: 4.5rem; padding-right: 4.5rem; }
    .xl-padding-5-rem-lr { padding-left: 5rem; padding-right: 5rem; }
    .xl-padding-5-half-rem-lr { padding-left: 5.5rem; padding-right: 5.5rem; }
    .xl-padding-6-rem-lr { padding-left: 6rem; padding-right: 6rem; }
    .xl-padding-6-half-rem-lr { padding-left: 6.5rem; padding-right: 6.5rem; }
    .xl-padding-7-rem-lr { padding-left: 7rem; padding-right: 7rem; }
    .xl-padding-7-half-rem-lr { padding-left: 7.5rem; padding-right: 7.5rem; }
    .xl-padding-8-rem-lr { padding-left: 8rem; padding-right: 8rem; }
    .xl-padding-8-half-rem-lr { padding-left: 8.5rem; padding-right: 8.5rem; }
    .xl-padding-9-rem-lr { padding-left: 9rem; padding-right: 9rem; }
    .xl-padding-9-half-rem-lr { padding-left: 9.5rem; padding-right: 9.5rem; }
    .xl-padding-10-rem-lr { padding-left: 10rem; padding-right: 10rem; }
    .xl-padding-10-half-rem-lr { padding-left: 10.5rem; padding-right: 10.5rem; }    

    .xl-no-padding { padding:0 !important; }
    .xl-no-padding-lr { padding-left: 0 !important; padding-right: 0 !important; }
    .xl-no-padding-tb { padding-top: 0 !important; padding-bottom: 0 !important; }
    .xl-no-padding-top { padding-top:0 !important; }
    .xl-no-padding-bottom { padding-bottom:0 !important; }
    .xl-no-padding-left { padding-left:0 !important; }
    .xl-no-padding-right { padding-right:0 !important; }

       /* width */
    .xl-w-1px { width:1px !important; }
    .xl-w-2px { width:2px !important; }
    .xl-w-3px { width:3px !important; }
    .xl-w-4px { width:4px !important; }
    .xl-w-5px { width:5px !important; }
    .xl-w-6px { width:6px !important; }
    .xl-w-7px { width:7px !important; }
    .xl-w-8px { width:8px !important; }
    .xl-w-9px { width:9px !important; }
    .xl-w-10px { width:10px !important; }
    .xl-w-15px { width:15px !important; }
    .xl-w-20px { width:20px !important; }
    .xl-w-25px { width:25px !important; }
    .xl-w-30px { width:30px !important; }
    .xl-w-35px { width:35px !important; }
    .xl-w-40px { width:40px !important; }
    .xl-w-50px { width:50px !important; }
    .xl-w-55px { width:55px !important; }
    .xl-w-60px { width:60px !important; }
    .xl-w-65px { width:65px !important; }
    .xl-w-70px { width:70px !important; }
    .xl-w-75px { width:75px !important; }
    .xl-w-80px { width:80px !important; }
    .xl-w-85px { width:85px !important; }
    .xl-w-90px { width:90px !important; }
    .xl-w-95px { width:95px !important; }
    .xl-w-100px { width:100px !important; }
    .xl-w-110px { width:110px !important; }
    .xl-w-120px { width:120px !important; }
    .xl-w-130px { width:130px !important; }
    .xl-w-140px { width:140px !important; }
    .xl-w-150px { width:150px !important; }
    .xl-w-160px { width:160px !important; }
    .xl-w-170px { width:170px !important; }
    .xl-w-180px { width:180px !important; }
    .xl-w-190px { width:190px !important; }
    .xl-w-200px { width:200px !important; }
    .xl-w-250px { width:250px !important; }
    .xl-w-300px { width:300px !important; }
    .xl-w-350px { width:350px !important; }
    .xl-w-400px { width:400px !important; }
    .xl-w-450px { width:450px !important; }
    .xl-w-500px { width:500px !important; }
    .xl-w-550px { width:550px !important; }
    .xl-w-600px { width:600px !important; }
    .xl-w-650px { width:650px !important; }
    .xl-w-700px { width:700px !important; }
    .xl-w-750px { width:750px !important; }
    .xl-w-800px { width:800px !important; }
    .xl-w-850px { width:850px !important; }
    .xl-w-900px { width:900px !important; }
    .xl-w-950px { width:950px !important; }
    .xl-w-1000px { width:1000px !important; }
    .xl-w-10 { width: 10% !important; }
    .xl-w-15 { width: 15% !important; }
    .xl-w-20 { width: 20% !important; }
    .xl-w-25 { width: 25% !important; }
    .xl-w-30 { width: 30% !important; }
    .xl-w-35 { width: 35% !important; }
    .xl-w-40 { width: 40% !important; }
    .xl-w-45 { width: 45% !important; }
    .xl-w-50 { width: 50% !important; }
    .xl-w-55 { width: 55% !important; }
    .xl-w-60 { width: 60% !important; }
    .xl-w-65 { width: 65% !important; }
    .xl-w-70 { width: 70% !important; }
    .xl-w-75 { width: 75% !important; }
    .xl-w-80 { width: 80% !important; }
    .xl-w-85 { width: 85% !important; }
    .xl-w-90 { width: 90% !important; }
    .xl-w-95 { width: 95% !important; }
    .xl-w-100 { width: 100% !important; }
    .xl-w-auto { width:auto !important; }

    /* height */
    .xl-h-1px { height: 1px !important; }
    .xl-h-2px { height: 2px !important; }
    .xl-h-3px { height: 3px !important; }
    .xl-h-4px { height: 4px !important; }
    .xl-h-5px { height: 5px !important; }
    .xl-h-6px { height: 6px !important; }
    .xl-h-7px { height: 7px !important; }
    .xl-h-8px { height: 8px !important; }
    .xl-h-9px { height: 9px !important; }
    .xl-h-10px { height: 10px !important; }
    .xl-h-20px { height: 20px !important; }
    .xl-h-30px { height: 30px !important; }
    .xl-h-40px { height: 40px !important; }
    .xl-h-42px { height: 42px !important; }
    .xl-h-50px { height: 50px !important; }
    .xl-h-60px { height: 60px !important; }
    .xl-h-70px { height: 70px !important; }
    .xl-h-80px { height: 80px !important; }
    .xl-h-90px { height: 90px !important; }
    .xl-h-100px { height: 100px !important; }
    .xl-h-110px { height: 110px !important; }
    .xl-h-120px { height: 120px !important; }
    .xl-h-130px { height: 130px !important; }
    .xl-h-140px { height: 140px !important; }
    .xl-h-150px { height: 150px !important; }
    .xl-h-160px { height: 160px !important; }
    .xl-h-170px { height: 170px !important; }
    .xl-h-180px { height: 180px !important; }
    .xl-h-190px { height: 190px !important; }
    .xl-h-200px { height: 200px !important; }
    .xl-h-250px { height: 250px !important; }
    .xl-h-300px { height: 300px !important; }
    .xl-h-350px { height: 350px !important; }
    .xl-h-400px { height: 400px !important; }
    .xl-h-450px { height: 450px !important; }
    .xl-h-500px { height: 500px !important; }
    .xl-h-520px { height: 520px !important; }
    .xl-h-550px { height: 550px !important; }
    .xl-h-580px { height: 580px !important; }
    .xl-h-600px { height: 600px !important; }
    .xl-h-650px { height: 650px !important; }
    .xl-h-700px { height: 700px !important; }
    .xl-h-720px { height: 720px !important; }
    .xl-h-750px { height: 750px !important; }
    .xl-h-800px { height: 800px !important; }
    .xl-h-820px { height: 820px !important; }
    .xl-h-830px { height: 830px !important; }
    .xl-h-850px { height: 850px !important; }
    .xl-h-50 { height: 50% !important; }
    .xl-h-100 { height: 100% !important; }
    .xl-h-auto { height:auto !important; }        

    /* min-height */
    .xl-min-h-100px { min-height: 100px; }
    .xl-min-h-200px { min-height: 200px; }
    .xl-min-h-300px { min-height: 300px; }
    .xl-min-h-400px { min-height: 400px; }
    .xl-min-h-500px { min-height: 500px; }
    .xl-min-h-600px { min-height: 600px; }
    .xl-min-h-700px { min-height: 700px; }
}

@media(max-width:1199px){
    .lg-padding-one-all { padding:1%; }
    .lg-padding-two-all { padding:2%; }
    .lg-padding-three-all { padding:3%; }
    .lg-padding-four-all { padding:4%; }
    .lg-padding-five-all { padding:5%; }
    .lg-padding-six-all { padding:6%; }
    .lg-padding-seven-all { padding:7%; }
    .lg-padding-eight-all { padding:8%; }
    .lg-padding-nine-all { padding:9%; }
    .lg-padding-ten-all { padding:10%; }
    .lg-padding-eleven-all { padding:11%; }
    .lg-padding-twelve-all { padding:12%; }
    .lg-padding-thirteen-all { padding:13%; }
    .lg-padding-fourteen-all { padding:14%; }
    .lg-padding-fifteen-all { padding:15%; }
    .lg-padding-sixteen-all { padding:16%; }
    .lg-padding-seventeen-all { padding:17%; }
    .lg-padding-eighteen-all { padding:18%; }
    .lg-padding-nineteen-all { padding:19%; }
    .lg-padding-twenty-all { padding:20%; }
    .lg-padding-5px-all { padding:5px; }
    .lg-padding-10px-all { padding:10px; }
    .lg-padding-15px-all { padding:15px; }
    .lg-padding-20px-all { padding:20px; }
    .lg-padding-25px-all { padding:25px; }
    .lg-padding-30px-all { padding:30px; }
    .lg-padding-35px-all { padding:35px; }
    .lg-padding-40px-all { padding:40px; }
    .lg-padding-45px-all { padding:45px; }
    .lg-padding-50px-all { padding:50px; }
    .lg-padding-55px-all { padding:55px; }
    .lg-padding-60px-all { padding:60px; }
    .lg-padding-65px-all { padding:65px; }
    .lg-padding-70px-all { padding:70px; }
    .lg-padding-75px-all { padding:75px; }
    .lg-padding-80px-all { padding:80px; }
    .lg-padding-85px-all { padding:85px; }
    .lg-padding-90px-all { padding:90px; }
    .lg-padding-95px-all { padding:95px; }
    .lg-padding-100px-all { padding:100px; }
    .lg-padding-1-rem-all { padding: 1rem; }
    .lg-padding-1-half-rem-all { padding: 1.5rem; }
    .lg-padding-2-rem-all { padding: 2rem; }
    .lg-padding-2-half-rem-all { padding: 2.5rem; }
    .lg-padding-3-rem-all { padding: 3rem; }
    .lg-padding-3-half-rem-all { padding: 3.5rem; }
    .lg-padding-4-rem-all { padding: 4rem; }
    .lg-padding-4-half-rem-all { padding: 4.5rem; }
    .lg-padding-5-rem-all { padding: 5rem; }
    .lg-padding-5-half-rem-all { padding: 5.5rem; }
    .lg-padding-6-rem-all { padding: 6rem; }
    .lg-padding-6-half-rem-all { padding: 6.5rem; }
    .lg-padding-7-rem-all { padding: 7rem; }
    .lg-padding-7-half-rem-all { padding: 7.5rem; }
    .lg-padding-8-rem-all { padding: 8rem; }
    .lg-padding-8-half-rem-all { padding: 8.5rem; }
    .lg-padding-9-rem-all { padding: 9rem; }
    .lg-padding-9-half-rem-all { padding: 9.5rem; }
    .lg-padding-10-rem-all { padding: 10rem; }
    .lg-padding-10-half-rem-all { padding: 10.5rem; }

    /* padding top */
    .lg-padding-one-top { padding-top:1%; }
    .lg-padding-two-top { padding-top:2%; }
    .lg-padding-three-top { padding-top:3%; }
    .lg-padding-four-top { padding-top:4%; }
    .lg-padding-five-top { padding-top:5%; }
    .lg-padding-six-top { padding-top:6%; }
    .lg-padding-seven-top { padding-top:7%; }
    .lg-padding-eight-top { padding-top:8%; }
    .lg-padding-nine-top { padding-top:9%; }
    .lg-padding-ten-top { padding-top:10%; }
    .lg-padding-eleven-top { padding-top:11%; }
    .lg-padding-twelve-top { padding-top:12%; }
    .lg-padding-thirteen-top { padding-top:13%; }
    .lg-padding-fourteen-top { padding-top:14%; }
    .lg-padding-fifteen-top { padding-top:15%; }
    .lg-padding-sixteen-top { padding-top:16%; }
    .lg-padding-seventeen-top { padding-top:17%; }
    .lg-padding-eighteen-top { padding-top:18%; }
    .lg-padding-nineteen-top { padding-top:19%; }
    .lg-padding-twenty-top { padding-top:20%; }
    .lg-padding-5px-top { padding-top:5px; }
    .lg-padding-10px-top { padding-top:10px; }
    .lg-padding-15px-top { padding-top:15px; }
    .lg-padding-20px-top { padding-top:20px; }
    .lg-padding-25px-top { padding-top:25px; }
    .lg-padding-30px-top { padding-top:30px; }
    .lg-padding-35px-top { padding-top:35px; }
    .lg-padding-40px-top { padding-top:40px; }
    .lg-padding-45px-top { padding-top:45px; }
    .lg-padding-50px-top { padding-top:50px; }
    .lg-padding-55px-top { padding-top:55px; }
    .lg-padding-60px-top { padding-top:60px; }
    .lg-padding-65px-top { padding-top:65px; }
    .lg-padding-70px-top { padding-top:70px; }
    .lg-padding-75px-top { padding-top:75px; }
    .lg-padding-80px-top { padding-top:80px; }
    .lg-padding-85px-top { padding-top:85px; }
    .lg-padding-90px-top { padding-top:90px; }
    .lg-padding-95px-top { padding-top:95px; }
    .lg-padding-100px-top { padding-top:100px; }
    .lg-padding-1-rem-top { padding-top: 1rem; }
    .lg-padding-1-half-rem-top { padding-top: 1.5rem; }
    .lg-padding-2-rem-top { padding-top: 2rem; }
    .lg-padding-2-half-rem-top { padding-top: 2.5rem; }
    .lg-padding-3-rem-top { padding-top: 3rem; }
    .lg-padding-3-half-rem-top { padding-top: 3.5rem; }
    .lg-padding-4-rem-top { padding-top: 4rem; }
    .lg-padding-4-half-rem-top { padding-top: 4.5rem; }
    .lg-padding-5-rem-top { padding-top: 5rem; }
    .lg-padding-5-half-rem-top { padding-top: 5.5rem; }
    .lg-padding-6-rem-top { padding-top: 6rem; }
    .lg-padding-6-half-rem-top { padding-top: 6.5rem; }
    .lg-padding-7-rem-top { padding-top: 7rem; }
    .lg-padding-7-half-rem-top { padding-top: 7.5rem; }
    .lg-padding-8-rem-top { padding-top: 8rem; }
    .lg-padding-8-half-rem-top { padding-top: 8.5rem; }
    .lg-padding-9-rem-top { padding-top: 9rem; }
    .lg-padding-9-half-rem-top { padding-top: 9.5rem; }
    .lg-padding-10-rem-top { padding-top: 10rem; }
    .lg-padding-10-half-rem-top { padding-top: 10.5rem; }

    /* padding bottom */
    .lg-padding-one-bottom { padding-bottom:1%; }
    .lg-padding-two-bottom { padding-bottom:2%; }
    .lg-padding-three-bottom { padding-bottom:3%; }
    .lg-padding-four-bottom { padding-bottom:4%; }
    .lg-padding-five-bottom { padding-bottom:5%; }
    .lg-padding-six-bottom { padding-bottom:6%; }
    .lg-padding-seven-bottom { padding-bottom:7%; }
    .lg-padding-eight-bottom { padding-bottom:8%; }
    .lg-padding-nine-bottom { padding-bottom:9%; }
    .lg-padding-ten-bottom { padding-bottom:10%; }
    .lg-padding-eleven-bottom { padding-bottom:11%; }
    .lg-padding-twelve-bottom { padding-bottom:12%; }
    .lg-padding-thirteen-bottom { padding-bottom:13%; }
    .lg-padding-fourteen-bottom { padding-bottom:14%; }
    .lg-padding-fifteen-bottom { padding-bottom:15%; }
    .lg-padding-sixteen-bottom { padding-bottom:16%; }
    .lg-padding-seventeen-bottom { padding-bottom:17%; }
    .lg-padding-eighteen-bottom { padding-bottom:18%; }
    .lg-padding-nineteen-bottom { padding-bottom:19%; }
    .lg-padding-twenty-bottom { padding-bottom:20%; }
    .lg-padding-5px-bottom { padding-bottom:5px; }
    .lg-padding-10px-bottom { padding-bottom:10px; }
    .lg-padding-15px-bottom { padding-bottom:15px; }
    .lg-padding-20px-bottom { padding-bottom:20px; }
    .lg-padding-25px-bottom { padding-bottom:25px; }
    .lg-padding-30px-bottom { padding-bottom:30px; }
    .lg-padding-35px-bottom { padding-bottom:35px; }
    .lg-padding-40px-bottom { padding-bottom:40px; }
    .lg-padding-45px-bottom { padding-bottom:45px; }
    .lg-padding-50px-bottom { padding-bottom:50px; }
    .lg-padding-55px-bottom { padding-bottom:55px; }
    .lg-padding-60px-bottom { padding-bottom:60px; }
    .lg-padding-65px-bottom { padding-bottom:65px; }
    .lg-padding-70px-bottom { padding-bottom:70px; }
    .lg-padding-75px-bottom { padding-bottom:75px; }
    .lg-padding-80px-bottom { padding-bottom:80px; }
    .lg-padding-85px-bottom { padding-bottom:85px; }
    .lg-padding-90px-bottom { padding-bottom:90px; }
    .lg-padding-95px-bottom { padding-bottom:95px; }
    .lg-padding-100px-bottom { padding-bottom:100px; }
    .lg-padding-1-rem-bottom { padding-bottom: 1rem; }
    .lg-padding-1-half-rem-bottom { padding-bottom: 1.5rem; }
    .lg-padding-2-rem-bottom { padding-bottom: 2rem; }
    .lg-padding-2-half-rem-bottom { padding-bottom: 2.5rem; }
    .lg-padding-3-rem-bottom { padding-bottom: 3rem; }
    .lg-padding-3-half-rem-bottom { padding-bottom: 3.5rem; }
    .lg-padding-4-rem-bottom { padding-bottom: 4rem; }
    .lg-padding-4-half-rem-bottom { padding-bottom: 4.5rem; }
    .lg-padding-5-rem-bottom { padding-bottom: 5rem; }
    .lg-padding-5-half-rem-bottom { padding-bottom: 5.5rem; }
    .lg-padding-6-rem-bottom { padding-bottom: 6rem; }
    .lg-padding-6-half-rem-bottom { padding-bottom: 6.5rem; }
    .lg-padding-7-rem-bottom { padding-bottom: 7rem; }
    .lg-padding-7-half-rem-bottom { padding-bottom: 7.5rem; }
    .lg-padding-8-rem-bottom { padding-bottom: 8rem; }
    .lg-padding-8-half-rem-bottom { padding-bottom: 8.5rem; }
    .lg-padding-9-rem-bottom { padding-bottom: 9rem; }
    .lg-padding-9-half-rem-bottom { padding-bottom: 9.5rem; }
    .lg-padding-10-rem-bottom { padding-bottom: 10rem; }
    .lg-padding-10-half-rem-bottom { padding-bottom: 10.5rem; }

    /* padding right */
    .lg-padding-one-right { padding-right:1%; }
    .lg-padding-two-right { padding-right:2%; }
    .lg-padding-three-right { padding-right:3%; }
    .lg-padding-four-right { padding-right:4% }
    .lg-padding-five-right { padding-right:5%; }
    .lg-padding-six-right { padding-right:6%; }
    .lg-padding-seven-right { padding-right:7%; }
    .lg-padding-eight-right { padding-right:8%; }
    .lg-padding-nine-right { padding-right:9%; }
    .lg-padding-ten-right { padding-right:10%; }
    .lg-padding-eleven-right { padding-right:11%; }
    .lg-padding-twelve-right { padding-right:12%; }
    .lg-padding-thirteen-right { padding-right:13%; }
    .lg-padding-fourteen-right { padding-right:14%; }
    .lg-padding-fifteen-right { padding-right:15%; }
    .lg-padding-sixteen-right { padding-right:16%; }
    .lg-padding-seventeen-right { padding-right:17%; }
    .lg-padding-eighteen-right { padding-right:18%; }
    .lg-padding-nineteen-right { padding-right:19%; }
    .lg-padding-twenty-right { padding-right:20%; }
    .lg-padding-5px-right { padding-right:5px; }
    .lg-padding-10px-right { padding-right:10px; }
    .lg-padding-15px-right { padding-right:15px; }
    .lg-padding-20px-right { padding-right:20px; }
    .lg-padding-25px-right { padding-right:25px; }
    .lg-padding-30px-right { padding-right:30px; }
    .lg-padding-35px-right { padding-right:35px; }
    .lg-padding-40px-right { padding-right:40px; }
    .lg-padding-45px-right { padding-right:45px; }
    .lg-padding-50px-right { padding-right:50px; }
    .lg-padding-55px-right { padding-right:55px; }
    .lg-padding-60px-right { padding-right:60px; }
    .lg-padding-65px-right { padding-right:65px; }
    .lg-padding-70px-right { padding-right:70px; }
    .lg-padding-75px-right { padding-right:75px; }
    .lg-padding-80px-right { padding-right:80px; }
    .lg-padding-85px-right { padding-right:85px; }
    .lg-padding-90px-right { padding-right:90px; }
    .lg-padding-95px-right { padding-right:95px; }
    .lg-padding-100px-right { padding-right:100px; }
    .lg-padding-1-rem-right { padding-right: 1rem; }
    .lg-padding-1-half-rem-right { padding-right: 1.5rem; }
    .lg-padding-2-rem-right { padding-right: 2rem; }
    .lg-padding-2-half-rem-right { padding-right: 2.5rem; }
    .lg-padding-3-rem-right { padding-right: 3rem; }
    .lg-padding-3-half-rem-right { padding-right: 3.5rem; }
    .lg-padding-4-rem-right { padding-right: 4rem; }
    .lg-padding-4-half-rem-right { padding-right: 4.5rem; }
    .lg-padding-5-rem-right { padding-right: 5rem; }
    .lg-padding-5-half-rem-right { padding-right: 5.5rem; }
    .lg-padding-6-rem-right { padding-right: 6rem; }
    .lg-padding-6-half-rem-right { padding-right: 6.5rem; }
    .lg-padding-7-rem-right { padding-right: 7rem; }
    .lg-padding-7-half-rem-right { padding-right: 7.5rem; }
    .lg-padding-8-rem-right { padding-right: 8rem; }
    .lg-padding-8-half-rem-right { padding-right: 8.5rem; }
    .lg-padding-9-rem-right { padding-right: 9rem; }
    .lg-padding-9-half-rem-right { padding-right: 9.5rem; }
    .lg-padding-10-rem-right { padding-right: 10rem; }
    .lg-padding-10-half-rem-right { padding-right: 10.5rem; }

    /* padding left */
    .lg-padding-one-left { padding-left:1%; }
    .lg-padding-two-left { padding-left:2%; }
    .lg-padding-three-left { padding-left:3%; }
    .lg-padding-four-left { padding-left:4%; }
    .lg-padding-five-left { padding-left:5%; }
    .lg-padding-six-left { padding-left:6%; }
    .lg-padding-seven-left { padding-left:7%; }
    .lg-padding-eight-left { padding-left:8%; }
    .lg-padding-nine-left { padding-left:9%; }
    .lg-padding-ten-left { padding-left:10%; }
    .lg-padding-eleven-left { padding-left:11%; }
    .lg-padding-twelve-left { padding-left:12%; }
    .lg-padding-thirteen-left { padding-left:13%; }
    .lg-padding-fourteen-left { padding-left:14%; }
    .lg-padding-fifteen-left { padding-left:15%; }
    .lg-padding-sixteen-left { padding-left:16%; }
    .lg-padding-seventeen-left { padding-left:17%; }
    .lg-padding-eighteen-left { padding-left:18%; }
    .lg-padding-nineteen-left { padding-left:19%; }
    .lg-padding-twenty-left { padding-left:20%; }
    .lg-padding-5px-left { padding-left:5px; }
    .lg-padding-10px-left { padding-left:10px; }
    .lg-padding-15px-left { padding-left:15px; }
    .lg-padding-20px-left { padding-left:20px; }
    .lg-padding-25px-left { padding-left:25px; }
    .lg-padding-30px-left { padding-left:30px; }
    .lg-padding-35px-left { padding-left:35px; }
    .lg-padding-40px-left { padding-left:40px; }
    .lg-padding-45px-left { padding-left:45px; }
    .lg-padding-50px-left { padding-left:50px; }
    .lg-padding-55px-left { padding-left:55px; }
    .lg-padding-60px-left { padding-left:60px; }
    .lg-padding-65px-left { padding-left:65px; }
    .lg-padding-70px-left { padding-left:70px; }
    .lg-padding-75px-left { padding-left:75px; }
    .lg-padding-80px-left { padding-left:80px; }
    .lg-padding-85px-left { padding-left:85px; }
    .lg-padding-90px-left { padding-left:90px; }
    .lg-padding-95px-left { padding-left:95px; }
    .lg-padding-100px-left { padding-left:100px; }
    .lg-padding-1-rem-left { padding-left: 1rem; }
    .lg-padding-1-half-rem-left { padding-left: 1.5rem; }
    .lg-padding-2-rem-left { padding-left: 2rem; }
    .lg-padding-2-half-rem-left { padding-left: 2.5rem; }
    .lg-padding-3-rem-left { padding-left: 3rem; }
    .lg-padding-3-half-rem-left { padding-left: 3.5rem; }
    .lg-padding-4-rem-left { padding-left: 4rem; }
    .lg-padding-4-half-rem-left { padding-left: 4.5rem; }
    .lg-padding-5-rem-left { padding-left: 5rem; }
    .lg-padding-5-half-rem-left { padding-left: 5.5rem; }
    .lg-padding-6-rem-left { padding-left: 6rem; }
    .lg-padding-6-half-rem-left { padding-left: 6.5rem; }
    .lg-padding-7-rem-left { padding-left: 7rem; }
    .lg-padding-7-half-rem-left { padding-left: 7.5rem; }
    .lg-padding-8-rem-left { padding-left: 8rem; }
    .lg-padding-8-half-rem-left { padding-left: 8.5rem; }
    .lg-padding-9-rem-left { padding-left: 9rem; }
    .lg-padding-9-half-rem-left { padding-left: 9.5rem; }
    .lg-padding-10-rem-left { padding-left: 10rem; }
    .lg-padding-10-half-rem-left { padding-left: 10.5rem; }

    /* padding top bottom */
    .lg-padding-one-tb { padding-top:1%; padding-bottom:1%; }
    .lg-padding-two-tb { padding-top:2%; padding-bottom:2%; }
    .lg-padding-three-tb { padding-top:3%; padding-bottom:3%; }
    .lg-padding-four-tb { padding-top:4%; padding-bottom:4%; }
    .lg-padding-five-tb { padding-top:5%; padding-bottom:5%; }
    .lg-padding-six-tb { padding-top:6%; padding-bottom:6%; }
    .lg-padding-seven-tb { padding-top:7%; padding-bottom:7%; }
    .lg-padding-eight-tb { padding-top:8%; padding-bottom:8%; }
    .lg-padding-nine-tb { padding-top:9%; padding-bottom:9%; }
    .lg-padding-ten-tb { padding-top:10%; padding-bottom:10%; }
    .lg-padding-eleven-tb { padding-top:11%; padding-bottom:11%; }
    .lg-padding-twelve-tb { padding-top:12%; padding-bottom:12%; }
    .lg-padding-thirteen-tb { padding-top:13%; padding-bottom:13%; }
    .lg-padding-fourteen-tb { padding-top:14%; padding-bottom:14%; }
    .lg-padding-fifteen-tb { padding-top:15%; padding-bottom:15%; }
    .lg-padding-sixteen-tb { padding-top:16%; padding-bottom:16%; }
    .lg-padding-seventeen-tb { padding-top:17%; padding-bottom:17%; }
    .lg-padding-eighteen-tb { padding-top:18%; padding-bottom:18%; }
    .lg-padding-nineteen-tb { padding-top:19%; padding-bottom:19%; }
    .lg-padding-twenty-tb { padding-top:20%; padding-bottom:20%; }
    .lg-padding-5px-tb { padding-top:5px; padding-bottom:5px; }
    .lg-padding-10px-tb { padding-top:10px; padding-bottom:10px; }
    .lg-padding-15px-tb { padding-top:15px; padding-bottom:15px; }
    .lg-padding-20px-tb { padding-top:20px; padding-bottom:20px; }
    .lg-padding-25px-tb { padding-top:25px; padding-bottom:25px; }
    .lg-padding-30px-tb { padding-top:30px; padding-bottom:30px; }
    .lg-padding-35px-tb { padding-top:35px; padding-bottom:35px; }
    .lg-padding-40px-tb { padding-top:40px; padding-bottom:40px; }
    .lg-padding-45px-tb { padding-top:45px; padding-bottom:45px; }
    .lg-padding-50px-tb { padding-top:50px; padding-bottom:50px; }
    .lg-padding-55px-tb { padding-top:55px; padding-bottom:55px; }
    .lg-padding-60px-tb { padding-top:60px; padding-bottom:60px; }
    .lg-padding-65px-tb { padding-top:65px; padding-bottom:65px; }
    .lg-padding-70px-tb { padding-top:70px; padding-bottom:70px; }
    .lg-padding-75px-tb { padding-top:75px; padding-bottom:75px; }
    .lg-padding-80px-tb { padding-top:80px; padding-bottom:80px; }
    .lg-padding-85px-tb { padding-top:85px; padding-bottom:85px; }
    .lg-padding-90px-tb { padding-top:90px; padding-bottom:90px; }
    .lg-padding-95px-tb { padding-top:95px; padding-bottom:95px; }
    .lg-padding-100px-tb { padding-top:100px; padding-bottom:100px; }
    .lg-padding-1-rem-tb { padding-top: 1rem; padding-bottom: 1rem; }
    .lg-padding-1-half-rem-tb { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .lg-padding-2-rem-tb { padding-top: 2rem; padding-bottom: 2rem; }
    .lg-padding-2-half-rem-tb { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .lg-padding-3-rem-tb { padding-top: 3rem; padding-bottom: 3rem; }
    .lg-padding-3-half-rem-tb { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .lg-padding-4-rem-tb { padding-top: 4rem; padding-bottom: 4rem; }
    .lg-padding-4-half-rem-tb { padding-top: 4.5rem; padding-bottom: 4.5rem; }
    .lg-padding-5-rem-tb { padding-top: 5rem; padding-bottom: 5rem; }
    .lg-padding-5-half-rem-tb { padding-top: 5.5rem; padding-bottom: 5.5rem; }
    .lg-padding-6-rem-tb { padding-top: 6rem; padding-bottom: 6rem; }
    .lg-padding-6-half-rem-tb { padding-top: 6.5rem; padding-bottom: 6.5rem; }
    .lg-padding-7-rem-tb { padding-top: 7rem; padding-bottom: 7rem; }
    .lg-padding-7-half-rem-tb { padding-top: 7.5rem; padding-bottom: 7.5rem; }
    .lg-padding-8-rem-tb { padding-top: 8rem; padding-bottom: 8rem; }
    .lg-padding-8-half-rem-tb { padding-top: 8.5rem; padding-bottom: 8.5rem; }
    .lg-padding-9-rem-tb { padding-top: 9rem; padding-bottom: 9rem; }
    .lg-padding-9-half-rem-tb { padding-top: 9.5rem; padding-bottom: 9.5rem; }
    .lg-padding-10-rem-tb { padding-top: 10rem; padding-bottom: 10rem; }
    .lg-padding-10-half-rem-tb { padding-top: 10.5rem; padding-bottom: 10.5rem; }

    /* padding left right */
    .lg-padding-one-lr { padding-left:1%; padding-right:1%; }
    .lg-padding-two-lr { padding-left:2%; padding-right:2%; }
    .lg-padding-three-lr { padding-left:3%; padding-right:3%; }
    .lg-padding-four-lr { padding-left:4%; padding-right:4%; }
    .lg-padding-five-lr { padding-left:5%; padding-right:5%; }
    .lg-padding-six-lr { padding-left:6%; padding-right:6%; }
    .lg-padding-seven-lr { padding-left:7%; padding-right:7%; }
    .lg-padding-eight-lr { padding-left:8%; padding-right:8%; }
    .lg-padding-nine-lr { padding-left:9%; padding-right:9%; }
    .lg-padding-ten-lr { padding-left:10%; padding-right:10%; }
    .lg-padding-eleven-lr { padding-left:11%; padding-right:11%; }
    .lg-padding-twelve-lr { padding-left:12%; padding-right:12%; }
    .lg-padding-thirteen-lr { padding-left:13%; padding-right:13%; }
    .lg-padding-fourteen-lr { padding-left:14%; padding-right:14%; }
    .lg-padding-fifteen-lr { padding-left:15%; padding-right:15%; }
    .lg-padding-sixteen-lr { padding-left:16%; padding-right:16%; }
    .lg-padding-seventeen-lr { padding-left:17%; padding-right:17%; }
    .lg-padding-eighteen-lr { padding-left:18%; padding-right:18%; }
    .lg-padding-nineteen-lr { padding-left:19%; padding-right:19%; }
    .lg-padding-twenty-lr { padding-left:20%; padding-right:20%; }
    .lg-padding-5px-lr { padding-left:5px; padding-right:5px; }
    .lg-padding-10px-lr { padding-left:10px; padding-right:10px; }
    .lg-padding-15px-lr { padding-left:15px; padding-right:15px; }
    .lg-padding-20px-lr { padding-left:20px; padding-right:20px; }
    .lg-padding-25px-lr { padding-left:25px; padding-right:25px; }
    .lg-padding-30px-lr { padding-left:30px; padding-right:30px; }
    .lg-padding-35px-lr { padding-left:35px; padding-right:35px; }
    .lg-padding-40px-lr { padding-left:40px; padding-right:40px; }
    .lg-padding-45px-lr { padding-left:45px; padding-right:45px; }
    .lg-padding-50px-lr { padding-left:50px; padding-right:50px; }
    .lg-padding-55px-lr { padding-left:55px; padding-right:55px; }
    .lg-padding-60px-lr { padding-left:60px; padding-right:60px; }
    .lg-padding-65px-lr { padding-left:65px; padding-right:65px; }
    .lg-padding-70px-lr { padding-left:70px; padding-right:70px; }
    .lg-padding-75px-lr { padding-left:75px; padding-right:75px; }
    .lg-padding-80px-lr { padding-left:80px; padding-right:80px; }
    .lg-padding-85px-lr { padding-left:85px; padding-right:85px; }
    .lg-padding-90px-lr { padding-left:90px; padding-right:90px; }
    .lg-padding-95px-lr { padding-left:95px; padding-right:95px; }
    .lg-padding-100px-lr { padding-left:100px; padding-right:100px; }
    .lg-padding-1-rem-lr { padding-left: 1rem; padding-right: 1rem; }
    .lg-padding-1-half-rem-lr { padding-left: 1.5rem; padding-right: 1.5rem; }
    .lg-padding-2-rem-lr { padding-left: 2rem; padding-right: 2rem; }
    .lg-padding-2-half-rem-lr { padding-left: 2.5rem; padding-right: 2.5rem; }
    .lg-padding-3-rem-lr { padding-left: 3rem; padding-right: 3rem; }
    .lg-padding-3-half-rem-lr { padding-left: 3.5rem; padding-right: 3.5rem; }
    .lg-padding-4-rem-lr { padding-left: 4rem; padding-right: 4rem; }
    .lg-padding-4-half-rem-lr { padding-left: 4.5rem; padding-right: 4.5rem; }
    .lg-padding-5-rem-lr { padding-left: 5rem; padding-right: 5rem; }
    .lg-padding-5-half-rem-lr { padding-left: 5.5rem; padding-right: 5.5rem; }
    .lg-padding-6-rem-lr { padding-left: 6rem; padding-right: 6rem; }
    .lg-padding-6-half-rem-lr { padding-left: 6.5rem; padding-right: 6.5rem; }
    .lg-padding-7-rem-lr { padding-left: 7rem; padding-right: 7rem; }
    .lg-padding-7-half-rem-lr { padding-left: 7.5rem; padding-right: 7.5rem; }
    .lg-padding-8-rem-lr { padding-left: 8rem; padding-right: 8rem; }
    .lg-padding-8-half-rem-lr { padding-left: 8.5rem; padding-right: 8.5rem; }
    .lg-padding-9-rem-lr { padding-left: 9rem; padding-right: 9rem; }
    .lg-padding-9-half-rem-lr { padding-left: 9.5rem; padding-right: 9.5rem; }
    .lg-padding-10-rem-lr { padding-left: 10rem; padding-right: 10rem; }
    .lg-padding-10-half-rem-lr { padding-left: 10.5rem; padding-right: 10.5rem; }

    .lg-no-padding { padding:0 !important; }
    .lg-no-padding-lr { padding-left: 0 !important; padding-right: 0 !important; }
    .lg-no-padding-tb { padding-top: 0 !important; padding-bottom: 0 !important; }
    .lg-no-padding-top { padding-top:0 !important; }
    .lg-no-padding-bottom { padding-bottom:0 !important; }
    .lg-no-padding-left { padding-left:0 !important; }
    .lg-no-padding-right { padding-right:0 !important; }
}

@media (max-width: 991px) {
    .md-padding-one-all { padding:1%; }
    .md-padding-two-all { padding:2%; }
    .md-padding-three-all { padding:3%; }
    .md-padding-four-all { padding:4%; }
    .md-padding-five-all { padding:5%; }
    .md-padding-six-all { padding:6%; }
    .md-padding-seven-all { padding:7%; }
    .md-padding-eight-all { padding:8%; }
    .md-padding-nine-all { padding:9%; }
    .md-padding-ten-all { padding:10%; }
    .md-padding-eleven-all { padding:11%; }
    .md-padding-twelve-all { padding:12%; }
    .md-padding-thirteen-all { padding:13%; }
    .md-padding-fourteen-all { padding:14%; }
    .md-padding-fifteen-all { padding:15%; }
    .md-padding-sixteen-all { padding:16%; }
    .md-padding-seventeen-all { padding:17%; }
    .md-padding-eighteen-all { padding:18%; }
    .md-padding-nineteen-all { padding:19%; }
    .md-padding-twenty-all { padding:20%; }
    .md-padding-5px-all { padding:5px; }
    .md-padding-10px-all { padding:10px; }
    .md-padding-15px-all { padding:15px; }
    .md-padding-20px-all { padding:20px; }
    .md-padding-25px-all { padding:25px; }
    .md-padding-30px-all { padding:30px; }
    .md-padding-35px-all { padding:35px; }
    .md-padding-40px-all { padding:40px; }
    .md-padding-45px-all { padding:45px; }
    .md-padding-50px-all { padding:50px; }
    .md-padding-55px-all { padding:55px; }
    .md-padding-60px-all { padding:60px; }
    .md-padding-65px-all { padding:65px; }
    .md-padding-70px-all { padding:70px; }
    .md-padding-75px-all { padding:75px; }
    .md-padding-80px-all { padding:80px; }
    .md-padding-85px-all { padding:85px; }
    .md-padding-90px-all { padding:90px; }
    .md-padding-95px-all { padding:95px; }
    .md-padding-100px-all { padding:100px; }
    .md-padding-1-rem-all { padding: 1rem; }
    .md-padding-1-half-rem-all { padding: 1.5rem; }
    .md-padding-2-rem-all { padding: 2rem; }
    .md-padding-2-half-rem-all { padding: 2.5rem; }
    .md-padding-3-rem-all { padding: 3rem; }
    .md-padding-3-half-rem-all { padding: 3.5rem; }
    .md-padding-4-rem-all { padding: 4rem; }
    .md-padding-4-half-rem-all { padding: 4.5rem; }
    .md-padding-5-rem-all { padding: 5rem; }
    .md-padding-5-half-rem-all { padding: 5.5rem; }
    .md-padding-6-rem-all { padding: 6rem; }
    .md-padding-6-half-rem-all { padding: 6.5rem; }
    .md-padding-7-rem-all { padding: 7rem; }
    .md-padding-7-half-rem-all { padding: 7.5rem; }
    .md-padding-8-rem-all { padding: 8rem; }
    .md-padding-8-half-rem-all { padding: 8.5rem; }
    .md-padding-9-rem-all { padding: 9rem; }
    .md-padding-9-half-rem-all { padding: 9.5rem; }
    .md-padding-10-rem-all { padding: 10rem; }
    .md-padding-10-half-rem-all { padding: 10.5rem; }

    /* padding top */
    .md-padding-one-top { padding-top:1%; }
    .md-padding-two-top { padding-top:2%; }
    .md-padding-three-top { padding-top:3%; }
    .md-padding-four-top { padding-top:4%; }
    .md-padding-five-top { padding-top:5%; }
    .md-padding-six-top { padding-top:6%; }
    .md-padding-seven-top { padding-top:7%; }
    .md-padding-eight-top { padding-top:8%; }
    .md-padding-nine-top { padding-top:9%; }
    .md-padding-ten-top { padding-top:10%; }
    .md-padding-eleven-top { padding-top:11%; }
    .md-padding-twelve-top { padding-top:12%; }
    .md-padding-thirteen-top { padding-top:13%; }
    .md-padding-fourteen-top { padding-top:14%; }
    .md-padding-fifteen-top { padding-top:15%; }
    .md-padding-sixteen-top { padding-top:16%; }
    .md-padding-seventeen-top { padding-top:17%; }
    .md-padding-eighteen-top { padding-top:18%; }
    .md-padding-nineteen-top { padding-top:19%; }
    .md-padding-twenty-top { padding-top:20%; }
    .md-padding-5px-top { padding-top:5px; }
    .md-padding-10px-top { padding-top:10px; }
    .md-padding-15px-top { padding-top:15px; }
    .md-padding-20px-top { padding-top:20px; }
    .md-padding-25px-top { padding-top:25px; }
    .md-padding-30px-top { padding-top:30px; }
    .md-padding-35px-top { padding-top:35px; }
    .md-padding-40px-top { padding-top:40px; }
    .md-padding-45px-top { padding-top:45px; }
    .md-padding-50px-top { padding-top:50px; }
    .md-padding-55px-top { padding-top:55px; }
    .md-padding-60px-top { padding-top:60px; }
    .md-padding-65px-top { padding-top:65px; }
    .md-padding-70px-top { padding-top:70px; }
    .md-padding-75px-top { padding-top:75px; }
    .md-padding-80px-top { padding-top:80px; }
    .md-padding-85px-top { padding-top:85px; }
    .md-padding-90px-top { padding-top:90px; }
    .md-padding-95px-top { padding-top:95px; }
    .md-padding-100px-top { padding-top:100px; }
    .md-padding-1-rem-top { padding-top: 1rem; }
    .md-padding-1-half-rem-top { padding-top: 1.5rem; }
    .md-padding-2-rem-top { padding-top: 2rem; }
    .md-padding-2-half-rem-top { padding-top: 2.5rem; }
    .md-padding-3-rem-top { padding-top: 3rem; }
    .md-padding-3-half-rem-top { padding-top: 3.5rem; }
    .md-padding-4-rem-top { padding-top: 4rem; }
    .md-padding-4-half-rem-top { padding-top: 4.5rem; }
    .md-padding-5-rem-top { padding-top: 5rem; }
    .md-padding-5-half-rem-top { padding-top: 5.5rem; }
    .md-padding-6-rem-top { padding-top: 6rem; }
    .md-padding-6-half-rem-top { padding-top: 6.5rem; }
    .md-padding-7-rem-top { padding-top: 7rem; }
    .md-padding-7-half-rem-top { padding-top: 7.5rem; }
    .md-padding-8-rem-top { padding-top: 8rem; }
    .md-padding-8-half-rem-top { padding-top: 8.5rem; }
    .md-padding-9-rem-top { padding-top: 9rem; }
    .md-padding-9-half-rem-top { padding-top: 9.5rem; }
    .md-padding-10-rem-top { padding-top: 10rem; }
    .md-padding-10-half-rem-top { padding-top: 10.5rem; }

    /* padding bottom */
    .md-padding-one-bottom { padding-bottom:1%; }
    .md-padding-two-bottom { padding-bottom:2%; }
    .md-padding-three-bottom { padding-bottom:3%; }
    .md-padding-four-bottom { padding-bottom:4%; }
    .md-padding-five-bottom { padding-bottom:5%; }
    .md-padding-six-bottom { padding-bottom:6%; }
    .md-padding-seven-bottom { padding-bottom:7%; }
    .md-padding-eight-bottom { padding-bottom:8%; }
    .md-padding-nine-bottom { padding-bottom:9%; }
    .md-padding-ten-bottom { padding-bottom:10%; }
    .md-padding-eleven-bottom { padding-bottom:11%; }
    .md-padding-twelve-bottom { padding-bottom:12%; }
    .md-padding-thirteen-bottom { padding-bottom:13%; }
    .md-padding-fourteen-bottom { padding-bottom:14%; }
    .md-padding-fifteen-bottom { padding-bottom:15%; }
    .md-padding-sixteen-bottom { padding-bottom:16%; }
    .md-padding-seventeen-bottom { padding-bottom:17%; }
    .md-padding-eighteen-bottom { padding-bottom:18%; }
    .md-padding-nineteen-bottom { padding-bottom:19%; }
    .md-padding-twenty-bottom { padding-bottom:20%; }
    .md-padding-5px-bottom { padding-bottom:5px; }
    .md-padding-10px-bottom { padding-bottom:10px; }
    .md-padding-15px-bottom { padding-bottom:15px; }
    .md-padding-20px-bottom { padding-bottom:20px; }
    .md-padding-25px-bottom { padding-bottom:25px; }
    .md-padding-30px-bottom { padding-bottom:30px; }
    .md-padding-35px-bottom { padding-bottom:35px; }
    .md-padding-40px-bottom { padding-bottom:40px; }
    .md-padding-45px-bottom { padding-bottom:45px; }
    .md-padding-50px-bottom { padding-bottom:50px; }
    .md-padding-55px-bottom { padding-bottom:55px; }
    .md-padding-60px-bottom { padding-bottom:60px; }
    .md-padding-65px-bottom { padding-bottom:65px; }
    .md-padding-70px-bottom { padding-bottom:70px; }
    .md-padding-75px-bottom { padding-bottom:75px; }
    .md-padding-80px-bottom { padding-bottom:80px; }
    .md-padding-85px-bottom { padding-bottom:85px; }
    .md-padding-90px-bottom { padding-bottom:90px; }
    .md-padding-95px-bottom { padding-bottom:95px; }
    .md-padding-100px-bottom { padding-bottom:100px; }
    .md-padding-1-rem-bottom { padding-bottom: 1rem; }
    .md-padding-1-half-rem-bottom { padding-bottom: 1.5rem; }
    .md-padding-2-rem-bottom { padding-bottom: 2rem; }
    .md-padding-2-half-rem-bottom { padding-bottom: 2.5rem; }
    .md-padding-3-rem-bottom { padding-bottom: 3rem; }
    .md-padding-3-half-rem-bottom { padding-bottom: 3.5rem; }
    .md-padding-4-rem-bottom { padding-bottom: 4rem; }
    .md-padding-4-half-rem-bottom { padding-bottom: 4.5rem; }
    .md-padding-5-rem-bottom { padding-bottom: 5rem; }
    .md-padding-5-half-rem-bottom { padding-bottom: 5.5rem; }
    .md-padding-6-rem-bottom { padding-bottom: 6rem; }
    .md-padding-6-half-rem-bottom { padding-bottom: 6.5rem; }
    .md-padding-7-rem-bottom { padding-bottom: 7rem; }
    .md-padding-7-half-rem-bottom { padding-bottom: 7.5rem; }
    .md-padding-8-rem-bottom { padding-bottom: 8rem; }
    .md-padding-8-half-rem-bottom { padding-bottom: 8.5rem; }
    .md-padding-9-rem-bottom { padding-bottom: 9rem; }
    .md-padding-9-half-rem-bottom { padding-bottom: 9.5rem; }
    .md-padding-10-rem-bottom { padding-bottom: 10rem; }
    .md-padding-10-half-rem-bottom { padding-bottom: 10.5rem; }

    /* padding right */
    .md-padding-one-right { padding-right:1%; }
    .md-padding-two-right { padding-right:2%; }
    .md-padding-three-right { padding-right:3%; }
    .md-padding-four-right { padding-right:4% }
    .md-padding-five-right { padding-right:5%; }
    .md-padding-six-right { padding-right:6%; }
    .md-padding-seven-right { padding-right:7%; }
    .md-padding-eight-right { padding-right:8%; }
    .md-padding-nine-right { padding-right:9%; }
    .md-padding-ten-right { padding-right:10%; }
    .md-padding-eleven-right { padding-right:11%; }
    .md-padding-twelve-right { padding-right:12%; }
    .md-padding-thirteen-right { padding-right:13%; }
    .md-padding-fourteen-right { padding-right:14%; }
    .md-padding-fifteen-right { padding-right:15%; }
    .md-padding-sixteen-right { padding-right:16%; }
    .md-padding-seventeen-right { padding-right:17%; }
    .md-padding-eighteen-right { padding-right:18%; }
    .md-padding-nineteen-right { padding-right:19%; }
    .md-padding-twenty-right { padding-right:20%; }
    .md-padding-5px-right { padding-right:5px; }
    .md-padding-10px-right { padding-right:10px; }
    .md-padding-15px-right { padding-right:15px; }
    .md-padding-20px-right { padding-right:20px; }
    .md-padding-25px-right { padding-right:25px; }
    .md-padding-30px-right { padding-right:30px; }
    .md-padding-35px-right { padding-right:35px; }
    .md-padding-40px-right { padding-right:40px; }
    .md-padding-45px-right { padding-right:45px; }
    .md-padding-50px-right { padding-right:50px; }
    .md-padding-55px-right { padding-right:55px; }
    .md-padding-60px-right { padding-right:60px; }
    .md-padding-65px-right { padding-right:65px; }
    .md-padding-70px-right { padding-right:70px; }
    .md-padding-75px-right { padding-right:75px; }
    .md-padding-80px-right { padding-right:80px; }
    .md-padding-85px-right { padding-right:85px; }
    .md-padding-90px-right { padding-right:90px; }
    .md-padding-95px-right { padding-right:95px; }
    .md-padding-100px-right { padding-right:100px; }
    .md-padding-1-rem-right { padding-right: 1rem; }
    .md-padding-1-half-rem-right { padding-right: 1.5rem; }
    .md-padding-2-rem-right { padding-right: 2rem; }
    .md-padding-2-half-rem-right { padding-right: 2.5rem; }
    .md-padding-3-rem-right { padding-right: 3rem; }
    .md-padding-3-half-rem-right { padding-right: 3.5rem; }
    .md-padding-4-rem-right { padding-right: 4rem; }
    .md-padding-4-half-rem-right { padding-right: 4.5rem; }
    .md-padding-5-rem-right { padding-right: 5rem; }
    .md-padding-5-half-rem-right { padding-right: 5.5rem; }
    .md-padding-6-rem-right { padding-right: 6rem; }
    .md-padding-6-half-rem-right { padding-right: 6.5rem; }
    .md-padding-7-rem-right { padding-right: 7rem; }
    .md-padding-7-half-rem-right { padding-right: 7.5rem; }
    .md-padding-8-rem-right { padding-right: 8rem; }
    .md-padding-8-half-rem-right { padding-right: 8.5rem; }
    .md-padding-9-rem-right { padding-right: 9rem; }
    .md-padding-9-half-rem-right { padding-right: 9.5rem; }
    .md-padding-10-rem-right { padding-right: 10rem; }
    .md-padding-10-half-rem-right { padding-right: 10.5rem; }

    /* padding left */
    .md-padding-one-left { padding-left:1%; }
    .md-padding-two-left { padding-left:2%; }
    .md-padding-three-left { padding-left:3%; }
    .md-padding-four-left { padding-left:4%; }
    .md-padding-five-left { padding-left:5%; }
    .md-padding-six-left { padding-left:6%; }
    .md-padding-seven-left { padding-left:7%; }
    .md-padding-eight-left { padding-left:8%; }
    .md-padding-nine-left { padding-left:9%; }
    .md-padding-ten-left { padding-left:10%; }
    .md-padding-eleven-left { padding-left:11%; }
    .md-padding-twelve-left { padding-left:12%; }
    .md-padding-thirteen-left { padding-left:13%; }
    .md-padding-fourteen-left { padding-left:14%; }
    .md-padding-fifteen-left { padding-left:15%; }
    .md-padding-sixteen-left { padding-left:16%; }
    .md-padding-seventeen-left { padding-left:17%; }
    .md-padding-eighteen-left { padding-left:18%; }
    .md-padding-nineteen-left { padding-left:19%; }
    .md-padding-twenty-left { padding-left:20%; }
    .md-padding-5px-left { padding-left:5px; }
    .md-padding-10px-left { padding-left:10px; }
    .md-padding-15px-left { padding-left:15px; }
    .md-padding-20px-left { padding-left:20px; }
    .md-padding-25px-left { padding-left:25px; }
    .md-padding-30px-left { padding-left:30px; }
    .md-padding-35px-left { padding-left:35px; }
    .md-padding-40px-left { padding-left:40px; }
    .md-padding-45px-left { padding-left:45px; }
    .md-padding-50px-left { padding-left:50px; }
    .md-padding-55px-left { padding-left:55px; }
    .md-padding-60px-left { padding-left:60px; }
    .md-padding-65px-left { padding-left:65px; }
    .md-padding-70px-left { padding-left:70px; }
    .md-padding-75px-left { padding-left:75px; }
    .md-padding-80px-left { padding-left:80px; }
    .md-padding-85px-left { padding-left:85px; }
    .md-padding-90px-left { padding-left:90px; }
    .md-padding-95px-left { padding-left:95px; }
    .md-padding-100px-left { padding-left:100px; }
    .md-padding-1-rem-left { padding-left: 1rem; }
    .md-padding-1-half-rem-left { padding-left: 1.5rem; }
    .md-padding-2-rem-left { padding-left: 2rem; }
    .md-padding-2-half-rem-left { padding-left: 2.5rem; }
    .md-padding-3-rem-left { padding-left: 3rem; }
    .md-padding-3-half-rem-left { padding-left: 3.5rem; }
    .md-padding-4-rem-left { padding-left: 4rem; }
    .md-padding-4-half-rem-left { padding-left: 4.5rem; }
    .md-padding-5-rem-left { padding-left: 5rem; }
    .md-padding-5-half-rem-left { padding-left: 5.5rem; }
    .md-padding-6-rem-left { padding-left: 6rem; }
    .md-padding-6-half-rem-left { padding-left: 6.5rem; }
    .md-padding-7-rem-left { padding-left: 7rem; }
    .md-padding-7-half-rem-left { padding-left: 7.5rem; }
    .md-padding-8-rem-left { padding-left: 8rem; }
    .md-padding-8-half-rem-left { padding-left: 8.5rem; }
    .md-padding-9-rem-left { padding-left: 9rem; }
    .md-padding-9-half-rem-left { padding-left: 9.5rem; }
    .md-padding-10-rem-left { padding-left: 10rem; }
    .md-padding-10-half-rem-left { padding-left: 10.5rem; }

    /* padding top bottom */
    .md-padding-one-tb { padding-top:1%; padding-bottom:1%; }
    .md-padding-two-tb { padding-top:2%; padding-bottom:2%; }
    .md-padding-three-tb { padding-top:3%; padding-bottom:3%; }
    .md-padding-four-tb { padding-top:4%; padding-bottom:4%; }
    .md-padding-five-tb { padding-top:5%; padding-bottom:5%; }
    .md-padding-six-tb { padding-top:6%; padding-bottom:6%; }
    .md-padding-seven-tb { padding-top:7%; padding-bottom:7%; }
    .md-padding-eight-tb { padding-top:8%; padding-bottom:8%; }
    .md-padding-nine-tb { padding-top:9%; padding-bottom:9%; }
    .md-padding-ten-tb { padding-top:10%; padding-bottom:10%; }
    .md-padding-eleven-tb { padding-top:11%; padding-bottom:11%; }
    .md-padding-twelve-tb { padding-top:12%; padding-bottom:12%; }
    .md-padding-thirteen-tb { padding-top:13%; padding-bottom:13%; }
    .md-padding-fourteen-tb { padding-top:14%; padding-bottom:14%; }
    .md-padding-fifteen-tb { padding-top:15%; padding-bottom:15%; }
    .md-padding-sixteen-tb { padding-top:16%; padding-bottom:16%; }
    .md-padding-seventeen-tb { padding-top:17%; padding-bottom:17%; }
    .md-padding-eighteen-tb { padding-top:18%; padding-bottom:18%; }
    .md-padding-nineteen-tb { padding-top:19%; padding-bottom:19%; }
    .md-padding-twenty-tb { padding-top:20%; padding-bottom:20%; }
    .md-padding-5px-tb { padding-top:5px; padding-bottom:5px; }
    .md-padding-10px-tb { padding-top:10px; padding-bottom:10px; }
    .md-padding-15px-tb { padding-top:15px; padding-bottom:15px; }
    .md-padding-20px-tb { padding-top:20px; padding-bottom:20px; }
    .md-padding-25px-tb { padding-top:25px; padding-bottom:25px; }
    .md-padding-30px-tb { padding-top:30px; padding-bottom:30px; }
    .md-padding-35px-tb { padding-top:35px; padding-bottom:35px; }
    .md-padding-40px-tb { padding-top:40px; padding-bottom:40px; }
    .md-padding-45px-tb { padding-top:45px; padding-bottom:45px; }
    .md-padding-50px-tb { padding-top:50px; padding-bottom:50px; }
    .md-padding-55px-tb { padding-top:55px; padding-bottom:55px; }
    .md-padding-60px-tb { padding-top:60px; padding-bottom:60px; }
    .md-padding-65px-tb { padding-top:65px; padding-bottom:65px; }
    .md-padding-70px-tb { padding-top:70px; padding-bottom:70px; }
    .md-padding-75px-tb { padding-top:75px; padding-bottom:75px; }
    .md-padding-80px-tb { padding-top:80px; padding-bottom:80px; }
    .md-padding-85px-tb { padding-top:85px; padding-bottom:85px; }
    .md-padding-90px-tb { padding-top:90px; padding-bottom:90px; }
    .md-padding-95px-tb { padding-top:95px; padding-bottom:95px; }
    .md-padding-100px-tb { padding-top:100px; padding-bottom:100px; }
    .md-padding-1-rem-tb { padding-top: 1rem; padding-bottom: 1rem; }
    .md-padding-1-half-rem-tb { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .md-padding-2-rem-tb { padding-top: 2rem; padding-bottom: 2rem; }
    .md-padding-2-half-rem-tb { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .md-padding-3-rem-tb { padding-top: 3rem; padding-bottom: 3rem; }
    .md-padding-3-half-rem-tb { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .md-padding-4-rem-tb { padding-top: 4rem; padding-bottom: 4rem; }
    .md-padding-4-half-rem-tb { padding-top: 4.5rem; padding-bottom: 4.5rem; }
    .md-padding-5-rem-tb { padding-top: 5rem; padding-bottom: 5rem; }
    .md-padding-5-half-rem-tb { padding-top: 5.5rem; padding-bottom: 5.5rem; }
    .md-padding-6-rem-tb { padding-top: 6rem; padding-bottom: 6rem; }
    .md-padding-6-half-rem-tb { padding-top: 6.5rem; padding-bottom: 6.5rem; }
    .md-padding-7-rem-tb { padding-top: 7rem; padding-bottom: 7rem; }
    .md-padding-7-half-rem-tb { padding-top: 7.5rem; padding-bottom: 7.5rem; }
    .md-padding-8-rem-tb { padding-top: 8rem; padding-bottom: 8rem; }
    .md-padding-8-half-rem-tb { padding-top: 8.5rem; padding-bottom: 8.5rem; }
    .md-padding-9-rem-tb { padding-top: 9rem; padding-bottom: 9rem; }
    .md-padding-9-half-rem-tb { padding-top: 9.5rem; padding-bottom: 9.5rem; }
    .md-padding-10-rem-tb { padding-top: 10rem; padding-bottom: 10rem; }
    .md-padding-10-half-rem-tb { padding-top: 10.5rem; padding-bottom: 10.5rem; }

    /* padding left right */
    .md-padding-one-lr { padding-left:1%; padding-right:1%; }
    .md-padding-two-lr { padding-left:2%; padding-right:2%; }
    .md-padding-three-lr { padding-left:3%; padding-right:3%; }
    .md-padding-four-lr { padding-left:4%; padding-right:4%; }
    .md-padding-five-lr { padding-left:5%; padding-right:5%; }
    .md-padding-six-lr { padding-left:6%; padding-right:6%; }
    .md-padding-seven-lr { padding-left:7%; padding-right:7%; }
    .md-padding-eight-lr { padding-left:8%; padding-right:8%; }
    .md-padding-nine-lr { padding-left:9%; padding-right:9%; }
    .md-padding-ten-lr { padding-left:10%; padding-right:10%; }
    .md-padding-eleven-lr { padding-left:11%; padding-right:11%; }
    .md-padding-twelve-lr { padding-left:12%; padding-right:12%; }
    .md-padding-thirteen-lr { padding-left:13%; padding-right:13%; }
    .md-padding-fourteen-lr { padding-left:14%; padding-right:14%; }
    .md-padding-fifteen-lr { padding-left:15%; padding-right:15%; }
    .md-padding-sixteen-lr { padding-left:16%; padding-right:16%; }
    .md-padding-seventeen-lr { padding-left:17%; padding-right:17%; }
    .md-padding-eighteen-lr { padding-left:18%; padding-right:18%; }
    .md-padding-nineteen-lr { padding-left:19%; padding-right:19%; }
    .md-padding-twenty-lr { padding-left:20%; padding-right:20%; }
    .md-padding-5px-lr { padding-left:5px; padding-right:5px; }
    .md-padding-10px-lr { padding-left:10px; padding-right:10px; }
    .md-padding-15px-lr { padding-left:15px; padding-right:15px; }
    .md-padding-20px-lr { padding-left:20px; padding-right:20px; }
    .md-padding-25px-lr { padding-left:25px; padding-right:25px; }
    .md-padding-30px-lr { padding-left:30px; padding-right:30px; }
    .md-padding-35px-lr { padding-left:35px; padding-right:35px; }
    .md-padding-40px-lr { padding-left:40px; padding-right:40px; }
    .md-padding-45px-lr { padding-left:45px; padding-right:45px; }
    .md-padding-50px-lr { padding-left:50px; padding-right:50px; }
    .md-padding-55px-lr { padding-left:55px; padding-right:55px; }
    .md-padding-60px-lr { padding-left:60px; padding-right:60px; }
    .md-padding-65px-lr { padding-left:65px; padding-right:65px; }
    .md-padding-70px-lr { padding-left:70px; padding-right:70px; }
    .md-padding-75px-lr { padding-left:75px; padding-right:75px; }
    .md-padding-80px-lr { padding-left:80px; padding-right:80px; }
    .md-padding-85px-lr { padding-left:85px; padding-right:85px; }
    .md-padding-90px-lr { padding-left:90px; padding-right:90px; }
    .md-padding-95px-lr { padding-left:95px; padding-right:95px; }
    .md-padding-100px-lr { padding-left:100px; padding-right:100px; }
    .md-padding-1-rem-lr { padding-left: 1rem; padding-right: 1rem; }
    .md-padding-1-half-rem-lr { padding-left: 1.5rem; padding-right: 1.5rem; }
    .md-padding-2-rem-lr { padding-left: 2rem; padding-right: 2rem; }
    .md-padding-2-half-rem-lr { padding-left: 2.5rem; padding-right: 2.5rem; }
    .md-padding-3-rem-lr { padding-left: 3rem; padding-right: 3rem; }
    .md-padding-3-half-rem-lr { padding-left: 3.5rem; padding-right: 3.5rem; }
    .md-padding-4-rem-lr { padding-left: 4rem; padding-right: 4rem; }
    .md-padding-4-half-rem-lr { padding-left: 4.5rem; padding-right: 4.5rem; }
    .md-padding-5-rem-lr { padding-left: 5rem; padding-right: 5rem; }
    .md-padding-5-half-rem-lr { padding-left: 5.5rem; padding-right: 5.5rem; }
    .md-padding-6-rem-lr { padding-left: 6rem; padding-right: 6rem; }
    .md-padding-6-half-rem-lr { padding-left: 6.5rem; padding-right: 6.5rem; }
    .md-padding-7-rem-lr { padding-left: 7rem; padding-right: 7rem; }
    .md-padding-7-half-rem-lr { padding-left: 7.5rem; padding-right: 7.5rem; }
    .md-padding-8-rem-lr { padding-left: 8rem; padding-right: 8rem; }
    .md-padding-8-half-rem-lr { padding-left: 8.5rem; padding-right: 8.5rem; }
    .md-padding-9-rem-lr { padding-left: 9rem; padding-right: 9rem; }
    .md-padding-9-half-rem-lr { padding-left: 9.5rem; padding-right: 9.5rem; }
    .md-padding-10-rem-lr { padding-left: 10rem; padding-right: 10rem; }
    .md-padding-10-half-rem-lr { padding-left: 10.5rem; padding-right: 10.5rem; }

    .md-no-padding { padding:0 !important; }
    .md-no-padding-lr { padding-left: 0 !important; padding-right: 0 !important; }
    .md-no-padding-tb { padding-top: 0 !important; padding-bottom: 0 !important; }
    .md-no-padding-top { padding-top:0 !important; }
    .md-no-padding-bottom { padding-bottom:0 !important; }
    .md-no-padding-left { padding-left:0 !important; }
    .md-no-padding-right { padding-right:0 !important; }
}

@media (max-width: 767px) {
    .sm-padding-one-all { padding:1%; }
    .sm-padding-two-all { padding:2%; }
    .sm-padding-three-all { padding:3%; }
    .sm-padding-four-all { padding:4%; }
    .sm-padding-five-all { padding:5%; }
    .sm-padding-six-all { padding:6%; }
    .sm-padding-seven-all { padding:7%; }
    .sm-padding-eight-all { padding:8%; }
    .sm-padding-nine-all { padding:9%; }
    .sm-padding-ten-all { padding:10%; }
    .sm-padding-eleven-all { padding:11%; }
    .sm-padding-twelve-all { padding:12%; }
    .sm-padding-thirteen-all { padding:13%; }
    .sm-padding-fourteen-all { padding:14%; }
    .sm-padding-fifteen-all { padding:15%; }
    .sm-padding-sixteen-all { padding:16%; }
    .sm-padding-seventeen-all { padding:17%; }
    .sm-padding-eighteen-all { padding:18%; }
    .sm-padding-nineteen-all { padding:19%; }
    .sm-padding-twenty-all { padding:20%; }
    .sm-padding-5px-all { padding:5px; }
    .sm-padding-10px-all { padding:10px; }
    .sm-padding-15px-all { padding:15px; }
    .sm-padding-20px-all { padding:20px; }
    .sm-padding-25px-all { padding:25px; }
    .sm-padding-30px-all { padding:30px; }
    .sm-padding-35px-all { padding:35px; }
    .sm-padding-40px-all { padding:40px; }
    .sm-padding-45px-all { padding:45px; }
    .sm-padding-50px-all { padding:50px; }
    .sm-padding-55px-all { padding:55px; }
    .sm-padding-60px-all { padding:60px; }
    .sm-padding-65px-all { padding:65px; }
    .sm-padding-70px-all { padding:70px; }
    .sm-padding-75px-all { padding:75px; }
    .sm-padding-80px-all { padding:80px; }
    .sm-padding-85px-all { padding:85px; }
    .sm-padding-90px-all { padding:90px; }
    .sm-padding-95px-all { padding:95px; }
    .sm-padding-100px-all { padding:100px; }
    .sm-padding-1-rem-all { padding: 1rem; }
    .sm-padding-1-half-rem-all { padding: 1.5rem; }
    .sm-padding-2-rem-all { padding: 2rem; }
    .sm-padding-2-half-rem-all { padding: 2.5rem; }
    .sm-padding-3-rem-all { padding: 3rem; }
    .sm-padding-3-half-rem-all { padding: 3.5rem; }
    .sm-padding-4-rem-all { padding: 4rem; }
    .sm-padding-4-half-rem-all { padding: 4.5rem; }
    .sm-padding-5-rem-all { padding: 5rem; }
    .sm-padding-5-half-rem-all { padding: 5.5rem; }
    .sm-padding-6-rem-all { padding: 6rem; }
    .sm-padding-6-half-rem-all { padding: 6.5rem; }
    .sm-padding-7-rem-all { padding: 7rem; }
    .sm-padding-7-half-rem-all { padding: 7.5rem; }
    .sm-padding-8-rem-all { padding: 8rem; }
    .sm-padding-8-half-rem-all { padding: 8.5rem; }
    .sm-padding-9-rem-all { padding: 9rem; }
    .sm-padding-9-half-rem-all { padding: 9.5rem; }
    .sm-padding-10-rem-all { padding: 10rem; }
    .sm-padding-10-half-rem-all { padding: 10.5rem; }

    /* padding top */
    .sm-padding-one-top { padding-top:1%; }
    .sm-padding-two-top { padding-top:2%; }
    .sm-padding-three-top { padding-top:3%; }
    .sm-padding-four-top { padding-top:4%; }
    .sm-padding-five-top { padding-top:5%; }
    .sm-padding-six-top { padding-top:6%; }
    .sm-padding-seven-top { padding-top:7%; }
    .sm-padding-eight-top { padding-top:8%; }
    .sm-padding-nine-top { padding-top:9%; }
    .sm-padding-ten-top { padding-top:10%; }
    .sm-padding-eleven-top { padding-top:11%; }
    .sm-padding-twelve-top { padding-top:12%; }
    .sm-padding-thirteen-top { padding-top:13%; }
    .sm-padding-fourteen-top { padding-top:14%; }
    .sm-padding-fifteen-top { padding-top:15%; }
    .sm-padding-sixteen-top { padding-top:16%; }
    .sm-padding-seventeen-top { padding-top:17%; }
    .sm-padding-eighteen-top { padding-top:18%; }
    .sm-padding-nineteen-top { padding-top:19%; }
    .sm-padding-twenty-top { padding-top:20%; }
    .sm-padding-5px-top { padding-top:5px; }
    .sm-padding-10px-top { padding-top:10px; }
    .sm-padding-15px-top { padding-top:15px; }
    .sm-padding-20px-top { padding-top:20px; }
    .sm-padding-25px-top { padding-top:25px; }
    .sm-padding-30px-top { padding-top:30px; }
    .sm-padding-35px-top { padding-top:35px; }
    .sm-padding-40px-top { padding-top:40px; }
    .sm-padding-45px-top { padding-top:45px; }
    .sm-padding-50px-top { padding-top:50px; }
    .sm-padding-55px-top { padding-top:55px; }
    .sm-padding-60px-top { padding-top:60px; }
    .sm-padding-65px-top { padding-top:65px; }
    .sm-padding-70px-top { padding-top:70px; }
    .sm-padding-75px-top { padding-top:75px; }
    .sm-padding-80px-top { padding-top:80px; }
    .sm-padding-85px-top { padding-top:85px; }
    .sm-padding-90px-top { padding-top:90px; }
    .sm-padding-95px-top { padding-top:95px; }
    .sm-padding-100px-top { padding-top:100px; }
    .sm-padding-1-rem-top { padding-top: 1rem; }
    .sm-padding-1-half-rem-top { padding-top: 1.5rem; }
    .sm-padding-2-rem-top { padding-top: 2rem; }
    .sm-padding-2-half-rem-top { padding-top: 2.5rem; }
    .sm-padding-3-rem-top { padding-top: 3rem; }
    .sm-padding-3-half-rem-top { padding-top: 3.5rem; }
    .sm-padding-4-rem-top { padding-top: 4rem; }
    .sm-padding-4-half-rem-top { padding-top: 4.5rem; }
    .sm-padding-5-rem-top { padding-top: 5rem; }
    .sm-padding-5-half-rem-top { padding-top: 5.5rem; }
    .sm-padding-6-rem-top { padding-top: 6rem; }
    .sm-padding-6-half-rem-top { padding-top: 6.5rem; }
    .sm-padding-7-rem-top { padding-top: 7rem; }
    .sm-padding-7-half-rem-top { padding-top: 7.5rem; }
    .sm-padding-8-rem-top { padding-top: 8rem; }
    .sm-padding-8-half-rem-top { padding-top: 8.5rem; }
    .sm-padding-9-rem-top { padding-top: 9rem; }
    .sm-padding-9-half-rem-top { padding-top: 9.5rem; }
    .sm-padding-10-rem-top { padding-top: 10rem; }
    .sm-padding-10-half-rem-top { padding-top: 10.5rem; }

    /* padding bottom */
    .sm-padding-one-bottom { padding-bottom:1%; }
    .sm-padding-two-bottom { padding-bottom:2%; }
    .sm-padding-three-bottom { padding-bottom:3%; }
    .sm-padding-four-bottom { padding-bottom:4%; }
    .sm-padding-five-bottom { padding-bottom:5%; }
    .sm-padding-six-bottom { padding-bottom:6%; }
    .sm-padding-seven-bottom { padding-bottom:7%; }
    .sm-padding-eight-bottom { padding-bottom:8%; }
    .sm-padding-nine-bottom { padding-bottom:9%; }
    .sm-padding-ten-bottom { padding-bottom:10%; }
    .sm-padding-eleven-bottom { padding-bottom:11%; }
    .sm-padding-twelve-bottom { padding-bottom:12%; }
    .sm-padding-thirteen-bottom { padding-bottom:13%; }
    .sm-padding-fourteen-bottom { padding-bottom:14%; }
    .sm-padding-fifteen-bottom { padding-bottom:15%; }
    .sm-padding-sixteen-bottom { padding-bottom:16%; }
    .sm-padding-seventeen-bottom { padding-bottom:17%; }
    .sm-padding-eighteen-bottom { padding-bottom:18%; }
    .sm-padding-nineteen-bottom { padding-bottom:19%; }
    .sm-padding-twenty-bottom { padding-bottom:20%; }
    .sm-padding-5px-bottom { padding-bottom:5px; }
    .sm-padding-10px-bottom { padding-bottom:10px; }
    .sm-padding-15px-bottom { padding-bottom:15px; }
    .sm-padding-20px-bottom { padding-bottom:20px; }
    .sm-padding-25px-bottom { padding-bottom:25px; }
    .sm-padding-30px-bottom { padding-bottom:30px; }
    .sm-padding-35px-bottom { padding-bottom:35px; }
    .sm-padding-40px-bottom { padding-bottom:40px; }
    .sm-padding-45px-bottom { padding-bottom:45px; }
    .sm-padding-50px-bottom { padding-bottom:50px; }
    .sm-padding-55px-bottom { padding-bottom:55px; }
    .sm-padding-60px-bottom { padding-bottom:60px; }
    .sm-padding-65px-bottom { padding-bottom:65px; }
    .sm-padding-70px-bottom { padding-bottom:70px; }
    .sm-padding-75px-bottom { padding-bottom:75px; }
    .sm-padding-80px-bottom { padding-bottom:80px; }
    .sm-padding-85px-bottom { padding-bottom:85px; }
    .sm-padding-90px-bottom { padding-bottom:90px; }
    .sm-padding-95px-bottom { padding-bottom:95px; }
    .sm-padding-100px-bottom { padding-bottom:100px; }
    .sm-padding-1-rem-bottom { padding-bottom: 1rem; }
    .sm-padding-1-half-rem-bottom { padding-bottom: 1.5rem; }
    .sm-padding-2-rem-bottom { padding-bottom: 2rem; }
    .sm-padding-2-half-rem-bottom { padding-bottom: 2.5rem; }
    .sm-padding-3-rem-bottom { padding-bottom: 3rem; }
    .sm-padding-3-half-rem-bottom { padding-bottom: 3.5rem; }
    .sm-padding-4-rem-bottom { padding-bottom: 4rem; }
    .sm-padding-4-half-rem-bottom { padding-bottom: 4.5rem; }
    .sm-padding-5-rem-bottom { padding-bottom: 5rem; }
    .sm-padding-5-half-rem-bottom { padding-bottom: 5.5rem; }
    .sm-padding-6-rem-bottom { padding-bottom: 6rem; }
    .sm-padding-6-half-rem-bottom { padding-bottom: 6.5rem; }
    .sm-padding-7-rem-bottom { padding-bottom: 7rem; }
    .sm-padding-7-half-rem-bottom { padding-bottom: 7.5rem; }
    .sm-padding-8-rem-bottom { padding-bottom: 8rem; }
    .sm-padding-8-half-rem-bottom { padding-bottom: 8.5rem; }
    .sm-padding-9-rem-bottom { padding-bottom: 9rem; }
    .sm-padding-9-half-rem-bottom { padding-bottom: 9.5rem; }
    .sm-padding-10-rem-bottom { padding-bottom: 10rem; }
    .sm-padding-10-half-rem-bottom { padding-bottom: 10.5rem; }

    /* padding right */
    .sm-padding-one-right { padding-right:1%; }
    .sm-padding-two-right { padding-right:2%; }
    .sm-padding-three-right { padding-right:3%; }
    .sm-padding-four-right { padding-right:4% }
    .sm-padding-five-right { padding-right:5%; }
    .sm-padding-six-right { padding-right:6%; }
    .sm-padding-seven-right { padding-right:7%; }
    .sm-padding-eight-right { padding-right:8%; }
    .sm-padding-nine-right { padding-right:9%; }
    .sm-padding-ten-right { padding-right:10%; }
    .sm-padding-eleven-right { padding-right:11%; }
    .sm-padding-twelve-right { padding-right:12%; }
    .sm-padding-thirteen-right { padding-right:13%; }
    .sm-padding-fourteen-right { padding-right:14%; }
    .sm-padding-fifteen-right { padding-right:15%; }
    .sm-padding-sixteen-right { padding-right:16%; }
    .sm-padding-seventeen-right { padding-right:17%; }
    .sm-padding-eighteen-right { padding-right:18%; }
    .sm-padding-nineteen-right { padding-right:19%; }
    .sm-padding-twenty-right { padding-right:20%; }
    .sm-padding-5px-right { padding-right:5px; }
    .sm-padding-10px-right { padding-right:10px; }
    .sm-padding-15px-right { padding-right:15px; }
    .sm-padding-20px-right { padding-right:20px; }
    .sm-padding-25px-right { padding-right:25px; }
    .sm-padding-30px-right { padding-right:30px; }
    .sm-padding-35px-right { padding-right:35px; }
    .sm-padding-40px-right { padding-right:40px; }
    .sm-padding-45px-right { padding-right:45px; }
    .sm-padding-50px-right { padding-right:50px; }
    .sm-padding-55px-right { padding-right:55px; }
    .sm-padding-60px-right { padding-right:60px; }
    .sm-padding-65px-right { padding-right:65px; }
    .sm-padding-70px-right { padding-right:70px; }
    .sm-padding-75px-right { padding-right:75px; }
    .sm-padding-80px-right { padding-right:80px; }
    .sm-padding-85px-right { padding-right:85px; }
    .sm-padding-90px-right { padding-right:90px; }
    .sm-padding-95px-right { padding-right:95px; }
    .sm-padding-100px-right { padding-right:100px; }
    .sm-padding-1-rem-right { padding-right: 1rem; }
    .sm-padding-1-half-rem-right { padding-right: 1.5rem; }
    .sm-padding-2-rem-right { padding-right: 2rem; }
    .sm-padding-2-half-rem-right { padding-right: 2.5rem; }
    .sm-padding-3-rem-right { padding-right: 3rem; }
    .sm-padding-3-half-rem-right { padding-right: 3.5rem; }
    .sm-padding-4-rem-right { padding-right: 4rem; }
    .sm-padding-4-half-rem-right { padding-right: 4.5rem; }
    .sm-padding-5-rem-right { padding-right: 5rem; }
    .sm-padding-5-half-rem-right { padding-right: 5.5rem; }
    .sm-padding-6-rem-right { padding-right: 6rem; }
    .sm-padding-6-half-rem-right { padding-right: 6.5rem; }
    .sm-padding-7-rem-right { padding-right: 7rem; }
    .sm-padding-7-half-rem-right { padding-right: 7.5rem; }
    .sm-padding-8-rem-right { padding-right: 8rem; }
    .sm-padding-8-half-rem-right { padding-right: 8.5rem; }
    .sm-padding-9-rem-right { padding-right: 9rem; }
    .sm-padding-9-half-rem-right { padding-right: 9.5rem; }
    .sm-padding-10-rem-right { padding-right: 10rem; }
    .sm-padding-10-half-rem-right { padding-right: 10.5rem; }

    /* padding left */
    .sm-padding-one-left { padding-left:1%; }
    .sm-padding-two-left { padding-left:2%; }
    .sm-padding-three-left { padding-left:3%; }
    .sm-padding-four-left { padding-left:4%; }
    .sm-padding-five-left { padding-left:5%; }
    .sm-padding-six-left { padding-left:6%; }
    .sm-padding-seven-left { padding-left:7%; }
    .sm-padding-eight-left { padding-left:8%; }
    .sm-padding-nine-left { padding-left:9%; }
    .sm-padding-ten-left { padding-left:10%; }
    .sm-padding-eleven-left { padding-left:11%; }
    .sm-padding-twelve-left { padding-left:12%; }
    .sm-padding-thirteen-left { padding-left:13%; }
    .sm-padding-fourteen-left { padding-left:14%; }
    .sm-padding-fifteen-left { padding-left:15%; }
    .sm-padding-sixteen-left { padding-left:16%; }
    .sm-padding-seventeen-left { padding-left:17%; }
    .sm-padding-eighteen-left { padding-left:18%; }
    .sm-padding-nineteen-left { padding-left:19%; }
    .sm-padding-twenty-left { padding-left:20%; }
    .sm-padding-5px-left { padding-left:5px; }
    .sm-padding-10px-left { padding-left:10px; }
    .sm-padding-15px-left { padding-left:15px; }
    .sm-padding-20px-left { padding-left:20px; }
    .sm-padding-25px-left { padding-left:25px; }
    .sm-padding-30px-left { padding-left:30px; }
    .sm-padding-35px-left { padding-left:35px; }
    .sm-padding-40px-left { padding-left:40px; }
    .sm-padding-45px-left { padding-left:45px; }
    .sm-padding-50px-left { padding-left:50px; }
    .sm-padding-55px-left { padding-left:55px; }
    .sm-padding-60px-left { padding-left:60px; }
    .sm-padding-65px-left { padding-left:65px; }
    .sm-padding-70px-left { padding-left:70px; }
    .sm-padding-75px-left { padding-left:75px; }
    .sm-padding-80px-left { padding-left:80px; }
    .sm-padding-85px-left { padding-left:85px; }
    .sm-padding-90px-left { padding-left:90px; }
    .sm-padding-95px-left { padding-left:95px; }
    .sm-padding-100px-left { padding-left:100px; }
    .sm-padding-1-rem-left { padding-left: 1rem; }
    .sm-padding-1-half-rem-left { padding-left: 1.5rem; }
    .sm-padding-2-rem-left { padding-left: 2rem; }
    .sm-padding-2-half-rem-left { padding-left: 2.5rem; }
    .sm-padding-3-rem-left { padding-left: 3rem; }
    .sm-padding-3-half-rem-left { padding-left: 3.5rem; }
    .sm-padding-4-rem-left { padding-left: 4rem; }
    .sm-padding-4-half-rem-left { padding-left: 4.5rem; }
    .sm-padding-5-rem-left { padding-left: 5rem; }
    .sm-padding-5-half-rem-left { padding-left: 5.5rem; }
    .sm-padding-6-rem-left { padding-left: 6rem; }
    .sm-padding-6-half-rem-left { padding-left: 6.5rem; }
    .sm-padding-7-rem-left { padding-left: 7rem; }
    .sm-padding-7-half-rem-left { padding-left: 7.5rem; }
    .sm-padding-8-rem-left { padding-left: 8rem; }
    .sm-padding-8-half-rem-left { padding-left: 8.5rem; }
    .sm-padding-9-rem-left { padding-left: 9rem; }
    .sm-padding-9-half-rem-left { padding-left: 9.5rem; }
    .sm-padding-10-rem-left { padding-left: 10rem; }
    .sm-padding-10-half-rem-left { padding-left: 10.5rem; }

    /* padding top bottom */
    .sm-padding-one-tb { padding-top:1%; padding-bottom:1%; }
    .sm-padding-two-tb { padding-top:2%; padding-bottom:2%; }
    .sm-padding-three-tb { padding-top:3%; padding-bottom:3%; }
    .sm-padding-four-tb { padding-top:4%; padding-bottom:4%; }
    .sm-padding-five-tb { padding-top:5%; padding-bottom:5%; }
    .sm-padding-six-tb { padding-top:6%; padding-bottom:6%; }
    .sm-padding-seven-tb { padding-top:7%; padding-bottom:7%; }
    .sm-padding-eight-tb { padding-top:8%; padding-bottom:8%; }
    .sm-padding-nine-tb { padding-top:9%; padding-bottom:9%; }
    .sm-padding-ten-tb { padding-top:10%; padding-bottom:10%; }
    .sm-padding-eleven-tb { padding-top:11%; padding-bottom:11%; }
    .sm-padding-twelve-tb { padding-top:12%; padding-bottom:12%; }
    .sm-padding-thirteen-tb { padding-top:13%; padding-bottom:13%; }
    .sm-padding-fourteen-tb { padding-top:14%; padding-bottom:14%; }
    .sm-padding-fifteen-tb { padding-top:15%; padding-bottom:15%; }
    .sm-padding-sixteen-tb { padding-top:16%; padding-bottom:16%; }
    .sm-padding-seventeen-tb { padding-top:17%; padding-bottom:17%; }
    .sm-padding-eighteen-tb { padding-top:18%; padding-bottom:18%; }
    .sm-padding-nineteen-tb { padding-top:19%; padding-bottom:19%; }
    .sm-padding-twenty-tb { padding-top:20%; padding-bottom:20%; }
    .sm-padding-5px-tb { padding-top:5px; padding-bottom:5px; }
    .sm-padding-10px-tb { padding-top:10px; padding-bottom:10px; }
    .sm-padding-15px-tb { padding-top:15px; padding-bottom:15px; }
    .sm-padding-20px-tb { padding-top:20px; padding-bottom:20px; }
    .sm-padding-25px-tb { padding-top:25px; padding-bottom:25px; }
    .sm-padding-30px-tb { padding-top:30px; padding-bottom:30px; }
    .sm-padding-35px-tb { padding-top:35px; padding-bottom:35px; }
    .sm-padding-40px-tb { padding-top:40px; padding-bottom:40px; }
    .sm-padding-45px-tb { padding-top:45px; padding-bottom:45px; }
    .sm-padding-50px-tb { padding-top:50px; padding-bottom:50px; }
    .sm-padding-55px-tb { padding-top:55px; padding-bottom:55px; }
    .sm-padding-60px-tb { padding-top:60px; padding-bottom:60px; }
    .sm-padding-65px-tb { padding-top:65px; padding-bottom:65px; }
    .sm-padding-70px-tb { padding-top:70px; padding-bottom:70px; }
    .sm-padding-75px-tb { padding-top:75px; padding-bottom:75px; }
    .sm-padding-80px-tb { padding-top:80px; padding-bottom:80px; }
    .sm-padding-85px-tb { padding-top:85px; padding-bottom:85px; }
    .sm-padding-90px-tb { padding-top:90px; padding-bottom:90px; }
    .sm-padding-95px-tb { padding-top:95px; padding-bottom:95px; }
    .sm-padding-100px-tb { padding-top:100px; padding-bottom:100px; }
    .sm-padding-1-rem-tb { padding-top: 1rem; padding-bottom: 1rem; }
    .sm-padding-1-half-rem-tb { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .sm-padding-2-rem-tb { padding-top: 2rem; padding-bottom: 2rem; }
    .sm-padding-2-half-rem-tb { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .sm-padding-3-rem-tb { padding-top: 3rem; padding-bottom: 3rem; }
    .sm-padding-3-half-rem-tb { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .sm-padding-4-rem-tb { padding-top: 4rem; padding-bottom: 4rem; }
    .sm-padding-4-half-rem-tb { padding-top: 4.5rem; padding-bottom: 4.5rem; }
    .sm-padding-5-rem-tb { padding-top: 5rem; padding-bottom: 5rem; }
    .sm-padding-5-half-rem-tb { padding-top: 5.5rem; padding-bottom: 5.5rem; }
    .sm-padding-6-rem-tb { padding-top: 6rem; padding-bottom: 6rem; }
    .sm-padding-6-half-rem-tb { padding-top: 6.5rem; padding-bottom: 6.5rem; }
    .sm-padding-7-rem-tb { padding-top: 7rem; padding-bottom: 7rem; }
    .sm-padding-7-half-rem-tb { padding-top: 7.5rem; padding-bottom: 7.5rem; }
    .sm-padding-8-rem-tb { padding-top: 8rem; padding-bottom: 8rem; }
    .sm-padding-8-half-rem-tb { padding-top: 8.5rem; padding-bottom: 8.5rem; }
    .sm-padding-9-rem-tb { padding-top: 9rem; padding-bottom: 9rem; }
    .sm-padding-9-half-rem-tb { padding-top: 9.5rem; padding-bottom: 9.5rem; }
    .sm-padding-10-rem-tb { padding-top: 10rem; padding-bottom: 10rem; }
    .sm-padding-10-half-rem-tb { padding-top: 10.5rem; padding-bottom: 10.5rem; }

    /* padding left right */
    .sm-padding-one-lr { padding-left:1%; padding-right:1%; }
    .sm-padding-two-lr { padding-left:2%; padding-right:2%; }
    .sm-padding-three-lr { padding-left:3%; padding-right:3%; }
    .sm-padding-four-lr { padding-left:4%; padding-right:4%; }
    .sm-padding-five-lr { padding-left:5%; padding-right:5%; }
    .sm-padding-six-lr { padding-left:6%; padding-right:6%; }
    .sm-padding-seven-lr { padding-left:7%; padding-right:7%; }
    .sm-padding-eight-lr { padding-left:8%; padding-right:8%; }
    .sm-padding-nine-lr { padding-left:9%; padding-right:9%; }
    .sm-padding-ten-lr { padding-left:10%; padding-right:10%; }
    .sm-padding-eleven-lr { padding-left:11%; padding-right:11%; }
    .sm-padding-twelve-lr { padding-left:12%; padding-right:12%; }
    .sm-padding-thirteen-lr { padding-left:13%; padding-right:13%; }
    .sm-padding-fourteen-lr { padding-left:14%; padding-right:14%; }
    .sm-padding-fifteen-lr { padding-left:15%; padding-right:15%; }
    .sm-padding-sixteen-lr { padding-left:16%; padding-right:16%; }
    .sm-padding-seventeen-lr { padding-left:17%; padding-right:17%; }
    .sm-padding-eighteen-lr { padding-left:18%; padding-right:18%; }
    .sm-padding-nineteen-lr { padding-left:19%; padding-right:19%; }
    .sm-padding-twenty-lr { padding-left:20%; padding-right:20%; }
    .sm-padding-5px-lr { padding-left:5px; padding-right:5px; }
    .sm-padding-10px-lr { padding-left:10px; padding-right:10px; }
    .sm-padding-15px-lr { padding-left:15px; padding-right:15px; }
    .sm-padding-20px-lr { padding-left:20px; padding-right:20px; }
    .sm-padding-25px-lr { padding-left:25px; padding-right:25px; }
    .sm-padding-30px-lr { padding-left:30px; padding-right:30px; }
    .sm-padding-35px-lr { padding-left:35px; padding-right:35px; }
    .sm-padding-40px-lr { padding-left:40px; padding-right:40px; }
    .sm-padding-45px-lr { padding-left:45px; padding-right:45px; }
    .sm-padding-50px-lr { padding-left:50px; padding-right:50px; }
    .sm-padding-55px-lr { padding-left:55px; padding-right:55px; }
    .sm-padding-60px-lr { padding-left:60px; padding-right:60px; }
    .sm-padding-65px-lr { padding-left:65px; padding-right:65px; }
    .sm-padding-70px-lr { padding-left:70px; padding-right:70px; }
    .sm-padding-75px-lr { padding-left:75px; padding-right:75px; }
    .sm-padding-80px-lr { padding-left:80px; padding-right:80px; }
    .sm-padding-85px-lr { padding-left:85px; padding-right:85px; }
    .sm-padding-90px-lr { padding-left:90px; padding-right:90px; }
    .sm-padding-95px-lr { padding-left:95px; padding-right:95px; }
    .sm-padding-100px-lr { padding-left:100px; padding-right:100px; }
    .sm-padding-1-rem-lr { padding-left: 1rem; padding-right: 1rem; }
    .sm-padding-1-half-rem-lr { padding-left: 1.5rem; padding-right: 1.5rem; }
    .sm-padding-2-rem-lr { padding-left: 2rem; padding-right: 2rem; }
    .sm-padding-2-half-rem-lr { padding-left: 2.5rem; padding-right: 2.5rem; }
    .sm-padding-3-rem-lr { padding-left: 3rem; padding-right: 3rem; }
    .sm-padding-3-half-rem-lr { padding-left: 3.5rem; padding-right: 3.5rem; }
    .sm-padding-4-rem-lr { padding-left: 4rem; padding-right: 4rem; }
    .sm-padding-4-half-rem-lr { padding-left: 4.5rem; padding-right: 4.5rem; }
    .sm-padding-5-rem-lr { padding-left: 5rem; padding-right: 5rem; }
    .sm-padding-5-half-rem-lr { padding-left: 5.5rem; padding-right: 5.5rem; }
    .sm-padding-6-rem-lr { padding-left: 6rem; padding-right: 6rem; }
    .sm-padding-6-half-rem-lr { padding-left: 6.5rem; padding-right: 6.5rem; }
    .sm-padding-7-rem-lr { padding-left: 7rem; padding-right: 7rem; }
    .sm-padding-7-half-rem-lr { padding-left: 7.5rem; padding-right: 7.5rem; }
    .sm-padding-8-rem-lr { padding-left: 8rem; padding-right: 8rem; }
    .sm-padding-8-half-rem-lr { padding-left: 8.5rem; padding-right: 8.5rem; }
    .sm-padding-9-rem-lr { padding-left: 9rem; padding-right: 9rem; }
    .sm-padding-9-half-rem-lr { padding-left: 9.5rem; padding-right: 9.5rem; }
    .sm-padding-10-rem-lr { padding-left: 10rem; padding-right: 10rem; }
    .sm-padding-10-half-rem-lr { padding-left: 10.5rem; padding-right: 10.5rem; }

    .sm-no-padding { padding:0 !important; }
    .sm-no-padding-lr { padding-left: 0 !important; padding-right: 0 !important; }
    .sm-no-padding-tb { padding-top: 0 !important; padding-bottom: 0 !important; }
    .sm-no-padding-top { padding-top:0 !important; }
    .sm-no-padding-bottom { padding-bottom:0 !important; }
    .sm-no-padding-left { padding-left:0 !important; }
    .sm-no-padding-right { padding-right:0 !important; }
}

@media screen and (max-width: 575px) {
    .xs-padding-one-all { padding:1%; }
    .xs-padding-two-all { padding:2%; }
    .xs-padding-three-all { padding:3%; }
    .xs-padding-four-all { padding:4%; }
    .xs-padding-five-all { padding:5%; }
    .xs-padding-six-all { padding:6%; }
    .xs-padding-seven-all { padding:7%; }
    .xs-padding-eight-all { padding:8%; }
    .xs-padding-nine-all { padding:9%; }
    .xs-padding-ten-all { padding:10%; }
    .xs-padding-eleven-all { padding:11%; }
    .xs-padding-twelve-all { padding:12%; }
    .xs-padding-thirteen-all { padding:13%; }
    .xs-padding-fourteen-all { padding:14%; }
    .xs-padding-fifteen-all { padding:15%; }
    .xs-padding-sixteen-all { padding:16%; }
    .xs-padding-seventeen-all { padding:17%; }
    .xs-padding-eighteen-all { padding:18%; }
    .xs-padding-nineteen-all { padding:19%; }
    .xs-padding-twenty-all { padding:20%; }
    .xs-padding-5px-all { padding:5px; }
    .xs-padding-10px-all { padding:10px; }
    .xs-padding-15px-all { padding:15px; }
    .xs-padding-20px-all { padding:20px; }
    .xs-padding-25px-all { padding:25px; }
    .xs-padding-30px-all { padding:30px; }
    .xs-padding-35px-all { padding:35px; }
    .xs-padding-40px-all { padding:40px; }
    .xs-padding-45px-all { padding:45px; }
    .xs-padding-50px-all { padding:50px; }
    .xs-padding-55px-all { padding:55px; }
    .xs-padding-60px-all { padding:60px; }
    .xs-padding-65px-all { padding:65px; }
    .xs-padding-70px-all { padding:70px; }
    .xs-padding-75px-all { padding:75px; }
    .xs-padding-80px-all { padding:80px; }
    .xs-padding-85px-all { padding:85px; }
    .xs-padding-90px-all { padding:90px; }
    .xs-padding-95px-all { padding:95px; }
    .xs-padding-100px-all { padding:100px; }
    .xs-padding-1-rem-all { padding: 1rem; }
    .xs-padding-1-half-rem-all { padding: 1.5rem; }
    .xs-padding-2-rem-all { padding: 2rem; }
    .xs-padding-2-half-rem-all { padding: 2.5rem; }
    .xs-padding-3-rem-all { padding: 3rem; }
    .xs-padding-3-half-rem-all { padding: 3.5rem; }
    .xs-padding-4-rem-all { padding: 4rem; }
    .xs-padding-4-half-rem-all { padding: 4.5rem; }
    .xs-padding-5-rem-all { padding: 5rem; }
    .xs-padding-5-half-rem-all { padding: 5.5rem; }
    .xs-padding-6-rem-all { padding: 6rem; }
    .xs-padding-6-half-rem-all { padding: 6.5rem; }
    .xs-padding-7-rem-all { padding: 7rem; }
    .xs-padding-7-half-rem-all { padding: 7.5rem; }
    .xs-padding-8-rem-all { padding: 8rem; }
    .xs-padding-8-half-rem-all { padding: 8.5rem; }
    .xs-padding-9-rem-all { padding: 9rem; }
    .xs-padding-9-half-rem-all { padding: 9.5rem; }
    .xs-padding-10-rem-all { padding: 10rem; }
    .xs-padding-10-half-rem-all { padding: 10.5rem; }

    /* padding top */
    .xs-padding-one-top { padding-top:1%; }
    .xs-padding-two-top { padding-top:2%; }
    .xs-padding-three-top { padding-top:3%; }
    .xs-padding-four-top { padding-top:4%; }
    .xs-padding-five-top { padding-top:5%; }
    .xs-padding-six-top { padding-top:6%; }
    .xs-padding-seven-top { padding-top:7%; }
    .xs-padding-eight-top { padding-top:8%; }
    .xs-padding-nine-top { padding-top:9%; }
    .xs-padding-ten-top { padding-top:10%; }
    .xs-padding-eleven-top { padding-top:11%; }
    .xs-padding-twelve-top { padding-top:12%; }
    .xs-padding-thirteen-top { padding-top:13%; }
    .xs-padding-fourteen-top { padding-top:14%; }
    .xs-padding-fifteen-top { padding-top:15%; }
    .xs-padding-sixteen-top { padding-top:16%; }
    .xs-padding-seventeen-top { padding-top:17%; }
    .xs-padding-eighteen-top { padding-top:18%; }
    .xs-padding-nineteen-top { padding-top:19%; }
    .xs-padding-twenty-top { padding-top:20%; }
    .xs-padding-5px-top { padding-top:5px; }
    .xs-padding-10px-top { padding-top:10px; }
    .xs-padding-15px-top { padding-top:15px; }
    .xs-padding-20px-top { padding-top:20px; }
    .xs-padding-25px-top { padding-top:25px; }
    .xs-padding-30px-top { padding-top:30px; }
    .xs-padding-35px-top { padding-top:35px; }
    .xs-padding-40px-top { padding-top:40px; }
    .xs-padding-45px-top { padding-top:45px; }
    .xs-padding-50px-top { padding-top:50px; }
    .xs-padding-55px-top { padding-top:55px; }
    .xs-padding-60px-top { padding-top:60px; }
    .xs-padding-65px-top { padding-top:65px; }
    .xs-padding-70px-top { padding-top:70px; }
    .xs-padding-75px-top { padding-top:75px; }
    .xs-padding-80px-top { padding-top:80px; }
    .xs-padding-85px-top { padding-top:85px; }
    .xs-padding-90px-top { padding-top:90px; }
    .xs-padding-95px-top { padding-top:95px; }
    .xs-padding-100px-top { padding-top:100px; }
    .xs-padding-1-rem-top { padding-top: 1rem; }
    .xs-padding-1-half-rem-top { padding-top: 1.5rem; }
    .xs-padding-2-rem-top { padding-top: 2rem; }
    .xs-padding-2-half-rem-top { padding-top: 2.5rem; }
    .xs-padding-3-rem-top { padding-top: 3rem; }
    .xs-padding-3-half-rem-top { padding-top: 3.5rem; }
    .xs-padding-4-rem-top { padding-top: 4rem; }
    .xs-padding-4-half-rem-top { padding-top: 4.5rem; }
    .xs-padding-5-rem-top { padding-top: 5rem; }
    .xs-padding-5-half-rem-top { padding-top: 5.5rem; }
    .xs-padding-6-rem-top { padding-top: 6rem; }
    .xs-padding-6-half-rem-top { padding-top: 6.5rem; }
    .xs-padding-7-rem-top { padding-top: 7rem; }
    .xs-padding-7-half-rem-top { padding-top: 7.5rem; }
    .xs-padding-8-rem-top { padding-top: 8rem; }
    .xs-padding-8-half-rem-top { padding-top: 8.5rem; }
    .xs-padding-9-rem-top { padding-top: 9rem; }
    .xs-padding-9-half-rem-top { padding-top: 9.5rem; }
    .xs-padding-10-rem-top { padding-top: 10rem; }
    .xs-padding-10-half-rem-top { padding-top: 10.5rem; }

    .xs-padding-one-bottom { padding-bottom:1%; }
    .xs-padding-two-bottom { padding-bottom:2%; }
    .xs-padding-three-bottom { padding-bottom:3%; }
    .xs-padding-four-bottom { padding-bottom:4%; }
    .xs-padding-five-bottom { padding-bottom:5%; }
    .xs-padding-six-bottom { padding-bottom:6%; }
    .xs-padding-seven-bottom { padding-bottom:7%; }
    .xs-padding-eight-bottom { padding-bottom:8%; }
    .xs-padding-nine-bottom { padding-bottom:9%; }
    .xs-padding-ten-bottom { padding-bottom:10%; }
    .xs-padding-eleven-bottom { padding-bottom:11%; }
    .xs-padding-twelve-bottom { padding-bottom:12%; }
    .xs-padding-thirteen-bottom { padding-bottom:13%; }
    .xs-padding-fourteen-bottom { padding-bottom:14%; }
    .xs-padding-fifteen-bottom { padding-bottom:15%; }
    .xs-padding-sixteen-bottom { padding-bottom:16%; }
    .xs-padding-seventeen-bottom { padding-bottom:17%; }
    .xs-padding-eighteen-bottom { padding-bottom:18%; }
    .xs-padding-nineteen-bottom { padding-bottom:19%; }
    .xs-padding-twenty-bottom { padding-bottom:20%; }
    .xs-padding-5px-bottom { padding-bottom:5px; }
    .xs-padding-10px-bottom { padding-bottom:10px; }
    .xs-padding-15px-bottom { padding-bottom:15px; }
    .xs-padding-20px-bottom { padding-bottom:20px; }
    .xs-padding-25px-bottom { padding-bottom:25px; }
    .xs-padding-30px-bottom { padding-bottom:30px; }
    .xs-padding-35px-bottom { padding-bottom:35px; }
    .xs-padding-40px-bottom { padding-bottom:40px; }
    .xs-padding-45px-bottom { padding-bottom:45px; }
    .xs-padding-50px-bottom { padding-bottom:50px; }
    .xs-padding-55px-bottom { padding-bottom:55px; }
    .xs-padding-60px-bottom { padding-bottom:60px; }
    .xs-padding-65px-bottom { padding-bottom:65px; }
    .xs-padding-70px-bottom { padding-bottom:70px; }
    .xs-padding-75px-bottom { padding-bottom:75px; }
    .xs-padding-80px-bottom { padding-bottom:80px; }
    .xs-padding-85px-bottom { padding-bottom:85px; }
    .xs-padding-90px-bottom { padding-bottom:90px; }
    .xs-padding-95px-bottom { padding-bottom:95px; }
    .xs-padding-100px-bottom { padding-bottom:100px; }
    .xs-padding-1-rem-bottom { padding-bottom: 1rem; }
    .xs-padding-1-half-rem-bottom { padding-bottom: 1.5rem; }
    .xs-padding-2-rem-bottom { padding-bottom: 2rem; }
    .xs-padding-2-half-rem-bottom { padding-bottom: 2.5rem; }
    .xs-padding-3-rem-bottom { padding-bottom: 3rem; }
    .xs-padding-3-half-rem-bottom { padding-bottom: 3.5rem; }
    .xs-padding-4-rem-bottom { padding-bottom: 4rem; }
    .xs-padding-4-half-rem-bottom { padding-bottom: 4.5rem; }
    .xs-padding-5-rem-bottom { padding-bottom: 5rem; }
    .xs-padding-5-half-rem-bottom { padding-bottom: 5.5rem; }
    .xs-padding-6-rem-bottom { padding-bottom: 6rem; }
    .xs-padding-6-half-rem-bottom { padding-bottom: 6.5rem; }
    .xs-padding-7-rem-bottom { padding-bottom: 7rem; }
    .xs-padding-7-half-rem-bottom { padding-bottom: 7.5rem; }
    .xs-padding-8-rem-bottom { padding-bottom: 8rem; }
    .xs-padding-8-half-rem-bottom { padding-bottom: 8.5rem; }
    .xs-padding-9-rem-bottom { padding-bottom: 9rem; }
    .xs-padding-9-half-rem-bottom { padding-bottom: 9.5rem; }
    .xs-padding-10-rem-bottom { padding-bottom: 10rem; }
    .xs-padding-10-half-rem-bottom { padding-bottom: 10.5rem; }

    /* padding right */
    .xs-padding-one-right { padding-right:1%; }
    .xs-padding-two-right { padding-right:2%; }
    .xs-padding-three-right { padding-right:3%; }
    .xs-padding-four-right { padding-right:4% }
    .xs-padding-five-right { padding-right:5%; }
    .xs-padding-six-right { padding-right:6%; }
    .xs-padding-seven-right { padding-right:7%; }
    .xs-padding-eight-right { padding-right:8%; }
    .xs-padding-nine-right { padding-right:9%; }
    .xs-padding-ten-right { padding-right:10%; }
    .xs-padding-eleven-right { padding-right:11%; }
    .xs-padding-twelve-right { padding-right:12%; }
    .xs-padding-thirteen-right { padding-right:13%; }
    .xs-padding-fourteen-right { padding-right:14%; }
    .xs-padding-fifteen-right { padding-right:15%; }
    .xs-padding-sixteen-right { padding-right:16%; }
    .xs-padding-seventeen-right { padding-right:17%; }
    .xs-padding-eighteen-right { padding-right:18%; }
    .xs-padding-nineteen-right { padding-right:19%; }
    .xs-padding-twenty-right { padding-right:20%; }
    .xs-padding-5px-right { padding-right:5px; }
    .xs-padding-10px-right { padding-right:10px; }
    .xs-padding-15px-right { padding-right:15px; }
    .xs-padding-20px-right { padding-right:20px; }
    .xs-padding-25px-right { padding-right:25px; }
    .xs-padding-30px-right { padding-right:30px; }
    .xs-padding-35px-right { padding-right:35px; }
    .xs-padding-40px-right { padding-right:40px; }
    .xs-padding-45px-right { padding-right:45px; }
    .xs-padding-50px-right { padding-right:50px; }
    .xs-padding-55px-right { padding-right:55px; }
    .xs-padding-60px-right { padding-right:60px; }
    .xs-padding-65px-right { padding-right:65px; }
    .xs-padding-70px-right { padding-right:70px; }
    .xs-padding-75px-right { padding-right:75px; }
    .xs-padding-80px-right { padding-right:80px; }
    .xs-padding-85px-right { padding-right:85px; }
    .xs-padding-90px-right { padding-right:90px; }
    .xs-padding-95px-right { padding-right:95px; }
    .xs-padding-100px-right { padding-right:100px; }
    .xs-padding-1-rem-right { padding-right: 1rem; }
    .xs-padding-1-half-rem-right { padding-right: 1.5rem; }
    .xs-padding-2-rem-right { padding-right: 2rem; }
    .xs-padding-2-half-rem-right { padding-right: 2.5rem; }
    .xs-padding-3-rem-right { padding-right: 3rem; }
    .xs-padding-3-half-rem-right { padding-right: 3.5rem; }
    .xs-padding-4-rem-right { padding-right: 4rem; }
    .xs-padding-4-half-rem-right { padding-right: 4.5rem; }
    .xs-padding-5-rem-right { padding-right: 5rem; }
    .xs-padding-5-half-rem-right { padding-right: 5.5rem; }
    .xs-padding-6-rem-right { padding-right: 6rem; }
    .xs-padding-6-half-rem-right { padding-right: 6.5rem; }
    .xs-padding-7-rem-right { padding-right: 7rem; }
    .xs-padding-7-half-rem-right { padding-right: 7.5rem; }
    .xs-padding-8-rem-right { padding-right: 8rem; }
    .xs-padding-8-half-rem-right { padding-right: 8.5rem; }
    .xs-padding-9-rem-right { padding-right: 9rem; }
    .xs-padding-9-half-rem-right { padding-right: 9.5rem; }
    .xs-padding-10-rem-right { padding-right: 10rem; }
    .xs-padding-10-half-rem-right { padding-right: 10.5rem; }

    /* padding left */
    .xs-padding-one-left { padding-left:1%; }
    .xs-padding-two-left { padding-left:2%; }
    .xs-padding-three-left { padding-left:3%; }
    .xs-padding-four-left { padding-left:4%; }
    .xs-padding-five-left { padding-left:5%; }
    .xs-padding-six-left { padding-left:6%; }
    .xs-padding-seven-left { padding-left:7%; }
    .xs-padding-eight-left { padding-left:8%; }
    .xs-padding-nine-left { padding-left:9%; }
    .xs-padding-ten-left { padding-left:10%; }
    .xs-padding-eleven-left { padding-left:11%; }
    .xs-padding-twelve-left { padding-left:12%; }
    .xs-padding-thirteen-left { padding-left:13%; }
    .xs-padding-fourteen-left { padding-left:14%; }
    .xs-padding-fifteen-left { padding-left:15%; }
    .xs-padding-sixteen-left { padding-left:16%; }
    .xs-padding-seventeen-left { padding-left:17%; }
    .xs-padding-eighteen-left { padding-left:18%; }
    .xs-padding-nineteen-left { padding-left:19%; }
    .xs-padding-twenty-left { padding-left:20%; }
    .xs-padding-5px-left { padding-left:5px; }
    .xs-padding-10px-left { padding-left:10px; }
    .xs-padding-15px-left { padding-left:15px; }
    .xs-padding-20px-left { padding-left:20px; }
    .xs-padding-25px-left { padding-left:25px; }
    .xs-padding-30px-left { padding-left:30px; }
    .xs-padding-35px-left { padding-left:35px; }
    .xs-padding-40px-left { padding-left:40px; }
    .xs-padding-45px-left { padding-left:45px; }
    .xs-padding-50px-left { padding-left:50px; }
    .xs-padding-55px-left { padding-left:55px; }
    .xs-padding-60px-left { padding-left:60px; }
    .xs-padding-65px-left { padding-left:65px; }
    .xs-padding-70px-left { padding-left:70px; }
    .xs-padding-75px-left { padding-left:75px; }
    .xs-padding-80px-left { padding-left:80px; }
    .xs-padding-85px-left { padding-left:85px; }
    .xs-padding-90px-left { padding-left:90px; }
    .xs-padding-95px-left { padding-left:95px; }
    .xs-padding-100px-left { padding-left:100px; }
    .xs-padding-1-rem-left { padding-left: 1rem; }
    .xs-padding-1-half-rem-left { padding-left: 1.5rem; }
    .xs-padding-2-rem-left { padding-left: 2rem; }
    .xs-padding-2-half-rem-left { padding-left: 2.5rem; }
    .xs-padding-3-rem-left { padding-left: 3rem; }
    .xs-padding-3-half-rem-left { padding-left: 3.5rem; }
    .xs-padding-4-rem-left { padding-left: 4rem; }
    .xs-padding-4-half-rem-left { padding-left: 4.5rem; }
    .xs-padding-5-rem-left { padding-left: 5rem; }
    .xs-padding-5-half-rem-left { padding-left: 5.5rem; }
    .xs-padding-6-rem-left { padding-left: 6rem; }
    .xs-padding-6-half-rem-left { padding-left: 6.5rem; }
    .xs-padding-7-rem-left { padding-left: 7rem; }
    .xs-padding-7-half-rem-left { padding-left: 7.5rem; }
    .xs-padding-8-rem-left { padding-left: 8rem; }
    .xs-padding-8-half-rem-left { padding-left: 8.5rem; }
    .xs-padding-9-rem-left { padding-left: 9rem; }
    .xs-padding-9-half-rem-left { padding-left: 9.5rem; }
    .xs-padding-10-rem-left { padding-left: 10rem; }
    .xs-padding-10-half-rem-left { padding-left: 10.5rem; }

    /* padding top bottom */
    .xs-padding-one-tb { padding-top:1%; padding-bottom:1%; }
    .xs-padding-two-tb { padding-top:2%; padding-bottom:2%; }
    .xs-padding-three-tb { padding-top:3%; padding-bottom:3%; }
    .xs-padding-four-tb { padding-top:4%; padding-bottom:4%; }
    .xs-padding-five-tb { padding-top:5%; padding-bottom:5%; }
    .xs-padding-six-tb { padding-top:6%; padding-bottom:6%; }
    .xs-padding-seven-tb { padding-top:7%; padding-bottom:7%; }
    .xs-padding-eight-tb { padding-top:8%; padding-bottom:8%; }
    .xs-padding-nine-tb { padding-top:9%; padding-bottom:9%; }
    .xs-padding-ten-tb { padding-top:10%; padding-bottom:10%; }
    .xs-padding-eleven-tb { padding-top:11%; padding-bottom:11%; }
    .xs-padding-twelve-tb { padding-top:12%; padding-bottom:12%; }
    .xs-padding-thirteen-tb { padding-top:13%; padding-bottom:13%; }
    .xs-padding-fourteen-tb { padding-top:14%; padding-bottom:14%; }
    .xs-padding-fifteen-tb { padding-top:15%; padding-bottom:15%; }
    .xs-padding-sixteen-tb { padding-top:16%; padding-bottom:16%; }
    .xs-padding-seventeen-tb { padding-top:17%; padding-bottom:17%; }
    .xs-padding-eighteen-tb { padding-top:18%; padding-bottom:18%; }
    .xs-padding-nineteen-tb { padding-top:19%; padding-bottom:19%; }
    .xs-padding-twenty-tb { padding-top:20%; padding-bottom:20%; }
    .xs-padding-5px-tb { padding-top:5px; padding-bottom:5px; }
    .xs-padding-10px-tb { padding-top:10px; padding-bottom:10px; }
    .xs-padding-15px-tb { padding-top:15px; padding-bottom:15px; }
    .xs-padding-20px-tb { padding-top:20px; padding-bottom:20px; }
    .xs-padding-25px-tb { padding-top:25px; padding-bottom:25px; }
    .xs-padding-30px-tb { padding-top:30px; padding-bottom:30px; }
    .xs-padding-35px-tb { padding-top:35px; padding-bottom:35px; }
    .xs-padding-40px-tb { padding-top:40px; padding-bottom:40px; }
    .xs-padding-45px-tb { padding-top:45px; padding-bottom:45px; }
    .xs-padding-50px-tb { padding-top:50px; padding-bottom:50px; }
    .xs-padding-55px-tb { padding-top:55px; padding-bottom:55px; }
    .xs-padding-60px-tb { padding-top:60px; padding-bottom:60px; }
    .xs-padding-65px-tb { padding-top:65px; padding-bottom:65px; }
    .xs-padding-70px-tb { padding-top:70px; padding-bottom:70px; }
    .xs-padding-75px-tb { padding-top:75px; padding-bottom:75px; }
    .xs-padding-80px-tb { padding-top:80px; padding-bottom:80px; }
    .xs-padding-85px-tb { padding-top:85px; padding-bottom:85px; }
    .xs-padding-90px-tb { padding-top:90px; padding-bottom:90px; }
    .xs-padding-95px-tb { padding-top:95px; padding-bottom:95px; }
    .xs-padding-100px-tb { padding-top:100px; padding-bottom:100px; }
    .xs-padding-1-rem-tb { padding-top: 1rem; padding-bottom: 1rem; }
    .xs-padding-1-half-rem-tb { padding-top: 1.5rem; padding-bottom: 1.5rem; }
    .xs-padding-2-rem-tb { padding-top: 2rem; padding-bottom: 2rem; }
    .xs-padding-2-half-rem-tb { padding-top: 2.5rem; padding-bottom: 2.5rem; }
    .xs-padding-3-rem-tb { padding-top: 3rem; padding-bottom: 3rem; }
    .xs-padding-3-half-rem-tb { padding-top: 3.5rem; padding-bottom: 3.5rem; }
    .xs-padding-4-rem-tb { padding-top: 4rem; padding-bottom: 4rem; }
    .xs-padding-4-half-rem-tb { padding-top: 4.5rem; padding-bottom: 4.5rem; }
    .xs-padding-5-rem-tb { padding-top: 5rem; padding-bottom: 5rem; }
    .xs-padding-5-half-rem-tb { padding-top: 5.5rem; padding-bottom: 5.5rem; }
    .xs-padding-6-rem-tb { padding-top: 6rem; padding-bottom: 6rem; }
    .xs-padding-6-half-rem-tb { padding-top: 6.5rem; padding-bottom: 6.5rem; }
    .xs-padding-7-rem-tb { padding-top: 7rem; padding-bottom: 7rem; }
    .xs-padding-7-half-rem-tb { padding-top: 7.5rem; padding-bottom: 7.5rem; }
    .xs-padding-8-rem-tb { padding-top: 8rem; padding-bottom: 8rem; }
    .xs-padding-8-half-rem-tb { padding-top: 8.5rem; padding-bottom: 8.5rem; }
    .xs-padding-9-rem-tb { padding-top: 9rem; padding-bottom: 9rem; }
    .xs-padding-9-half-rem-tb { padding-top: 9.5rem; padding-bottom: 9.5rem; }
    .xs-padding-10-rem-tb { padding-top: 10rem; padding-bottom: 10rem; }
    .xs-padding-10-half-rem-tb { padding-top: 10.5rem; padding-bottom: 10.5rem; }

    /* padding left right */
    .xs-padding-one-lr { padding-left:1%; padding-right:1%; }
    .xs-padding-two-lr { padding-left:2%; padding-right:2%; }
    .xs-padding-three-lr { padding-left:3%; padding-right:3%; }
    .xs-padding-four-lr { padding-left:4%; padding-right:4%; }
    .xs-padding-five-lr { padding-left:5%; padding-right:5%; }
    .xs-padding-six-lr { padding-left:6%; padding-right:6%; }
    .xs-padding-seven-lr { padding-left:7%; padding-right:7%; }
    .xs-padding-eight-lr { padding-left:8%; padding-right:8%; }
    .xs-padding-nine-lr { padding-left:9%; padding-right:9%; }
    .xs-padding-ten-lr { padding-left:10%; padding-right:10%; }
    .xs-padding-eleven-lr { padding-left:11%; padding-right:11%; }
    .xs-padding-twelve-lr { padding-left:12%; padding-right:12%; }
    .xs-padding-thirteen-lr { padding-left:13%; padding-right:13%; }
    .xs-padding-fourteen-lr { padding-left:14%; padding-right:14%; }
    .xs-padding-fifteen-lr { padding-left:15%; padding-right:15%; }
    .xs-padding-sixteen-lr { padding-left:16%; padding-right:16%; }
    .xs-padding-seventeen-lr { padding-left:17%; padding-right:17%; }
    .xs-padding-eighteen-lr { padding-left:18%; padding-right:18%; }
    .xs-padding-nineteen-lr { padding-left:19%; padding-right:19%; }
    .xs-padding-twenty-lr { padding-left:20%; padding-right:20%; }
    .xs-padding-5px-lr { padding-left:5px; padding-right:5px; }
    .xs-padding-10px-lr { padding-left:10px; padding-right:10px; }
    .xs-padding-15px-lr { padding-left:15px; padding-right:15px; }
    .xs-padding-20px-lr { padding-left:20px; padding-right:20px; }
    .xs-padding-25px-lr { padding-left:25px; padding-right:25px; }
    .xs-padding-30px-lr { padding-left:30px; padding-right:30px; }
    .xs-padding-35px-lr { padding-left:35px; padding-right:35px; }
    .xs-padding-40px-lr { padding-left:40px; padding-right:40px; }
    .xs-padding-45px-lr { padding-left:45px; padding-right:45px; }
    .xs-padding-50px-lr { padding-left:50px; padding-right:50px; }
    .xs-padding-55px-lr { padding-left:55px; padding-right:55px; }
    .xs-padding-60px-lr { padding-left:60px; padding-right:60px; }
    .xs-padding-65px-lr { padding-left:65px; padding-right:65px; }
    .xs-padding-70px-lr { padding-left:70px; padding-right:70px; }
    .xs-padding-75px-lr { padding-left:75px; padding-right:75px; }
    .xs-padding-80px-lr { padding-left:80px; padding-right:80px; }
    .xs-padding-85px-lr { padding-left:85px; padding-right:85px; }
    .xs-padding-90px-lr { padding-left:90px; padding-right:90px; }
    .xs-padding-95px-lr { padding-left:95px; padding-right:95px; }
    .xs-padding-100px-lr { padding-left:100px; padding-right:100px; }
    .xs-padding-1-rem-lr { padding-left: 1rem; padding-right: 1rem; }
    .xs-padding-1-half-rem-lr { padding-left: 1.5rem; padding-right: 1.5rem; }
    .xs-padding-2-rem-lr { padding-left: 2rem; padding-right: 2rem; }
    .xs-padding-2-half-rem-lr { padding-left: 2.5rem; padding-right: 2.5rem; }
    .xs-padding-3-rem-lr { padding-left: 3rem; padding-right: 3rem; }
    .xs-padding-3-half-rem-lr { padding-left: 3.5rem; padding-right: 3.5rem; }
    .xs-padding-4-rem-lr { padding-left: 4rem; padding-right: 4rem; }
    .xs-padding-4-half-rem-lr { padding-left: 4.5rem; padding-right: 4.5rem; }
    .xs-padding-5-rem-lr { padding-left: 5rem; padding-right: 5rem; }
    .xs-padding-5-half-rem-lr { padding-left: 5.5rem; padding-right: 5.5rem; }
    .xs-padding-6-rem-lr { padding-left: 6rem; padding-right: 6rem; }
    .xs-padding-6-half-rem-lr { padding-left: 6.5rem; padding-right: 6.5rem; }
    .xs-padding-7-rem-lr { padding-left: 7rem; padding-right: 7rem; }
    .xs-padding-7-half-rem-lr { padding-left: 7.5rem; padding-right: 7.5rem; }
    .xs-padding-8-rem-lr { padding-left: 8rem; padding-right: 8rem; }
    .xs-padding-8-half-rem-lr { padding-left: 8.5rem; padding-right: 8.5rem; }
    .xs-padding-9-rem-lr { padding-left: 9rem; padding-right: 9rem; }
    .xs-padding-9-half-rem-lr { padding-left: 9.5rem; padding-right: 9.5rem; }
    .xs-padding-10-rem-lr { padding-left: 10rem; padding-right: 10rem; }
    .xs-padding-10-half-rem-lr { padding-left: 10.5rem; padding-right: 10.5rem; }

    .xs-no-padding { padding:0 !important; }
    .xs-no-padding-lr { padding-left: 0 !important; padding-right: 0 !important; }
    .xs-no-padding-tb { padding-top: 0 !important; padding-bottom: 0 !important; }
    .xs-no-padding-top { padding-top:0 !important; }
    .xs-no-padding-bottom { padding-bottom:0 !important; }
    .xs-no-padding-left { padding-left:0 !important; }
    .xs-no-padding-right { padding-right:0 !important; }
}
.mission{
    .absolute-far-left-center {
        left: 69%;
        top: 22%;
        position: absolute;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
      
        &.move-top-bottom, &.move-bottom-top {
            margin-left: -10px;
            margin-top: -10px;
        }
        @media (min-width:576px){
            left: 70%;
            top: 28%;
        }
        @media (min-width:768px){
            left: 70%;
            top: 40%;
        }
        @media (min-width: 992px){
            left: 71%;
            top: 50%;
        }
    }

    .cta-btn{
        &.mission-video{
            .process-step-number {
                width: 45px;
                height: 45px;
                .play-cta {
                    width: 15px;
                    height: 15px;
                    left: 17px;
                    top: 7px !important;
                }
                @media(min-width: 768px){
                    width: 60px;
                    height: 60px;
                    .play-cta {
                        width: 20px;
                        height: 20px;
                        left: 22px;
                        top: 15px !important;
                    }
                }
                @media(min-width: 1024px){
                    width: 70px;
                    height: 70px;
                    .play-cta {
                        width: 30px;
                        height: 30px;
                        left: 25px;
                        top: 20px !important;
                    }
                }
            }
        }
    }
}
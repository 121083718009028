
/* portfolio classic */

.portfolio-classic {
    .portfolio-box {
        .portfolio-image > a {
            position: relative;
            height: 100%;
            z-index: 1;
        }
    }
    
  
    .portfolio-icon{
        a {
            width: 40px;
            height: 40px;
            display: inline-block;
            vertical-align: middle;
            line-height: 40px;
            text-align: center;
            margin: 0 3px;
            position: relative;
            z-index: 3;
        }
    } 
  
    .portfolio-box {
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
  
        &:hover {
            transform: translateY(-5px);
            -moz-transform: translateY(-5px);
            -webkit-transform: translateY(-5px);
            -ms-transform: translateY(-5px);
  
            .portfolio-image img {
                transform: scale(1, 1);
                -moz-transform: scale(1, 1);
                -webkit-transform: scale(1, 1);
                -ms-transform: scale(1, 1);
            }
        }
    }
  
    .portfolio-icon{
        a {
            &:hover {
                box-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
            }
        }
    } 
    
  
    .portfolio-box {
        .portfolio-icon{
            a {
                opacity: 0;
                transform: translateY(15px);
                -moz-transform: translateY(15px);
                -webkit-transform: translateY(15px);
                -ms-transform: translateY(15px);
            }
        } 
  
        &:hover .portfolio-icon a {
            opacity: 1;
            transition-delay: .1s;
            transform: translateY(0);
            -moz-transform: translateY(0);
            -webkit-transform: translateY(0);
            -ms-transform: translateY(0);
  
            &:last-child {
                transition-delay: .2s;
            }
        }
    }
}

.portfolio-classic .portfolio-box:hover .portfolio-image img, .portfolio-overlay .portfolio-box:hover .portfolio-image img {
    transform: scale(1.1, 1.1);
    -moz-transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1, 1.1);
    -ms-transform: scale(1.1, 1.1);
    opacity: 0.15;
}

/* ===================================
    21. Grid
====================================== */

.blog-post-image img, .portfolio-image img {
    width: 100%;
}
  
  .grid {
    list-style: none;
  }
  
  .grid-sizer {
    padding: 0 !important;
    margin: 0 !important;
  }
  
  /* gutter size */
  
  .grid {
    &.gutter-very-small li {
      padding: 3px 3px;
    }
  
    &.gutter-small li {
      padding: 5px 5px;
    }
  
    &.gutter-medium li {
      padding: 7px 7px;
    }
  
    &.gutter-large li {
      padding: 10px 10px;
    }
  
    &.gutter-extra-large li {
      padding: 15px;
    }
  
    &.gutter-double-extra-large li {
      padding: 20px;
    }
  
    &.gutter-triple-extra-large li {
      padding: 30px;
    }
  
    &.grid-6col li {
      width: 16.67%;
      list-style: none;
  
      &.grid-item-double {
        width: 33.33%;
      }
    }
  
    &.grid-5col li {
      width: 20%;
      list-style: none;
  
      &.grid-item-double {
        width: 40%;
      }
    }
  
    &.grid-4col li {
      width: 25%;
      list-style: none;
  
      &.grid-item-double {
        width: 50%;
      }
    }
  
    &.grid-3col li {
      width: 33.33%;
      float: left;
      list-style: none;
  
      &.grid-item-double {
        width: 66.67%;
      }
    }
  
    &.grid-2col li {
      width: 50%;
      list-style: none;
  
      &.grid-item-double {
        width: 100%;
      }
    }
  
    &.grid-1col li {
      width: 100%;
      list-style: none;
    }
  }

  .grid-filter {
    li {
      display: inline-block;
      float: none;
      padding: 0 20px;
      border: 0;
      position: relative;
  
      &:first-child {
        padding-left: 0;
      }
  
      &:last-child {
        padding-right: 0;
      }
  
      a {
        border: 0;
        border-bottom: 1px solid;
        border-color: transparent;
        color: #828282;
        padding-bottom: 2px;
      }
    }
  
    > li {
      &.active > a {
        border: 0;
        color: inherit;
        border-bottom: 1px solid;
        color: #232323;
  
        &:focus, &:hover {
          border: 0;
          color: inherit;
          border-bottom: 1px solid;
          color: #232323;
        }
      }
  
      > a:hover {
        border: 0;
        color: inherit;
        border-bottom: 1px solid;
        color: #232323;
      }
    }
}

/*Responsive */

@media(max-width: 1600px){
    /* grid */
    .grid.xl-grid-6col li { width: 16.67%; }
    .grid.xl-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.xl-grid-5col li { width: 20%; }
    .grid.xl-grid-5col li.grid-item-double { width: 40%; }
    .grid.xl-grid-4col li { width: 25%; }
    .grid.xl-grid-4col li.grid-item-double { width: 50%; }
    .grid.xl-grid-3col li { width: 33.33%; }
    .grid.xl-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.xl-grid-2col li { width: 50%; }
    .grid.xl-grid-2col li.grid-item-double { width: 100%; }
    .grid.xl-grid-1col li { width: 100%; }
}

@media(max-width: 1199px){
    /* grid */
    .grid.lg-grid-6col li { width: 16.67%; }
    .grid.lg-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.lg-grid-5col li { width: 20%; }
    .grid.lg-grid-5col li.grid-item-double { width: 40%; }
    .grid.lg-grid-4col li { width: 25%; }
    .grid.lg-grid-4col li.grid-item-double { width: 50%; }
    .grid.lg-grid-3col li { width: 33.33%; }
    .grid.lg-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.lg-grid-2col li { width: 50%; }
    .grid.lg-grid-2col li.grid-item-double { width: 100%; }
    .grid.lg-grid-1col li { width: 100%; }
}

@media(max-width:991px){
    /* grid */
    .grid.md-grid-6col li { width: 16.67%; }
    .grid.md-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.md-grid-5col li { width: 20%; }
    .grid.md-grid-5col li.grid-item-double { width: 40%; }
    .grid.md-grid-4col li { width: 25%; }
    .grid.md-grid-4col li.grid-item-double { width: 50%; }
    .grid.md-grid-3col li { width: 33.33%; }
    .grid.md-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.md-grid-2col li { width: 50%; }
    .grid.md-grid-2col li.grid-item-double { width: 100%; }
    .grid.md-grid-1col li { width: 100%; }
}

@media (max-width: 767px) {
    /* grid */
    .grid.sm-grid-6col li { width: 16.67%; }
    .grid.sm-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.sm-grid-5col li { width: 20%; }
    .grid.sm-grid-5col li.grid-item-double { width: 40%; }
    .grid.sm-grid-4col li { width: 25%; }
    .grid.sm-grid-4col li.grid-item-double { width: 50%; }
    .grid.sm-grid-3col li { width: 33.33%; }
    .grid.sm-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.sm-grid-2col li { width: 50%; }
    .grid.sm-grid-2col li.grid-item-double { width: 100%; }
    .grid.sm-grid-1col li { width: 100%; }
}

@media screen and (max-width: 575px) {
    /* grid */
    .grid.xs-grid-6col li { width: 16.67%; }
    .grid.xs-grid-6col li.grid-item-double { width: 33.33%; }
    .grid.xs-grid-5col li { width: 20%; }
    .grid.xs-grid-5col li.grid-item-double { width: 40%; }
    .grid.xs-grid-4col li { width: 25%; }
    .grid.xs-grid-4col li.grid-item-double { width: 50%; }
    .grid.xs-grid-3col li { width: 33.33%; }
    .grid.xs-grid-3col li.grid-item-double { width: 66.67%; }
    .grid.xs-grid-2col li { width: 50%; }
    .grid.xs-grid-2col li.grid-item-double { width: 100%; }
    .grid.xs-grid-1col li { width: 100%; }
}
.costumForm{
    label, input:not([type=checkbox]), textarea{
        width: 100%;
    }
    label{
        font-size: 16px;
        margin-left: 15px;
        color: $purple;
        font-weight: 700;
        @media(max-width:575px){
            font-size: 12px;
            a{
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
    input:not([type=checkbox]), textarea, select{
        border: 0px;
        margin-bottom: 20px;
        border-radius: 0px;
        background: transparent 0% 0% no-repeat padding-box;
        padding: 18px 15px;
        border: 2px solid $purple; 
        font-size: 14px;
        //@include font-size(14);
        @media(min-width:768px){
            margin-bottom: 40px;
        }
        
        &:hover{
            outline: unset;
            box-shadow: unset;
        }
        &:focus{
            outline: unset;
            box-shadow: unset;
            border-color: $green;
        }
        &.error{
            border-color: $pink !important;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus
        {
            -webkit-text-fill-color: $black !important;
            -webkit-transition: background-color 5000s ease-in-out 0s !important;
            transition: background-color 5000s ease-in-out 0s !important;
        }
    }
    .errorMsg{
        margin-top: -20px;
        font-size: 12px;
        color: $pink;
        font-weight: 700;
        @media (min-width: 768px){
            position: absolute;
            margin-top: -40px;
        }
    }
    textarea{
        resize: none;
        max-height: 176px;
    }

    //placeholders
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #919191;
        opacity: 1; /* Firefox */
    }
      
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #919191;
    }
      
    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #919191;
    }
    a{
        color: $black;
        text-decoration: none;
        font-weight: 500;
        &:hover{
            color: $purple;
        }
    }
}

.medium-input, .medium-textarea, .medium-select select {
    padding: 15px 20px;
    font-size: 14px;
}

.medium-input + .btn {
    padding: 4px 18px;
}

.medium-input, .medium-select select {
    min-height: 50px;
    line-height: normal;
}

/*costum checkbox */
.control {
    font-family: arial;
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: 13px;
    input{
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
}
.control_indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background: #fff;
    border: 2px solid $purple;
    border-radius: 0px;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
    background: #fff;
}

.control input:checked ~ .control_indicator {
    background: #fff;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
    background: #fff;
}
.control input:disabled ~ .control_indicator {
    background: #e6e6e6;
    opacity: 0.6;
    pointer-events: none;
}
.control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
}
.control input:checked ~ .control_indicator:after {
    display: block;
}
.control-checkbox .control_indicator:after {
    left: 6px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid $purple;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
    border-color: #7b7b7b;
}

.control-group{
    label{
        margin-left: 0;
    }
}
.title-center{
    position: relative;
    &:before{
        content: "";
        width: 100%;
        position: absolute;
        left: 0;
    }
    &.purple{
        &:before{
            @media(min-width: 768px){
                background-color: $purple;
                height: 80px;
                top:-50px;
            }
            @media(max-width:991px){
                height: 75px;
            }
        }
    }
    &.green{
        &:before{
            background-color: $green;
            height: 80px;
            top:-50px;
           
            @media(max-width:991px){
                height: 75px;
            }
            @media(max-width:575px){
                display: none;
            }
        }
        @media(max-width:767px){
            background-color: $green;
            padding: 20px 0;
        }
    }
}
#infoBlockIcon{
    .feature-box-content {
        @media(max-width:767px){
            position: relative;
            padding-left: 10px;
            &:after{
                content: " ";
                width: 5px;
                height: calc(100%);
                position: absolute;
                left: 0px;
                top:0;
                z-index: 10;
                background-color: $green;
            }
        }
    }
}
.btn {
    display: inline-block;
    border: 2px solid transparent;
    border-radius: 0;
    text-transform: uppercase;
    width: auto;
    //font-family: 'Poppins', sans-serif;
    font-weight: 700;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -ms-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    &.active {
        &.focus, &:focus {
          outline: medium none;
          outline-offset: 0;
        }
    }
    
    &.focus {
        outline: medium none;
        outline-offset: 0;
    
        &:active {
          outline: medium none;
          outline-offset: 0;
        }
    }
    
    &:active:focus, &:focus {
        outline: medium none;
        outline-offset: 0;
        box-shadow: none;
    }
  
    i {
        vertical-align: middle;
        position: relative;
        top: -1px;
    }
  
    &.btn-very-small {
        font-size: 10px;
        padding: 6px 16px;
    }
  
    &.btn-small {
        font-size: 11px;
        padding: 8px 22px;
    }
  
    &.btn-medium {
        font-size: 12px;
        padding: 10px 26px;
    }
  
    &.btn-large {
        font-size: 13px;
        padding: 12px 32px;
    }
  
    &.btn-extra-large {
        font-size: 14px;
        padding: 16px 40px;
        @media(max-width:767px){
            padding: 10px 40px;
        }
        @media(max-width:575px){
            padding: 10px 30px;
        }
    }
    &.error{
        background-color: blue;
    }
    &.loading{
        position: relative;
        &::after{
            content: " ";
            border: 8px solid $purple;
            position: absolute;
            border-top: 8px solid transparent;
            border-radius: 50%;
            width: 40px;
            right: 150px;
            top: 5px;
            height: 40px;
            animation: load 2s infinite ease;
            @media (max-width: 1299px) {
                left: -60px;
                right: initial;
            }
        }
    }
    &.loading-cv{
        position: relative;
        &::after{
            content: " ";
            border: 8px solid $purple;
            position: absolute;
            border-top: 8px solid transparent;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            right: 40px;
            top: 5px;
            animation: load 2s infinite ease;
        }
    }
}

.btn-green {
    background: $green;
    color: $purple;
    font-weight: bold;
    background-size: 200% auto;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border: solid $green 3px;
    &:hover, &:focus {
        background-position: right center;
        color: $purple;
        font-weight: bold;
        background-color: #B2F8E7;
        border: solid $green 3px;
    }
}

.btn-white {
    background: $white;
    color: $purple;
    font-weight: bold;
    background-size: 200% auto;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow: 0px 3px 6px #00000029;
    &:hover, &:focus {
        background-position: right center;
        color: $purple;
        font-weight: bold;
    }
}
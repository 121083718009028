.process-step-style-04 {
    .process-step-item {
        display: block;
        position: relative;
        padding: 45px 25px 0;
        z-index: 1;
        @media (max-width: 768px) {
            float: none;
        }
  
      &:hover {
        .process-step-item-bfr, .process-step-icon-bfr, .process-step-number-bfr {
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -ootransform: scale(1.2);
            transform: scale(1.2);
        }
      }
  
      .process-step-item-bfr {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: 50%;
            margin-left: -20px;
            -webkit-transition: 0.3s cubic-bezier(0.3, 0.58, 0.55, 1);
            transition: 0.3s cubic-bezier(0.3, 0.58, 0.55, 1);
        }
    }
  
    .process-step-icon .process-step-icon-bfr, .process-step-number .process-step-number-bfr {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        left: 50%;
        margin-left: -20px;
        -webkit-transition: 0.3s cubic-bezier(0.3, 0.58, 0.55, 1);
        transition: 0.3s cubic-bezier(0.3, 0.58, 0.55, 1);
    }
  
    .process-step-icon {
        position: absolute;
        margin-bottom: 0;
        line-height: 1;
        top: 10px;
        left: 50%;
        font-weight: 700;
        margin-left: -18px;
        width: 36px;
        height: 36px;
        text-align: center;
    }
  
    .process-step-item-box .process-step-item-box-bfr {
        display: block;
        width: 60%;
        height: 4px;
        position: absolute;
        content: '';
        top: 28px;
        left: 75%;
    }
  
    &:last-child .process-step-item-box .process-step-item-box-bfr {
        display: none;
    }
  
    .process-step-icon .process-step-icon-bfr {
        width: 72px;
        height: 72px;
        opacity: .2;
        margin-left: -36px;
        top: -18px;
        z-index: -1;
    }
  
    .process-step-number {
        position: relative;
        z-index: 1;
        text-align: center;
        font-size: 18px;
        line-height: 38px;
        display: block;
        -webkit-transition: all 250ms ease-in-out;
        -moz-transition: all 250ms ease-in-out;
        -o-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out;
  
        .process-step-number-bfr {
            width: 56px;
            height: 56px;
            top: -10px;
            margin-left: -28px;
            z-index: -1;
            opacity: .4;
        }
    }
  
    .process-content {
        padding-top: 50px;
        text-shadow: 0px 2px 6px #000000A6;
    }
}
.steps-section{
    position: relative;  
    span{
        &.text-purple{
            position: relative;
            z-index:5;
        }
    }
    &:before{
        content: url('../../../assets/images/decoration-elements/steps-top.svg');
        position: absolute;
        top:0px;       
        left: 50px;
        z-index: 2;
        @media(max-width:767px){
            display: none;
        }
    }
    &:after{
        content: url('../../../assets/images/decoration-elements/steps.svg');
        position: absolute;
        bottom:-10px;
        right: 50px;
        z-index: 1;
        @media(max-width:767px){
            display: none;
        }
    }
    .container{
        padding: 90px 0;
        @media(min-width:768px) and (max-width:991px){
            padding: 95px 0 50px;
        }
        @media(max-width:767px){
            padding: 30px 15px;
            &:last-child{
                padding-bottom: 15px;

            }
        }
    }
}

.step-content-mobile{
        @media(max-width:767px){
            min-height: 60px;
            .step-number{
                border-radius: 50%;
                text-align: center;
                position: relative;
                z-index: 1;
                font-size: 16px;
                line-height: 40px;
                display: block;
                transition: all 250ms;
                flex-shrink: 0;
                &:before{
                    content:" ";
                    width: 60px;
                    height: 60px;
                    opacity: 0.4;
                    position: absolute;
                    background-color: $white;
                    border-radius: 50%;
                    transform: translate(-25px, -10px);
                }
                &:after{
                    content:" ";
                    width: 80px;
                    height: 80px;
                    opacity: 0.2;
                    position: absolute;
                    background-color: $white;
                    border-radius: 50%;
                    transform: translate(-45px, -20px);
                }
            }
            &:not(.last){
                &:after{
                    content: " ";
                    background-color: $green;
                    position: absolute;
                    top: 70px;
                    height: calc(100% - 35px);
                    left:30px;
                    width: 5px;
                }
            }
    }
    @media(max-width:575px){
        .text-content{
            flex-direction: column;
        }
    }
}


  /*Responsive */

@media (max-width: 1199px) {
    .process-step-style-04 .process-step-item { padding-left: 15px; padding-right: 15px; }
}

@media (max-width: 991px) {
  .process-step-style-04 .process-step-item { padding-left: 0; padding-right: 0; }
}

@media (max-width: 767px) {
    .process-step-style-04 .process-content { padding-top: 40px; padding-left: 10px; padding-right: 10px; }
}
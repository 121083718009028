/* ===================================
10. Margin
====================================== */

.margin-one-all {
    margin: 1%;
}
  
.margin-two-all {
    margin: 2%;
}
  
.margin-three-all {
    margin: 3%;    
}

.margin-four-all {
    margin: 4%;
}

.margin-five-all {
    margin: 5%;
}

.margin-six-all {
    margin: 6%;
}

.margin-seven-all {
    margin: 7%;
}

.margin-eight-all {
    margin: 8%;
}
  
.margin-nine-all {
    margin: 9%;
}
  
.margin-ten-all {
    margin: 10%;
}
  
.margin-eleven-all {
    margin: 11%;
}
  
.margin-twelve-all {
    margin: 12%;
}
  
.margin-thirteen-all {
    margin: 13%;
}
  
.margin-fourteen-all {
    margin: 14%;
}
  
.margin-fifteen-all {
    margin: 15%;
}
  
.margin-sixteen-all {
    margin: 16%;
}
  
.margin-seventeen-all {
    margin: 17%;
}
  
.margin-eighteen-all {
    margin: 18%;
}
  
.margin-nineteen-all {
    margin: 19%;
}
  
.margin-twenty-all {
    margin: 20%;
}
  
.margin-5px-all {
    margin: 5px;
}
  
.margin-10px-all {
    margin: 10px;
}
  
.margin-15px-all {
    margin: 15px;
}
  
.margin-20px-all {
    margin: 20px;
}
  
.margin-25px-all {
    margin: 25px;
}
  
.margin-30px-all {
    margin: 30px;
}
  
.margin-35px-all {
    margin: 35px;
}
  
.margin-40px-all {
    margin: 40px;
}
  
.margin-45px-all {
    margin: 45px;
}
  
.margin-50px-all {
    margin: 50px;
}
  
.margin-55px-all {
    margin: 55px;
}
  
.margin-60px-all {
    margin: 60px;
}
  
.margin-65px-all {
    margin: 65px;
}
  
.margin-70px-all {
    margin: 70px;
}
  
.margin-75px-all {
    margin: 75px;
}
  
.margin-80px-all {
    margin: 80px;
}
  
.margin-85px-all {
    margin: 85px;
}
  
.margin-90px-all {
    margin: 90px;
}
  
.margin-95px-all {
    margin: 95px;
}
  
.margin-100px-all {
    margin: 100px;
}
  
.margin-1-rem-all {
    margin: 1rem;
}
  
.margin-1-half-rem-all {
    margin: 1.5rem;
}
  
.margin-2-rem-all {
    margin: 2rem;
}
  
.margin-2-half-rem-all {
    margin: 2.5rem;
}
  
.margin-3-rem-all {
    margin: 3rem;
}
  
.margin-3-half-rem-all {
    margin: 3.5rem;
}
  
.margin-4-rem-all {
    margin: 4rem;
}
  
.margin-4-half-rem-all {
    margin: 4.5rem;
}
  
.margin-5-rem-all {
    margin: 5rem;
}
  
.margin-5-half-rem-all {
    margin: 5.5rem;
}
  
.margin-6-rem-all {
    margin: 6rem;
}
  
.margin-6-half-rem-all {
    margin: 6.5rem;
}
  
.margin-7-rem-all {
    margin: 7rem;
}
  
.margin-7-half-rem-all {
    margin: 7.5rem;
}
  
.margin-8-rem-all {
    margin: 8rem;
}
  
.margin-8-half-rem-all {
    margin: 8.5rem;
}
  
.margin-9-rem-all {
    margin: 9rem;
}
  
.margin-9-half-rem-all {
    margin: 9.5rem;
}
  
.margin-10-rem-all {
    margin: 10rem;
}
  
.margin-10-half-rem-all {
    margin: 10.5rem;
}
  
/* margin top */
  
.margin-one-top {
    margin-top: 1%;
}
  
.margin-two-top {
    margin-top: 2%;
}
  
.margin-three-top {
    margin-top: 3%;
}
  
.margin-four-top {
    margin-top: 4%;
}
  
.margin-five-top {
    margin-top: 5%;
}
  
.margin-six-top {
    margin-top: 6%;
}
  
.margin-seven-top {
    margin-top: 7%;
}
  
.margin-eight-top {
    margin-top: 8%;
}
  
.margin-nine-top {
    margin-top: 9%;
}
  
.margin-ten-top {
    margin-top: 10%;
}
  
.margin-eleven-top {
    margin-top: 11%;
}
  
.margin-twelve-top {
    margin-top: 12%;
}
  
.margin-thirteen-top {
    margin-top: 13%;
}
  
.margin-fourteen-top {
    margin-top: 14%;
}
  
.margin-fifteen-top {
    margin-top: 15%;
}
  
.margin-sixteen-top {
    margin-top: 16%;
}
  
.margin-seventeen-top {
    margin-top: 17%;
}
  
.margin-eighteen-top {
    margin-top: 18%;
}
  
.margin-nineteen-top {
    margin-top: 19%;
}
  
.margin-twenty-top, .margin-twenty-five-top {
    margin-top: 20%;
}
  
.margin-5px-top {
    margin-top: 5px;
}
  
.margin-10px-top {
    margin-top: 10px;
}
  
.margin-15px-top {
    margin-top: 15px;
}
  
.margin-20px-top {
    margin-top: 20px;
}
  
.margin-25px-top {
    margin-top: 25px;
}
  
.margin-30px-top {
    margin-top: 30px;
}
  
.margin-35px-top {
    margin-top: 35px;
}
  
.margin-40px-top {
    margin-top: 40px;
}
  
.margin-45px-top {
    margin-top: 45px;
}
  
.margin-50px-top {
    margin-top: 50px;
}
  
.margin-55px-top {
    margin-top: 55px;
}
  
.margin-60px-top {
    margin-top: 60px;
}
  
.margin-65px-top {
    margin-top: 65px;
}
  
.margin-70px-top {
    margin-top: 70px;
}
  
.margin-75px-top {
    margin-top: 75px;
}
  
.margin-80px-top {
    margin-top: 80px;
}
  
.margin-85px-top {
    margin-top: 85px;
}
  
.margin-90px-top {
    margin-top: 90px;
}
  
.margin-95px-top {
    margin-top: 95px;
}
  
.margin-100px-top {
    margin-top: 100px;
}
  
.margin-1-rem-top {
    margin-top: 1rem;
}
  
.margin-1-half-rem-top {
    margin-top: 1.5rem;
}
  
.margin-2-rem-top {
    margin-top: 2rem;
}
  
.margin-2-half-rem-top {
    margin-top: 2.5rem;
}
  
.margin-3-rem-top {
    margin-top: 3rem;
}
  
.margin-3-half-rem-top {
    margin-top: 3.5rem;
}
  
.margin-4-rem-top {
    margin-top: 4rem;
}
  
.margin-4-half-rem-top {
    margin-top: 4.5rem;
}
  
.margin-5-rem-top {
    margin-top: 5rem;
}
  
.margin-5-half-rem-top {
    margin-top: 5.5rem;
}
  
.margin-6-rem-top {
    margin-top: 6rem;
}
  
.margin-6-half-rem-top {
    margin-top: 6.5rem;
}
  
.margin-7-rem-top {
    margin-top: 7rem;
}
  
.margin-7-half-rem-top {
    margin-top: 7.5rem;
}
  
.margin-8-rem-top {
    margin-top: 8rem;
}
  
.margin-8-half-rem-top {
    margin-top: 8.5rem;
}
  
.margin-9-rem-top {
    margin-top: 9rem;
}
  
.margin-9-half-rem-top {
    margin-top: 9.5rem;
}
  
.margin-10-rem-top {
    margin-top: 10rem;
}
  
.margin-10-half-rem-top {
    margin-top: 10.5rem;
}
  
.margin-negative-top-left {
    margin-top: -6.5rem; 
    margin-left: -55px;
    @media (max-width: 767px) {
        margin-top: -4rem;
    }
}
  
/* margin bottom */
  
.margin-one-bottom {
    margin-bottom: 1%;
}
  
.margin-two-bottom {
    margin-bottom: 2%;
}
  
.margin-three-bottom {
    margin-bottom: 3%;
}
  
.margin-four-bottom {
    margin-bottom: 4%;
}
  
.margin-five-bottom {
    margin-bottom: 5%;
}
  
.margin-six-bottom {
    margin-bottom: 6%;
}
  
.margin-seven-bottom {
    margin-bottom: 7%;
}
  
.margin-eight-bottom {
    margin-bottom: 8%;
}
  
.margin-nine-bottom {
    margin-bottom: 9%;
}
  
.margin-ten-bottom {
    margin-bottom: 10%;
}
  
.margin-eleven-bottom {
    margin-bottom: 11%;
}
  
.margin-twelve-bottom {
    margin-bottom: 12%;
}
  
.margin-thirteen-bottom {
    margin-bottom: 13%;
}
  
.margin-fourteen-bottom {
    margin-bottom: 14%;
}
  
.margin-fifteen-bottom {
    margin-bottom: 15%;
}
  
.margin-sixteen-bottom {
    margin-bottom: 16%;
}
  
.margin-seventeen-bottom {
    margin-bottom: 17%;
}
  
.margin-eighteen-bottom {
    margin-bottom: 18%;
}
  
.margin-nineteen-bottom {
    margin-bottom: 19%;
}
  
.margin-twenty-bottom {
    margin-bottom: 20%;
}
  
.margin-5px-bottom {
    margin-bottom: 5px;
}
  
.margin-10px-bottom {
    margin-bottom: 10px;
}
  
.margin-15px-bottom {
    margin-bottom: 15px;
}
  
.margin-20px-bottom {
    margin-bottom: 20px;
}
  
.margin-25px-bottom {
    margin-bottom: 25px;
}
  
.margin-30px-bottom {
    margin-bottom: 30px;
}
  
.margin-35px-bottom {
    margin-bottom: 35px;
}
  
.margin-40px-bottom {
    margin-bottom: 40px;
}
  
.margin-45px-bottom {
    margin-bottom: 45px;
}
  
.margin-50px-bottom {
    margin-bottom: 50px;
}
  
.margin-55px-bottom {
    margin-bottom: 55px;
}
  
.margin-60px-bottom {
    margin-bottom: 60px;
}
  
.margin-65px-bottom {
    margin-bottom: 65px;
}
  
.margin-70px-bottom {
    margin-bottom: 70px;
}
  
.margin-75px-bottom {
    margin-bottom: 75px;
}
  
.margin-80px-bottom {
    margin-bottom: 80px;
}
  
.margin-85px-bottom {
    margin-bottom: 85px;
}
  
.margin-90px-bottom {
    margin-bottom: 90px;
}
  
.margin-95px-bottom {
    margin-bottom: 95px;
}
  
.margin-100px-bottom {
    margin-bottom: 100px;
}
  
.margin-1-rem-bottom {
    margin-bottom: 1rem;
}
  
.margin-1-half-rem-bottom {
    margin-bottom: 1.5rem;
}
  
.margin-2-rem-bottom {
    margin-bottom: 2rem;
}
  
.margin-2-half-rem-bottom {
    margin-bottom: 2.5rem;
}
  
.margin-3-rem-bottom {
    margin-bottom: 3rem;
}
  
.margin-3-half-rem-bottom {
    margin-bottom: 3.5rem;
}
  
.margin-4-rem-bottom {
    margin-bottom: 4rem;
}
  
.margin-4-half-rem-bottom {
    margin-bottom: 4.5rem;
}
  
.margin-5-rem-bottom {
    margin-bottom: 5rem;
}
  
.margin-5-half-rem-bottom {
    margin-bottom: 5.5rem;
}
  
.margin-6-rem-bottom {
    margin-bottom: 6rem;
}
  
.margin-6-half-rem-bottom {
    margin-bottom: 6.5rem;
}
  
.margin-7-rem-bottom {
    margin-bottom: 7rem;
}
  
.margin-7-half-rem-bottom {
    margin-bottom: 7.5rem;
}
  
.margin-8-rem-bottom {
    margin-bottom: 8rem;
}
  
.margin-8-half-rem-bottom {
    margin-bottom: 8.5rem;
}
  
.margin-9-rem-bottom {
    margin-bottom: 9rem;
}
  
.margin-9-half-rem-bottom {
    margin-bottom: 9.5rem;
}
  
.margin-10-rem-bottom {
    margin-bottom: 10rem;
}
  
.margin-10-half-rem-bottom {
    margin-bottom: 10.5rem;
}
  
.margin-15-rem-bottom {
    margin-bottom: 15rem;
}
  
.margin-27-rem-bottom {
    margin-bottom: 27rem;
}
  
/* margin right */
  
.margin-one-right {
    margin-right: 1%;
}
  
.margin-two-right {
    margin-right: 2%;
}
  
.margin-three-right {
    margin-right: 3%;
}
  
.margin-four-right {
    margin-right: 4%;
}
  
.margin-five-right {
    margin-right: 5%;
}
  
.margin-six-right {
    margin-right: 6%;
}
  
.margin-seven-right {
    margin-right: 7%;
}
  
.margin-eight-right {
    margin-right: 8%;
}
  
.margin-nine-right {
    margin-right: 9%;
}
  
.margin-ten-right {
    margin-right: 10%;
}
  
.margin-eleven-right {
    margin-right: 11%;
}
  
.margin-twelve-right {
    margin-right: 12%;
}
  
.margin-thirteen-right {
    margin-right: 13%;
}
  
.margin-fourteen-right {
    margin-right: 14%;
}
  
.margin-fifteen-right {
    margin-right: 15%;
}
  
.margin-sixteen-right {
    margin-right: 16%;
}
  
.margin-seventeen-right {
    margin-right: 17%;
}
  
.margin-eighteen-right {
    margin-right: 18%;
}
  
.margin-nineteen-right {
    margin-right: 19%;
}
  
.margin-twenty-right {
    margin-right: 20%;
}
  
.margin-5px-right {
    margin-right: 5px;
}
  
.margin-10px-right {
    margin-right: 10px;
}
  
.margin-15px-right {
    margin-right: 15px;
}
  
.margin-20px-right {
    margin-right: 20px;
}
  
.margin-25px-right {
    margin-right: 25px;
}
  
.margin-30px-right {
    margin-right: 30px;
}
  
.margin-35px-right {
    margin-right: 35px;
}
  
.margin-40px-right {
    margin-right: 40px;
}
  
.margin-45px-right {
    margin-right: 45px;
}
  
.margin-50px-right {
    margin-right: 50px;
}
  
.margin-55px-right {
    margin-right: 55px;
}
  
.margin-60px-right {
    margin-right: 60px;
}
  
.margin-65px-right {
    margin-right: 65px;
}
  
.margin-70px-right {
    margin-right: 70px;
}
  
.margin-75px-right {
    margin-right: 75px;
}
  
.margin-80px-right {
    margin-right: 80px;
}
  
.margin-85px-right {
    margin-right: 85px;
}
  
.margin-90px-right {
    margin-right: 90px;
}
  
.margin-95px-right {
    margin-right: 95px;
}
  
.margin-100px-right {
    margin-right: 100px;
}
  
.margin-1-rem-right {
    margin-right: 1rem;
}
  
.margin-1-half-rem-right {
    margin-right: 1.5rem;
}
  
.margin-2-rem-right {
    margin-right: 2rem;
}
  
.margin-2-half-rem-right {
    margin-right: 2.5rem;
}
  
.margin-3-rem-right {
    margin-right: 3rem;
}
  
.margin-3-half-rem-right {
    margin-right: 3.5rem;
}
  
.margin-4-rem-right {
    margin-right: 4rem;
}
  
.margin-4-half-rem-right {
    margin-right: 4.5rem;
}
  
.margin-5-rem-right {
    margin-right: 5rem;
}
  
.margin-5-half-rem-right {
    margin-right: 5.5rem;
}
  
.margin-6-rem-right {
    margin-right: 6rem;
}
  
.margin-6-half-rem-right {
    margin-right: 6.5rem;
}
  
.margin-7-rem-right {
    margin-right: 7rem;
}
  
.margin-7-half-rem-right {
    margin-right: 7.5rem;
}
  
.margin-8-rem-right {
    margin-right: 8rem;
}
  
.margin-8-half-rem-right {
    margin-right: 8.5rem;
}
  
.margin-9-rem-right {
    margin-right: 9rem;
}
  
.margin-9-half-rem-right {
    margin-right: 9.5rem;
}
  
.margin-10-rem-right {
    margin-right: 10rem;
}
  
.margin-10-half-rem-right {
    margin-right: 10.5rem;
}
  
/* margin left */
  
.margin-one-left {
    margin-left: 1%;
}
  
.margin-two-left {
    margin-left: 2%;
}
  
.margin-three-left {
    margin-left: 3%;
}
  
.margin-four-left {
    margin-left: 4%;
}
  
.margin-five-left {
    margin-left: 5%;
}
  
.margin-six-left {
    margin-left: 6%;
}
  
.margin-seven-left {
    margin-left: 7%;
}
  
.margin-eight-left {
    margin-left: 8%;
}
  
.margin-nine-left {
    margin-left: 9%;
}
  
.margin-ten-left {
    margin-left: 10%;
}
  
.margin-eleven-left {
    margin-left: 11%;
}
  
.margin-twelve-left {
    margin-left: 12%;
}
  
.margin-thirteen-left {
    margin-left: 13%;
}
  
.margin-fourteen-left {
    margin-left: 14%;
}
  
.margin-fifteen-left {
    margin-left: 15%;
}
  
.margin-sixteen-left {
    margin-left: 16%;
}
  
.margin-seventeen-left {
    margin-left: 17%;
}
  
.margin-eighteen-left {
    margin-left: 18%;
}
  
.margin-nineteen-left {
    margin-left: 19%;
}
  
.margin-twenty-left {
    margin-left: 20%;
}
  
.margin-5px-left {
    margin-left: 5px;
}
  
.margin-10px-left {
    margin-left: 10px;
}
  
.margin-15px-left {
    margin-left: 15px;
}
  
.margin-20px-left {
    margin-left: 20px;
}
  
.margin-25px-left {
    margin-left: 25px;
}
  
.margin-30px-left {
    margin-left: 30px;
}
  
.margin-35px-left {
    margin-left: 35px;
}
  
.margin-40px-left {
    margin-left: 40px;
}
  
.margin-45px-left {
    margin-left: 45px;
}
  
.margin-50px-left {
    margin-left: 50px;
}
  
.margin-55px-left {
    margin-left: 55px;
}
  
.margin-60px-left {
    margin-left: 60px;
}
  
.margin-65px-left {
    margin-left: 65px;
}
  
.margin-70px-left {
    margin-left: 70px;
}
  
.margin-75px-left {
    margin-left: 75px;
}
  
.margin-80px-left {
    margin-left: 80px;
}
  
.margin-85px-left {
    margin-left: 85px;
}
  
.margin-90px-left {
    margin-left: 90px;
}
  
.margin-95px-left {
    margin-left: 95px;
}
  
.margin-100px-left {
    margin-left: 100px;
}
  
.margin-1-rem-left {
    margin-left: 1rem;
}
  
.margin-1-half-rem-left {
    margin-left: 1.5rem;
}
  
.margin-2-rem-left {
    margin-left: 2rem;
}
  
.margin-2-half-rem-left {
    margin-left: 2.5rem;
}
  
.margin-3-rem-left {
    margin-left: 3rem;
}
  
.margin-3-half-rem-left {
    margin-left: 3.5rem;
}
  
.margin-4-rem-left {
    margin-left: 4rem;
}
  
.margin-4-half-rem-left {
    margin-left: 4.5rem;
}
  
.margin-5-rem-left {
    margin-left: 5rem;
}
  
.margin-5-half-rem-left {
    margin-left: 5.5rem;
}
  
.margin-6-rem-left {
    margin-left: 6rem;
}
  
.margin-6-half-rem-left {
    margin-left: 6.5rem;
}
  
.margin-7-rem-left {
    margin-left: 7rem;
}
  
.margin-7-half-rem-left {
    margin-left: 7.5rem;
}
  
.margin-8-rem-left {
    margin-left: 8rem;
}
  
.margin-8-half-rem-left {
    margin-left: 8.5rem;
}
  
.margin-9-rem-left {
    margin-left: 9rem;
}
  
.margin-9-half-rem-left {
    margin-left: 9.5rem;
}
  
.margin-10-rem-left {
    margin-left: 10rem;
}
  
.margin-10-half-rem-left {
    margin-left: 10.5rem;
}
  
/* margin left right */
  
.margin-one-lr {
    margin-left: 1%;
    margin-right: 1%;
}
  
.margin-two-lr {
    margin-left: 2%;
    margin-right: 2%;
}
  
.margin-three-lr {
    margin-left: 3%;
    margin-right: 3%;
}
  
.margin-four-lr {
    margin-left: 4%;
    margin-right: 4%;
}
  
.margin-five-lr {
    margin-left: 5%;
    margin-right: 5%;
}
  
.margin-six-lr {
    margin-left: 6%;
    margin-right: 6%;
}
  
.margin-seven-lr {
    margin-left: 7%;
    margin-right: 7%;
}
  
.margin-eight-lr {
    margin-left: 8%;
    margin-right: 8%;
}
  
.margin-nine-lr {
    margin-left: 9%;
    margin-right: 9%;
}
  
.margin-ten-lr {
    margin-left: 10%;
    margin-right: 10%;
}
  
.margin-eleven-lr {
    margin-left: 11%;
    margin-right: 11%;
}
  
.margin-twelve-lr {
    margin-left: 12%;
    margin-right: 12%;
}
  
.margin-thirteen-lr {
    margin-left: 13%;
    margin-right: 13%;
}
  
.margin-fourteen-lr {
    margin-left: 14%;
    margin-right: 14%;
}
  
.margin-fifteen-lr {
    margin-left: 15%;
    margin-right: 15%;
}
  
.margin-sixteen-lr {
    margin-left: 16%;
    margin-right: 16%;
}
  
.margin-seventeen-lr {
    margin-left: 17%;
    margin-right: 17%;
}
  
.margin-eighteen-lr {
    margin-left: 18%;
    margin-right: 18%;
}
  
.margin-nineteen-lr {
    margin-left: 19%;
    margin-right: 19%;
}
  
.margin-twenty-lr {
    margin-left: 20%;
    margin-right: 20%;
}
  
.margin-5px-lr {
    margin-left: 5px;
    margin-right: 5px;
}
  
.margin-10px-lr {
    margin-left: 10px;
    margin-right: 10px;
}
  
.margin-15px-lr {
    margin-left: 15px;
    margin-right: 15px;
}
  
.margin-20px-lr {
    margin-left: 20px;
    margin-right: 20px;
}
  
.margin-25px-lr {
    margin-left: 25px;
    margin-right: 25px;
}
  
.margin-30px-lr {
    margin-left: 30px;
    margin-right: 30px;
}
  
.margin-35px-lr {
    margin-left: 35px;
    margin-right: 35px;
}
  
.margin-40px-lr {
    margin-left: 40px;
    margin-right: 40px;
}
  
.margin-45px-lr {
    margin-left: 45px;
    margin-right: 45px;
}
  
.margin-50px-lr {
    margin-left: 50px;
    margin-right: 50px;
}
  
.margin-55px-lr {
    margin-left: 55px;
    margin-right: 55px;
}
  
.margin-60px-lr {
    margin-left: 60px;
    margin-right: 60px;
}
  
.margin-65px-lr {
    margin-left: 65px;
    margin-right: 65px;
}
  
.margin-70px-lr {
    margin-left: 70px;
    margin-right: 70px;
}
  
.margin-75px-lr {
    margin-left: 75px;
    margin-right: 75px;
}
  
.margin-80px-lr {
    margin-left: 80px;
    margin-right: 80px;
}
  
.margin-85px-lr {
    margin-left: 85px;
    margin-right: 85px;
}
  
.margin-90px-lr {
    margin-left: 90px;
    margin-right: 90px;
}
  
.margin-95px-lr {
    margin-left: 95px;
    margin-right: 95px;
}
  
.margin-100px-lr {
    margin-left: 100px;
    margin-right: 100px;
}
  
.margin-1-rem-lr {
    margin-left: 1rem;
    margin-right: 1rem;
}
  
.margin-1-half-rem-lr {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}
  
.margin-2-rem-lr {
    margin-left: 2rem;
    margin-right: 2rem;
}
  
.margin-2-half-rem-lr {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}
  
.margin-3-rem-lr {
    margin-left: 3rem;
    margin-right: 3rem;
}
  
.margin-3-half-rem-lr {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
}
  
.margin-4-rem-lr {
    margin-left: 4rem;
    margin-right: 4rem;
}
  
.margin-4-half-rem-lr {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
}
  
.margin-5-rem-lr {
    margin-left: 5rem;
    margin-right: 5rem;
}
  
.margin-5-half-rem-lr {
    margin-left: 5.5rem;
    margin-right: 5.5rem;
}
  
.margin-6-rem-lr {
    margin-left: 6rem;
    margin-right: 6rem;
}
  
.margin-6-half-rem-lr {
    margin-left: 6.5rem;
    margin-right: 6.5rem;
}
  
.margin-7-rem-lr {
    margin-left: 7rem;
    margin-right: 7rem;
}
  
.margin-7-half-rem-lr {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
}
  
.margin-8-rem-lr {
    margin-left: 8rem;
    margin-right: 8rem;
}
  
.margin-8-half-rem-lr {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
}
  
.margin-9-rem-lr {
    margin-left: 9rem;
    margin-right: 9rem;
}
  
.margin-9-half-rem-lr {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
}
  
.margin-10-rem-lr {
    margin-left: 10rem;
    margin-right: 10rem;
}
  
.margin-10-half-rem-lr {
    margin-left: 10.5rem;
    margin-right: 10.5rem;
}
  
/* margin top bottom */
  
.margin-one-tb {
    margin-top: 1%;
    margin-bottom: 1%;
}
  
.margin-two-tb {
    margin-top: 2%;
    margin-bottom: 2%;
}
  
.margin-three-tb {
    margin-top: 3%;
    margin-bottom: 3%;
}
  
.margin-four-tb {
    margin-top: 4%;
    margin-bottom: 4%;
}
  
.margin-five-tb {
    margin-top: 5%;
    margin-bottom: 5%;
}
  
.margin-six-tb {
    margin-top: 6%;
    margin-bottom: 6%;
}
  
.margin-seven-tb {
    margin-top: 7%;
    margin-bottom: 7%;
}
  
.margin-eight-tb {
    margin-top: 8%;
    margin-bottom: 8%;
}
  
.margin-nine-tb {
    margin-top: 9%;
    margin-bottom: 9%;
}
  
.margin-ten-tb {
    margin-top: 10%;
    margin-bottom: 10%;
}
  
.margin-eleven-tb {
    margin-top: 11%;
    margin-bottom: 11%;
}
  
.margin-twelve-tb {
    margin-top: 12%;
    margin-bottom: 12%;
}
  
.margin-thirteen-tb {
    margin-top: 13%;
    margin-bottom: 13%;
}
  
.margin-fourteen-tb {
    margin-top: 14%;
    margin-bottom: 14%;
}
  
.margin-fifteen-tb {
    margin-top: 15%;
    margin-bottom: 15%;
}
  
.margin-sixteen-tb {
    margin-top: 16%;
    margin-bottom: 16%;
}
  
.margin-seventeen-tb {
    margin-top: 17%;
    margin-bottom: 17%;
}
  
.margin-eighteen-tb {
    margin-top: 18%;
    margin-bottom: 18%;
}
  
.margin-nineteen-tb {
    margin-top: 19%;
    margin-bottom: 19%;
}
  
.margin-twenty-tb {
    margin-top: 20%;
    margin-bottom: 20%;
}
  
.margin-5px-tb {
    margin-top: 5px;
    margin-bottom: 5px;
}
  
.margin-10px-tb {
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.margin-15px-tb {
    margin-top: 15px;
    margin-bottom: 15px;
}
  
.margin-20px-tb {
    margin-top: 20px;
    margin-bottom: 20px;
}
  
.margin-25px-tb {
    margin-top: 25px;
    margin-bottom: 25px;
}
  
.margin-30px-tb {
    margin-top: 30px;
    margin-bottom: 30px;
}
  
.margin-35px-tb {
    margin-top: 35px;
    margin-bottom: 35px;
}
  
.margin-40px-tb {
    margin-top: 40px;
    margin-bottom: 40px;
}
  
.margin-45px-tb {
    margin-top: 45px;
    margin-bottom: 45px;
}
  
.margin-50px-tb {
    margin-top: 50px;
    margin-bottom: 50px;
}
  
.margin-55px-tb {
    margin-top: 55px;
    margin-bottom: 55px;
}
  
.margin-60px-tb {
    margin-top: 60px;
    margin-bottom: 60px;
}
  
.margin-65px-tb {
    margin-top: 65px;
    margin-bottom: 65px;
}
  
.margin-70px-tb {
    margin-top: 70px;
    margin-bottom: 70px;
}
  
.margin-75px-tb {
    margin-top: 75px;
    margin-bottom: 75px;
}
  
.margin-80px-tb {
    margin-top: 80px;
    margin-bottom: 80px;
}
  
.margin-85px-tb {
    margin-top: 85px;
    margin-bottom: 85px;
}
  
.margin-90px-tb {
    margin-top: 90px;
    margin-bottom: 90px;
}
  
.margin-95px-tb {
    margin-top: 95px;
    margin-bottom: 95px;
}
  
.margin-100px-tb {
    margin-top: 100px;
    margin-bottom: 100px;
}
  
.margin-1-rem-tb {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
  
.margin-1-half-rem-tb {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
  
.margin-2-rem-tb {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
  
.margin-2-half-rem-tb {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
  
.margin-3-rem-tb {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
  
.margin-3-half-rem-tb {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}
  
.margin-4-rem-tb {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
  
.margin-4-half-rem-tb {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
}
  
.margin-5-rem-tb {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
  
.margin-5-half-rem-tb {
    margin-top: 5.5rem;
    margin-bottom: 5.5rem;
}
  
.margin-6-rem-tb {
    margin-top: 6rem;
    margin-bottom: 6rem;
}
  
.margin-6-half-rem-tb {
    margin-top: 6.5rem;
    margin-bottom: 6.5rem;
}
  
.margin-7-rem-tb {
    margin-top: 7rem;
    margin-bottom: 7rem;
}
  
.margin-7-half-rem-tb {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
}
  
.margin-8-rem-tb {
    margin-top: 8rem;
    margin-bottom: 8rem;
}
  
.margin-8-half-rem-tb {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
}
  
.margin-9-rem-tb {
    margin-top: 9rem;
    margin-bottom: 9rem;
}
  
.margin-9-half-rem-tb {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
}
  
.margin-10-rem-tb {
    margin-top: 10rem;
    margin-bottom: 10rem;
}
  
.margin-10-half-rem-tb {
    margin-top: 10.5rem;
    margin-bottom: 10.5rem;
}
  
.margin-auto-lr {
    margin: 0 auto;
}
  
.margin-auto {
    margin: auto;
}
  
  .no-margin {
    margin: 0 !important;
}
  
.no-margin-top {
    margin-top: 0 !important;
}
  
.no-margin-bottom {
    margin-bottom: 0 !important;
}
  
.no-margin-left {
    margin-left: 0 !important;
}
  
.no-margin-right {
    margin-right: 0 !important;
}
  
.no-margin-lr {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

@media (max-width: 1600px) {
    .xl-margin-one-all { margin:1%; }
    .xl-margin-two-all { margin:2%; }
    .xl-margin-three-all { margin:3%; }
    .xl-margin-four-all { margin:4%; }
    .xl-margin-five-all { margin:5%; }
    .xl-margin-six-all { margin:6%; }
    .xl-margin-seven-all { margin:7%; }
    .xl-margin-eight-all { margin:8%; }
    .xl-margin-nine-all { margin:9%; }
    .xl-margin-ten-all { margin:10%; }
    .xl-margin-eleven-all { margin:11%; }
    .xl-margin-twelve-all { margin:12%; }
    .xl-margin-thirteen-all { margin:13%; }
    .xl-margin-fourteen-all { margin:14%; }
    .xl-margin-fifteen-all { margin:15%; }
    .xl-margin-sixteen-all { margin:16%; }
    .xl-margin-seventeen-all { margin:17%; }
    .xl-margin-eighteen-all { margin:18%; }
    .xl-margin-nineteen-all { margin:19%; }
    .xl-margin-twenty-all { margin:20%; }
    .xl-margin-5px-all { margin:5px; }
    .xl-margin-10px-all { margin:10px; }
    .xl-margin-15px-all { margin:15px; }
    .xl-margin-20px-all { margin:20px; }
    .xl-margin-25px-all { margin:25px; }
    .xl-margin-30px-all { margin:30px; }
    .xl-margin-35px-all { margin:35px; }
    .xl-margin-40px-all { margin:40px; }
    .xl-margin-45px-all { margin:45px; }
    .xl-margin-50px-all { margin:50px; }
    .xl-margin-55px-all { margin:55px; }
    .xl-margin-60px-all { margin:60px; }
    .xl-margin-65px-all { margin:65px; }
    .xl-margin-70px-all { margin:70px; }
    .xl-margin-75px-all { margin:75px; }
    .xl-margin-80px-all { margin:80px; }
    .xl-margin-85px-all { margin:85px; }
    .xl-margin-90px-all { margin:90px; }
    .xl-margin-95px-all { margin:95px; }
    .xl-margin-100px-all { margin:100px; }
    .xl-margin-1-rem-all { margin: 1rem; }
    .xl-margin-1-half-rem-all { margin: 1.5rem; }
    .xl-margin-2-rem-all { margin: 2rem; }
    .xl-margin-2-half-rem-all { margin: 2.5rem; }
    .xl-margin-3-rem-all { margin: 3rem; }
    .xl-margin-3-half-rem-all { margin: 3.5rem; }
    .xl-margin-4-rem-all { margin: 4rem; }
    .xl-margin-4-half-rem-all { margin: 4.5rem; }
    .xl-margin-5-rem-all { margin: 5rem; }
    .xl-margin-5-half-rem-all { margin: 5.5rem; }
    .xl-margin-6-rem-all { margin: 6rem; }
    .xl-margin-6-half-rem-all { margin: 6.5rem; }
    .xl-margin-7-rem-all { margin: 7rem; }
    .xl-margin-7-half-rem-all { margin: 7.5rem; }
    .xl-margin-8-rem-all { margin: 8rem; }
    .xl-margin-8-half-rem-all { margin: 8.5rem; }
    .xl-margin-9-rem-all { margin: 9rem; }
    .xl-margin-9-half-rem-all { margin: 9.5rem; }
    .xl-margin-10-rem-all { margin: 10rem; }
    .xl-margin-10-half-rem-all { margin: 10.5rem; }

    /* margin top */
    .xl-margin-one-top { margin-top:1%; }
    .xl-margin-two-top { margin-top:2%; }
    .xl-margin-three-top { margin-top:3%; }
    .xl-margin-four-top { margin-top:4%; }
    .xl-margin-five-top { margin-top:5%; }
    .xl-margin-six-top { margin-top:6%; }
    .xl-margin-seven-top { margin-top:7%; }
    .xl-margin-eight-top { margin-top:8%; }
    .xl-margin-nine-top { margin-top:9%; }
    .xl-margin-ten-top { margin-top:10%; }
    .xl-margin-eleven-top { margin-top:11%; }
    .xl-margin-twelve-top { margin-top:12%; }
    .xl-margin-thirteen-top { margin-top:13%; }
    .xl-margin-fourteen-top { margin-top:14%; }
    .xl-margin-fifteen-top { margin-top:15%; }
    .xl-margin-sixteen-top { margin-top:16%; }
    .xl-margin-seventeen-top { margin-top:17%; }
    .xl-margin-eighteen-top { margin-top:18%; }
    .xl-margin-nineteen-top { margin-top:19%; }
    .xl-margin-twenty-top { margin-top:20%; }
    .xl-margin-5px-top { margin-top:5px; }
    .xl-margin-10px-top { margin-top:10px; }
    .xl-margin-15px-top { margin-top:15px; }
    .xl-margin-20px-top { margin-top:20px; }
    .xl-margin-25px-top { margin-top:25px; }
    .xl-margin-30px-top { margin-top:30px; }
    .xl-margin-35px-top { margin-top:35px; }
    .xl-margin-40px-top { margin-top:40px; }
    .xl-margin-45px-top { margin-top:45px; }
    .xl-margin-50px-top { margin-top:50px; }
    .xl-margin-55px-top { margin-top:55px; }
    .xl-margin-60px-top { margin-top:60px; }
    .xl-margin-65px-top { margin-top:65px; }
    .xl-margin-70px-top { margin-top:70px; }
    .xl-margin-75px-top { margin-top:75px; }
    .xl-margin-80px-top { margin-top:80px; }
    .xl-margin-85px-top { margin-top:85px; }
    .xl-margin-90px-top { margin-top:90px; }
    .xl-margin-95px-top { margin-top:95px; }
    .xl-margin-100px-top { margin-top:100px; }
    .xl-margin-1-rem-top { margin-top: 1rem; }
    .xl-margin-1-half-rem-top { margin-top: 1.5rem; }
    .xl-margin-2-rem-top { margin-top: 2rem; }
    .xl-margin-2-half-rem-top { margin-top: 2.5rem; }
    .xl-margin-3-rem-top { margin-top: 3rem; }
    .xl-margin-3-half-rem-top { margin-top: 3.5rem; }
    .xl-margin-4-rem-top { margin-top: 4rem; }
    .xl-margin-4-half-rem-top { margin-top: 4.5rem; }
    .xl-margin-5-rem-top { margin-top: 5rem; }
    .xl-margin-5-half-rem-top { margin-top: 5.5rem; }
    .xl-margin-6-rem-top { margin-top: 6rem; }
    .xl-margin-6-half-rem-top { margin-top: 6.5rem; }
    .xl-margin-7-rem-top { margin-top: 7rem; }
    .xl-margin-7-half-rem-top { margin-top: 7.5rem; }
    .xl-margin-8-rem-top { margin-top: 8rem; }
    .xl-margin-8-half-rem-top { margin-top: 8.5rem; }
    .xl-margin-9-rem-top { margin-top: 9rem; }
    .xl-margin-9-half-rem-top { margin-top: 9.5rem; }
    .xl-margin-10-rem-top { margin-top: 10rem; }
    .xl-margin-10-half-rem-top { margin-top: 10.5rem; }

    /* margin bottom */
    .xl-margin-one-bottom { margin-bottom:1%; }
    .xl-margin-two-bottom { margin-bottom:2%; }
    .xl-margin-three-bottom { margin-bottom:3%; }
    .xl-margin-four-bottom { margin-bottom:4%; }
    .xl-margin-five-bottom { margin-bottom:5%; }
    .xl-margin-six-bottom { margin-bottom:6%; }
    .xl-margin-seven-bottom { margin-bottom:7%; }
    .xl-margin-eight-bottom { margin-bottom:8%; }
    .xl-margin-nine-bottom { margin-bottom:9%; }
    .xl-margin-ten-bottom { margin-bottom:10%; }
    .xl-margin-eleven-bottom { margin-bottom:11%; }
    .xl-margin-twelve-bottom { margin-bottom:12%; }
    .xl-margin-thirteen-bottom { margin-bottom:13%; }
    .xl-margin-fourteen-bottom { margin-bottom:14%; }
    .xl-margin-fifteen-bottom { margin-bottom:15%; }
    .xl-margin-sixteen-bottom { margin-bottom:16%; }
    .xl-margin-seventeen-bottom { margin-bottom:17%; }
    .xl-margin-eighteen-bottom { margin-bottom:18%; }
    .xl-margin-nineteen-bottom { margin-bottom:19%; }
    .xl-margin-twenty-bottom { margin-bottom:20%; }
    .xl-margin-5px-bottom { margin-bottom:5px; }
    .xl-margin-10px-bottom { margin-bottom:10px; }
    .xl-margin-15px-bottom { margin-bottom:15px; }
    .xl-margin-20px-bottom { margin-bottom:20px; }
    .xl-margin-25px-bottom { margin-bottom:25px; }
    .xl-margin-30px-bottom { margin-bottom:30px; }
    .xl-margin-35px-bottom { margin-bottom:35px; }
    .xl-margin-40px-bottom { margin-bottom:40px; }
    .xl-margin-45px-bottom { margin-bottom:45px; }
    .xl-margin-50px-bottom { margin-bottom:50px; }
    .xl-margin-55px-bottom { margin-bottom:55px; }
    .xl-margin-60px-bottom { margin-bottom:60px; }
    .xl-margin-65px-bottom { margin-bottom:65px; }
    .xl-margin-70px-bottom { margin-bottom:70px; }
    .xl-margin-75px-bottom { margin-bottom:75px; }
    .xl-margin-80px-bottom { margin-bottom:80px; }
    .xl-margin-85px-bottom { margin-bottom:85px; }
    .xl-margin-90px-bottom { margin-bottom:90px; }
    .xl-margin-95px-bottom { margin-bottom:95px; }
    .xl-margin-100px-bottom { margin-bottom:100px; }
    .xl-margin-1-rem-bottom { margin-bottom: 1rem; }
    .xl-margin-1-half-rem-bottom { margin-bottom: 1.5rem; }
    .xl-margin-2-rem-bottom { margin-bottom: 2rem; }
    .xl-margin-2-half-rem-bottom { margin-bottom: 2.5rem; }
    .xl-margin-3-rem-bottom { margin-bottom: 3rem; }
    .xl-margin-3-half-rem-bottom { margin-bottom: 3.5rem; }
    .xl-margin-4-rem-bottom { margin-bottom: 4rem; }
    .xl-margin-4-half-rem-bottom { margin-bottom: 4.5rem; }
    .xl-margin-5-rem-bottom { margin-bottom: 5rem; }
    .xl-margin-5-half-rem-bottom { margin-bottom: 5.5rem; }
    .xl-margin-6-rem-bottom { margin-bottom: 6rem; }
    .xl-margin-6-half-rem-bottom { margin-bottom: 6.5rem; }
    .xl-margin-7-rem-bottom { margin-bottom: 7rem; }
    .xl-margin-7-half-rem-bottom { margin-bottom: 7.5rem; }
    .xl-margin-8-rem-bottom { margin-bottom: 8rem; }
    .xl-margin-8-half-rem-bottom { margin-bottom: 8.5rem; }
    .xl-margin-9-rem-bottom { margin-bottom: 9rem; }
    .xl-margin-9-half-rem-bottom { margin-bottom: 9.5rem; }
    .xl-margin-10-rem-bottom { margin-bottom: 10rem; }
    .xl-margin-10-half-rem-bottom { margin-bottom: 10.5rem; }

    /* margin right */
    .xl-margin-one-right { margin-right:1%; }
    .xl-margin-two-right { margin-right:2%; }
    .xl-margin-three-right { margin-right:3%; }
    .xl-margin-four-right { margin-right:4%; }
    .xl-margin-five-right { margin-right:5%; }
    .xl-margin-six-right { margin-right:6%; }
    .xl-margin-seven-right { margin-right:7%; }
    .xl-margin-eight-right { margin-right:8%; }
    .xl-margin-nine-right { margin-right:9%; }
    .xl-margin-ten-right { margin-right:10%; }
    .xl-margin-eleven-right { margin-right:11%; }
    .xl-margin-twelve-right { margin-right:12%; }
    .xl-margin-thirteen-right { margin-right:13%; }
    .xl-margin-fourteen-right { margin-right:14%; }
    .xl-margin-fifteen-right { margin-right:15%; }
    .xl-margin-sixteen-right { margin-right:16%; }
    .xl-margin-seventeen-right { margin-right:17%; }
    .xl-margin-eighteen-right { margin-right:18%; }
    .xl-margin-nineteen-right { margin-right:19%; }
    .xl-margin-twenty-right { margin-right:20%; }
    .xl-margin-5px-right { margin-right:5px; }
    .xl-margin-10px-right { margin-right:10px; }
    .xl-margin-15px-right { margin-right:15px; }
    .xl-margin-20px-right { margin-right:20px; }
    .xl-margin-25px-right { margin-right:25px; }
    .xl-margin-30px-right { margin-right:30px; }
    .xl-margin-35px-right { margin-right:35px; }
    .xl-margin-40px-right { margin-right:40px; }
    .xl-margin-45px-right { margin-right:45px; }
    .xl-margin-50px-right { margin-right:50px; }
    .xl-margin-55px-right { margin-right:55px; }
    .xl-margin-60px-right { margin-right:60px; }
    .xl-margin-65px-right { margin-right:65px; }
    .xl-margin-70px-right { margin-right:70px; }
    .xl-margin-75px-right { margin-right:75px; }
    .xl-margin-80px-right { margin-right:80px; }
    .xl-margin-85px-right { margin-right:85px; }
    .xl-margin-90px-right { margin-right:90px; }
    .xl-margin-95px-right { margin-right:95px; }
    .xl-margin-100px-right { margin-right:100px; }
    .xl-margin-1-rem-right { margin-right: 1rem; }
    .xl-margin-1-half-rem-right { margin-right: 1.5rem; }
    .xl-margin-2-rem-right { margin-right: 2rem; }
    .xl-margin-2-half-rem-right { margin-right: 2.5rem; }
    .xl-margin-3-rem-right { margin-right: 3rem; }
    .xl-margin-3-half-rem-right { margin-right: 3.5rem; }
    .xl-margin-4-rem-right { margin-right: 4rem; }
    .xl-margin-4-half-rem-right { margin-right: 4.5rem; }
    .xl-margin-5-rem-right { margin-right: 5rem; }
    .xl-margin-5-half-rem-right { margin-right: 5.5rem; }
    .xl-margin-6-rem-right { margin-right: 6rem; }
    .xl-margin-6-half-rem-right { margin-right: 6.5rem; }
    .xl-margin-7-rem-right { margin-right: 7rem; }
    .xl-margin-7-half-rem-right { margin-right: 7.5rem; }
    .xl-margin-8-rem-right { margin-right: 8rem; }
    .xl-margin-8-half-rem-right { margin-right: 8.5rem; }
    .xl-margin-9-rem-right { margin-right: 9rem; }
    .xl-margin-9-half-rem-right { margin-right: 9.5rem; }
    .xl-margin-10-rem-right { margin-right: 10rem; }
    .xl-margin-10-half-rem-right { margin-right: 10.5rem; }

    /* margin left */
    .xl-margin-one-left { margin-left:1%; }
    .xl-margin-two-left { margin-left:2%; }
    .xl-margin-three-left { margin-left:3%; }
    .xl-margin-four-left { margin-left:4%; }
    .xl-margin-five-left { margin-left:5%; }
    .xl-margin-six-left { margin-left:6%; }
    .xl-margin-seven-left { margin-left:7%; }
    .xl-margin-eight-left { margin-left:8%; }
    .xl-margin-nine-left { margin-left:9%; }
    .xl-margin-ten-left { margin-left:10%; }
    .xl-margin-eleven-left { margin-left:11%; }
    .xl-margin-twelve-left { margin-left:12%; }
    .xl-margin-thirteen-left { margin-left:13%; }
    .xl-margin-fourteen-left { margin-left:14%; }
    .xl-margin-fifteen-left { margin-left:15%; }
    .xl-margin-sixteen-left { margin-left:16%; }
    .xl-margin-seventeen-left { margin-left:17%; }
    .xl-margin-eighteen-left { margin-left:18%; }
    .xl-margin-nineteen-left { margin-left:19%; }
    .xl-margin-twenty-left { margin-left:20%; }
    .xl-margin-5px-left { margin-left:5px; }
    .xl-margin-10px-left { margin-left:10px; }
    .xl-margin-15px-left { margin-left:15px; }
    .xl-margin-20px-left { margin-left:20px; }
    .xl-margin-25px-left { margin-left:25px; }
    .xl-margin-30px-left { margin-left:30px; }
    .xl-margin-35px-left { margin-left:35px; }
    .xl-margin-40px-left { margin-left:40px; }
    .xl-margin-45px-left { margin-left:45px; }
    .xl-margin-50px-left { margin-left:50px; }
    .xl-margin-55px-left { margin-left:55px; }
    .xl-margin-60px-left { margin-left:60px; }
    .xl-margin-65px-left { margin-left:65px; }
    .xl-margin-70px-left { margin-left:70px; }
    .xl-margin-75px-left { margin-left:75px; }
    .xl-margin-80px-left { margin-left:80px; }
    .xl-margin-85px-left { margin-left:85px; }
    .xl-margin-90px-left { margin-left:90px; }
    .xl-margin-95px-left { margin-left:95px; }
    .xl-margin-100px-left { margin-left:100px; }
    .xl-margin-1-rem-left { margin-left: 1rem; }
    .xl-margin-1-half-rem-left { margin-left: 1.5rem; }
    .xl-margin-2-rem-left { margin-left: 2rem; }
    .xl-margin-2-half-rem-left { margin-left: 2.5rem; }
    .xl-margin-3-rem-left { margin-left: 3rem; }
    .xl-margin-3-half-rem-left { margin-left: 3.5rem; }
    .xl-margin-4-rem-left { margin-left: 4rem; }
    .xl-margin-4-half-rem-left { margin-left: 4.5rem; }
    .xl-margin-5-rem-left { margin-left: 5rem; }
    .xl-margin-5-half-rem-left { margin-left: 5.5rem; }
    .xl-margin-6-rem-left { margin-left: 6rem; }
    .xl-margin-6-half-rem-left { margin-left: 6.5rem; }
    .xl-margin-7-rem-left { margin-left: 7rem; }
    .xl-margin-7-half-rem-left { margin-left: 7.5rem; }
    .xl-margin-8-rem-left { margin-left: 8rem; }
    .xl-margin-8-half-rem-left { margin-left: 8.5rem; }
    .xl-margin-9-rem-left { margin-left: 9rem; }
    .xl-margin-9-half-rem-left { margin-left: 9.5rem; }
    .xl-margin-10-rem-left { margin-left: 10rem; }
    .xl-margin-10-half-rem-left { margin-left: 10.5rem; }

    /* margin left right */
    .xl-margin-one-lr { margin-left:1%; margin-right:1%; }
    .xl-margin-two-lr { margin-left:2%; margin-right:2%; }
    .xl-margin-three-lr { margin-left:3%; margin-right:3%; }
    .xl-margin-four-lr { margin-left:4%; margin-right:4%; }
    .xl-margin-five-lr { margin-left:5%; margin-right:5%; }
    .xl-margin-six-lr { margin-left:6%; margin-right:6%; }
    .xl-margin-seven-lr { margin-left:7%; margin-right:7%; }
    .xl-margin-eight-lr { margin-left:8%; margin-right:8%; }
    .xl-margin-nine-lr { margin-left:9%; margin-right:9%; }
    .xl-margin-ten-lr { margin-left:10%; margin-right:10%; }
    .xl-margin-eleven-lr { margin-left:11%; margin-right:11%; }
    .xl-margin-twelve-lr { margin-left:12%; margin-right:12%; }
    .xl-margin-thirteen-lr { margin-left:13%; margin-right:13%; }
    .xl-margin-fourteen-lr { margin-left:14%; margin-right:14%; }
    .xl-margin-fifteen-lr { margin-left:15%; margin-right:15%; }
    .xl-margin-sixteen-lr { margin-left:16%; margin-right:16%; }
    .xl-margin-seventeen-lr { margin-left:17%; margin-right:17%; }
    .xl-margin-eighteen-lr { margin-left:18%; margin-right:18%; }
    .xl-margin-nineteen-lr { margin-left:19%; margin-right:19%; }
    .xl-margin-twenty-lr { margin-left:20%; margin-right:20%; }
    .xl-margin-5px-lr { margin-left:5px; margin-right:5px; }
    .xl-margin-10px-lr { margin-left:10px; margin-right:10px; }
    .xl-margin-15px-lr { margin-left:15px; margin-right:15px; }
    .xl-margin-20px-lr { margin-left:20px; margin-right:20px; }
    .xl-margin-25px-lr { margin-left:25px; margin-right:25px; }
    .xl-margin-30px-lr { margin-left:30px; margin-right:30px; }
    .xl-margin-35px-lr { margin-left:35px; margin-right:35px; }
    .xl-margin-40px-lr { margin-left:40px; margin-right:40px; }
    .xl-margin-45px-lr { margin-left:45px; margin-right:45px; }
    .xl-margin-50px-lr { margin-left:50px; margin-right:50px; }
    .xl-margin-55px-lr { margin-left:55px; margin-right:55px; }
    .xl-margin-60px-lr { margin-left:60px; margin-right:60px; }
    .xl-margin-65px-lr { margin-left:65px; margin-right:65px; }
    .xl-margin-70px-lr { margin-left:70px; margin-right:70px; }
    .xl-margin-75px-lr { margin-left:75px; margin-right:75px; }
    .xl-margin-80px-lr { margin-left:80px; margin-right:80px; }
    .xl-margin-85px-lr { margin-left:85px; margin-right:85px; }
    .xl-margin-90px-lr { margin-left:90px; margin-right:90px; }
    .xl-margin-95px-lr { margin-left:95px; margin-right:95px; }
    .xl-margin-100px-lr { margin-left:100px; margin-right:100px; }
    .xl-margin-1-rem-lr { margin-left: 1rem; margin-right: 1rem; }
    .xl-margin-1-half-rem-lr { margin-left: 1.5rem; margin-right: 1.5rem; }
    .xl-margin-2-rem-lr { margin-left: 2rem; margin-right: 2rem; }
    .xl-margin-2-half-rem-lr { margin-left: 2.5rem; margin-right: 2.5rem; }
    .xl-margin-3-rem-lr { margin-left: 3rem; margin-right: 3rem; }
    .xl-margin-3-half-rem-lr { margin-left: 3.5rem; margin-right: 3.5rem; }
    .xl-margin-4-rem-lr { margin-left: 4rem; margin-right: 4rem; }
    .xl-margin-4-half-rem-lr { margin-left: 4.5rem; margin-right: 4.5rem; }
    .xl-margin-5-rem-lr { margin-left: 5rem; margin-right: 5rem; }
    .xl-margin-5-half-rem-lr { margin-left: 5.5rem; margin-right: 5.5rem; }
    .xl-margin-6-rem-lr { margin-left: 6rem; margin-right: 6rem; }
    .xl-margin-6-half-rem-lr { margin-left: 6.5rem; margin-right: 6.5rem; }
    .xl-margin-7-rem-lr { margin-left: 7rem; margin-right: 7rem; }
    .xl-margin-7-half-rem-lr { margin-left: 7.5rem; margin-right: 7.5rem; }
    .xl-margin-8-rem-lr { margin-left: 8rem; margin-right: 8rem; }
    .xl-margin-8-half-rem-lr { margin-left: 8.5rem; margin-right: 8.5rem; }
    .xl-margin-9-rem-lr { margin-left: 9rem; margin-right: 9rem; }
    .xl-margin-9-half-rem-lr { margin-left: 9.5rem; margin-right: 9.5rem; }
    .xl-margin-10-rem-lr { margin-left: 10rem; margin-right: 10rem; }
    .xl-margin-10-half-rem-lr { margin-left: 10.5rem; margin-right: 10.5rem; }

    /* margin top bottom */
    .xl-margin-one-tb { margin-top:1%; margin-bottom:1%; }
    .xl-margin-two-tb { margin-top:2%; margin-bottom:2%; }
    .xl-margin-three-tb { margin-top:3%; margin-bottom:3%; }
    .xl-margin-four-tb { margin-top:4%; margin-bottom:4%; }
    .xl-margin-five-tb { margin-top:5%; margin-bottom:5%; }
    .xl-margin-six-tb { margin-top:6%; margin-bottom:6%; }
    .xl-margin-seven-tb { margin-top:7%; margin-bottom:7%; }
    .xl-margin-eight-tb { margin-top:8%; margin-bottom:8%; }
    .xl-margin-nine-tb { margin-top:9%; margin-bottom:9%; }
    .xl-margin-ten-tb { margin-top:10%; margin-bottom:10%; }
    .xl-margin-eleven-tb { margin-top:11%; margin-bottom:11%; }
    .xl-margin-twelve-tb { margin-top:12%; margin-bottom:12%; }
    .xl-margin-thirteen-tb { margin-top:13%; margin-bottom:13%; }
    .xl-margin-fourteen-tb { margin-top:14%; margin-bottom:14%; }
    .xl-margin-fifteen-tb { margin-top:15%; margin-bottom:15%; }
    .xl-margin-sixteen-tb { margin-top:16%; margin-bottom:16%; }
    .xl-margin-seventeen-tb { margin-top:17%; margin-bottom:17%; }
    .xl-margin-eighteen-tb { margin-top:18%; margin-bottom:18%; }
    .xl-margin-nineteen-tb { margin-top:19%; margin-bottom:19%; }
    .xl-margin-twenty-tb { margin-top:20%; margin-bottom:20%; }
    .xl-margin-5px-tb { margin-top:5px; margin-bottom:5px; }
    .xl-margin-10px-tb { margin-top:10px; margin-bottom:10px; }
    .xl-margin-15px-tb { margin-top:15px; margin-bottom:15px; }
    .xl-margin-20px-tb { margin-top:20px; margin-bottom:20px; }
    .xl-margin-25px-tb { margin-top:25px; margin-bottom:25px; }
    .xl-margin-30px-tb { margin-top:30px; margin-bottom:30px; }
    .xl-margin-35px-tb { margin-top:35px; margin-bottom:35px; }
    .xl-margin-40px-tb { margin-top:40px; margin-bottom:40px; }
    .xl-margin-45px-tb { margin-top:45px; margin-bottom:45px; }
    .xl-margin-50px-tb { margin-top:50px; margin-bottom:50px; }
    .xl-margin-55px-tb { margin-top:55px; margin-bottom:55px; }
    .xl-margin-60px-tb { margin-top:60px; margin-bottom:60px; }
    .xl-margin-65px-tb { margin-top:65px; margin-bottom:65px; }
    .xl-margin-70px-tb { margin-top:70px; margin-bottom:70px; }
    .xl-margin-75px-tb { margin-top:75px; margin-bottom:75px; }
    .xl-margin-80px-tb { margin-top:80px; margin-bottom:80px; }
    .xl-margin-85px-tb { margin-top:85px; margin-bottom:85px; }
    .xl-margin-90px-tb { margin-top:90px; margin-bottom:90px; }
    .xl-margin-95px-tb { margin-top:95px; margin-bottom:95px; }
    .xl-margin-100px-tb { margin-top:100px; margin-bottom:100px; }
    .xl-margin-1-rem-tb { margin-top: 1rem; margin-bottom: 1rem; }
    .xl-margin-1-half-rem-tb { margin-top: 1.5rem; margin-bottom: 1.5rem; }
    .xl-margin-2-rem-tb { margin-top: 2rem; margin-bottom: 2rem; }
    .xl-margin-2-half-rem-tb { margin-top: 2.5rem; margin-bottom: 2.5rem; }
    .xl-margin-3-rem-tb { margin-top: 3rem; margin-bottom: 3rem; }
    .xl-margin-3-half-rem-tb { margin-top: 3.5rem; margin-bottom: 3.5rem; }
    .xl-margin-4-rem-tb { margin-top: 4rem; margin-bottom: 4rem; }
    .xl-margin-4-half-rem-tb { margin-top: 4.5rem; margin-bottom: 4.5rem; }
    .xl-margin-5-rem-tb { margin-top: 5rem; margin-bottom: 5rem; }
    .xl-margin-5-half-rem-tb { margin-top: 5.5rem; margin-bottom: 5.5rem; }
    .xl-margin-6-rem-tb { margin-top: 6rem; margin-bottom: 6rem; }
    .xl-margin-6-half-rem-tb { margin-top: 6.5rem; margin-bottom: 6.5rem; }
    .xl-margin-7-rem-tb { margin-top: 7rem; margin-bottom: 7rem; }
    .xl-margin-7-half-rem-tb { margin-top: 7.5rem; margin-bottom: 7.5rem; }
    .xl-margin-8-rem-tb { margin-top: 8rem; margin-bottom: 8rem; }
    .xl-margin-8-half-rem-tb { margin-top: 8.5rem; margin-bottom: 8.5rem; }
    .xl-margin-9-rem-tb { margin-top: 9rem; margin-bottom: 9rem; }
    .xl-margin-9-half-rem-tb { margin-top: 9.5rem; margin-bottom: 9.5rem; }
    .xl-margin-10-rem-tb { margin-top: 10rem; margin-bottom: 10rem; }
    .xl-margin-10-half-rem-tb { margin-top: 10.5rem; margin-bottom: 10.5rem; }  

    .xl-margin-auto-lr { margin-left: auto !important; margin-right: auto !important; }
    .xl-margin-auto { margin: auto; }
    .xl-no-margin { margin: 0 !important; }
    .xl-no-margin-top { margin-top: 0 !important; }
    .xl-no-margin-bottom { margin-bottom: 0 !important; }
    .xl-no-margin-left { margin-left: 0 !important; }
    .xl-no-margin-right { margin-right: 0 !important; }
    .xl-no-margin-tb { margin-top: 0 !important; margin-bottom: 0 !important; }
    .xl-no-margin-lr { margin-right: 0 !important; margin-left: 0 !important; }
}

@media (max-width: 1199px) {
    .lg-margin-one-all { margin:1%; }
    .lg-margin-two-all { margin:2%; }
    .lg-margin-three-all { margin:3%; }
    .lg-margin-four-all { margin:4%; }
    .lg-margin-five-all { margin:5%; }
    .lg-margin-six-all { margin:6%; }
    .lg-margin-seven-all { margin:7%; }
    .lg-margin-eight-all { margin:8%; }
    .lg-margin-nine-all { margin:9%; }
    .lg-margin-ten-all { margin:10%; }
    .lg-margin-eleven-all { margin:11%; }
    .lg-margin-twelve-all { margin:12%; }
    .lg-margin-thirteen-all { margin:13%; }
    .lg-margin-fourteen-all { margin:14%; }
    .lg-margin-fifteen-all { margin:15%; }
    .lg-margin-sixteen-all { margin:16%; }
    .lg-margin-seventeen-all { margin:17%; }
    .lg-margin-eighteen-all { margin:18%; }
    .lg-margin-nineteen-all { margin:19%; }
    .lg-margin-twenty-all { margin:20%; }
    .lg-margin-5px-all { margin:5px; }
    .lg-margin-10px-all { margin:10px; }
    .lg-margin-15px-all { margin:15px; }
    .lg-margin-20px-all { margin:20px; }
    .lg-margin-25px-all { margin:25px; }
    .lg-margin-30px-all { margin:30px; }
    .lg-margin-35px-all { margin:35px; }
    .lg-margin-40px-all { margin:40px; }
    .lg-margin-45px-all { margin:45px; }
    .lg-margin-50px-all { margin:50px; }
    .lg-margin-55px-all { margin:55px; }
    .lg-margin-60px-all { margin:60px; }
    .lg-margin-65px-all { margin:65px; }
    .lg-margin-70px-all { margin:70px; }
    .lg-margin-75px-all { margin:75px; }
    .lg-margin-80px-all { margin:80px; }
    .lg-margin-85px-all { margin:85px; }
    .lg-margin-90px-all { margin:90px; }
    .lg-margin-95px-all { margin:95px; }
    .lg-margin-100px-all { margin:100px; }
    .lg-margin-1-rem-all { margin: 1rem; }
    .lg-margin-1-half-rem-all { margin: 1.5rem; }
    .lg-margin-2-rem-all { margin: 2rem; }
    .lg-margin-2-half-rem-all { margin: 2.5rem; }
    .lg-margin-3-rem-all { margin: 3rem; }
    .lg-margin-3-half-rem-all { margin: 3.5rem; }
    .lg-margin-4-rem-all { margin: 4rem; }
    .lg-margin-4-half-rem-all { margin: 4.5rem; }
    .lg-margin-5-rem-all { margin: 5rem; }
    .lg-margin-5-half-rem-all { margin: 5.5rem; }
    .lg-margin-6-rem-all { margin: 6rem; }
    .lg-margin-6-half-rem-all { margin: 6.5rem; }
    .lg-margin-7-rem-all { margin: 7rem; }
    .lg-margin-7-half-rem-all { margin: 7.5rem; }
    .lg-margin-8-rem-all { margin: 8rem; }
    .lg-margin-8-half-rem-all { margin: 8.5rem; }
    .lg-margin-9-rem-all { margin: 9rem; }
    .lg-margin-9-half-rem-all { margin: 9.5rem; }
    .lg-margin-10-rem-all { margin: 10rem; }
    .lg-margin-10-half-rem-all { margin: 10.5rem; }

    /* margin top */
    .lg-margin-one-top { margin-top:1%; }
    .lg-margin-two-top { margin-top:2%; }
    .lg-margin-three-top { margin-top:3%; }
    .lg-margin-four-top { margin-top:4%; }
    .lg-margin-five-top { margin-top:5%; }
    .lg-margin-six-top { margin-top:6%; }
    .lg-margin-seven-top { margin-top:7%; }
    .lg-margin-eight-top { margin-top:8%; }
    .lg-margin-nine-top { margin-top:9%; }
    .lg-margin-ten-top { margin-top:10%; }
    .lg-margin-eleven-top { margin-top:11%; }
    .lg-margin-twelve-top { margin-top:12%; }
    .lg-margin-thirteen-top { margin-top:13%; }
    .lg-margin-fourteen-top { margin-top:14%; }
    .lg-margin-fifteen-top { margin-top:15%; }
    .lg-margin-sixteen-top { margin-top:16%; }
    .lg-margin-seventeen-top { margin-top:17%; }
    .lg-margin-eighteen-top { margin-top:18%; }
    .lg-margin-nineteen-top { margin-top:19%; }
    .lg-margin-twenty-top { margin-top:20%; }
    .lg-margin-5px-top { margin-top:5px; }
    .lg-margin-10px-top { margin-top:10px; }
    .lg-margin-15px-top { margin-top:15px; }
    .lg-margin-20px-top { margin-top:20px; }
    .lg-margin-25px-top { margin-top:25px; }
    .lg-margin-30px-top { margin-top:30px; }
    .lg-margin-35px-top { margin-top:35px; }
    .lg-margin-40px-top { margin-top:40px; }
    .lg-margin-45px-top { margin-top:45px; }
    .lg-margin-50px-top { margin-top:50px; }
    .lg-margin-55px-top { margin-top:55px; }
    .lg-margin-60px-top { margin-top:60px; }
    .lg-margin-65px-top { margin-top:65px; }
    .lg-margin-70px-top { margin-top:70px; }
    .lg-margin-75px-top { margin-top:75px; }
    .lg-margin-80px-top { margin-top:80px; }
    .lg-margin-85px-top { margin-top:85px; }
    .lg-margin-90px-top { margin-top:90px; }
    .lg-margin-95px-top { margin-top:95px; }
    .lg-margin-100px-top { margin-top:100px; }
    .lg-margin-1-rem-top { margin-top: 1rem; }
    .lg-margin-1-half-rem-top { margin-top: 1.5rem; }
    .lg-margin-2-rem-top { margin-top: 2rem; }
    .lg-margin-2-half-rem-top { margin-top: 2.5rem; }
    .lg-margin-3-rem-top { margin-top: 3rem; }
    .lg-margin-3-half-rem-top { margin-top: 3.5rem; }
    .lg-margin-4-rem-top { margin-top: 4rem; }
    .lg-margin-4-half-rem-top { margin-top: 4.5rem; }
    .lg-margin-5-rem-top { margin-top: 5rem; }
    .lg-margin-5-half-rem-top { margin-top: 5.5rem; }
    .lg-margin-6-rem-top { margin-top: 6rem; }
    .lg-margin-6-half-rem-top { margin-top: 6.5rem; }
    .lg-margin-7-rem-top { margin-top: 7rem; }
    .lg-margin-7-half-rem-top { margin-top: 7.5rem; }
    .lg-margin-8-rem-top { margin-top: 8rem; }
    .lg-margin-8-half-rem-top { margin-top: 8.5rem; }
    .lg-margin-9-rem-top { margin-top: 9rem; }
    .lg-margin-9-half-rem-top { margin-top: 9.5rem; }
    .lg-margin-10-rem-top { margin-top: 10rem; }
    .lg-margin-10-half-rem-top { margin-top: 10.5rem; }

    /* margin bottom */
    .lg-margin-one-bottom { margin-bottom:1%; }
    .lg-margin-two-bottom { margin-bottom:2%; }
    .lg-margin-three-bottom { margin-bottom:3%; }
    .lg-margin-four-bottom { margin-bottom:4%; }
    .lg-margin-five-bottom { margin-bottom:5%; }
    .lg-margin-six-bottom { margin-bottom:6%; }
    .lg-margin-seven-bottom { margin-bottom:7%; }
    .lg-margin-eight-bottom { margin-bottom:8%; }
    .lg-margin-nine-bottom { margin-bottom:9%; }
    .lg-margin-ten-bottom { margin-bottom:10%; }
    .lg-margin-eleven-bottom { margin-bottom:11%; }
    .lg-margin-twelve-bottom { margin-bottom:12%; }
    .lg-margin-thirteen-bottom { margin-bottom:13%; }
    .lg-margin-fourteen-bottom { margin-bottom:14%; }
    .lg-margin-fifteen-bottom { margin-bottom:15%; }
    .lg-margin-sixteen-bottom { margin-bottom:16%; }
    .lg-margin-seventeen-bottom { margin-bottom:17%; }
    .lg-margin-eighteen-bottom { margin-bottom:18%; }
    .lg-margin-nineteen-bottom { margin-bottom:19%; }
    .lg-margin-twenty-bottom { margin-bottom:20%; }
    .lg-margin-5px-bottom { margin-bottom:5px; }
    .lg-margin-10px-bottom { margin-bottom:10px; }
    .lg-margin-15px-bottom { margin-bottom:15px; }
    .lg-margin-20px-bottom { margin-bottom:20px; }
    .lg-margin-25px-bottom { margin-bottom:25px; }
    .lg-margin-30px-bottom { margin-bottom:30px; }
    .lg-margin-35px-bottom { margin-bottom:35px; }
    .lg-margin-40px-bottom { margin-bottom:40px; }
    .lg-margin-45px-bottom { margin-bottom:45px; }
    .lg-margin-50px-bottom { margin-bottom:50px; }
    .lg-margin-55px-bottom { margin-bottom:55px; }
    .lg-margin-60px-bottom { margin-bottom:60px; }
    .lg-margin-65px-bottom { margin-bottom:65px; }
    .lg-margin-70px-bottom { margin-bottom:70px; }
    .lg-margin-75px-bottom { margin-bottom:75px; }
    .lg-margin-80px-bottom { margin-bottom:80px; }
    .lg-margin-85px-bottom { margin-bottom:85px; }
    .lg-margin-90px-bottom { margin-bottom:90px; }
    .lg-margin-95px-bottom { margin-bottom:95px; }
    .lg-margin-100px-bottom { margin-bottom:100px; }
    .lg-margin-1-rem-bottom { margin-bottom: 1rem; }
    .lg-margin-1-half-rem-bottom { margin-bottom: 1.5rem; }
    .lg-margin-2-rem-bottom { margin-bottom: 2rem; }
    .lg-margin-2-half-rem-bottom { margin-bottom: 2.5rem; }
    .lg-margin-3-rem-bottom { margin-bottom: 3rem; }
    .lg-margin-3-half-rem-bottom { margin-bottom: 3.5rem; }
    .lg-margin-4-rem-bottom { margin-bottom: 4rem; }
    .lg-margin-4-half-rem-bottom { margin-bottom: 4.5rem; }
    .lg-margin-5-rem-bottom { margin-bottom: 5rem; }
    .lg-margin-5-half-rem-bottom { margin-bottom: 5.5rem; }
    .lg-margin-6-rem-bottom { margin-bottom: 6rem; }
    .lg-margin-6-half-rem-bottom { margin-bottom: 6.5rem; }
    .lg-margin-7-rem-bottom { margin-bottom: 7rem; }
    .lg-margin-7-half-rem-bottom { margin-bottom: 7.5rem; }
    .lg-margin-8-rem-bottom { margin-bottom: 8rem; }
    .lg-margin-8-half-rem-bottom { margin-bottom: 8.5rem; }
    .lg-margin-9-rem-bottom { margin-bottom: 9rem; }
    .lg-margin-9-half-rem-bottom { margin-bottom: 9.5rem; }
    .lg-margin-10-rem-bottom { margin-bottom: 10rem; }
    .lg-margin-10-half-rem-bottom { margin-bottom: 10.5rem; }

    /* margin right */
    .lg-margin-one-right { margin-right:1%; }
    .lg-margin-two-right { margin-right:2%; }
    .lg-margin-three-right { margin-right:3%; }
    .lg-margin-four-right { margin-right:4%; }
    .lg-margin-five-right { margin-right:5%; }
    .lg-margin-six-right { margin-right:6%; }
    .lg-margin-seven-right { margin-right:7%; }
    .lg-margin-eight-right { margin-right:8%; }
    .lg-margin-nine-right { margin-right:9%; }
    .lg-margin-ten-right { margin-right:10%; }
    .lg-margin-eleven-right { margin-right:11%; }
    .lg-margin-twelve-right { margin-right:12%; }
    .lg-margin-thirteen-right { margin-right:13%; }
    .lg-margin-fourteen-right { margin-right:14%; }
    .lg-margin-fifteen-right { margin-right:15%; }
    .lg-margin-sixteen-right { margin-right:16%; }
    .lg-margin-seventeen-right { margin-right:17%; }
    .lg-margin-eighteen-right { margin-right:18%; }
    .lg-margin-nineteen-right { margin-right:19%; }
    .lg-margin-twenty-right { margin-right:20%; }
    .lg-margin-5px-right { margin-right:5px; }
    .lg-margin-10px-right { margin-right:10px; }
    .lg-margin-15px-right { margin-right:15px; }
    .lg-margin-20px-right { margin-right:20px; }
    .lg-margin-25px-right { margin-right:25px; }
    .lg-margin-30px-right { margin-right:30px; }
    .lg-margin-35px-right { margin-right:35px; }
    .lg-margin-40px-right { margin-right:40px; }
    .lg-margin-45px-right { margin-right:45px; }
    .lg-margin-50px-right { margin-right:50px; }
    .lg-margin-55px-right { margin-right:55px; }
    .lg-margin-60px-right { margin-right:60px; }
    .lg-margin-65px-right { margin-right:65px; }
    .lg-margin-70px-right { margin-right:70px; }
    .lg-margin-75px-right { margin-right:75px; }
    .lg-margin-80px-right { margin-right:80px; }
    .lg-margin-85px-right { margin-right:85px; }
    .lg-margin-90px-right { margin-right:90px; }
    .lg-margin-95px-right { margin-right:95px; }
    .lg-margin-100px-right { margin-right:100px; }
    .lg-margin-1-rem-right { margin-right: 1rem; }
    .lg-margin-1-half-rem-right { margin-right: 1.5rem; }
    .lg-margin-2-rem-right { margin-right: 2rem; }
    .lg-margin-2-half-rem-right { margin-right: 2.5rem; }
    .lg-margin-3-rem-right { margin-right: 3rem; }
    .lg-margin-3-half-rem-right { margin-right: 3.5rem; }
    .lg-margin-4-rem-right { margin-right: 4rem; }
    .lg-margin-4-half-rem-right { margin-right: 4.5rem; }
    .lg-margin-5-rem-right { margin-right: 5rem; }
    .lg-margin-5-half-rem-right { margin-right: 5.5rem; }
    .lg-margin-6-rem-right { margin-right: 6rem; }
    .lg-margin-6-half-rem-right { margin-right: 6.5rem; }
    .lg-margin-7-rem-right { margin-right: 7rem; }
    .lg-margin-7-half-rem-right { margin-right: 7.5rem; }
    .lg-margin-8-rem-right { margin-right: 8rem; }
    .lg-margin-8-half-rem-right { margin-right: 8.5rem; }
    .lg-margin-9-rem-right { margin-right: 9rem; }
    .lg-margin-9-half-rem-right { margin-right: 9.5rem; }
    .lg-margin-10-rem-right { margin-right: 10rem; }
    .lg-margin-10-half-rem-right { margin-right: 10.5rem; }

    /* margin left */
    .lg-margin-one-left { margin-left:1%; }
    .lg-margin-two-left { margin-left:2%; }
    .lg-margin-three-left { margin-left:3%; }
    .lg-margin-four-left { margin-left:4%; }
    .lg-margin-five-left { margin-left:5%; }
    .lg-margin-six-left { margin-left:6%; }
    .lg-margin-seven-left { margin-left:7%; }
    .lg-margin-eight-left { margin-left:8%; }
    .lg-margin-nine-left { margin-left:9%; }
    .lg-margin-ten-left { margin-left:10%; }
    .lg-margin-eleven-left { margin-left:11%; }
    .lg-margin-twelve-left { margin-left:12%; }
    .lg-margin-thirteen-left { margin-left:13%; }
    .lg-margin-fourteen-left { margin-left:14%; }
    .lg-margin-fifteen-left { margin-left:15%; }
    .lg-margin-sixteen-left { margin-left:16%; }
    .lg-margin-seventeen-left { margin-left:17%; }
    .lg-margin-eighteen-left { margin-left:18%; }
    .lg-margin-nineteen-left { margin-left:19%; }
    .lg-margin-twenty-left { margin-left:20%; }
    .lg-margin-5px-left { margin-left:5px; }
    .lg-margin-10px-left { margin-left:10px; }
    .lg-margin-15px-left { margin-left:15px; }
    .lg-margin-20px-left { margin-left:20px; }
    .lg-margin-25px-left { margin-left:25px; }
    .lg-margin-30px-left { margin-left:30px; }
    .lg-margin-35px-left { margin-left:35px; }
    .lg-margin-40px-left { margin-left:40px; }
    .lg-margin-45px-left { margin-left:45px; }
    .lg-margin-50px-left { margin-left:50px; }
    .lg-margin-55px-left { margin-left:55px; }
    .lg-margin-60px-left { margin-left:60px; }
    .lg-margin-65px-left { margin-left:65px; }
    .lg-margin-70px-left { margin-left:70px; }
    .lg-margin-75px-left { margin-left:75px; }
    .lg-margin-80px-left { margin-left:80px; }
    .lg-margin-85px-left { margin-left:85px; }
    .lg-margin-90px-left { margin-left:90px; }
    .lg-margin-95px-left { margin-left:95px; }
    .lg-margin-100px-left { margin-left:100px; }
    .lg-margin-1-rem-left { margin-left: 1rem; }
    .lg-margin-1-half-rem-left { margin-left: 1.5rem; }
    .lg-margin-2-rem-left { margin-left: 2rem; }
    .lg-margin-2-half-rem-left { margin-left: 2.5rem; }
    .lg-margin-3-rem-left { margin-left: 3rem; }
    .lg-margin-3-half-rem-left { margin-left: 3.5rem; }
    .lg-margin-4-rem-left { margin-left: 4rem; }
    .lg-margin-4-half-rem-left { margin-left: 4.5rem; }
    .lg-margin-5-rem-left { margin-left: 5rem; }
    .lg-margin-5-half-rem-left { margin-left: 5.5rem; }
    .lg-margin-6-rem-left { margin-left: 6rem; }
    .lg-margin-6-half-rem-left { margin-left: 6.5rem; }
    .lg-margin-7-rem-left { margin-left: 7rem; }
    .lg-margin-7-half-rem-left { margin-left: 7.5rem; }
    .lg-margin-8-rem-left { margin-left: 8rem; }
    .lg-margin-8-half-rem-left { margin-left: 8.5rem; }
    .lg-margin-9-rem-left { margin-left: 9rem; }
    .lg-margin-9-half-rem-left { margin-left: 9.5rem; }
    .lg-margin-10-rem-left { margin-left: 10rem; }
    .lg-margin-10-half-rem-left { margin-left: 10.5rem; }

    /* margin left right */
    .lg-margin-one-lr { margin-left:1%; margin-right:1%; }
    .lg-margin-two-lr { margin-left:2%; margin-right:2%; }
    .lg-margin-three-lr { margin-left:3%; margin-right:3%; }
    .lg-margin-four-lr { margin-left:4%; margin-right:4%; }
    .lg-margin-five-lr { margin-left:5%; margin-right:5%; }
    .lg-margin-six-lr { margin-left:6%; margin-right:6%; }
    .lg-margin-seven-lr { margin-left:7%; margin-right:7%; }
    .lg-margin-eight-lr { margin-left:8%; margin-right:8%; }
    .lg-margin-nine-lr { margin-left:9%; margin-right:9%; }
    .lg-margin-ten-lr { margin-left:10%; margin-right:10%; }
    .lg-margin-eleven-lr { margin-left:11%; margin-right:11%; }
    .lg-margin-twelve-lr { margin-left:12%; margin-right:12%; }
    .lg-margin-thirteen-lr { margin-left:13%; margin-right:13%; }
    .lg-margin-fourteen-lr { margin-left:14%; margin-right:14%; }
    .lg-margin-fifteen-lr { margin-left:15%; margin-right:15%; }
    .lg-margin-sixteen-lr { margin-left:16%; margin-right:16%; }
    .lg-margin-seventeen-lr { margin-left:17%; margin-right:17%; }
    .lg-margin-eighteen-lr { margin-left:18%; margin-right:18%; }
    .lg-margin-nineteen-lr { margin-left:19%; margin-right:19%; }
    .lg-margin-twenty-lr { margin-left:20%; margin-right:20%; }
    .lg-margin-5px-lr { margin-left:5px; margin-right:5px; }
    .lg-margin-10px-lr { margin-left:10px; margin-right:10px; }
    .lg-margin-15px-lr { margin-left:15px; margin-right:15px; }
    .lg-margin-20px-lr { margin-left:20px; margin-right:20px; }
    .lg-margin-25px-lr { margin-left:25px; margin-right:25px; }
    .lg-margin-30px-lr { margin-left:30px; margin-right:30px; }
    .lg-margin-35px-lr { margin-left:35px; margin-right:35px; }
    .lg-margin-40px-lr { margin-left:40px; margin-right:40px; }
    .lg-margin-45px-lr { margin-left:45px; margin-right:45px; }
    .lg-margin-50px-lr { margin-left:50px; margin-right:50px; }
    .lg-margin-55px-lr { margin-left:55px; margin-right:55px; }
    .lg-margin-60px-lr { margin-left:60px; margin-right:60px; }
    .lg-margin-65px-lr { margin-left:65px; margin-right:65px; }
    .lg-margin-70px-lr { margin-left:70px; margin-right:70px; }
    .lg-margin-75px-lr { margin-left:75px; margin-right:75px; }
    .lg-margin-80px-lr { margin-left:80px; margin-right:80px; }
    .lg-margin-85px-lr { margin-left:85px; margin-right:85px; }
    .lg-margin-90px-lr { margin-left:90px; margin-right:90px; }
    .lg-margin-95px-lr { margin-left:95px; margin-right:95px; }
    .lg-margin-100px-lr { margin-left:100px; margin-right:100px; }
    .lg-margin-1-rem-lr { margin-left: 1rem; margin-right: 1rem; }
    .lg-margin-1-half-rem-lr { margin-left: 1.5rem; margin-right: 1.5rem; }
    .lg-margin-2-rem-lr { margin-left: 2rem; margin-right: 2rem; }
    .lg-margin-2-half-rem-lr { margin-left: 2.5rem; margin-right: 2.5rem; }
    .lg-margin-3-rem-lr { margin-left: 3rem; margin-right: 3rem; }
    .lg-margin-3-half-rem-lr { margin-left: 3.5rem; margin-right: 3.5rem; }
    .lg-margin-4-rem-lr { margin-left: 4rem; margin-right: 4rem; }
    .lg-margin-4-half-rem-lr { margin-left: 4.5rem; margin-right: 4.5rem; }
    .lg-margin-5-rem-lr { margin-left: 5rem; margin-right: 5rem; }
    .lg-margin-5-half-rem-lr { margin-left: 5.5rem; margin-right: 5.5rem; }
    .lg-margin-6-rem-lr { margin-left: 6rem; margin-right: 6rem; }
    .lg-margin-6-half-rem-lr { margin-left: 6.5rem; margin-right: 6.5rem; }
    .lg-margin-7-rem-lr { margin-left: 7rem; margin-right: 7rem; }
    .lg-margin-7-half-rem-lr { margin-left: 7.5rem; margin-right: 7.5rem; }
    .lg-margin-8-rem-lr { margin-left: 8rem; margin-right: 8rem; }
    .lg-margin-8-half-rem-lr { margin-left: 8.5rem; margin-right: 8.5rem; }
    .lg-margin-9-rem-lr { margin-left: 9rem; margin-right: 9rem; }
    .lg-margin-9-half-rem-lr { margin-left: 9.5rem; margin-right: 9.5rem; }
    .lg-margin-10-rem-lr { margin-left: 10rem; margin-right: 10rem; }
    .lg-margin-10-half-rem-lr { margin-left: 10.5rem; margin-right: 10.5rem; }

    /* margin top bottom */
    .lg-margin-one-tb { margin-top:1%; margin-bottom:1%; }
    .lg-margin-two-tb { margin-top:2%; margin-bottom:2%; }
    .lg-margin-three-tb { margin-top:3%; margin-bottom:3%; }
    .lg-margin-four-tb { margin-top:4%; margin-bottom:4%; }
    .lg-margin-five-tb { margin-top:5%; margin-bottom:5%; }
    .lg-margin-six-tb { margin-top:6%; margin-bottom:6%; }
    .lg-margin-seven-tb { margin-top:7%; margin-bottom:7%; }
    .lg-margin-eight-tb { margin-top:8%; margin-bottom:8%; }
    .lg-margin-nine-tb { margin-top:9%; margin-bottom:9%; }
    .lg-margin-ten-tb { margin-top:10%; margin-bottom:10%; }
    .lg-margin-eleven-tb { margin-top:11%; margin-bottom:11%; }
    .lg-margin-twelve-tb { margin-top:12%; margin-bottom:12%; }
    .lg-margin-thirteen-tb { margin-top:13%; margin-bottom:13%; }
    .lg-margin-fourteen-tb { margin-top:14%; margin-bottom:14%; }
    .lg-margin-fifteen-tb { margin-top:15%; margin-bottom:15%; }
    .lg-margin-sixteen-tb { margin-top:16%; margin-bottom:16%; }
    .lg-margin-seventeen-tb { margin-top:17%; margin-bottom:17%; }
    .lg-margin-eighteen-tb { margin-top:18%; margin-bottom:18%; }
    .lg-margin-nineteen-tb { margin-top:19%; margin-bottom:19%; }
    .lg-margin-twenty-tb { margin-top:20%; margin-bottom:20%; }
    .lg-margin-5px-tb { margin-top:5px; margin-bottom:5px; }
    .lg-margin-10px-tb { margin-top:10px; margin-bottom:10px; }
    .lg-margin-15px-tb { margin-top:15px; margin-bottom:15px; }
    .lg-margin-20px-tb { margin-top:20px; margin-bottom:20px; }
    .lg-margin-25px-tb { margin-top:25px; margin-bottom:25px; }
    .lg-margin-30px-tb { margin-top:30px; margin-bottom:30px; }
    .lg-margin-35px-tb { margin-top:35px; margin-bottom:35px; }
    .lg-margin-40px-tb { margin-top:40px; margin-bottom:40px; }
    .lg-margin-45px-tb { margin-top:45px; margin-bottom:45px; }
    .lg-margin-50px-tb { margin-top:50px; margin-bottom:50px; }
    .lg-margin-55px-tb { margin-top:55px; margin-bottom:55px; }
    .lg-margin-60px-tb { margin-top:60px; margin-bottom:60px; }
    .lg-margin-65px-tb { margin-top:65px; margin-bottom:65px; }
    .lg-margin-70px-tb { margin-top:70px; margin-bottom:70px; }
    .lg-margin-75px-tb { margin-top:75px; margin-bottom:75px; }
    .lg-margin-80px-tb { margin-top:80px; margin-bottom:80px; }
    .lg-margin-85px-tb { margin-top:85px; margin-bottom:85px; }
    .lg-margin-90px-tb { margin-top:90px; margin-bottom:90px; }
    .lg-margin-95px-tb { margin-top:95px; margin-bottom:95px; }
    .lg-margin-100px-tb { margin-top:100px; margin-bottom:100px; }
    .lg-margin-1-rem-tb { margin-top: 1rem; margin-bottom: 1rem; }
    .lg-margin-1-half-rem-tb { margin-top: 1.5rem; margin-bottom: 1.5rem; }
    .lg-margin-2-rem-tb { margin-top: 2rem; margin-bottom: 2rem; }
    .lg-margin-2-half-rem-tb { margin-top: 2.5rem; margin-bottom: 2.5rem; }
    .lg-margin-3-rem-tb { margin-top: 3rem; margin-bottom: 3rem; }
    .lg-margin-3-half-rem-tb { margin-top: 3.5rem; margin-bottom: 3.5rem; }
    .lg-margin-4-rem-tb { margin-top: 4rem; margin-bottom: 4rem; }
    .lg-margin-4-half-rem-tb { margin-top: 4.5rem; margin-bottom: 4.5rem; }
    .lg-margin-5-rem-tb { margin-top: 5rem; margin-bottom: 5rem; }
    .lg-margin-5-half-rem-tb { margin-top: 5.5rem; margin-bottom: 5.5rem; }
    .lg-margin-6-rem-tb { margin-top: 6rem; margin-bottom: 6rem; }
    .lg-margin-6-half-rem-tb { margin-top: 6.5rem; margin-bottom: 6.5rem; }
    .lg-margin-7-rem-tb { margin-top: 7rem; margin-bottom: 7rem; }
    .lg-margin-7-half-rem-tb { margin-top: 7.5rem; margin-bottom: 7.5rem; }
    .lg-margin-8-rem-tb { margin-top: 8rem; margin-bottom: 8rem; }
    .lg-margin-8-half-rem-tb { margin-top: 8.5rem; margin-bottom: 8.5rem; }
    .lg-margin-9-rem-tb { margin-top: 9rem; margin-bottom: 9rem; }
    .lg-margin-9-half-rem-tb { margin-top: 9.5rem; margin-bottom: 9.5rem; }
    .lg-margin-10-rem-tb { margin-top: 10rem; margin-bottom: 10rem; }
    .lg-margin-10-half-rem-tb { margin-top: 10.5rem; margin-bottom: 10.5rem; }

    .lg-margin-auto-lr { margin-left: auto !important; margin-right: auto !important; }
    .lg-margin-auto { margin: auto; }
    .lg-no-margin { margin: 0 !important; }
    .lg-no-margin-top { margin-top: 0 !important; }
    .lg-no-margin-bottom { margin-bottom: 0 !important; }
    .lg-no-margin-left { margin-left: 0 !important; }
    .lg-no-margin-right { margin-right: 0 !important; }
    .lg-no-margin-tb { margin-top: 0 !important; margin-bottom: 0 !important; }
    .lg-no-margin-lr { margin-right: 0 !important; margin-left: 0 !important; }

}

@media (max-width: 991px) {
    .md-margin-one-all { margin:1%; }
    .md-margin-two-all { margin:2%; }
    .md-margin-three-all { margin:3%; }
    .md-margin-four-all { margin:4%; }
    .md-margin-five-all { margin:5%; }
    .md-margin-six-all { margin:6%; }
    .md-margin-seven-all { margin:7%; }
    .md-margin-eight-all { margin:8%; }
    .md-margin-nine-all { margin:9%; }
    .md-margin-ten-all { margin:10%; }
    .md-margin-eleven-all { margin:11%; }
    .md-margin-twelve-all { margin:12%; }
    .md-margin-thirteen-all { margin:13%; }
    .md-margin-fourteen-all { margin:14%; }
    .md-margin-fifteen-all { margin:15%; }
    .md-margin-sixteen-all { margin:16%; }
    .md-margin-seventeen-all { margin:17%; }
    .md-margin-eighteen-all { margin:18%; }
    .md-margin-nineteen-all { margin:19%; }
    .md-margin-twenty-all { margin:20%; }
    .md-margin-5px-all { margin:5px; }
    .md-margin-10px-all { margin:10px; }
    .md-margin-15px-all { margin:15px; }
    .md-margin-20px-all { margin:20px; }
    .md-margin-25px-all { margin:25px; }
    .md-margin-30px-all { margin:30px; }
    .md-margin-35px-all { margin:35px; }
    .md-margin-40px-all { margin:40px; }
    .md-margin-45px-all { margin:45px; }
    .md-margin-50px-all { margin:50px; }
    .md-margin-55px-all { margin:55px; }
    .md-margin-60px-all { margin:60px; }
    .md-margin-65px-all { margin:65px; }
    .md-margin-70px-all { margin:70px; }
    .md-margin-75px-all { margin:75px; }
    .md-margin-80px-all { margin:80px; }
    .md-margin-85px-all { margin:85px; }
    .md-margin-90px-all { margin:90px; }
    .md-margin-95px-all { margin:95px; }
    .md-margin-100px-all { margin:100px; }
    .md-margin-1-rem-all { margin: 1rem; }
    .md-margin-1-half-rem-all { margin: 1.5rem; }
    .md-margin-2-rem-all { margin: 2rem; }
    .md-margin-2-half-rem-all { margin: 2.5rem; }
    .md-margin-3-rem-all { margin: 3rem; }
    .md-margin-3-half-rem-all { margin: 3.5rem; }
    .md-margin-4-rem-all { margin: 4rem; }
    .md-margin-4-half-rem-all { margin: 4.5rem; }
    .md-margin-5-rem-all { margin: 5rem; }
    .md-margin-5-half-rem-all { margin: 5.5rem; }
    .md-margin-6-rem-all { margin: 6rem; }
    .md-margin-6-half-rem-all { margin: 6.5rem; }
    .md-margin-7-rem-all { margin: 7rem; }
    .md-margin-7-half-rem-all { margin: 7.5rem; }
    .md-margin-8-rem-all { margin: 8rem; }
    .md-margin-8-half-rem-all { margin: 8.5rem; }
    .md-margin-9-rem-all { margin: 9rem; }
    .md-margin-9-half-rem-all { margin: 9.5rem; }
    .md-margin-10-rem-all { margin: 10rem; }
    .md-margin-10-half-rem-all { margin: 10.5rem; }

    /* margin top */
    .md-margin-one-top { margin-top:1%; }
    .md-margin-two-top { margin-top:2%; }
    .md-margin-three-top { margin-top:3%; }
    .md-margin-four-top { margin-top:4%; }
    .md-margin-five-top { margin-top:5%; }
    .md-margin-six-top { margin-top:6%; }
    .md-margin-seven-top { margin-top:7%; }
    .md-margin-eight-top { margin-top:8%; }
    .md-margin-nine-top { margin-top:9%; }
    .md-margin-ten-top { margin-top:10%; }
    .md-margin-eleven-top { margin-top:11%; }
    .md-margin-twelve-top { margin-top:12%; }
    .md-margin-thirteen-top { margin-top:13%; }
    .md-margin-fourteen-top { margin-top:14%; }
    .md-margin-fifteen-top { margin-top:15%; }
    .md-margin-sixteen-top { margin-top:16%; }
    .md-margin-seventeen-top { margin-top:17%; }
    .md-margin-eighteen-top { margin-top:18%; }
    .md-margin-nineteen-top { margin-top:19%; }
    .md-margin-twenty-top { margin-top:20%; }
    .md-margin-5px-top { margin-top:5px; }
    .md-margin-10px-top { margin-top:10px; }
    .md-margin-15px-top { margin-top:15px; }
    .md-margin-20px-top { margin-top:20px; }
    .md-margin-25px-top { margin-top:25px; }
    .md-margin-30px-top { margin-top:30px; }
    .md-margin-35px-top { margin-top:35px; }
    .md-margin-40px-top { margin-top:40px; }
    .md-margin-45px-top { margin-top:45px; }
    .md-margin-50px-top { margin-top:50px; }
    .md-margin-55px-top { margin-top:55px; }
    .md-margin-60px-top { margin-top:60px; }
    .md-margin-65px-top { margin-top:65px; }
    .md-margin-70px-top { margin-top:70px; }
    .md-margin-75px-top { margin-top:75px; }
    .md-margin-80px-top { margin-top:80px; }
    .md-margin-85px-top { margin-top:85px; }
    .md-margin-90px-top { margin-top:90px; }
    .md-margin-95px-top { margin-top:95px; }
    .md-margin-100px-top { margin-top:100px; }
    .md-margin-1-rem-top { margin-top: 1rem; }
    .md-margin-1-half-rem-top { margin-top: 1.5rem; }
    .md-margin-2-rem-top { margin-top: 2rem; }
    .md-margin-2-half-rem-top { margin-top: 2.5rem; }
    .md-margin-3-rem-top { margin-top: 3rem; }
    .md-margin-3-half-rem-top { margin-top: 3.5rem; }
    .md-margin-4-rem-top { margin-top: 4rem; }
    .md-margin-4-half-rem-top { margin-top: 4.5rem; }
    .md-margin-5-rem-top { margin-top: 5rem; }
    .md-margin-5-half-rem-top { margin-top: 5.5rem; }
    .md-margin-6-rem-top { margin-top: 6rem; }
    .md-margin-6-half-rem-top { margin-top: 6.5rem; }
    .md-margin-7-rem-top { margin-top: 7rem; }
    .md-margin-7-half-rem-top { margin-top: 7.5rem; }
    .md-margin-8-rem-top { margin-top: 8rem; }
    .md-margin-8-half-rem-top { margin-top: 8.5rem; }
    .md-margin-9-rem-top { margin-top: 9rem; }
    .md-margin-9-half-rem-top { margin-top: 9.5rem; }
    .md-margin-10-rem-top { margin-top: 10rem; }
    .md-margin-10-half-rem-top { margin-top: 10.5rem; }

    /* margin bottom */
    .md-margin-one-bottom { margin-bottom:1%; }
    .md-margin-two-bottom { margin-bottom:2%; }
    .md-margin-three-bottom { margin-bottom:3%; }
    .md-margin-four-bottom { margin-bottom:4%; }
    .md-margin-five-bottom { margin-bottom:5%; }
    .md-margin-six-bottom { margin-bottom:6%; }
    .md-margin-seven-bottom { margin-bottom:7%; }
    .md-margin-eight-bottom { margin-bottom:8%; }
    .md-margin-nine-bottom { margin-bottom:9%; }
    .md-margin-ten-bottom { margin-bottom:10%; }
    .md-margin-eleven-bottom { margin-bottom:11%; }
    .md-margin-twelve-bottom { margin-bottom:12%; }
    .md-margin-thirteen-bottom { margin-bottom:13%; }
    .md-margin-fourteen-bottom { margin-bottom:14%; }
    .md-margin-fifteen-bottom { margin-bottom:15%; }
    .md-margin-sixteen-bottom { margin-bottom:16%; }
    .md-margin-seventeen-bottom { margin-bottom:17%; }
    .md-margin-eighteen-bottom { margin-bottom:18%; }
    .md-margin-nineteen-bottom { margin-bottom:19%; }
    .md-margin-twenty-bottom { margin-bottom:20%; }
    .md-margin-5px-bottom { margin-bottom:5px; }
    .md-margin-10px-bottom { margin-bottom:10px; }
    .md-margin-15px-bottom { margin-bottom:15px; }
    .md-margin-20px-bottom { margin-bottom:20px; }
    .md-margin-25px-bottom { margin-bottom:25px; }
    .md-margin-30px-bottom { margin-bottom:30px; }
    .md-margin-35px-bottom { margin-bottom:35px; }
    .md-margin-40px-bottom { margin-bottom:40px; }
    .md-margin-45px-bottom { margin-bottom:45px; }
    .md-margin-50px-bottom { margin-bottom:50px; }
    .md-margin-55px-bottom { margin-bottom:55px; }
    .md-margin-60px-bottom { margin-bottom:60px; }
    .md-margin-65px-bottom { margin-bottom:65px; }
    .md-margin-70px-bottom { margin-bottom:70px; }
    .md-margin-75px-bottom { margin-bottom:75px; }
    .md-margin-80px-bottom { margin-bottom:80px; }
    .md-margin-85px-bottom { margin-bottom:85px; }
    .md-margin-90px-bottom { margin-bottom:90px; }
    .md-margin-95px-bottom { margin-bottom:95px; }
    .md-margin-100px-bottom { margin-bottom:100px; }
    .md-margin-1-rem-bottom { margin-bottom: 1rem; }
    .md-margin-1-half-rem-bottom { margin-bottom: 1.5rem; }
    .md-margin-2-rem-bottom { margin-bottom: 2rem; }
    .md-margin-2-half-rem-bottom { margin-bottom: 2.5rem; }
    .md-margin-3-rem-bottom { margin-bottom: 3rem; }
    .md-margin-3-half-rem-bottom { margin-bottom: 3.5rem; }
    .md-margin-4-rem-bottom { margin-bottom: 4rem; }
    .md-margin-4-half-rem-bottom { margin-bottom: 4.5rem; }
    .md-margin-5-rem-bottom { margin-bottom: 5rem; }
    .md-margin-5-half-rem-bottom { margin-bottom: 5.5rem; }
    .md-margin-6-rem-bottom { margin-bottom: 6rem; }
    .md-margin-6-half-rem-bottom { margin-bottom: 6.5rem; }
    .md-margin-7-rem-bottom { margin-bottom: 7rem; }
    .md-margin-7-half-rem-bottom { margin-bottom: 7.5rem; }
    .md-margin-8-rem-bottom { margin-bottom: 8rem; }
    .md-margin-8-half-rem-bottom { margin-bottom: 8.5rem; }
    .md-margin-9-rem-bottom { margin-bottom: 9rem; }
    .md-margin-9-half-rem-bottom { margin-bottom: 9.5rem; }
    .md-margin-10-rem-bottom { margin-bottom: 10rem; }
    .md-margin-10-half-rem-bottom { margin-bottom: 10.5rem; }

    /* margin right */
    .md-margin-one-right { margin-right:1%; }
    .md-margin-two-right { margin-right:2%; }
    .md-margin-three-right { margin-right:3%; }
    .md-margin-four-right { margin-right:4%; }
    .md-margin-five-right { margin-right:5%; }
    .md-margin-six-right { margin-right:6%; }
    .md-margin-seven-right { margin-right:7%; }
    .md-margin-eight-right { margin-right:8%; }
    .md-margin-nine-right { margin-right:9%; }
    .md-margin-ten-right { margin-right:10%; }
    .md-margin-eleven-right { margin-right:11%; }
    .md-margin-twelve-right { margin-right:12%; }
    .md-margin-thirteen-right { margin-right:13%; }
    .md-margin-fourteen-right { margin-right:14%; }
    .md-margin-fifteen-right { margin-right:15%; }
    .md-margin-sixteen-right { margin-right:16%; }
    .md-margin-seventeen-right { margin-right:17%; }
    .md-margin-eighteen-right { margin-right:18%; }
    .md-margin-nineteen-right { margin-right:19%; }
    .md-margin-twenty-right { margin-right:20%; }
    .md-margin-5px-right { margin-right:5px; }
    .md-margin-10px-right { margin-right:10px; }
    .md-margin-15px-right { margin-right:15px; }
    .md-margin-20px-right { margin-right:20px; }
    .md-margin-25px-right { margin-right:25px; }
    .md-margin-30px-right { margin-right:30px; }
    .md-margin-35px-right { margin-right:35px; }
    .md-margin-40px-right { margin-right:40px; }
    .md-margin-45px-right { margin-right:45px; }
    .md-margin-50px-right { margin-right:50px; }
    .md-margin-55px-right { margin-right:55px; }
    .md-margin-60px-right { margin-right:60px; }
    .md-margin-65px-right { margin-right:65px; }
    .md-margin-70px-right { margin-right:70px; }
    .md-margin-75px-right { margin-right:75px; }
    .md-margin-80px-right { margin-right:80px; }
    .md-margin-85px-right { margin-right:85px; }
    .md-margin-90px-right { margin-right:90px; }
    .md-margin-95px-right { margin-right:95px; }
    .md-margin-100px-right { margin-right:100px; }
    .md-margin-1-rem-right { margin-right: 1rem; }
    .md-margin-1-half-rem-right { margin-right: 1.5rem; }
    .md-margin-2-rem-right { margin-right: 2rem; }
    .md-margin-2-half-rem-right { margin-right: 2.5rem; }
    .md-margin-3-rem-right { margin-right: 3rem; }
    .md-margin-3-half-rem-right { margin-right: 3.5rem; }
    .md-margin-4-rem-right { margin-right: 4rem; }
    .md-margin-4-half-rem-right { margin-right: 4.5rem; }
    .md-margin-5-rem-right { margin-right: 5rem; }
    .md-margin-5-half-rem-right { margin-right: 5.5rem; }
    .md-margin-6-rem-right { margin-right: 6rem; }
    .md-margin-6-half-rem-right { margin-right: 6.5rem; }
    .md-margin-7-rem-right { margin-right: 7rem; }
    .md-margin-7-half-rem-right { margin-right: 7.5rem; }
    .md-margin-8-rem-right { margin-right: 8rem; }
    .md-margin-8-half-rem-right { margin-right: 8.5rem; }
    .md-margin-9-rem-right { margin-right: 9rem; }
    .md-margin-9-half-rem-right { margin-right: 9.5rem; }
    .md-margin-10-rem-right { margin-right: 10rem; }
    .md-margin-10-half-rem-right { margin-right: 10.5rem; }

    /* margin left */
    .md-margin-one-left { margin-left:1%; }
    .md-margin-two-left { margin-left:2%; }
    .md-margin-three-left { margin-left:3%; }
    .md-margin-four-left { margin-left:4%; }
    .md-margin-five-left { margin-left:5%; }
    .md-margin-six-left { margin-left:6%; }
    .md-margin-seven-left { margin-left:7%; }
    .md-margin-eight-left { margin-left:8%; }
    .md-margin-nine-left { margin-left:9%; }
    .md-margin-ten-left { margin-left:10%; }
    .md-margin-eleven-left { margin-left:11%; }
    .md-margin-twelve-left { margin-left:12%; }
    .md-margin-thirteen-left { margin-left:13%; }
    .md-margin-fourteen-left { margin-left:14%; }
    .md-margin-fifteen-left { margin-left:15%; }
    .md-margin-sixteen-left { margin-left:16%; }
    .md-margin-seventeen-left { margin-left:17%; }
    .md-margin-eighteen-left { margin-left:18%; }
    .md-margin-nineteen-left { margin-left:19%; }
    .md-margin-twenty-left { margin-left:20%; }
    .md-margin-5px-left { margin-left:5px; }
    .md-margin-10px-left { margin-left:10px; }
    .md-margin-15px-left { margin-left:15px; }
    .md-margin-20px-left { margin-left:20px; }
    .md-margin-25px-left { margin-left:25px; }
    .md-margin-30px-left { margin-left:30px; }
    .md-margin-35px-left { margin-left:35px; }
    .md-margin-40px-left { margin-left:40px; }
    .md-margin-45px-left { margin-left:45px; }
    .md-margin-50px-left { margin-left:50px; }
    .md-margin-55px-left { margin-left:55px; }
    .md-margin-60px-left { margin-left:60px; }
    .md-margin-65px-left { margin-left:65px; }
    .md-margin-70px-left { margin-left:70px; }
    .md-margin-75px-left { margin-left:75px; }
    .md-margin-80px-left { margin-left:80px; }
    .md-margin-85px-left { margin-left:85px; }
    .md-margin-90px-left { margin-left:90px; }
    .md-margin-95px-left { margin-left:95px; }
    .md-margin-100px-left { margin-left:100px; }
    .md-margin-1-rem-left { margin-left: 1rem; }
    .md-margin-1-half-rem-left { margin-left: 1.5rem; }
    .md-margin-2-rem-left { margin-left: 2rem; }
    .md-margin-2-half-rem-left { margin-left: 2.5rem; }
    .md-margin-3-rem-left { margin-left: 3rem; }
    .md-margin-3-half-rem-left { margin-left: 3.5rem; }
    .md-margin-4-rem-left { margin-left: 4rem; }
    .md-margin-4-half-rem-left { margin-left: 4.5rem; }
    .md-margin-5-rem-left { margin-left: 5rem; }
    .md-margin-5-half-rem-left { margin-left: 5.5rem; }
    .md-margin-6-rem-left { margin-left: 6rem; }
    .md-margin-6-half-rem-left { margin-left: 6.5rem; }
    .md-margin-7-rem-left { margin-left: 7rem; }
    .md-margin-7-half-rem-left { margin-left: 7.5rem; }
    .md-margin-8-rem-left { margin-left: 8rem; }
    .md-margin-8-half-rem-left { margin-left: 8.5rem; }
    .md-margin-9-rem-left { margin-left: 9rem; }
    .md-margin-9-half-rem-left { margin-left: 9.5rem; }
    .md-margin-10-rem-left { margin-left: 10rem; }
    .md-margin-10-half-rem-left { margin-left: 10.5rem; }

    /* margin left right */
    .md-margin-one-lr { margin-left:1%; margin-right:1%; }
    .md-margin-two-lr { margin-left:2%; margin-right:2%; }
    .md-margin-three-lr { margin-left:3%; margin-right:3%; }
    .md-margin-four-lr { margin-left:4%; margin-right:4%; }
    .md-margin-five-lr { margin-left:5%; margin-right:5%; }
    .md-margin-six-lr { margin-left:6%; margin-right:6%; }
    .md-margin-seven-lr { margin-left:7%; margin-right:7%; }
    .md-margin-eight-lr { margin-left:8%; margin-right:8%; }
    .md-margin-nine-lr { margin-left:9%; margin-right:9%; }
    .md-margin-ten-lr { margin-left:10%; margin-right:10%; }
    .md-margin-eleven-lr { margin-left:11%; margin-right:11%; }
    .md-margin-twelve-lr { margin-left:12%; margin-right:12%; }
    .md-margin-thirteen-lr { margin-left:13%; margin-right:13%; }
    .md-margin-fourteen-lr { margin-left:14%; margin-right:14%; }
    .md-margin-fifteen-lr { margin-left:15%; margin-right:15%; }
    .md-margin-sixteen-lr { margin-left:16%; margin-right:16%; }
    .md-margin-seventeen-lr { margin-left:17%; margin-right:17%; }
    .md-margin-eighteen-lr { margin-left:18%; margin-right:18%; }
    .md-margin-nineteen-lr { margin-left:19%; margin-right:19%; }
    .md-margin-twenty-lr { margin-left:20%; margin-right:20%; }
    .md-margin-5px-lr { margin-left:5px; margin-right:5px; }
    .md-margin-10px-lr { margin-left:10px; margin-right:10px; }
    .md-margin-15px-lr { margin-left:15px; margin-right:15px; }
    .md-margin-20px-lr { margin-left:20px; margin-right:20px; }
    .md-margin-25px-lr { margin-left:25px; margin-right:25px; }
    .md-margin-30px-lr { margin-left:30px; margin-right:30px; }
    .md-margin-35px-lr { margin-left:35px; margin-right:35px; }
    .md-margin-40px-lr { margin-left:40px; margin-right:40px; }
    .md-margin-45px-lr { margin-left:45px; margin-right:45px; }
    .md-margin-50px-lr { margin-left:50px; margin-right:50px; }
    .md-margin-55px-lr { margin-left:55px; margin-right:55px; }
    .md-margin-60px-lr { margin-left:60px; margin-right:60px; }
    .md-margin-65px-lr { margin-left:65px; margin-right:65px; }
    .md-margin-70px-lr { margin-left:70px; margin-right:70px; }
    .md-margin-75px-lr { margin-left:75px; margin-right:75px; }
    .md-margin-80px-lr { margin-left:80px; margin-right:80px; }
    .md-margin-85px-lr { margin-left:85px; margin-right:85px; }
    .md-margin-90px-lr { margin-left:90px; margin-right:90px; }
    .md-margin-95px-lr { margin-left:95px; margin-right:95px; }
    .md-margin-100px-lr { margin-left:100px; margin-right:100px; }
    .md-margin-1-rem-lr { margin-left: 1rem; margin-right: 1rem; }
    .md-margin-1-half-rem-lr { margin-left: 1.5rem; margin-right: 1.5rem; }
    .md-margin-2-rem-lr { margin-left: 2rem; margin-right: 2rem; }
    .md-margin-2-half-rem-lr { margin-left: 2.5rem; margin-right: 2.5rem; }
    .md-margin-3-rem-lr { margin-left: 3rem; margin-right: 3rem; }
    .md-margin-3-half-rem-lr { margin-left: 3.5rem; margin-right: 3.5rem; }
    .md-margin-4-rem-lr { margin-left: 4rem; margin-right: 4rem; }
    .md-margin-4-half-rem-lr { margin-left: 4.5rem; margin-right: 4.5rem; }
    .md-margin-5-rem-lr { margin-left: 5rem; margin-right: 5rem; }
    .md-margin-5-half-rem-lr { margin-left: 5.5rem; margin-right: 5.5rem; }
    .md-margin-6-rem-lr { margin-left: 6rem; margin-right: 6rem; }
    .md-margin-6-half-rem-lr { margin-left: 6.5rem; margin-right: 6.5rem; }
    .md-margin-7-rem-lr { margin-left: 7rem; margin-right: 7rem; }
    .md-margin-7-half-rem-lr { margin-left: 7.5rem; margin-right: 7.5rem; }
    .md-margin-8-rem-lr { margin-left: 8rem; margin-right: 8rem; }
    .md-margin-8-half-rem-lr { margin-left: 8.5rem; margin-right: 8.5rem; }
    .md-margin-9-rem-lr { margin-left: 9rem; margin-right: 9rem; }
    .md-margin-9-half-rem-lr { margin-left: 9.5rem; margin-right: 9.5rem; }
    .md-margin-10-rem-lr { margin-left: 10rem; margin-right: 10rem; }
    .md-margin-10-half-rem-lr { margin-left: 10.5rem; margin-right: 10.5rem; }

    /* margin top bottom */
    .md-margin-one-tb { margin-top:1%; margin-bottom:1%; }
    .md-margin-two-tb { margin-top:2%; margin-bottom:2%; }
    .md-margin-three-tb { margin-top:3%; margin-bottom:3%; }
    .md-margin-four-tb { margin-top:4%; margin-bottom:4%; }
    .md-margin-five-tb { margin-top:5%; margin-bottom:5%; }
    .md-margin-six-tb { margin-top:6%; margin-bottom:6%; }
    .md-margin-seven-tb { margin-top:7%; margin-bottom:7%; }
    .md-margin-eight-tb { margin-top:8%; margin-bottom:8%; }
    .md-margin-nine-tb { margin-top:9%; margin-bottom:9%; }
    .md-margin-ten-tb { margin-top:10%; margin-bottom:10%; }
    .md-margin-eleven-tb { margin-top:11%; margin-bottom:11%; }
    .md-margin-twelve-tb { margin-top:12%; margin-bottom:12%; }
    .md-margin-thirteen-tb { margin-top:13%; margin-bottom:13%; }
    .md-margin-fourteen-tb { margin-top:14%; margin-bottom:14%; }
    .md-margin-fifteen-tb { margin-top:15%; margin-bottom:15%; }
    .md-margin-sixteen-tb { margin-top:16%; margin-bottom:16%; }
    .md-margin-seventeen-tb { margin-top:17%; margin-bottom:17%; }
    .md-margin-eighteen-tb { margin-top:18%; margin-bottom:18%; }
    .md-margin-nineteen-tb { margin-top:19%; margin-bottom:19%; }
    .md-margin-twenty-tb { margin-top:20%; margin-bottom:20%; }
    .md-margin-5px-tb { margin-top:5px; margin-bottom:5px; }
    .md-margin-10px-tb { margin-top:10px; margin-bottom:10px; }
    .md-margin-15px-tb { margin-top:15px; margin-bottom:15px; }
    .md-margin-20px-tb { margin-top:20px; margin-bottom:20px; }
    .md-margin-25px-tb { margin-top:25px; margin-bottom:25px; }
    .md-margin-30px-tb { margin-top:30px; margin-bottom:30px; }
    .md-margin-35px-tb { margin-top:35px; margin-bottom:35px; }
    .md-margin-40px-tb { margin-top:40px; margin-bottom:40px; }
    .md-margin-45px-tb { margin-top:45px; margin-bottom:45px; }
    .md-margin-50px-tb { margin-top:50px; margin-bottom:50px; }
    .md-margin-55px-tb { margin-top:55px; margin-bottom:55px; }
    .md-margin-60px-tb { margin-top:60px; margin-bottom:60px; }
    .md-margin-65px-tb { margin-top:65px; margin-bottom:65px; }
    .md-margin-70px-tb { margin-top:70px; margin-bottom:70px; }
    .md-margin-75px-tb { margin-top:75px; margin-bottom:75px; }
    .md-margin-80px-tb { margin-top:80px; margin-bottom:80px; }
    .md-margin-85px-tb { margin-top:85px; margin-bottom:85px; }
    .md-margin-90px-tb { margin-top:90px; margin-bottom:90px; }
    .md-margin-95px-tb { margin-top:95px; margin-bottom:95px; }
    .md-margin-100px-tb { margin-top:100px; margin-bottom:100px; }
    .md-margin-1-rem-tb { margin-top: 1rem; margin-bottom: 1rem; }
    .md-margin-1-half-rem-tb { margin-top: 1.5rem; margin-bottom: 1.5rem; }
    .md-margin-2-rem-tb { margin-top: 2rem; margin-bottom: 2rem; }
    .md-margin-2-half-rem-tb { margin-top: 2.5rem; margin-bottom: 2.5rem; }
    .md-margin-3-rem-tb { margin-top: 3rem; margin-bottom: 3rem; }
    .md-margin-3-half-rem-tb { margin-top: 3.5rem; margin-bottom: 3.5rem; }
    .md-margin-4-rem-tb { margin-top: 4rem; margin-bottom: 4rem; }
    .md-margin-4-half-rem-tb { margin-top: 4.5rem; margin-bottom: 4.5rem; }
    .md-margin-5-rem-tb { margin-top: 5rem; margin-bottom: 5rem; }
    .md-margin-5-half-rem-tb { margin-top: 5.5rem; margin-bottom: 5.5rem; }
    .md-margin-6-rem-tb { margin-top: 6rem; margin-bottom: 6rem; }
    .md-margin-6-half-rem-tb { margin-top: 6.5rem; margin-bottom: 6.5rem; }
    .md-margin-7-rem-tb { margin-top: 7rem; margin-bottom: 7rem; }
    .md-margin-7-half-rem-tb { margin-top: 7.5rem; margin-bottom: 7.5rem; }
    .md-margin-8-rem-tb { margin-top: 8rem; margin-bottom: 8rem; }
    .md-margin-8-half-rem-tb { margin-top: 8.5rem; margin-bottom: 8.5rem; }
    .md-margin-9-rem-tb { margin-top: 9rem; margin-bottom: 9rem; }
    .md-margin-9-half-rem-tb { margin-top: 9.5rem; margin-bottom: 9.5rem; }
    .md-margin-10-rem-tb { margin-top: 10rem; margin-bottom: 10rem; }
    .md-margin-10-half-rem-tb { margin-top: 10.5rem; margin-bottom: 10.5rem; }

    .md-margin-auto-lr { margin-left: auto !important; margin-right: auto !important; }
    .md-margin-auto { margin: auto; }
    .md-no-margin { margin: 0 !important; }
    .md-no-margin-top { margin-top: 0 !important; }
    .md-no-margin-bottom { margin-bottom: 0 !important; }
    .md-no-margin-left { margin-left: 0 !important; }
    .md-no-margin-right { margin-right: 0 !important; }
    .md-no-margin-tb { margin-top: 0 !important; margin-bottom: 0 !important; }
    .md-no-margin-lr { margin-right: 0 !important; margin-left: 0 !important; }

}

@media (max-width: 767px) {
    .sm-margin-one-all { margin:1%; }
    .sm-margin-two-all { margin:2%; }
    .sm-margin-three-all { margin:3%; }
    .sm-margin-four-all { margin:4%; }
    .sm-margin-five-all { margin:5%; }
    .sm-margin-six-all { margin:6%; }
    .sm-margin-seven-all { margin:7%; }
    .sm-margin-eight-all { margin:8%; }
    .sm-margin-nine-all { margin:9%; }
    .sm-margin-ten-all { margin:10%; }
    .sm-margin-eleven-all { margin:11%; }
    .sm-margin-twelve-all { margin:12%; }
    .sm-margin-thirteen-all { margin:13%; }
    .sm-margin-fourteen-all { margin:14%; }
    .sm-margin-fifteen-all { margin:15%; }
    .sm-margin-sixteen-all { margin:16%; }
    .sm-margin-seventeen-all { margin:17%; }
    .sm-margin-eighteen-all { margin:18%; }
    .sm-margin-nineteen-all { margin:19%; }
    .sm-margin-twenty-all { margin:20%; }
    .sm-margin-5px-all { margin:5px; }
    .sm-margin-10px-all { margin:10px; }
    .sm-margin-15px-all { margin:15px; }
    .sm-margin-20px-all { margin:20px; }
    .sm-margin-25px-all { margin:25px; }
    .sm-margin-30px-all { margin:30px; }
    .sm-margin-35px-all { margin:35px; }
    .sm-margin-40px-all { margin:40px; }
    .sm-margin-45px-all { margin:45px; }
    .sm-margin-50px-all { margin:50px; }
    .sm-margin-55px-all { margin:55px; }
    .sm-margin-60px-all { margin:60px; }
    .sm-margin-65px-all { margin:65px; }
    .sm-margin-70px-all { margin:70px; }
    .sm-margin-75px-all { margin:75px; }
    .sm-margin-80px-all { margin:80px; }
    .sm-margin-85px-all { margin:85px; }
    .sm-margin-90px-all { margin:90px; }
    .sm-margin-95px-all { margin:95px; }
    .sm-margin-100px-all { margin:100px; }
    .sm-margin-1-rem-all { margin: 1rem; }
    .sm-margin-1-half-rem-all { margin: 1.5rem; }
    .sm-margin-2-rem-all { margin: 2rem; }
    .sm-margin-2-half-rem-all { margin: 2.5rem; }
    .sm-margin-3-rem-all { margin: 3rem; }
    .sm-margin-3-half-rem-all { margin: 3.5rem; }
    .sm-margin-4-rem-all { margin: 4rem; }
    .sm-margin-4-half-rem-all { margin: 4.5rem; }
    .sm-margin-5-rem-all { margin: 5rem; }
    .sm-margin-5-half-rem-all { margin: 5.5rem; }
    .sm-margin-6-rem-all { margin: 6rem; }
    .sm-margin-6-half-rem-all { margin: 6.5rem; }
    .sm-margin-7-rem-all { margin: 7rem; }
    .sm-margin-7-half-rem-all { margin: 7.5rem; }
    .sm-margin-8-rem-all { margin: 8rem; }
    .sm-margin-8-half-rem-all { margin: 8.5rem; }
    .sm-margin-9-rem-all { margin: 9rem; }
    .sm-margin-9-half-rem-all { margin: 9.5rem; }
    .sm-margin-10-rem-all { margin: 10rem; }
    .sm-margin-10-half-rem-all { margin: 10.5rem; }

    /* margin top */
    .sm-margin-one-top { margin-top:1%; }
    .sm-margin-two-top { margin-top:2%; }
    .sm-margin-three-top { margin-top:3%; }
    .sm-margin-four-top { margin-top:4%; }
    .sm-margin-five-top { margin-top:5%; }
    .sm-margin-six-top { margin-top:6%; }
    .sm-margin-seven-top { margin-top:7%; }
    .sm-margin-eight-top { margin-top:8%; }
    .sm-margin-nine-top { margin-top:9%; }
    .sm-margin-ten-top { margin-top:10%; }
    .sm-margin-eleven-top { margin-top:11%; }
    .sm-margin-twelve-top { margin-top:12%; }
    .sm-margin-thirteen-top { margin-top:13%; }
    .sm-margin-fourteen-top { margin-top:14%; }
    .sm-margin-fifteen-top { margin-top:15%; }
    .sm-margin-sixteen-top { margin-top:16%; }
    .sm-margin-seventeen-top { margin-top:17%; }
    .sm-margin-eighteen-top { margin-top:18%; }
    .sm-margin-nineteen-top { margin-top:19%; }
    .sm-margin-twenty-top { margin-top:20%; }
    .sm-margin-5px-top { margin-top:5px; }
    .sm-margin-10px-top { margin-top:10px; }
    .sm-margin-15px-top { margin-top:15px; }
    .sm-margin-20px-top { margin-top:20px; }
    .sm-margin-25px-top { margin-top:25px; }
    .sm-margin-30px-top { margin-top:30px; }
    .sm-margin-35px-top { margin-top:35px; }
    .sm-margin-40px-top { margin-top:40px; }
    .sm-margin-45px-top { margin-top:45px; }
    .sm-margin-50px-top { margin-top:50px; }
    .sm-margin-55px-top { margin-top:55px; }
    .sm-margin-60px-top { margin-top:60px; }
    .sm-margin-65px-top { margin-top:65px; }
    .sm-margin-70px-top { margin-top:70px; }
    .sm-margin-75px-top { margin-top:75px; }
    .sm-margin-80px-top { margin-top:80px; }
    .sm-margin-85px-top { margin-top:85px; }
    .sm-margin-90px-top { margin-top:90px; }
    .sm-margin-95px-top { margin-top:95px; }
    .sm-margin-100px-top { margin-top:100px; }
    .sm-margin-1-rem-top { margin-top: 1rem; }
    .sm-margin-1-half-rem-top { margin-top: 1.5rem; }
    .sm-margin-2-rem-top { margin-top: 2rem; }
    .sm-margin-2-half-rem-top { margin-top: 2.5rem; }
    .sm-margin-3-rem-top { margin-top: 3rem; }
    .sm-margin-3-half-rem-top { margin-top: 3.5rem; }
    .sm-margin-4-rem-top { margin-top: 4rem; }
    .sm-margin-4-half-rem-top { margin-top: 4.5rem; }
    .sm-margin-5-rem-top { margin-top: 5rem; }
    .sm-margin-5-half-rem-top { margin-top: 5.5rem; }
    .sm-margin-6-rem-top { margin-top: 6rem; }
    .sm-margin-6-half-rem-top { margin-top: 6.5rem; }
    .sm-margin-7-rem-top { margin-top: 7rem; }
    .sm-margin-7-half-rem-top { margin-top: 7.5rem; }
    .sm-margin-8-rem-top { margin-top: 8rem; }
    .sm-margin-8-half-rem-top { margin-top: 8.5rem; }
    .sm-margin-9-rem-top { margin-top: 9rem; }
    .sm-margin-9-half-rem-top { margin-top: 9.5rem; }
    .sm-margin-10-rem-top { margin-top: 10rem; }
    .sm-margin-10-half-rem-top { margin-top: 10.5rem; }

    /* margin bottom */
    .sm-margin-one-bottom { margin-bottom:1%; }
    .sm-margin-two-bottom { margin-bottom:2%; }
    .sm-margin-three-bottom { margin-bottom:3%; }
    .sm-margin-four-bottom { margin-bottom:4%; }
    .sm-margin-five-bottom { margin-bottom:5%; }
    .sm-margin-six-bottom { margin-bottom:6%; }
    .sm-margin-seven-bottom { margin-bottom:7%; }
    .sm-margin-eight-bottom { margin-bottom:8%; }
    .sm-margin-nine-bottom { margin-bottom:9%; }
    .sm-margin-ten-bottom { margin-bottom:10%; }
    .sm-margin-eleven-bottom { margin-bottom:11%; }
    .sm-margin-twelve-bottom { margin-bottom:12%; }
    .sm-margin-thirteen-bottom { margin-bottom:13%; }
    .sm-margin-fourteen-bottom { margin-bottom:14%; }
    .sm-margin-fifteen-bottom { margin-bottom:15%; }
    .sm-margin-sixteen-bottom { margin-bottom:16%; }
    .sm-margin-seventeen-bottom { margin-bottom:17%; }
    .sm-margin-eighteen-bottom { margin-bottom:18%; }
    .sm-margin-nineteen-bottom { margin-bottom:19%; }
    .sm-margin-twenty-bottom { margin-bottom:20%; }
    .sm-margin-5px-bottom { margin-bottom:5px; }
    .sm-margin-10px-bottom { margin-bottom:10px; }
    .sm-margin-15px-bottom { margin-bottom:15px; }
    .sm-margin-20px-bottom { margin-bottom:20px; }
    .sm-margin-25px-bottom { margin-bottom:25px; }
    .sm-margin-30px-bottom { margin-bottom:30px; }
    .sm-margin-35px-bottom { margin-bottom:35px; }
    .sm-margin-40px-bottom { margin-bottom:40px; }
    .sm-margin-45px-bottom { margin-bottom:45px; }
    .sm-margin-50px-bottom { margin-bottom:50px; }
    .sm-margin-55px-bottom { margin-bottom:55px; }
    .sm-margin-60px-bottom { margin-bottom:60px; }
    .sm-margin-65px-bottom { margin-bottom:65px; }
    .sm-margin-70px-bottom { margin-bottom:70px; }
    .sm-margin-75px-bottom { margin-bottom:75px; }
    .sm-margin-80px-bottom { margin-bottom:80px; }
    .sm-margin-85px-bottom { margin-bottom:85px; }
    .sm-margin-90px-bottom { margin-bottom:90px; }
    .sm-margin-95px-bottom { margin-bottom:95px; }
    .sm-margin-100px-bottom { margin-bottom:100px; }
    .sm-margin-1-rem-bottom { margin-bottom: 1rem; }
    .sm-margin-1-half-rem-bottom { margin-bottom: 1.5rem; }
    .sm-margin-2-rem-bottom { margin-bottom: 2rem; }
    .sm-margin-2-half-rem-bottom { margin-bottom: 2.5rem; }
    .sm-margin-3-rem-bottom { margin-bottom: 3rem; }
    .sm-margin-3-half-rem-bottom { margin-bottom: 3.5rem; }
    .sm-margin-4-rem-bottom { margin-bottom: 4rem; }
    .sm-margin-4-half-rem-bottom { margin-bottom: 4.5rem; }
    .sm-margin-5-rem-bottom { margin-bottom: 5rem; }
    .sm-margin-5-half-rem-bottom { margin-bottom: 5.5rem; }
    .sm-margin-6-rem-bottom { margin-bottom: 6rem; }
    .sm-margin-6-half-rem-bottom { margin-bottom: 6.5rem; }
    .sm-margin-7-rem-bottom { margin-bottom: 7rem; }
    .sm-margin-7-half-rem-bottom { margin-bottom: 7.5rem; }
    .sm-margin-8-rem-bottom { margin-bottom: 8rem; }
    .sm-margin-8-half-rem-bottom { margin-bottom: 8.5rem; }
    .sm-margin-9-rem-bottom { margin-bottom: 9rem; }
    .sm-margin-9-half-rem-bottom { margin-bottom: 9.5rem; }
    .sm-margin-10-rem-bottom { margin-bottom: 10rem; }
    .sm-margin-10-half-rem-bottom { margin-bottom: 10.5rem; }

    /* margin right */
    .sm-margin-one-right { margin-right:1%; }
    .sm-margin-two-right { margin-right:2%; }
    .sm-margin-three-right { margin-right:3%; }
    .sm-margin-four-right { margin-right:4%; }
    .sm-margin-five-right { margin-right:5%; }
    .sm-margin-six-right { margin-right:6%; }
    .sm-margin-seven-right { margin-right:7%; }
    .sm-margin-eight-right { margin-right:8%; }
    .sm-margin-nine-right { margin-right:9%; }
    .sm-margin-ten-right { margin-right:10%; }
    .sm-margin-eleven-right { margin-right:11%; }
    .sm-margin-twelve-right { margin-right:12%; }
    .sm-margin-thirteen-right { margin-right:13%; }
    .sm-margin-fourteen-right { margin-right:14%; }
    .sm-margin-fifteen-right { margin-right:15%; }
    .sm-margin-sixteen-right { margin-right:16%; }
    .sm-margin-seventeen-right { margin-right:17%; }
    .sm-margin-eighteen-right { margin-right:18%; }
    .sm-margin-nineteen-right { margin-right:19%; }
    .sm-margin-twenty-right { margin-right:20%; }
    .sm-margin-5px-right { margin-right:5px; }
    .sm-margin-10px-right { margin-right:10px; }
    .sm-margin-15px-right { margin-right:15px; }
    .sm-margin-20px-right { margin-right:20px; }
    .sm-margin-25px-right { margin-right:25px; }
    .sm-margin-30px-right { margin-right:30px; }
    .sm-margin-35px-right { margin-right:35px; }
    .sm-margin-40px-right { margin-right:40px; }
    .sm-margin-45px-right { margin-right:45px; }
    .sm-margin-50px-right { margin-right:50px; }
    .sm-margin-55px-right { margin-right:55px; }
    .sm-margin-60px-right { margin-right:60px; }
    .sm-margin-65px-right { margin-right:65px; }
    .sm-margin-70px-right { margin-right:70px; }
    .sm-margin-75px-right { margin-right:75px; }
    .sm-margin-80px-right { margin-right:80px; }
    .sm-margin-85px-right { margin-right:85px; }
    .sm-margin-90px-right { margin-right:90px; }
    .sm-margin-95px-right { margin-right:95px; }
    .sm-margin-100px-right { margin-right:100px; }
    .sm-margin-1-rem-right { margin-right: 1rem; }
    .sm-margin-1-half-rem-right { margin-right: 1.5rem; }
    .sm-margin-2-rem-right { margin-right: 2rem; }
    .sm-margin-2-half-rem-right { margin-right: 2.5rem; }
    .sm-margin-3-rem-right { margin-right: 3rem; }
    .sm-margin-3-half-rem-right { margin-right: 3.5rem; }
    .sm-margin-4-rem-right { margin-right: 4rem; }
    .sm-margin-4-half-rem-right { margin-right: 4.5rem; }
    .sm-margin-5-rem-right { margin-right: 5rem; }
    .sm-margin-5-half-rem-right { margin-right: 5.5rem; }
    .sm-margin-6-rem-right { margin-right: 6rem; }
    .sm-margin-6-half-rem-right { margin-right: 6.5rem; }
    .sm-margin-7-rem-right { margin-right: 7rem; }
    .sm-margin-7-half-rem-right { margin-right: 7.5rem; }
    .sm-margin-8-rem-right { margin-right: 8rem; }
    .sm-margin-8-half-rem-right { margin-right: 8.5rem; }
    .sm-margin-9-rem-right { margin-right: 9rem; }
    .sm-margin-9-half-rem-right { margin-right: 9.5rem; }
    .sm-margin-10-rem-right { margin-right: 10rem; }
    .sm-margin-10-half-rem-right { margin-right: 10.5rem; }

    /* margin left */
    .sm-margin-one-left { margin-left:1%; }
    .sm-margin-two-left { margin-left:2%; }
    .sm-margin-three-left { margin-left:3%; }
    .sm-margin-four-left { margin-left:4%; }
    .sm-margin-five-left { margin-left:5%; }
    .sm-margin-six-left { margin-left:6%; }
    .sm-margin-seven-left { margin-left:7%; }
    .sm-margin-eight-left { margin-left:8%; }
    .sm-margin-nine-left { margin-left:9%; }
    .sm-margin-ten-left { margin-left:10%; }
    .sm-margin-eleven-left { margin-left:11%; }
    .sm-margin-twelve-left { margin-left:12%; }
    .sm-margin-thirteen-left { margin-left:13%; }
    .sm-margin-fourteen-left { margin-left:14%; }
    .sm-margin-fifteen-left { margin-left:15%; }
    .sm-margin-sixteen-left { margin-left:16%; }
    .sm-margin-seventeen-left { margin-left:17%; }
    .sm-margin-eighteen-left { margin-left:18%; }
    .sm-margin-nineteen-left { margin-left:19%; }
    .sm-margin-twenty-left { margin-left:20%; }
    .sm-margin-5px-left { margin-left:5px; }
    .sm-margin-10px-left { margin-left:10px; }
    .sm-margin-15px-left { margin-left:15px; }
    .sm-margin-20px-left { margin-left:20px; }
    .sm-margin-25px-left { margin-left:25px; }
    .sm-margin-30px-left { margin-left:30px; }
    .sm-margin-35px-left { margin-left:35px; }
    .sm-margin-40px-left { margin-left:40px; }
    .sm-margin-45px-left { margin-left:45px; }
    .sm-margin-50px-left { margin-left:50px; }
    .sm-margin-55px-left { margin-left:55px; }
    .sm-margin-60px-left { margin-left:60px; }
    .sm-margin-65px-left { margin-left:65px; }
    .sm-margin-70px-left { margin-left:70px; }
    .sm-margin-75px-left { margin-left:75px; }
    .sm-margin-80px-left { margin-left:80px; }
    .sm-margin-85px-left { margin-left:85px; }
    .sm-margin-90px-left { margin-left:90px; }
    .sm-margin-95px-left { margin-left:95px; }
    .sm-margin-100px-left { margin-left:100px; }
    .sm-margin-1-rem-left { margin-left: 1rem; }
    .sm-margin-1-half-rem-left { margin-left: 1.5rem; }
    .sm-margin-2-rem-left { margin-left: 2rem; }
    .sm-margin-2-half-rem-left { margin-left: 2.5rem; }
    .sm-margin-3-rem-left { margin-left: 3rem; }
    .sm-margin-3-half-rem-left { margin-left: 3.5rem; }
    .sm-margin-4-rem-left { margin-left: 4rem; }
    .sm-margin-4-half-rem-left { margin-left: 4.5rem; }
    .sm-margin-5-rem-left { margin-left: 5rem; }
    .sm-margin-5-half-rem-left { margin-left: 5.5rem; }
    .sm-margin-6-rem-left { margin-left: 6rem; }
    .sm-margin-6-half-rem-left { margin-left: 6.5rem; }
    .sm-margin-7-rem-left { margin-left: 7rem; }
    .sm-margin-7-half-rem-left { margin-left: 7.5rem; }
    .sm-margin-8-rem-left { margin-left: 8rem; }
    .sm-margin-8-half-rem-left { margin-left: 8.5rem; }
    .sm-margin-9-rem-left { margin-left: 9rem; }
    .sm-margin-9-half-rem-left { margin-left: 9.5rem; }
    .sm-margin-10-rem-left { margin-left: 10rem; }
    .sm-margin-10-half-rem-left { margin-left: 10.5rem; }

    /* margin left right */
    .sm-margin-one-lr { margin-left:1%; margin-right:1%; }
    .sm-margin-two-lr { margin-left:2%; margin-right:2%; }
    .sm-margin-three-lr { margin-left:3%; margin-right:3%; }
    .sm-margin-four-lr { margin-left:4%; margin-right:4%; }
    .sm-margin-five-lr { margin-left:5%; margin-right:5%; }
    .sm-margin-six-lr { margin-left:6%; margin-right:6%; }
    .sm-margin-seven-lr { margin-left:7%; margin-right:7%; }
    .sm-margin-eight-lr { margin-left:8%; margin-right:8%; }
    .sm-margin-nine-lr { margin-left:9%; margin-right:9%; }
    .sm-margin-ten-lr { margin-left:10%; margin-right:10%; }
    .sm-margin-eleven-lr { margin-left:11%; margin-right:11%; }
    .sm-margin-twelve-lr { margin-left:12%; margin-right:12%; }
    .sm-margin-thirteen-lr { margin-left:13%; margin-right:13%; }
    .sm-margin-fourteen-lr { margin-left:14%; margin-right:14%; }
    .sm-margin-fifteen-lr { margin-left:15%; margin-right:15%; }
    .sm-margin-sixteen-lr { margin-left:16%; margin-right:16%; }
    .sm-margin-seventeen-lr { margin-left:17%; margin-right:17%; }
    .sm-margin-eighteen-lr { margin-left:18%; margin-right:18%; }
    .sm-margin-nineteen-lr { margin-left:19%; margin-right:19%; }
    .sm-margin-twenty-lr { margin-left:20%; margin-right:20%; }
    .sm-margin-5px-lr { margin-left:5px; margin-right:5px; }
    .sm-margin-10px-lr { margin-left:10px; margin-right:10px; }
    .sm-margin-15px-lr { margin-left:15px; margin-right:15px; }
    .sm-margin-20px-lr { margin-left:20px; margin-right:20px; }
    .sm-margin-25px-lr { margin-left:25px; margin-right:25px; }
    .sm-margin-30px-lr { margin-left:30px; margin-right:30px; }
    .sm-margin-35px-lr { margin-left:35px; margin-right:35px; }
    .sm-margin-40px-lr { margin-left:40px; margin-right:40px; }
    .sm-margin-45px-lr { margin-left:45px; margin-right:45px; }
    .sm-margin-50px-lr { margin-left:50px; margin-right:50px; }
    .sm-margin-55px-lr { margin-left:55px; margin-right:55px; }
    .sm-margin-60px-lr { margin-left:60px; margin-right:60px; }
    .sm-margin-65px-lr { margin-left:65px; margin-right:65px; }
    .sm-margin-70px-lr { margin-left:70px; margin-right:70px; }
    .sm-margin-75px-lr { margin-left:75px; margin-right:75px; }
    .sm-margin-80px-lr { margin-left:80px; margin-right:80px; }
    .sm-margin-85px-lr { margin-left:85px; margin-right:85px; }
    .sm-margin-90px-lr { margin-left:90px; margin-right:90px; }
    .sm-margin-95px-lr { margin-left:95px; margin-right:95px; }
    .sm-margin-100px-lr { margin-left:100px; margin-right:100px; }
    .sm-margin-1-rem-lr { margin-left: 1rem; margin-right: 1rem; }
    .sm-margin-1-half-rem-lr { margin-left: 1.5rem; margin-right: 1.5rem; }
    .sm-margin-2-rem-lr { margin-left: 2rem; margin-right: 2rem; }
    .sm-margin-2-half-rem-lr { margin-left: 2.5rem; margin-right: 2.5rem; }
    .sm-margin-3-rem-lr { margin-left: 3rem; margin-right: 3rem; }
    .sm-margin-3-half-rem-lr { margin-left: 3.5rem; margin-right: 3.5rem; }
    .sm-margin-4-rem-lr { margin-left: 4rem; margin-right: 4rem; }
    .sm-margin-4-half-rem-lr { margin-left: 4.5rem; margin-right: 4.5rem; }
    .sm-margin-5-rem-lr { margin-left: 5rem; margin-right: 5rem; }
    .sm-margin-5-half-rem-lr { margin-left: 5.5rem; margin-right: 5.5rem; }
    .sm-margin-6-rem-lr { margin-left: 6rem; margin-right: 6rem; }
    .sm-margin-6-half-rem-lr { margin-left: 6.5rem; margin-right: 6.5rem; }
    .sm-margin-7-rem-lr { margin-left: 7rem; margin-right: 7rem; }
    .sm-margin-7-half-rem-lr { margin-left: 7.5rem; margin-right: 7.5rem; }
    .sm-margin-8-rem-lr { margin-left: 8rem; margin-right: 8rem; }
    .sm-margin-8-half-rem-lr { margin-left: 8.5rem; margin-right: 8.5rem; }
    .sm-margin-9-rem-lr { margin-left: 9rem; margin-right: 9rem; }
    .sm-margin-9-half-rem-lr { margin-left: 9.5rem; margin-right: 9.5rem; }
    .sm-margin-10-rem-lr { margin-left: 10rem; margin-right: 10rem; }
    .sm-margin-10-half-rem-lr { margin-left: 10.5rem; margin-right: 10.5rem; }

    /* margin top bottom */
    .sm-margin-one-tb { margin-top:1%; margin-bottom:1%; }
    .sm-margin-two-tb { margin-top:2%; margin-bottom:2%; }
    .sm-margin-three-tb { margin-top:3%; margin-bottom:3%; }
    .sm-margin-four-tb { margin-top:4%; margin-bottom:4%; }
    .sm-margin-five-tb { margin-top:5%; margin-bottom:5%; }
    .sm-margin-six-tb { margin-top:6%; margin-bottom:6%; }
    .sm-margin-seven-tb { margin-top:7%; margin-bottom:7%; }
    .sm-margin-eight-tb { margin-top:8%; margin-bottom:8%; }
    .sm-margin-nine-tb { margin-top:9%; margin-bottom:9%; }
    .sm-margin-ten-tb { margin-top:10%; margin-bottom:10%; }
    .sm-margin-eleven-tb { margin-top:11%; margin-bottom:11%; }
    .sm-margin-twelve-tb { margin-top:12%; margin-bottom:12%; }
    .sm-margin-thirteen-tb { margin-top:13%; margin-bottom:13%; }
    .sm-margin-fourteen-tb { margin-top:14%; margin-bottom:14%; }
    .sm-margin-fifteen-tb { margin-top:15%; margin-bottom:15%; }
    .sm-margin-sixteen-tb { margin-top:16%; margin-bottom:16%; }
    .sm-margin-seventeen-tb { margin-top:17%; margin-bottom:17%; }
    .sm-margin-eighteen-tb { margin-top:18%; margin-bottom:18%; }
    .sm-margin-nineteen-tb { margin-top:19%; margin-bottom:19%; }
    .sm-margin-twenty-tb { margin-top:20%; margin-bottom:20%; }
    .sm-margin-5px-tb { margin-top:5px; margin-bottom:5px; }
    .sm-margin-10px-tb { margin-top:10px; margin-bottom:10px; }
    .sm-margin-15px-tb { margin-top:15px; margin-bottom:15px; }
    .sm-margin-20px-tb { margin-top:20px; margin-bottom:20px; }
    .sm-margin-25px-tb { margin-top:25px; margin-bottom:25px; }
    .sm-margin-30px-tb { margin-top:30px; margin-bottom:30px; }
    .sm-margin-35px-tb { margin-top:35px; margin-bottom:35px; }
    .sm-margin-40px-tb { margin-top:40px; margin-bottom:40px; }
    .sm-margin-45px-tb { margin-top:45px; margin-bottom:45px; }
    .sm-margin-50px-tb { margin-top:50px; margin-bottom:50px; }
    .sm-margin-55px-tb { margin-top:55px; margin-bottom:55px; }
    .sm-margin-60px-tb { margin-top:60px; margin-bottom:60px; }
    .sm-margin-65px-tb { margin-top:65px; margin-bottom:65px; }
    .sm-margin-70px-tb { margin-top:70px; margin-bottom:70px; }
    .sm-margin-75px-tb { margin-top:75px; margin-bottom:75px; }
    .sm-margin-80px-tb { margin-top:80px; margin-bottom:80px; }
    .sm-margin-85px-tb { margin-top:85px; margin-bottom:85px; }
    .sm-margin-90px-tb { margin-top:90px; margin-bottom:90px; }
    .sm-margin-95px-tb { margin-top:95px; margin-bottom:95px; }
    .sm-margin-100px-tb { margin-top:100px; margin-bottom:100px; }
    .sm-margin-1-rem-tb { margin-top: 1rem; margin-bottom: 1rem; }
    .sm-margin-1-half-rem-tb { margin-top: 1.5rem; margin-bottom: 1.5rem; }
    .sm-margin-2-rem-tb { margin-top: 2rem; margin-bottom: 2rem; }
    .sm-margin-2-half-rem-tb { margin-top: 2.5rem; margin-bottom: 2.5rem; }
    .sm-margin-3-rem-tb { margin-top: 3rem; margin-bottom: 3rem; }
    .sm-margin-3-half-rem-tb { margin-top: 3.5rem; margin-bottom: 3.5rem; }
    .sm-margin-4-rem-tb { margin-top: 4rem; margin-bottom: 4rem; }
    .sm-margin-4-half-rem-tb { margin-top: 4.5rem; margin-bottom: 4.5rem; }
    .sm-margin-5-rem-tb { margin-top: 5rem; margin-bottom: 5rem; }
    .sm-margin-5-half-rem-tb { margin-top: 5.5rem; margin-bottom: 5.5rem; }
    .sm-margin-6-rem-tb { margin-top: 6rem; margin-bottom: 6rem; }
    .sm-margin-6-half-rem-tb { margin-top: 6.5rem; margin-bottom: 6.5rem; }
    .sm-margin-7-rem-tb { margin-top: 7rem; margin-bottom: 7rem; }
    .sm-margin-7-half-rem-tb { margin-top: 7.5rem; margin-bottom: 7.5rem; }
    .sm-margin-8-rem-tb { margin-top: 8rem; margin-bottom: 8rem; }
    .sm-margin-8-half-rem-tb { margin-top: 8.5rem; margin-bottom: 8.5rem; }
    .sm-margin-9-rem-tb { margin-top: 9rem; margin-bottom: 9rem; }
    .sm-margin-9-half-rem-tb { margin-top: 9.5rem; margin-bottom: 9.5rem; }
    .sm-margin-10-rem-tb { margin-top: 10rem; margin-bottom: 10rem; }
    .sm-margin-10-half-rem-tb { margin-top: 10.5rem; margin-bottom: 10.5rem; }

    .sm-margin-auto-lr { margin-left: auto !important; margin-right: auto !important; }
    .sm-margin-auto { margin: auto; }
    .sm-no-margin { margin: 0 !important; }
    .sm-no-margin-top { margin-top: 0 !important; }
    .sm-no-margin-bottom { margin-bottom: 0 !important; }
    .sm-no-margin-left { margin-left: 0 !important; }
    .sm-no-margin-right { margin-right: 0 !important; }
    .sm-no-margin-tb { margin-top: 0 !important; margin-bottom: 0 !important; }
    .sm-no-margin-lr { margin-right: 0 !important; margin-left: 0 !important; }

}

@media screen and (max-width: 575px) {
    .xs-margin-one-all { margin:1%; }
    .xs-margin-two-all { margin:2%; }
    .xs-margin-three-all { margin:3%; }
    .xs-margin-four-all { margin:4%; }
    .xs-margin-five-all { margin:5%; }
    .xs-margin-six-all { margin:6%; }
    .xs-margin-seven-all { margin:7%; }
    .xs-margin-eight-all { margin:8%; }
    .xs-margin-nine-all { margin:9%; }
    .xs-margin-ten-all { margin:10%; }
    .xs-margin-eleven-all { margin:11%; }
    .xs-margin-twelve-all { margin:12%; }
    .xs-margin-thirteen-all { margin:13%; }
    .xs-margin-fourteen-all { margin:14%; }
    .xs-margin-fifteen-all { margin:15%; }
    .xs-margin-sixteen-all { margin:16%; }
    .xs-margin-seventeen-all { margin:17%; }
    .xs-margin-eighteen-all { margin:18%; }
    .xs-margin-nineteen-all { margin:19%; }
    .xs-margin-twenty-all { margin:20%; }
    .xs-margin-5px-all { margin:5px; }
    .xs-margin-10px-all { margin:10px; }
    .xs-margin-15px-all { margin:15px; }
    .xs-margin-20px-all { margin:20px; }
    .xs-margin-25px-all { margin:25px; }
    .xs-margin-30px-all { margin:30px; }
    .xs-margin-35px-all { margin:35px; }
    .xs-margin-40px-all { margin:40px; }
    .xs-margin-45px-all { margin:45px; }
    .xs-margin-50px-all { margin:50px; }
    .xs-margin-55px-all { margin:55px; }
    .xs-margin-60px-all { margin:60px; }
    .xs-margin-65px-all { margin:65px; }
    .xs-margin-70px-all { margin:70px; }
    .xs-margin-75px-all { margin:75px; }
    .xs-margin-80px-all { margin:80px; }
    .xs-margin-85px-all { margin:85px; }
    .xs-margin-90px-all { margin:90px; }
    .xs-margin-95px-all { margin:95px; }
    .xs-margin-100px-all { margin:100px; }
    .xs-margin-1-rem-all { margin: 1rem; }
    .xs-margin-1-half-rem-all { margin: 1.5rem; }
    .xs-margin-2-rem-all { margin: 2rem; }
    .xs-margin-2-half-rem-all { margin: 2.5rem; }
    .xs-margin-3-rem-all { margin: 3rem; }
    .xs-margin-3-half-rem-all { margin: 3.5rem; }
    .xs-margin-4-rem-all { margin: 4rem; }
    .xs-margin-4-half-rem-all { margin: 4.5rem; }
    .xs-margin-5-rem-all { margin: 5rem; }
    .xs-margin-5-half-rem-all { margin: 5.5rem; }
    .xs-margin-6-rem-all { margin: 6rem; }
    .xs-margin-6-half-rem-all { margin: 6.5rem; }
    .xs-margin-7-rem-all { margin: 7rem; }
    .xs-margin-7-half-rem-all { margin: 7.5rem; }
    .xs-margin-8-rem-all { margin: 8rem; }
    .xs-margin-8-half-rem-all { margin: 8.5rem; }
    .xs-margin-9-rem-all { margin: 9rem; }
    .xs-margin-9-half-rem-all { margin: 9.5rem; }
    .xs-margin-10-rem-all { margin: 10rem; }
    .xs-margin-10-half-rem-all { margin: 10.5rem; }

    /* margin top */
    .xs-margin-one-top { margin-top:1%; }
    .xs-margin-two-top { margin-top:2%; }
    .xs-margin-three-top { margin-top:3%; }
    .xs-margin-four-top { margin-top:4%; }
    .xs-margin-five-top { margin-top:5%; }
    .xs-margin-six-top { margin-top:6%; }
    .xs-margin-seven-top { margin-top:7%; }
    .xs-margin-eight-top { margin-top:8%; }
    .xs-margin-nine-top { margin-top:9%; }
    .xs-margin-ten-top { margin-top:10%; }
    .xs-margin-eleven-top { margin-top:11%; }
    .xs-margin-twelve-top { margin-top:12%; }
    .xs-margin-thirteen-top { margin-top:13%; }
    .xs-margin-fourteen-top { margin-top:14%; }
    .xs-margin-fifteen-top { margin-top:15%; }
    .xs-margin-sixteen-top { margin-top:16%; }
    .xs-margin-seventeen-top { margin-top:17%; }
    .xs-margin-eighteen-top { margin-top:18%; }
    .xs-margin-nineteen-top { margin-top:19%; }
    .xs-margin-twenty-top { margin-top:20%; }
    .xs-margin-5px-top { margin-top:5px; }
    .xs-margin-10px-top { margin-top:10px; }
    .xs-margin-15px-top { margin-top:15px; }
    .xs-margin-20px-top { margin-top:20px; }
    .xs-margin-25px-top { margin-top:25px; }
    .xs-margin-30px-top { margin-top:30px; }
    .xs-margin-35px-top { margin-top:35px; }
    .xs-margin-40px-top { margin-top:40px; }
    .xs-margin-45px-top { margin-top:45px; }
    .xs-margin-50px-top { margin-top:50px; }
    .xs-margin-55px-top { margin-top:55px; }
    .xs-margin-60px-top { margin-top:60px; }
    .xs-margin-65px-top { margin-top:65px; }
    .xs-margin-70px-top { margin-top:70px; }
    .xs-margin-75px-top { margin-top:75px; }
    .xs-margin-80px-top { margin-top:80px; }
    .xs-margin-85px-top { margin-top:85px; }
    .xs-margin-90px-top { margin-top:90px; }
    .xs-margin-95px-top { margin-top:95px; }
    .xs-margin-100px-top { margin-top:100px; }
    .xs-margin-1-rem-top { margin-top: 1rem; }
    .xs-margin-1-half-rem-top { margin-top: 1.5rem; }
    .xs-margin-2-rem-top { margin-top: 2rem; }
    .xs-margin-2-half-rem-top { margin-top: 2.5rem; }
    .xs-margin-3-rem-top { margin-top: 3rem; }
    .xs-margin-3-half-rem-top { margin-top: 3.5rem; }
    .xs-margin-4-rem-top { margin-top: 4rem; }
    .xs-margin-4-half-rem-top { margin-top: 4.5rem; }
    .xs-margin-5-rem-top { margin-top: 5rem; }
    .xs-margin-5-half-rem-top { margin-top: 5.5rem; }
    .xs-margin-6-rem-top { margin-top: 6rem; }
    .xs-margin-6-half-rem-top { margin-top: 6.5rem; }
    .xs-margin-7-rem-top { margin-top: 7rem; }
    .xs-margin-7-half-rem-top { margin-top: 7.5rem; }
    .xs-margin-8-rem-top { margin-top: 8rem; }
    .xs-margin-8-half-rem-top { margin-top: 8.5rem; }
    .xs-margin-9-rem-top { margin-top: 9rem; }
    .xs-margin-9-half-rem-top { margin-top: 9.5rem; }
    .xs-margin-10-rem-top { margin-top: 10rem; }
    .xs-margin-10-half-rem-top { margin-top: 10.5rem; }

    /* margin bottom */
    .xs-margin-one-bottom { margin-bottom:1%; }
    .xs-margin-two-bottom { margin-bottom:2%; }
    .xs-margin-three-bottom { margin-bottom:3%; }
    .xs-margin-four-bottom { margin-bottom:4%; }
    .xs-margin-five-bottom { margin-bottom:5%; }
    .xs-margin-six-bottom { margin-bottom:6%; }
    .xs-margin-seven-bottom { margin-bottom:7%; }
    .xs-margin-eight-bottom { margin-bottom:8%; }
    .xs-margin-nine-bottom { margin-bottom:9%; }
    .xs-margin-ten-bottom { margin-bottom:10%; }
    .xs-margin-eleven-bottom { margin-bottom:11%; }
    .xs-margin-twelve-bottom { margin-bottom:12%; }
    .xs-margin-thirteen-bottom { margin-bottom:13%; }
    .xs-margin-fourteen-bottom { margin-bottom:14%; }
    .xs-margin-fifteen-bottom { margin-bottom:15%; }
    .xs-margin-sixteen-bottom { margin-bottom:16%; }
    .xs-margin-seventeen-bottom { margin-bottom:17%; }
    .xs-margin-eighteen-bottom { margin-bottom:18%; }
    .xs-margin-nineteen-bottom { margin-bottom:19%; }
    .xs-margin-twenty-bottom { margin-bottom:20%; }
    .xs-margin-5px-bottom { margin-bottom:5px; }
    .xs-margin-10px-bottom { margin-bottom:10px; }
    .xs-margin-15px-bottom { margin-bottom:15px; }
    .xs-margin-20px-bottom { margin-bottom:20px; }
    .xs-margin-25px-bottom { margin-bottom:25px; }
    .xs-margin-30px-bottom { margin-bottom:30px; }
    .xs-margin-35px-bottom { margin-bottom:35px; }
    .xs-margin-40px-bottom { margin-bottom:40px; }
    .xs-margin-45px-bottom { margin-bottom:45px; }
    .xs-margin-50px-bottom { margin-bottom:50px; }
    .xs-margin-55px-bottom { margin-bottom:55px; }
    .xs-margin-60px-bottom { margin-bottom:60px; }
    .xs-margin-65px-bottom { margin-bottom:65px; }
    .xs-margin-70px-bottom { margin-bottom:70px; }
    .xs-margin-75px-bottom { margin-bottom:75px; }
    .xs-margin-80px-bottom { margin-bottom:80px; }
    .xs-margin-85px-bottom { margin-bottom:85px; }
    .xs-margin-90px-bottom { margin-bottom:90px; }
    .xs-margin-95px-bottom { margin-bottom:95px; }
    .xs-margin-100px-bottom { margin-bottom:100px; }
    .xs-margin-1-rem-bottom { margin-bottom: 1rem; }
    .xs-margin-1-half-rem-bottom { margin-bottom: 1.5rem; }
    .xs-margin-2-rem-bottom { margin-bottom: 2rem; }
    .xs-margin-2-half-rem-bottom { margin-bottom: 2.5rem; }
    .xs-margin-3-rem-bottom { margin-bottom: 3rem; }
    .xs-margin-3-half-rem-bottom { margin-bottom: 3.5rem; }
    .xs-margin-4-rem-bottom { margin-bottom: 4rem; }
    .xs-margin-4-half-rem-bottom { margin-bottom: 4.5rem; }
    .xs-margin-5-rem-bottom { margin-bottom: 5rem; }
    .xs-margin-5-half-rem-bottom { margin-bottom: 5.5rem; }
    .xs-margin-6-rem-bottom { margin-bottom: 6rem; }
    .xs-margin-6-half-rem-bottom { margin-bottom: 6.5rem; }
    .xs-margin-7-rem-bottom { margin-bottom: 7rem; }
    .xs-margin-7-half-rem-bottom { margin-bottom: 7.5rem; }
    .xs-margin-8-rem-bottom { margin-bottom: 8rem; }
    .xs-margin-8-half-rem-bottom { margin-bottom: 8.5rem; }
    .xs-margin-9-rem-bottom { margin-bottom: 9rem; }
    .xs-margin-9-half-rem-bottom { margin-bottom: 9.5rem; }
    .xs-margin-10-rem-bottom { margin-bottom: 10rem; }
    .xs-margin-10-half-rem-bottom { margin-bottom: 10.5rem; }

    /* margin right */
    .xs-margin-one-right { margin-right:1%; }
    .xs-margin-two-right { margin-right:2%; }
    .xs-margin-three-right { margin-right:3%; }
    .xs-margin-four-right { margin-right:4%; }
    .xs-margin-five-right { margin-right:5%; }
    .xs-margin-six-right { margin-right:6%; }
    .xs-margin-seven-right { margin-right:7%; }
    .xs-margin-eight-right { margin-right:8%; }
    .xs-margin-nine-right { margin-right:9%; }
    .xs-margin-ten-right { margin-right:10%; }
    .xs-margin-eleven-right { margin-right:11%; }
    .xs-margin-twelve-right { margin-right:12%; }
    .xs-margin-thirteen-right { margin-right:13%; }
    .xs-margin-fourteen-right { margin-right:14%; }
    .xs-margin-fifteen-right { margin-right:15%; }
    .xs-margin-sixteen-right { margin-right:16%; }
    .xs-margin-seventeen-right { margin-right:17%; }
    .xs-margin-eighteen-right { margin-right:18%; }
    .xs-margin-nineteen-right { margin-right:19%; }
    .xs-margin-twenty-right { margin-right:20%; }
    .xs-margin-5px-right { margin-right:5px; }
    .xs-margin-10px-right { margin-right:10px; }
    .xs-margin-15px-right { margin-right:15px; }
    .xs-margin-20px-right { margin-right:20px; }
    .xs-margin-25px-right { margin-right:25px; }
    .xs-margin-30px-right { margin-right:30px; }
    .xs-margin-35px-right { margin-right:35px; }
    .xs-margin-40px-right { margin-right:40px; }
    .xs-margin-45px-right { margin-right:45px; }
    .xs-margin-50px-right { margin-right:50px; }
    .xs-margin-55px-right { margin-right:55px; }
    .xs-margin-60px-right { margin-right:60px; }
    .xs-margin-65px-right { margin-right:65px; }
    .xs-margin-70px-right { margin-right:70px; }
    .xs-margin-75px-right { margin-right:75px; }
    .xs-margin-80px-right { margin-right:80px; }
    .xs-margin-85px-right { margin-right:85px; }
    .xs-margin-90px-right { margin-right:90px; }
    .xs-margin-95px-right { margin-right:95px; }
    .xs-margin-100px-right { margin-right:100px; }
    .xs-margin-1-rem-right { margin-right: 1rem; }
    .xs-margin-1-half-rem-right { margin-right: 1.5rem; }
    .xs-margin-2-rem-right { margin-right: 2rem; }
    .xs-margin-2-half-rem-right { margin-right: 2.5rem; }
    .xs-margin-3-rem-right { margin-right: 3rem; }
    .xs-margin-3-half-rem-right { margin-right: 3.5rem; }
    .xs-margin-4-rem-right { margin-right: 4rem; }
    .xs-margin-4-half-rem-right { margin-right: 4.5rem; }
    .xs-margin-5-rem-right { margin-right: 5rem; }
    .xs-margin-5-half-rem-right { margin-right: 5.5rem; }
    .xs-margin-6-rem-right { margin-right: 6rem; }
    .xs-margin-6-half-rem-right { margin-right: 6.5rem; }
    .xs-margin-7-rem-right { margin-right: 7rem; }
    .xs-margin-7-half-rem-right { margin-right: 7.5rem; }
    .xs-margin-8-rem-right { margin-right: 8rem; }
    .xs-margin-8-half-rem-right { margin-right: 8.5rem; }
    .xs-margin-9-rem-right { margin-right: 9rem; }
    .xs-margin-9-half-rem-right { margin-right: 9.5rem; }
    .xs-margin-10-rem-right { margin-right: 10rem; }
    .xs-margin-10-half-rem-right { margin-right: 10.5rem; }

    /* margin left */
    .xs-margin-one-left { margin-left:1%; }
    .xs-margin-two-left { margin-left:2%; }
    .xs-margin-three-left { margin-left:3%; }
    .xs-margin-four-left { margin-left:4%; }
    .xs-margin-five-left { margin-left:5%; }
    .xs-margin-six-left { margin-left:6%; }
    .xs-margin-seven-left { margin-left:7%; }
    .xs-margin-eight-left { margin-left:8%; }
    .xs-margin-nine-left { margin-left:9%; }
    .xs-margin-ten-left { margin-left:10%; }
    .xs-margin-eleven-left { margin-left:11%; }
    .xs-margin-twelve-left { margin-left:12%; }
    .xs-margin-thirteen-left { margin-left:13%; }
    .xs-margin-fourteen-left { margin-left:14%; }
    .xs-margin-fifteen-left { margin-left:15%; }
    .xs-margin-sixteen-left { margin-left:16%; }
    .xs-margin-seventeen-left { margin-left:17%; }
    .xs-margin-eighteen-left { margin-left:18%; }
    .xs-margin-nineteen-left { margin-left:19%; }
    .xs-margin-twenty-left { margin-left:20%; }
    .xs-margin-5px-left { margin-left:5px; }
    .xs-margin-10px-left { margin-left:10px; }
    .xs-margin-15px-left { margin-left:15px; }
    .xs-margin-20px-left { margin-left:20px; }
    .xs-margin-25px-left { margin-left:25px; }
    .xs-margin-30px-left { margin-left:30px; }
    .xs-margin-35px-left { margin-left:35px; }
    .xs-margin-40px-left { margin-left:40px; }
    .xs-margin-45px-left { margin-left:45px; }
    .xs-margin-50px-left { margin-left:50px; }
    .xs-margin-55px-left { margin-left:55px; }
    .xs-margin-60px-left { margin-left:60px; }
    .xs-margin-65px-left { margin-left:65px; }
    .xs-margin-70px-left { margin-left:70px; }
    .xs-margin-75px-left { margin-left:75px; }
    .xs-margin-80px-left { margin-left:80px; }
    .xs-margin-85px-left { margin-left:85px; }
    .xs-margin-90px-left { margin-left:90px; }
    .xs-margin-95px-left { margin-left:95px; }
    .xs-margin-100px-left { margin-left:100px; }
    .xs-margin-1-rem-left { margin-left: 1rem; }
    .xs-margin-1-half-rem-left { margin-left: 1.5rem; }
    .xs-margin-2-rem-left { margin-left: 2rem; }
    .xs-margin-2-half-rem-left { margin-left: 2.5rem; }
    .xs-margin-3-rem-left { margin-left: 3rem; }
    .xs-margin-3-half-rem-left { margin-left: 3.5rem; }
    .xs-margin-4-rem-left { margin-left: 4rem; }
    .xs-margin-4-half-rem-left { margin-left: 4.5rem; }
    .xs-margin-5-rem-left { margin-left: 5rem; }
    .xs-margin-5-half-rem-left { margin-left: 5.5rem; }
    .xs-margin-6-rem-left { margin-left: 6rem; }
    .xs-margin-6-half-rem-left { margin-left: 6.5rem; }
    .xs-margin-7-rem-left { margin-left: 7rem; }
    .xs-margin-7-half-rem-left { margin-left: 7.5rem; }
    .xs-margin-8-rem-left { margin-left: 8rem; }
    .xs-margin-8-half-rem-left { margin-left: 8.5rem; }
    .xs-margin-9-rem-left { margin-left: 9rem; }
    .xs-margin-9-half-rem-left { margin-left: 9.5rem; }
    .xs-margin-10-rem-left { margin-left: 10rem; }
    .xs-margin-10-half-rem-left { margin-left: 10.5rem; }

    /* margin left right */
    .xs-margin-one-lr { margin-left:1%; margin-right:1%; }
    .xs-margin-two-lr { margin-left:2%; margin-right:2%; }
    .xs-margin-three-lr { margin-left:3%; margin-right:3%; }
    .xs-margin-four-lr { margin-left:4%; margin-right:4%; }
    .xs-margin-five-lr { margin-left:5%; margin-right:5%; }
    .xs-margin-six-lr { margin-left:6%; margin-right:6%; }
    .xs-margin-seven-lr { margin-left:7%; margin-right:7%; }
    .xs-margin-eight-lr { margin-left:8%; margin-right:8%; }
    .xs-margin-nine-lr { margin-left:9%; margin-right:9%; }
    .xs-margin-ten-lr { margin-left:10%; margin-right:10%; }
    .xs-margin-eleven-lr { margin-left:11%; margin-right:11%; }
    .xs-margin-twelve-lr { margin-left:12%; margin-right:12%; }
    .xs-margin-thirteen-lr { margin-left:13%; margin-right:13%; }
    .xs-margin-fourteen-lr { margin-left:14%; margin-right:14%; }
    .xs-margin-fifteen-lr { margin-left:15%; margin-right:15%; }
    .xs-margin-sixteen-lr { margin-left:16%; margin-right:16%; }
    .xs-margin-seventeen-lr { margin-left:17%; margin-right:17%; }
    .xs-margin-eighteen-lr { margin-left:18%; margin-right:18%; }
    .xs-margin-nineteen-lr { margin-left:19%; margin-right:19%; }
    .xs-margin-twenty-lr { margin-left:20%; margin-right:20%; }
    .xs-margin-5px-lr { margin-left:5px; margin-right:5px; }
    .xs-margin-10px-lr { margin-left:10px; margin-right:10px; }
    .xs-margin-15px-lr { margin-left:15px; margin-right:15px; }
    .xs-margin-20px-lr { margin-left:20px; margin-right:20px; }
    .xs-margin-25px-lr { margin-left:25px; margin-right:25px; }
    .xs-margin-30px-lr { margin-left:30px; margin-right:30px; }
    .xs-margin-35px-lr { margin-left:35px; margin-right:35px; }
    .xs-margin-40px-lr { margin-left:40px; margin-right:40px; }
    .xs-margin-45px-lr { margin-left:45px; margin-right:45px; }
    .xs-margin-50px-lr { margin-left:50px; margin-right:50px; }
    .xs-margin-55px-lr { margin-left:55px; margin-right:55px; }
    .xs-margin-60px-lr { margin-left:60px; margin-right:60px; }
    .xs-margin-65px-lr { margin-left:65px; margin-right:65px; }
    .xs-margin-70px-lr { margin-left:70px; margin-right:70px; }
    .xs-margin-75px-lr { margin-left:75px; margin-right:75px; }
    .xs-margin-80px-lr { margin-left:80px; margin-right:80px; }
    .xs-margin-85px-lr { margin-left:85px; margin-right:85px; }
    .xs-margin-90px-lr { margin-left:90px; margin-right:90px; }
    .xs-margin-95px-lr { margin-left:95px; margin-right:95px; }
    .xs-margin-100px-lr { margin-left:100px; margin-right:100px; }
    .xs-margin-1-rem-lr { margin-left: 1rem; margin-right: 1rem; }
    .xs-margin-1-half-rem-lr { margin-left: 1.5rem; margin-right: 1.5rem; }
    .xs-margin-2-rem-lr { margin-left: 2rem; margin-right: 2rem; }
    .xs-margin-2-half-rem-lr { margin-left: 2.5rem; margin-right: 2.5rem; }
    .xs-margin-3-rem-lr { margin-left: 3rem; margin-right: 3rem; }
    .xs-margin-3-half-rem-lr { margin-left: 3.5rem; margin-right: 3.5rem; }
    .xs-margin-4-rem-lr { margin-left: 4rem; margin-right: 4rem; }
    .xs-margin-4-half-rem-lr { margin-left: 4.5rem; margin-right: 4.5rem; }
    .xs-margin-5-rem-lr { margin-left: 5rem; margin-right: 5rem; }
    .xs-margin-5-half-rem-lr { margin-left: 5.5rem; margin-right: 5.5rem; }
    .xs-margin-6-rem-lr { margin-left: 6rem; margin-right: 6rem; }
    .xs-margin-6-half-rem-lr { margin-left: 6.5rem; margin-right: 6.5rem; }
    .xs-margin-7-rem-lr { margin-left: 7rem; margin-right: 7rem; }
    .xs-margin-7-half-rem-lr { margin-left: 7.5rem; margin-right: 7.5rem; }
    .xs-margin-8-rem-lr { margin-left: 8rem; margin-right: 8rem; }
    .xs-margin-8-half-rem-lr { margin-left: 8.5rem; margin-right: 8.5rem; }
    .xs-margin-9-rem-lr { margin-left: 9rem; margin-right: 9rem; }
    .xs-margin-9-half-rem-lr { margin-left: 9.5rem; margin-right: 9.5rem; }
    .xs-margin-10-rem-lr { margin-left: 10rem; margin-right: 10rem; }
    .xs-margin-10-half-rem-lr { margin-left: 10.5rem; margin-right: 10.5rem; }

    /* margin top bottom */
    .xs-margin-one-tb { margin-top:1%; margin-bottom:1%; }
    .xs-margin-two-tb { margin-top:2%; margin-bottom:2%; }
    .xs-margin-three-tb { margin-top:3%; margin-bottom:3%; }
    .xs-margin-four-tb { margin-top:4%; margin-bottom:4%; }
    .xs-margin-five-tb { margin-top:5%; margin-bottom:5%; }
    .xs-margin-six-tb { margin-top:6%; margin-bottom:6%; }
    .xs-margin-seven-tb { margin-top:7%; margin-bottom:7%; }
    .xs-margin-eight-tb { margin-top:8%; margin-bottom:8%; }
    .xs-margin-nine-tb { margin-top:9%; margin-bottom:9%; }
    .xs-margin-ten-tb { margin-top:10%; margin-bottom:10%; }
    .xs-margin-eleven-tb { margin-top:11%; margin-bottom:11%; }
    .xs-margin-twelve-tb { margin-top:12%; margin-bottom:12%; }
    .xs-margin-thirteen-tb { margin-top:13%; margin-bottom:13%; }
    .xs-margin-fourteen-tb { margin-top:14%; margin-bottom:14%; }
    .xs-margin-fifteen-tb { margin-top:15%; margin-bottom:15%; }
    .xs-margin-sixteen-tb { margin-top:16%; margin-bottom:16%; }
    .xs-margin-seventeen-tb { margin-top:17%; margin-bottom:17%; }
    .xs-margin-eighteen-tb { margin-top:18%; margin-bottom:18%; }
    .xs-margin-nineteen-tb { margin-top:19%; margin-bottom:19%; }
    .xs-margin-twenty-tb { margin-top:20%; margin-bottom:20%; }
    .xs-margin-5px-tb { margin-top:5px; margin-bottom:5px; }
    .xs-margin-10px-tb { margin-top:10px; margin-bottom:10px; }
    .xs-margin-15px-tb { margin-top:15px; margin-bottom:15px; }
    .xs-margin-20px-tb { margin-top:20px; margin-bottom:20px; }
    .xs-margin-25px-tb { margin-top:25px; margin-bottom:25px; }
    .xs-margin-30px-tb { margin-top:30px; margin-bottom:30px; }
    .xs-margin-35px-tb { margin-top:35px; margin-bottom:35px; }
    .xs-margin-40px-tb { margin-top:40px; margin-bottom:40px; }
    .xs-margin-45px-tb { margin-top:45px; margin-bottom:45px; }
    .xs-margin-50px-tb { margin-top:50px; margin-bottom:50px; }
    .xs-margin-55px-tb { margin-top:55px; margin-bottom:55px; }
    .xs-margin-60px-tb { margin-top:60px; margin-bottom:60px; }
    .xs-margin-65px-tb { margin-top:65px; margin-bottom:65px; }
    .xs-margin-70px-tb { margin-top:70px; margin-bottom:70px; }
    .xs-margin-75px-tb { margin-top:75px; margin-bottom:75px; }
    .xs-margin-80px-tb { margin-top:80px; margin-bottom:80px; }
    .xs-margin-85px-tb { margin-top:85px; margin-bottom:85px; }
    .xs-margin-90px-tb { margin-top:90px; margin-bottom:90px; }
    .xs-margin-95px-tb { margin-top:95px; margin-bottom:95px; }
    .xs-margin-100px-tb { margin-top:100px; margin-bottom:100px; }
    .xs-margin-1-rem-tb { margin-top: 1rem; margin-bottom: 1rem; }
    .xs-margin-1-half-rem-tb { margin-top: 1.5rem; margin-bottom: 1.5rem; }
    .xs-margin-2-rem-tb { margin-top: 2rem; margin-bottom: 2rem; }
    .xs-margin-2-half-rem-tb { margin-top: 2.5rem; margin-bottom: 2.5rem; }
    .xs-margin-3-rem-tb { margin-top: 3rem; margin-bottom: 3rem; }
    .xs-margin-3-half-rem-tb { margin-top: 3.5rem; margin-bottom: 3.5rem; }
    .xs-margin-4-rem-tb { margin-top: 4rem; margin-bottom: 4rem; }
    .xs-margin-4-half-rem-tb { margin-top: 4.5rem; margin-bottom: 4.5rem; }
    .xs-margin-5-rem-tb { margin-top: 5rem; margin-bottom: 5rem; }
    .xs-margin-5-half-rem-tb { margin-top: 5.5rem; margin-bottom: 5.5rem; }
    .xs-margin-6-rem-tb { margin-top: 6rem; margin-bottom: 6rem; }
    .xs-margin-6-half-rem-tb { margin-top: 6.5rem; margin-bottom: 6.5rem; }
    .xs-margin-7-rem-tb { margin-top: 7rem; margin-bottom: 7rem; }
    .xs-margin-7-half-rem-tb { margin-top: 7.5rem; margin-bottom: 7.5rem; }
    .xs-margin-8-rem-tb { margin-top: 8rem; margin-bottom: 8rem; }
    .xs-margin-8-half-rem-tb { margin-top: 8.5rem; margin-bottom: 8.5rem; }
    .xs-margin-9-rem-tb { margin-top: 9rem; margin-bottom: 9rem; }
    .xs-margin-9-half-rem-tb { margin-top: 9.5rem; margin-bottom: 9.5rem; }
    .xs-margin-10-rem-tb { margin-top: 10rem; margin-bottom: 10rem; }
    .xs-margin-10-half-rem-tb { margin-top: 10.5rem; margin-bottom: 10.5rem; }

    .xs-margin-auto-lr { margin-left: auto !important; margin-right: auto !important }
    .xs-margin-auto { margin: auto; }
    .xs-no-margin { margin: 0 !important; }
    .xs-no-margin-top { margin-top: 0 !important; }
    .xs-no-margin-bottom { margin-bottom: 0 !important; }
    .xs-no-margin-left { margin-left: 0 !important; }
    .xs-no-margin-right { margin-right: 0 !important; }
    .xs-no-margin-tb { margin-top: 0 !important; margin-bottom: 0 !important; }
    .xs-no-margin-lr { margin-right: 0 !important; margin-left: 0 !important; }
}
.select-input__control{
    border-radius: 0px !important;
    border: solid 2px $purple !important;
    outline: unset !important;
    box-shadow: unset !important;
    &:focus{
        outline: unset !important;
        box-shadow: unset !important;
        border-color: $green !important;
    }
}
.select-input__input-container{
    padding: 4px 8px !important;

}

.select-input__option{
    &:active, &:hover, &:visited, &:focus{
        background-color: $purple !important;
        color: $white !important;
    }
    &:hover{
        opacity: 0.5;
    }
}
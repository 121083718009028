/* ===================================
    15. Height
====================================== */

.swiper-container {
    width: 100%;
    height: 100%;
}
  
.h-1px {
    height: 1px;
}
  
.h-2px {
    height: 2px;
}
  
.h-3px {
    height: 3px;
}
  
.h-4px {
    height: 4px;
}
  
.h-5px {
    height: 5px;
}
  
.h-6px {
    height: 6px;
}
  
.h-7px {
    height: 7px;
}
  
.h-8px {
    height: 8px;
}
  
.h-9px {
    height: 9px;
}
  
.h-10px {
    height: 10px;
}
  
.h-15px {
    height: 15px;
}
  
.h-20px {
    height: 20px;
}
  
.h-25px {
    height: 25px;
}
  
.h-30px {
    height: 30px;
}
  
.h-35px {
    height: 35px;
}
  
.h-40px {
    height: 40px;
}
  
.h-42px {
    height: 42px;
}
  
.h-45px {
    height: 45px;
}
  
.h-50px {
    height: 50px;
}
  
.h-55px {
    height: 55px;
}
  
.h-60px {
    height: 60px;
}
  
.h-65px {
    height: 65px;
}
  
.h-70px {
    height: 70px;
}
  
.h-75px {
    height: 75px;
}
  
.h-80px {
    height: 80px;
}
  
.h-85px {
    height: 85px;
}
  
.h-90px {
    height: 90px;
}
  
.h-95px {
    height: 95px;
}
  
.h-100px {
    height: 100px;
}
  
.h-110px {
    height: 110px;
}
  
.h-120px {
    height: 120px;
}
  
.h-130px {
    height: 130px;
}
  
.h-140px {
    height: 140px;
}
  
.h-150px {
    height: 150px;
}
  
.h-160px {
    height: 160px;
}
  
.h-170px {
    height: 170px;
}
  
.h-180px {
    height: 180px;
}
  
.h-190px {
    height: 190px;
}
  
.h-200px {
    height: 200px;
}
  
.h-250px {
    height: 250px;
}
  
.h-300px {
    height: 300px;
}
  
.h-350px {
    height: 350px;
}
  
.h-400px {
    height: 400px;
}
  
.h-450px {
    height: 450px;
}
  
.h-480px {
    height: 490px;
}
  
.h-500px {
    height: 500px;
}
  
.h-520px {
    height: 520px;
}
  
.h-550px {
    height: 550px;
}
  
.h-580px {
    height: 580px;
}
  
.h-600px {
    height: 600px;
}
  
.h-650px {
    height: 650px;
}
  
.h-700px {
    height: 700px;
}
  
.h-720px {
    height: 720px;
}
  
.h-750px {
    height: 750px;
}
  
.h-800px {
    height: 800px;
}
  
.h-820px {
    height: 820px;
}
  
.h-830px {
    height: 830px;
}
  
.h-850px {
    height: 850px;
}
  
.h-50 {
    height: 50%;
}
  
.h-90 {
    height: 90% !important;
}
.h-100 {
    height: 100% !important;
}
  
.h-auto {
    height: auto !important;
}
  
.max-h-100 {
    max-height: 100%;
}
  
.max-h-inherit {
    max-height: inherit !important;
}
  
.full-screen, .mobileoff-fullscreen-top-space, .fullscreenabanner {
    height: 980px;
}
  
  /* min height */
  
.min-h-100px {
    min-height: 100px;
}
  
.min-h-200px {
    min-height: 200px;
}
  
.min-h-300px {
    min-height: 300px;
}
  
.min-h-400px {
    min-height: 400px;
}
  
.min-h-500px {
    min-height: 500px;
}
  
.min-h-600px {
    min-height: 600px;
}
  
.min-h-700px {
    min-height: 700px;
}
  
.min-h-100vh {
    min-height: 100vh;
}
  
  /* screen height */
  
.extra-small-screen {
    height: 300px;
}
  
.small-screen {
    height: 400px;
}
  
.one-half-screen {
    height: 600px;
}
  
.one-third-screen {
    height: 700px;
}
  
.one-fourth-screen {
    height: 800px;
}
  
.one-fifth-screen {
    height: 900px;
}
  
  /* content box image */
  
.content-box-image {
    width: 100%;
    height: -webkit-calc(100% / 3);
    height: -moz-calc(100% / 3);
    height: calc(100% / 3);
}

/* RESPONSIVE */

@media (max-width: 1600px) {
    .xl-h-1px { height: 1px !important; }
    .xl-h-2px { height: 2px !important; }
    .xl-h-3px { height: 3px !important; }
    .xl-h-4px { height: 4px !important; }
    .xl-h-5px { height: 5px !important; }
    .xl-h-6px { height: 6px !important; }
    .xl-h-7px { height: 7px !important; }
    .xl-h-8px { height: 8px !important; }
    .xl-h-9px { height: 9px !important; }
    .xl-h-10px { height: 10px !important; }
    .xl-h-20px { height: 20px !important; }
    .xl-h-30px { height: 30px !important; }
    .xl-h-40px { height: 40px !important; }
    .xl-h-42px { height: 42px !important; }
    .xl-h-50px { height: 50px !important; }
    .xl-h-60px { height: 60px !important; }
    .xl-h-70px { height: 70px !important; }
    .xl-h-80px { height: 80px !important; }
    .xl-h-90px { height: 90px !important; }
    .xl-h-100px { height: 100px !important; }
    .xl-h-110px { height: 110px !important; }
    .xl-h-120px { height: 120px !important; }
    .xl-h-130px { height: 130px !important; }
    .xl-h-140px { height: 140px !important; }
    .xl-h-150px { height: 150px !important; }
    .xl-h-160px { height: 160px !important; }
    .xl-h-170px { height: 170px !important; }
    .xl-h-180px { height: 180px !important; }
    .xl-h-190px { height: 190px !important; }
    .xl-h-200px { height: 200px !important; }
    .xl-h-250px { height: 250px !important; }
    .xl-h-300px { height: 300px !important; }
    .xl-h-350px { height: 350px !important; }
    .xl-h-400px { height: 400px !important; }
    .xl-h-450px { height: 450px !important; }
    .xl-h-500px { height: 500px !important; }
    .xl-h-520px { height: 520px !important; }
    .xl-h-550px { height: 550px !important; }
    .xl-h-580px { height: 580px !important; }
    .xl-h-600px { height: 600px !important; }
    .xl-h-650px { height: 650px !important; }
    .xl-h-700px { height: 700px !important; }
    .xl-h-720px { height: 720px !important; }
    .xl-h-750px { height: 750px !important; }
    .xl-h-800px { height: 800px !important; }
    .xl-h-820px { height: 820px !important; }
    .xl-h-830px { height: 830px !important; }
    .xl-h-850px { height: 850px !important; }
    .xl-h-50 { height: 50% !important; }
    .xl-h-90 { height: 90% !important; }
    .xl-h-100 { height: 100% !important; }
    .xl-h-auto { height:auto !important; }        

    /* min-height */
    .xl-min-h-100px { min-height: 100px; }
    .xl-min-h-200px { min-height: 200px; }
    .xl-min-h-300px { min-height: 300px; }
    .xl-min-h-400px { min-height: 400px; }
    .xl-min-h-500px { min-height: 500px; }
    .xl-min-h-600px { min-height: 600px; }
    .xl-min-h-700px { min-height: 700px; }
}

@media (max-width: 1199px) {
    .lg-h-1px { height: 1px !important; }
    .lg-h-2px { height: 2px !important; }
    .lg-h-3px { height: 3px !important; }
    .lg-h-4px { height: 4px !important; }
    .lg-h-5px { height: 5px !important; }
    .lg-h-6px { height: 6px !important; }
    .lg-h-7px { height: 7px !important; }
    .lg-h-8px { height: 8px !important; }
    .lg-h-9px { height: 9px !important; }
    .lg-h-10px { height: 10px !important; }
    .lg-h-20px { height: 20px !important; }
    .lg-h-30px { height: 30px !important; }
    .lg-h-40px { height: 40px !important; }
    .lg-h-42px { height: 42px !important; }
    .lg-h-50px { height: 50px !important; }
    .lg-h-60px { height: 60px !important; }
    .lg-h-70px { height: 70px !important; }
    .lg-h-80px { height: 80px !important; }
    .lg-h-90px { height: 90px !important; }
    .lg-h-100px { height: 100px !important; }
    .lg-h-110px { height: 110px !important; }
    .lg-h-120px { height: 120px !important; }
    .lg-h-130px { height: 130px !important; }
    .lg-h-140px { height: 140px !important; }
    .lg-h-150px { height: 150px !important; }
    .lg-h-160px { height: 160px !important; }
    .lg-h-170px { height: 170px !important; }
    .lg-h-180px { height: 180px !important; }
    .lg-h-190px { height: 190px !important; }
    .lg-h-200px { height: 200px !important; }
    .lg-h-250px { height: 250px !important; }
    .lg-h-300px { height: 300px !important; }
    .lg-h-350px { height: 350px !important; }
    .lg-h-400px { height: 400px !important; }
    .lg-h-450px { height: 450px !important; }
    .lg-h-500px { height: 500px !important; }
    .lg-h-520px { height: 520px !important; }
    .lg-h-550px { height: 550px !important; }
    .lg-h-580px { height: 580px !important; }
    .lg-h-600px { height: 600px !important; }
    .lg-h-650px { height: 650px !important; }
    .lg-h-700px { height: 700px !important; }
    .lg-h-720px { height: 720px !important; }
    .lg-h-750px { height: 750px !important; }
    .lg-h-800px { height: 800px !important; }
    .lg-h-820px { height: 820px !important; }
    .lg-h-830px { height: 830px !important; }
    .lg-h-850px { height: 850px !important; }
    .lg-h-50 { height: 50% !important; }
    .lg-h-90 { height: 90% !important; }
    .lg-h-100 { height: 100% !important; }
    .lg-h-auto { height:auto !important; }

    /* min-height */
    .lg-min-h-100px { min-height: 100px; }
    .lg-min-h-200px { min-height: 200px; }
    .lg-min-h-300px { min-height: 300px; }
    .lg-min-h-400px { min-height: 400px; }
    .lg-min-h-500px { min-height: 500px; }
    .lg-min-h-600px { min-height: 600px; }
    .lg-min-h-700px { min-height: 700px; }

    /* screen height */
    .one-fifth-screen { height:750px; }
}

@media (max-width: 991px) {
     /* height */
    .md-h-1px { height: 1px !important; }
    .md-h-2px { height: 2px !important; }
    .md-h-3px { height: 3px !important; }
    .md-h-4px { height: 4px !important; }
    .md-h-5px { height: 5px !important; }
    .md-h-6px { height: 6px !important; }
    .md-h-7px { height: 7px !important; }
    .md-h-8px { height: 8px !important; }
    .md-h-9px { height: 9px !important; }
    .md-h-10px { height: 10px !important; }
    .md-h-20px { height: 20px !important; }
    .md-h-30px { height: 30px !important; }
    .md-h-40px { height: 40px !important; }
    .md-h-42px { height: 42px !important; }
    .md-h-50px { height: 50px !important; }
    .md-h-60px { height: 60px !important; }
    .md-h-70px { height: 70px !important; }
    .md-h-80px { height: 80px !important; }
    .md-h-90px { height: 90px !important; }
    .md-h-100px { height: 100px !important; }
    .md-h-110px { height: 110px !important; }
    .md-h-120px { height: 120px !important; }
    .md-h-130px { height: 130px !important; }
    .md-h-140px { height: 140px !important; }
    .md-h-150px { height: 150px !important; }
    .md-h-160px { height: 160px !important; }
    .md-h-170px { height: 170px !important; }
    .md-h-180px { height: 180px !important; }
    .md-h-190px { height: 190px !important; }
    .md-h-200px { height: 200px !important; }
    .md-h-250px { height: 250px !important; }
    .md-h-300px { height: 300px !important; }
    .md-h-350px { height: 350px !important; }
    .md-h-400px { height: 400px !important; }
    .md-h-450px { height: 450px !important; }
    .md-h-500px { height: 500px !important; }
    .md-h-520px { height: 520px !important; }
    .md-h-550px { height: 550px !important; }
    .md-h-580px { height: 580px !important; }
    .md-h-600px { height: 600px !important; }
    .md-h-650px { height: 650px !important; }
    .md-h-700px { height: 700px !important; }
    .md-h-720px { height: 720px !important; }
    .md-h-750px { height: 750px !important; }
    .md-h-800px { height: 800px !important; }
    .md-h-820px { height: 820px !important; }
    .md-h-830px { height: 830px !important; }
    .md-h-850px { height: 850px !important; }

    .md-h-50 { height: 50%; }
    .md-h-100 { height: 100% !important; }
    .md-h-auto { height:auto !important; }

    /* min-height */
    .md-min-h-100px { min-height: 100px; }
    .md-min-h-150px { min-height: 150px; }
    .md-min-h-200px { min-height: 200px; }
    .md-min-h-300px { min-height: 300px; }
    .md-min-h-400px { min-height: 400px; }
    .md-min-h-500px { min-height: 500px; }
    .md-min-h-600px { min-height: 600px; }
    .md-min-h-700px { min-height: 700px; }

    /* screen height */
    .one-third-screen { height:550px; }
    .one-fourth-screen { height:650px; }
    .one-fifth-screen { height:750px; }
}

@media only screen and (max-width: 991px) and (orientation: landscape) {

    .md-landscape-h-300px { height: 300px !important; }
    .md-landscape-h-350px { height: 350px !important; }
    .md-landscape-h-400px { height: 400px !important; }
    .md-landscape-h-450px { height: 450px !important; }
    .md-landscape-h-500px { height: 500px !important; }
    .md-landscape-h-520px { height: 520px !important; }
    .md-landscape-h-550px { height: 550px !important; }
    .md-landscape-h-580px { height: 580px !important; }
    .md-landscape-h-600px { height: 600px !important; }
    .md-landscape-h-650px { height: 650px !important; }
    .md-landscape-h-700px { height: 700px !important; }

}
@media (max-width: 767px) {
    .sm-h-1px { height: 1px !important; }
    .sm-h-2px { height: 2px !important; }
    .sm-h-3px { height: 3px !important; }
    .sm-h-4px { height: 4px !important; }
    .sm-h-5px { height: 5px !important; }
    .sm-h-6px { height: 6px !important; }
    .sm-h-7px { height: 7px !important; }
    .sm-h-8px { height: 8px !important; }
    .sm-h-9px { height: 9px !important; }
    .sm-h-10px { height: 10px !important; }
    .sm-h-20px { height: 20px !important; }
    .sm-h-30px { height: 30px !important; }
    .sm-h-40px { height: 40px !important; }
    .sm-h-42px { height: 42px !important; }
    .sm-h-50px { height: 50px !important; }
    .sm-h-60px { height: 60px !important; }
    .sm-h-70px { height: 70px !important; }
    .sm-h-80px { height: 80px !important; }
    .sm-h-90px { height: 90px !important; }
    .sm-h-100px { height: 100px !important; }
    .sm-h-110px { height: 110px !important; }
    .sm-h-120px { height: 120px !important; }
    .sm-h-130px { height: 130px !important; }
    .sm-h-140px { height: 140px !important; }
    .sm-h-150px { height: 150px !important; }
    .sm-h-160px { height: 160px !important; }
    .sm-h-170px { height: 170px !important; }
    .sm-h-180px { height: 180px !important; }
    .sm-h-190px { height: 190px !important; }
    .sm-h-200px { height: 200px !important; }
    .sm-h-250px { height: 250px !important; }
    .sm-h-300px { height: 300px !important; }
    .sm-h-350px { height: 350px !important; }
    .sm-h-400px { height: 400px !important; }
    .sm-h-450px { height: 450px !important; }
    .sm-h-500px { height: 500px !important; }
    .sm-h-520px { height: 520px !important; }
    .sm-h-550px { height: 550px !important; }
    .sm-h-580px { height: 580px !important; }
    .sm-h-600px { height: 600px !important; }
    .sm-h-650px { height: 650px !important; }
    .sm-h-700px { height: 700px !important; }
    .sm-h-720px { height: 720px !important; }
    .sm-h-750px { height: 750px !important; }
    .sm-h-800px { height: 800px !important; }
    .sm-h-820px { height: 820px !important; }
    .sm-h-830px { height: 830px !important; }
    .sm-h-850px { height: 850px !important; }

    .sm-h-50 { height: 50% !important; }
    .sm-h-100 { height: 100% !important; }
    .sm-h-auto { height:auto !important; }

    /* min-height */
    .sm-min-h-90px { min-height: 90px; }
    .sm-min-h-100px { min-height: 100px; }
    .sm-min-h-200px { min-height: 200px; }
    .sm-min-h-300px { min-height: 300px; }
    .sm-min-h-400px { min-height: 400px; }
    .sm-min-h-500px { min-height: 500px; }
    .sm-min-h-600px { min-height: 600px; }
    .sm-min-h-700px { min-height: 700px; }

    /* min-width */
    .sm-min-w-80px { min-width: 80px; }
   
    /* screen height */
    .small-screen { height: 280px; }

}

@media screen and (max-width: 575px) {
    /* height */
    .xs-h-1px { height: 1px !important; }
    .xs-h-2px { height: 2px !important; }
    .xs-h-3px { height: 3px !important; }
    .xs-h-4px { height: 4px !important; }
    .xs-h-5px { height: 5px !important; }
    .xs-h-6px { height: 6px !important; }
    .xs-h-7px { height: 7px !important; }
    .xs-h-8px { height: 8px !important; }
    .xs-h-9px { height: 9px !important; }
    .xs-h-10px { height: 10px !important; }
    .xs-h-20px { height: 20px !important; }
    .xs-h-30px { height: 30px !important; }
    .xs-h-40px { height: 40px !important; }
    .xs-h-42px { height: 42px !important; }
    .xs-h-50px { height: 50px !important; }
    .xs-h-60px { height: 60px !important; }
    .xs-h-70px { height: 70px !important; }
    .xs-h-80px { height: 80px !important; }
    .xs-h-90px { height: 90px !important; }
    .xs-h-100px { height: 100px !important; }
    .xs-h-110px { height: 110px !important; }
    .xs-h-120px { height: 120px !important; }
    .xs-h-130px { height: 130px !important; }
    .xs-h-140px { height: 140px !important; }
    .xs-h-150px { height: 150px !important; }
    .xs-h-160px { height: 160px !important; }
    .xs-h-170px { height: 170px !important; }
    .xs-h-180px { height: 180px !important; }
    .xs-h-190px { height: 190px !important; }
    .xs-h-200px { height: 200px !important; }
    .xs-h-250px { height: 250px !important; }
    .xs-h-300px { height: 300px !important; }
    .xs-h-350px { height: 350px !important; }
    .xs-h-400px { height: 400px !important; }
    .xs-h-450px { height: 450px !important; }
    .xs-h-500px { height: 500px !important; }
    .xs-h-520px { height: 520px !important; }
    .xs-h-550px { height: 550px !important; }
    .xs-h-580px { height: 580px !important; }
    .xs-h-600px { height: 600px !important; }
    .xs-h-650px { height: 650px !important; }
    .xs-h-700px { height: 700px !important; }
    .xs-h-720px { height: 720px !important; }
    .xs-h-750px { height: 750px !important; }
    .xs-h-800px { height: 800px !important; }
    .xs-h-820px { height: 820px !important; }
    .xs-h-830px { height: 830px !important; }
    .xs-h-850px { height: 850px !important; }

    .xs-h-50 { height: 50% !important; }
    .xs-h-100 { height: 100% !important; }
    .xs-h-auto { height:auto !important; }

    /* min-height */
    .xs-min-h-100px { min-height: 100px; }
    .xs-min-h-200px { min-height: 200px; }
    .xs-min-h-300px { min-height: 300px; }
    .xs-min-h-400px { min-height: 400px; }
    .xs-min-h-500px { min-height: 500px; }
    .xs-min-h-600px { min-height: 600px; }
    .xs-min-h-700px { min-height: 700px; }

    /* screen height */
    .small-screen { height: 280px; }
}


@media (max-height: 600px) {
    /* height */
    .full-screen-auto { height: auto !important; }
}

/* ===================================
    14. Width
====================================== */

.w-1px {
    width: 1px;
}
  
.w-2px {
    width: 2px;
}
  
.w-3px {
    width: 3px;
}
  
.w-4px {
    width: 4px;
}
  
.w-5px {
    width: 5px;
}
  
.w-6px {
    width: 6px;
}
  
.w-7px {
    width: 7px;
}
  
.w-8px {
    width: 8px;
}
  
.w-9px {
    width: 9px;
}
  
.w-10px {
    width: 10px;
}
  
.w-15px {
    width: 15px;
}
  
.w-20px {
    width: 20px;
}
  
.w-25px {
    width: 25px;
}
  
.w-30px {
    width: 30px;
}
  
.w-35px {
    width: 35px;
}
  
.w-40px {
    width: 40px;
}
  
.w-45px {
    width: 45px;
}
  
.w-50px {
    width: 50px;
}
  
.w-55px {
    width: 55px;
}
  
.w-60px {
    width: 60px;
}
  
.w-65px {
    width: 65px;
}
  
.w-70px {
    width: 70px;
}
  
.w-75px {
    width: 75px;
}
  
.w-80px {
    width: 80px;
}
  
.w-85px {
    width: 85px;
}
  
.w-90px {
    width: 90px;
}
  
.w-95px {
    width: 95px;
}
  
.w-100px {
    width: 100px;
}
  
.w-110px {
    width: 110px;
}
  
.w-120px {
    width: 120px;
}
  
.w-130px {
    width: 130px;
}
  
.w-140px {
    width: 140px;
}
  
.w-150px {
    width: 150px;
}
  
.w-160px {
    width: 160px;
}
  
.w-170px {
    width: 170px;
}
  
.w-180px {
    width: 180px;
}
  
.w-190px {
    width: 190px;
}
  
.w-200px {
    width: 200px;
}
  
.w-250px {
    width: 250px;
}
  
.w-300px {
    width: 300px;
}
  
.w-350px {
    width: 350px;
}
  
.w-400px {
    width: 400px;
}
  
.w-450px {
    width: 450px;
}
  
.w-500px {
    width: 500px;
}
  
.w-550px {
    width: 550px;
}
  
.w-600px {
    width: 600px;
}
  
.w-650px {
    width: 650px;
}
  
.w-700px {
    width: 700px;
}
  
.w-750px {
    width: 750px;
}
  
.w-800px {
    width: 800px;
}
  
.w-850px {
    width: 850px;
}
  
.w-900px {
    width: 900px;
}
  
.w-950px {
    width: 950px;
}
  
.w-1000px {
    width: 1000px;
}
  
.w-10 {
    width: 10%;
}
  
.w-15 {
    width: 15%;
}
  
.w-20 {
    width: 20%;
}
  
.w-25 {
    width: 25%;
}
  
.w-30 {
    width: 30%;
}
  
.w-35 {
    width: 35%;
}
  
.w-40 {
    width: 40%;
}
  
.w-45 {
    width: 45%;
}
  
.w-50 {
    width: 50%;
}
  
.w-55 {
    width: 55%;
}
  
.w-57 {
    width: 57%;
}
  
.w-60 {
    width: 60%;
}
  
.w-65 {
    width: 65%;
}
  
.w-70 {
    width: 70%;
}
  
.w-75 {
    width: 75%;
}
  
.w-80 {
    width: 80%;
}
  
.w-85 {
    width: 85%;
}
  
.w-90 {
    width: 90%;
}
  
.w-95 {
    width: 95%;
}
  
.w-100 {
    width: 100%;
}

.w-100-mobile {
    @media (max-width: 768px) {
      width: 100%;
    }
}
  
  
.w-auto {
    width: auto;
}

@media (max-width: 1600px) {
    /* width */
    .xl-w-1px { width:1px !important; }
    .xl-w-2px { width:2px !important; }
    .xl-w-3px { width:3px !important; }
    .xl-w-4px { width:4px !important; }
    .xl-w-5px { width:5px !important; }
    .xl-w-6px { width:6px !important; }
    .xl-w-7px { width:7px !important; }
    .xl-w-8px { width:8px !important; }
    .xl-w-9px { width:9px !important; }
    .xl-w-10px { width:10px !important; }
    .xl-w-15px { width:15px !important; }
    .xl-w-20px { width:20px !important; }
    .xl-w-25px { width:25px !important; }
    .xl-w-30px { width:30px !important; }
    .xl-w-35px { width:35px !important; }
    .xl-w-40px { width:40px !important; }
    .xl-w-50px { width:50px !important; }
    .xl-w-55px { width:55px !important; }
    .xl-w-60px { width:60px !important; }
    .xl-w-65px { width:65px !important; }
    .xl-w-70px { width:70px !important; }
    .xl-w-75px { width:75px !important; }
    .xl-w-80px { width:80px !important; }
    .xl-w-85px { width:85px !important; }
    .xl-w-90px { width:90px !important; }
    .xl-w-95px { width:95px !important; }
    .xl-w-100px { width:100px !important; }
    .xl-w-110px { width:110px !important; }
    .xl-w-120px { width:120px !important; }
    .xl-w-130px { width:130px !important; }
    .xl-w-140px { width:140px !important; }
    .xl-w-150px { width:150px !important; }
    .xl-w-160px { width:160px !important; }
    .xl-w-170px { width:170px !important; }
    .xl-w-180px { width:180px !important; }
    .xl-w-190px { width:190px !important; }
    .xl-w-200px { width:200px !important; }
    .xl-w-250px { width:250px !important; }
    .xl-w-300px { width:300px !important; }
    .xl-w-350px { width:350px !important; }
    .xl-w-400px { width:400px !important; }
    .xl-w-450px { width:450px !important; }
    .xl-w-500px { width:500px !important; }
    .xl-w-550px { width:550px !important; }
    .xl-w-600px { width:600px !important; }
    .xl-w-650px { width:650px !important; }
    .xl-w-700px { width:700px !important; }
    .xl-w-750px { width:750px !important; }
    .xl-w-800px { width:800px !important; }
    .xl-w-850px { width:850px !important; }
    .xl-w-900px { width:900px !important; }
    .xl-w-950px { width:950px !important; }
    .xl-w-1000px { width:1000px !important; }
    .xl-w-10 { width: 10% !important; }
    .xl-w-15 { width: 15% !important; }
    .xl-w-20 { width: 20% !important; }
    .xl-w-25 { width: 25% !important; }
    .xl-w-30 { width: 30% !important; }
    .xl-w-35 { width: 35% !important; }
    .xl-w-40 { width: 40% !important; }
    .xl-w-45 { width: 45% !important; }
    .xl-w-50 { width: 50% !important; }
    .xl-w-55 { width: 55% !important; }
    .xl-w-60 { width: 60% !important; }
    .xl-w-65 { width: 65% !important; }
    .xl-w-70 { width: 70% !important; }
    .xl-w-75 { width: 75% !important; }
    .xl-w-80 { width: 80% !important; }
    .xl-w-85 { width: 85% !important; }
    .xl-w-90 { width: 90% !important; }
    .xl-w-95 { width: 95% !important; }
    .xl-w-100 { width: 100% !important; }
    .xl-w-auto { width:auto !important; }
}

@media (max-width: 1199px) {
    /* width */
    .lg-w-1px { width:1px !important; }
    .lg-w-2px { width:2px !important; }
    .lg-w-3px { width:3px !important; }
    .lg-w-4px { width:4px !important; }
    .lg-w-5px { width:5px !important; }
    .lg-w-6px { width:6px !important; }
    .lg-w-7px { width:7px !important; }
    .lg-w-8px { width:8px !important; }
    .lg-w-9px { width:9px !important; }
    .lg-w-10px { width:10px !important; }
    .lg-w-15px { width:15px !important; }
    .lg-w-20px { width:20px !important; }
    .lg-w-25px { width:25px !important; }
    .lg-w-30px { width:30px !important; }
    .lg-w-35px { width:35px !important; }
    .lg-w-40px { width:40px !important; }
    .lg-w-50px { width:50px !important; }
    .lg-w-55px { width:55px !important; }
    .lg-w-60px { width:60px !important; }
    .lg-w-65px { width:65px !important; }
    .lg-w-70px { width:70px !important; }
    .lg-w-75px { width:75px !important; }
    .lg-w-80px { width:80px !important; }
    .lg-w-85px { width:85px !important; }
    .lg-w-90px { width:90px !important; }
    .lg-w-95px { width:95px !important; }
    .lg-w-100px { width:100px !important; }
    .lg-w-110px { width:110px !important; }
    .lg-w-120px { width:120px !important; }
    .lg-w-130px { width:130px !important; }
    .lg-w-140px { width:140px !important; }
    .lg-w-150px { width:150px !important; }
    .lg-w-160px { width:160px !important; }
    .lg-w-170px { width:170px !important; }
    .lg-w-180px { width:180px !important; }
    .lg-w-190px { width:190px !important; }
    .lg-w-200px { width:200px !important; }
    .lg-w-250px { width:250px !important; }
    .lg-w-300px { width:300px !important; }
    .lg-w-350px { width:350px !important; }
    .lg-w-400px { width:400px !important; }
    .lg-w-450px { width:450px !important; }
    .lg-w-500px { width:500px !important; }
    .lg-w-550px { width:550px !important; }
    .lg-w-600px { width:600px !important; }
    .lg-w-650px { width:650px !important; }
    .lg-w-700px { width:700px !important; }
    .lg-w-750px { width:750px !important; }
    .lg-w-800px { width:800px !important; }
    .lg-w-850px { width:850px !important; }
    .lg-w-900px { width:900px !important; }
    .lg-w-950px { width:950px !important; }
    .lg-w-1000px { width:1000px !important; }
    .lg-w-10 { width: 10% !important; }
    .lg-w-15 { width: 15% !important; }
    .lg-w-20 { width: 20% !important; }
    .lg-w-25 { width: 25% !important; }
    .lg-w-30 { width: 30% !important; }
    .lg-w-35 { width: 35% !important; }
    .lg-w-40 { width: 40% !important; }
    .lg-w-45 { width: 45% !important; }
    .lg-w-50 { width: 50% !important; }
    .lg-w-55 { width: 55% !important; }
    .lg-w-60 { width: 60% !important; }
    .lg-w-65 { width: 65% !important; }
    .lg-w-70 { width: 70% !important; }
    .lg-w-75 { width: 75% !important; }
    .lg-w-80 { width: 80% !important; }
    .lg-w-85 { width: 85% !important; }
    .lg-w-90 { width: 90% !important; }
    .lg-w-95 { width: 95% !important; }
    .lg-w-100 { width: 100% !important; }
    .lg-w-auto { width:auto !important; }
}

@media (max-width: 991px) {
    .md-w-1px { width:1px !important; }
    .md-w-2px { width:2px !important; }
    .md-w-3px { width:3px !important; }
    .md-w-4px { width:4px !important; }
    .md-w-5px { width:5px !important; }
    .md-w-6px { width:6px !important; }
    .md-w-7px { width:7px !important; }
    .md-w-8px { width:8px !important; }
    .md-w-9px { width:9px !important; }
    .md-w-10px { width:10px !important; }
    .md-w-15px { width:15px !important; }
    .md-w-20px { width:20px !important; }
    .md-w-25px { width:25px !important; }
    .md-w-30px { width:30px !important; }
    .md-w-35px { width:35px !important; }
    .md-w-40px { width:40px !important; }
    .md-w-50px { width:50px !important; }
    .md-w-55px { width:55px !important; }
    .md-w-60px { width:60px !important; }
    .md-w-65px { width:65px !important; }
    .md-w-70px { width:70px !important; }
    .md-w-75px { width:75px !important; }
    .md-w-80px { width:80px !important; }
    .md-w-85px { width:85px !important; }
    .md-w-90px { width:90px !important; }
    .md-w-95px { width:95px !important; }
    .md-w-100px { width:100px !important; }
    .md-w-110px { width:110px !important; }
    .md-w-120px { width:120px !important; }
    .md-w-130px { width:130px !important; }
    .md-w-140px { width:140px !important; }
    .md-w-150px { width:150px !important; }
    .md-w-160px { width:160px !important; }
    .md-w-170px { width:170px !important; }
    .md-w-180px { width:180px !important; }
    .md-w-190px { width:190px !important; }
    .md-w-200px { width:200px !important; }
    .md-w-250px { width:250px !important; }
    .md-w-300px { width:300px !important; }
    .md-w-350px { width:350px !important; }
    .md-w-400px { width:400px !important; }
    .md-w-450px { width:450px !important; }
    .md-w-500px { width:500px !important; }
    .md-w-550px { width:550px !important; }
    .md-w-600px { width:600px !important; }
    .md-w-650px { width:650px !important; }
    .md-w-700px { width:700px !important; }
    .md-w-750px { width:750px !important; }
    .md-w-800px { width:800px !important; }
    .md-w-850px { width:850px !important; }
    .md-w-900px { width:900px !important; }
    .md-w-950px { width:950px !important; }
    .md-w-1000px { width:1000px !important; }
    .md-w-10 { width: 10% !important; }
    .md-w-15 { width: 15% !important; }
    .md-w-20 { width: 20% !important; }
    .md-w-25 { width: 25% !important; }
    .md-w-30 { width: 30% !important; }
    .md-w-35 { width: 35% !important; }
    .md-w-40 { width: 40% !important; }
    .md-w-45 { width: 45% !important; }
    .md-w-50 { width: 50% !important; }
    .md-w-55 { width: 55% !important; }
    .md-w-60 { width: 60% !important; }
    .md-w-65 { width: 65% !important; }
    .md-w-70 { width: 70% !important; }
    .md-w-75 { width: 75% !important; }
    .md-w-80 { width: 80% !important; }
    .md-w-85 { width: 85% !important; }
    .md-w-90 { width: 90% !important; }
    .md-w-95 { width: 95% !important; }
    .md-w-100 { width: 100% !important; }
    .md-w-auto { width:auto !important; }
}

@media (max-width: 767px) {
    /* width */
    .sm-w-1px { width:1px !important; }
    .sm-w-2px { width:2px !important; }
    .sm-w-3px { width:3px !important; }
    .sm-w-4px { width:4px !important; }
    .sm-w-5px { width:5px !important; }
    .sm-w-6px { width:6px !important; }
    .sm-w-7px { width:7px !important; }
    .sm-w-8px { width:8px !important; }
    .sm-w-9px { width:9px !important; }
    .sm-w-10px { width:10px !important; }
    .sm-w-15px { width:15px !important; }
    .sm-w-20px { width:20px !important; }
    .sm-w-25px { width:25px !important; }
    .sm-w-30px { width:30px !important; }
    .sm-w-35px { width:35px !important; }
    .sm-w-40px { width:40px !important; }
    .sm-w-50px { width:50px !important; }
    .sm-w-55px { width:55px !important; }
    .sm-w-60px { width:60px !important; }
    .sm-w-65px { width:65px !important; }
    .sm-w-70px { width:70px !important; }
    .sm-w-75px { width:75px !important; }
    .sm-w-80px { width:80px !important; }
    .sm-w-85px { width:85px !important; }
    .sm-w-90px { width:90px !important; }
    .sm-w-95px { width:95px !important; }
    .sm-w-100px { width:100px !important; }
    .sm-w-110px { width:110px !important; }
    .sm-w-120px { width:120px !important; }
    .sm-w-130px { width:130px !important; }
    .sm-w-140px { width:140px !important; }
    .sm-w-150px { width:150px !important; }
    .sm-w-160px { width:160px !important; }
    .sm-w-170px { width:170px !important; }
    .sm-w-180px { width:180px !important; }
    .sm-w-190px { width:190px !important; }
    .sm-w-200px { width:200px !important; }
    .sm-w-250px { width:250px !important; }
    .sm-w-300px { width:300px !important; }
    .sm-w-350px { width:350px !important; }
    .sm-w-400px { width:400px !important; }
    .sm-w-450px { width:450px !important; }
    .sm-w-500px { width:500px !important; }
    .sm-w-550px { width:550px !important; }
    .sm-w-600px { width:600px !important; }
    .sm-w-650px { width:650px !important; }
    .sm-w-700px { width:700px !important; }
    .sm-w-750px { width:750px !important; }
    .sm-w-800px { width:800px !important; }
    .sm-w-850px { width:850px !important; }
    .sm-w-900px { width:900px !important; }
    .sm-w-950px { width:950px !important; }
    .sm-w-1000px { width:1000px !important; }
    .sm-w-10 { width: 10% !important; }
    .sm-w-15 { width: 15% !important; }
    .sm-w-20 { width: 20% !important; }
    .sm-w-25 { width: 25% !important; }
    .sm-w-30 { width: 30% !important; }
    .sm-w-35 { width: 35% !important; }
    .sm-w-40 { width: 40% !important; }
    .sm-w-45 { width: 45% !important; }
    .sm-w-50 { width: 50% !important; }
    .sm-w-55 { width: 55% !important; }
    .sm-w-60 { width: 60% !important; }
    .sm-w-65 { width: 65% !important; }
    .sm-w-70 { width: 70% !important; }
    .sm-w-75 { width: 75% !important; }
    .sm-w-80 { width: 80% !important; }
    .sm-w-85 { width: 85% !important; }
    .sm-w-90 { width: 90% !important; }
    .sm-w-95 { width: 95% !important; }
    .sm-w-100 { width: 100% !important; }
    .sm-w-auto { width:auto !important; }
}

@media screen and (max-width: 575px) {
    /* width */
    .xs-w-1px { width:1px !important; }
    .xs-w-2px { width:2px !important; }
    .xs-w-3px { width:3px !important; }
    .xs-w-4px { width:4px !important; }
    .xs-w-5px { width:5px !important; }
    .xs-w-6px { width:6px !important; }
    .xs-w-7px { width:7px !important; }
    .xs-w-8px { width:8px !important; }
    .xs-w-9px { width:9px !important; }
    .xs-w-10px { width:10px !important; }
    .xs-w-15px { width:15px !important; }
    .xs-w-20px { width:20px !important; }
    .xs-w-25px { width:25px !important; }
    .xs-w-30px { width:30px !important; }
    .xs-w-35px { width:35px !important; }
    .xs-w-40px { width:40px !important; }
    .xs-w-50px { width:50px !important; }
    .xs-w-55px { width:55px !important; }
    .xs-w-60px { width:60px !important; }
    .xs-w-65px { width:65px !important; }
    .xs-w-70px { width:70px !important; }
    .xs-w-75px { width:75px !important; }
    .xs-w-80px { width:80px !important; }
    .xs-w-85px { width:85px !important; }
    .xs-w-90px { width:90px !important; }
    .xs-w-95px { width:95px !important; }
    .xs-w-100px { width:100px !important; }
    .xs-w-110px { width:110px !important; }
    .xs-w-120px { width:120px !important; }
    .xs-w-130px { width:130px !important; }
    .xs-w-140px { width:140px !important; }
    .xs-w-150px { width:150px !important; }
    .xs-w-160px { width:160px !important; }
    .xs-w-170px { width:170px !important; }
    .xs-w-180px { width:180px !important; }
    .xs-w-190px { width:190px !important; }
    .xs-w-200px { width:200px !important; }
    .xs-w-250px { width:250px !important; }
    .xs-w-300px { width:300px !important; }
    .xs-w-350px { width:350px !important; }
    .xs-w-400px { width:400px !important; }
    .xs-w-450px { width:450px !important; }
    .xs-w-500px { width:500px !important; }
    .xs-w-550px { width:550px !important; }
    .xs-w-600px { width:600px !important; }
    .xs-w-650px { width:650px !important; }
    .xs-w-700px { width:700px !important; }
    .xs-w-750px { width:750px !important; }
    .xs-w-800px { width:800px !important; }
    .xs-w-850px { width:850px !important; }
    .xs-w-900px { width:900px !important; }
    .xs-w-950px { width:950px !important; }
    .xs-w-1000px { width:1000px !important; }
    .xs-w-10 { width: 10% !important; }
    .xs-w-15 { width: 15% !important; }
    .xs-w-20 { width: 20% !important; }
    .xs-w-25 { width: 25% !important; }
    .xs-w-30 { width: 30% !important; }
    .xs-w-35 { width: 35% !important; }
    .xs-w-40 { width: 40% !important; }
    .xs-w-45 { width: 45% !important; }
    .xs-w-50 { width: 50% !important; }
    .xs-w-55 { width: 55% !important; }
    .xs-w-60 { width: 60% !important; }
    .xs-w-65 { width: 65% !important; }
    .xs-w-70 { width: 70% !important; }
    .xs-w-75 { width: 75% !important; }
    .xs-w-80 { width: 80% !important; }
    .xs-w-85 { width: 85% !important; }
    .xs-w-90 { width: 90% !important; }
    .xs-w-95 { width: 95% !important; }
    .xs-w-100 { width: 100% !important; }
    .xs-w-auto { width:auto !important; }
}
/* Backgrounds */

/* ===================================
    04. Background color
====================================== */

.bg-black{
    background-color: $black;
}
.bg-white{
    background-color: $white;
}
.bg-extra-dark-gray {
    background-color: #232323;
}

.bg-medium-gray {
    background-color: #EFEFEF;
}
  
.bg-extra-light-gray {
    background-color: #cfcfcf;
}
  
.bg-medium-light-gray {
    background-color: #ededed;
}
  
.bg-very-light-gray {
    background-color: #fafafa;
}
  
.bg-purple {
    background-color: $purple;
}

.bg-green {
    background-color: $green;
}
.bg-light-green{
    background-color: $light-green;
}
.bg-pink {
    background-color: $pink;
}
  
.bg-transparent {
    background-color: transparent;
}

.bg-extra-dark-gray-opacity {
    background-color: rgba(35, 35, 35, 0.8);
    @media (max-width: 500px) {
        background-color: rgba(35, 35, 35, 1);
    }
}
  
/* background gradient color */

.bg-gradient-nearshore {
    background-image: linear-gradient(to right top, #4D00F0, #4713EC, #2977D9, #12C0CB, #05EDC3, #00FFC0);
}

.bg-gradiant-menu {
    background: linear-gradient( 88deg,#4D00F0 60%, #4713EC, #2977D9, #12C0CB, #05EDC3, #00FFC0);
}

.bg-gradient-nearshore-small {
    background: linear-gradient( 88deg,#4D00F0, #4713EC, #2977D9, #12C0CB, #05EDC3, #00FFC0);
}

.cover-background {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    overflow: hidden;
    position: relative;
   background-position: center top !important;
}

/* background parallax */

.parallax {
    position: relative !important;
    background-size: cover !important;
    overflow: hidden;
    background-attachment: fixed !important;
    transition-duration: 0s;
    -moz-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    -o-transition-duration: 0s;
}

.parallax-fix {
    background-attachment: fixed !important;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover !important;
    overflow: hidden;
    position: relative;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
}

/* Background elements */
.backgound-about {
    position: absolute;
    left: -2%;
    -webkit-transform: translateX(0%) translateY(-40%);
    transform: translateX(0%) translateY(-40%);
    @media (max-width: 1300px) {
        display: none!important;
    }
    @media (min-width: 1400px) {
        left: 0%;
    }
    
}

.background-vid {
    position: absolute;
    left: 15%;
    -webkit-transform: translateX(-6%) translateY(-21px);
    transform: translateX(-6%) translateY(-21px);
    @media (min-width:992px) and (max-width:1199px) {
        -webkit-transform: translateX(15%) translateY(-21px);
        transform: translateX(15%) translateY(-21px);
    }
    @media (max-width: 991px) {
        -webkit-transform: translateX(-6%) translateY(-51px);
        transform: translateX(-6%) translateY(-51px);
        left: 20%;
    }
}

.backgound-top-right {
    position: absolute;
    right: 15%;
    top: -11.5%;
    @media (max-width: 1624px) {
        right:5%;
    }
}

.backgound-top-right-2 {
    position: absolute;
    right: 15%;
    top: -94.5%;
    z-index:10;
    @media (max-width: 1624px) {
        right:5%;
    }
}

.background-top-bottom{
    position: relative;
    z-index:10;
    .container{
        @media(min-width:768px){
            &::after{
                content: url('../../assets/images/decoration-elements/element-bottom.svg');
                bottom: -86px;
                float: right;
                z-index: 10;
                position: relative;
                @media(max-width:991px){
                    bottom: -47px;
                }
            }
        }
    }
    @media(min-width:768px){
        &:after{
            content: " ";
            width: 100%;
            height: 80px;
            position: absolute;
            bottom: 0;
            background-color: $white;
            z-index:0;
        }
    }
}

.backgound-fututo {
    position: absolute;
    right: 15%;
    bottom: 10%;
    @media (max-width: 1024px) {
        right: 30%;
    }
}

.background-policy {
    margin-top: 30px;
    position: relative;
    left: 10%;
    bottom: 0;
    @media (min-width: 1024px) {
        left: 15%;
    }
    @media (min-width: 1600px) {
        left: 25%;
    }
}
.stack-section{
    .add-element-bottom{
        &:last-child{
            position: relative;
            @media(min-width:992px){
                &::after{
                    content: url('../images/decoration-elements/graf-stack.svg');
                    right: 15px;
                    bottom: -100px;
                    position: absolute;
                }
            }
        }
    }
    img{
        max-height: 100px;
        max-width: 100%;
        @media(max-width:767px){
            max-height: 50px;
            max-width: 120px;
        }
        @media(min-width:768px) and (max-width:991px){
            max-height: 70px;
            max-width: 140px;
        }
    }
}
.faqs-section{
    .container{
        @media(min-width:992px){
            position: relative;
            margin-top: 70px;
            &::before{
                content: url('../images/decoration-elements/element-faqs.svg');
                right: 0px;
                top: -120px;
                position: absolute;
            }
        }
    }
}


/* Images */
img {
    max-width: 100%;
    height: auto;
}

/* Videos */

/* video icons */

.video-icon-box {
    z-index: 1;
    cursor: pointer;
    > span {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-flow: row wrap;
        -ms-flex-direction: row wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
  
    .video-icon {
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
  
        i {
            margin-left: 5px;
            line-height: normal;
        }
    }
}

.video-icon-large{
    &.video-icon-box {
        .video-icon{
            width: 75px;
            height: 75px;
            font-size: 26px;
            @media (max-width: 767px) {
                width: 45px;
                height: 45px;
                font-size: 18px;
            }
            i {
                margin-left: 8px;
            }
            .video-icon-sonar {
                .video-icon-sonar-bfr {
                    width: 125px;
                    height: 125px;
                    @media (max-width: 767px) {
                        width: 95px;
                        height: 95px;
                    }
                }
                .video-icon-sonar-afr {
                    width: 95px;
                    height: 95px;
                    @media (max-width: 767px) {
                        width: 65px;
                        height: 65px;
                    }
                }
            }
        }
    }
}

/*Mission section*/
.video-icon{
    .video-icon-sonar {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
        z-index: -10;
        -webkit-animation: video-icon-sonar 2s linear infinite;
        animation: video-icon-sonar 2s linear infinite;
        .video-icon-sonar-bfr {
            position: absolute;
            border-radius: 50%;
            content: '';
            z-index: -100;
            width: 65px;
            height: 65px;
            @media(min-width: 768px){
                top: -25px;
                left: -25px;
                width: 110px;
                height: 110px;
            }
            @media(min-width: 1024px){
                width: 120px;
                height: 120px;
            }
        }
      
        // .video-icon-sonar-afr {
        //     position: absolute;
        //     border-radius: 50%;
        //     content: '';
        //     top: -10px;
        //     left: -10px;
        //     z-index: -50;
        // }
    }
} 


/*Responsive */
@media (max-width: 767px) {
    /* video icons */
    .video-icon .video-icon-sonar .video-icon-sonar-bfr { top: -10px; left: -10px; }
    .video-icon .video-icon-sonar .video-icon-sonar-afr { top: -15px; left: -15px; }
    .video-icon-large .video-icon .video-icon-sonar .video-icon-sonar-bfr { top: -25px; left: -25px; }
    .video-icon-large .video-icon .video-icon-sonar .video-icon-sonar-afr { top: -10px; left: -10px; }
    .video-icon-medium .video-icon .video-icon-sonar .video-icon-sonar-bfr { top: -25px; left: -25px; }
    .video-icon-medium .video-icon .video-icon-sonar .video-icon-sonar-afr { top: -10px; left: -10px; }
    .video-icon-extra-large .video-icon .video-icon-sonar .video-icon-sonar-afr { top: -10px; left: -10px; }

    /* video icon size */
    .video-icon-double-large .video-icon { width: 80px; height: 80px; font-size: 28px; }
    .video-icon-double-large.video-icon-box .video-icon i { margin-left: 5px; }
    .video-icon-double-large .video-icon .video-icon-sonar .video-icon-sonar-bfr { width: 140px; height: 140px; }
    .video-icon-double-large .video-icon .video-icon-sonar .video-icon-sonar-afr { width: 110px; height: 110px; }

}
/* header static overlay */
header{
    nav{
        &.navbar, &.navbar-static-overlay{
            position: fixed;
            width: 100%;
        }
    }
}
/* header logo */
header {
    .navbar{
        .navbar-brand {
            img {
                max-height: 36px;
            }
        }
    }
    .navbar{
        .navbar-brand{
            .default-logo{
                visibility: visible;
                opacity: 1;
                width: auto;
                transition-duration: 0.5s;
                -webkit-transition-duration: 0.5s;
                -moz-transition-duration: 0.5s;
                -ms-transition-duration: 0.5s;
                -o-transition-duration: 0.5s;
            }
        } 
    } 
}

.navbar {
    padding: 0;
    &.navbar-boxed {
        padding-left: 35px;
        padding-right: 35px;
    }
}

.navbar-brand {
    margin-right: 0;
    font-size: 0;
}


/* header push button */

.header-push-button, .header-button {
    display: inline-block;
    vertical-align: middle;
    font-size: 17px;

    &.social{
        i{
            padding: 7.5px;
            color: $white;
            font-size: 20px;
            line-height: 20px;
            width: 35px;
            height: 35px;
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            @media(max-width:575px){
                width: 30px;
                height: 30px;
                padding: 7.5px;
                font-size: 15px;
                line-height: 15px;
            }
        }
        border-radius: 50%;
        border: solid white 3px;
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        &:hover{
            border-color: $pink;
            i{
                color: $pink;
            }
        }
    }
    &.hover, &:hover{
        path{
            fill: $pink;
        }
    }
    svg{
        path{
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
        }
        position: relative;
        z-index: 5;
        cursor: pointer;
        @media(max-width:575px){
            width: 30px;
            height: 30px;
        }
    }
    &:not(:first-child){
        padding-left: 25px;
        @media screen and (max-width: 575px) {
            padding-left: 20px; 
        }
    }
}
 
.push-button {
    position: relative;
    width: 50px;
    height: 14px;
    display: block;
    top: 1px;
    span{
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }
    &:hover{
        span{
            background-color: $pink;
        }
    }
    > span {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 41px;
        height: 3px;
        background-color: #fff;
        border-radius: 0;
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        &:nth-child(1) {
            top: -2px;
            width: 29px;
        }
    
        &:nth-child(2), &:nth-child(3) {
            top: 8px;
        }
    
        &:nth-child(4) {
            top: 18px;
            width: 29px;
        }
    }
}
  
.push-button.alt-push-button-left > span:nth-child(2) {
    width: 22px;
}


/*RESPONSIVE*/

@media(max-width:991px){
    .navbar-expand-lg>.container-fluid{
        padding-right: 15px;
        padding-left: 15px;
    }
    .navbar{
        &.navbar-boxed { 
            padding-left: 0; 
            padding-right: 0; 
        }
    }
    
    /* header logo */
    header {
        .navbar{
            .navbar-brand{
                .mobile-logo{ 
                    visibility: visible; 
                    opacity: 1; 
                    width: auto;
                } 
            } 
        }
    }
}

@media(max-width: 767px){
    /* navigation */
    .navbar.navbar-boxed { padding-left: 0; padding-right: 0; }
    .navbar-expand-lg>.container-fluid { padding-right: 0; padding-left: 0; }
}


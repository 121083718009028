
/* RESPONSIVE */
@media(max-width:991px){
    .img-20-years{
        max-width: 350px;
    }
}

@media(max-width:767px){
    .home-freelancer{
        .title-extra-large-heavy { 
            line-height: 70px; 
        }
        .title-extra-large { 
            font-size: 65px; 
        }
        .home-freelancer-bg-img { 
            background: none !important; 
        }
    }  
    .img-20-years{
        max-width: 250px;
    }
}